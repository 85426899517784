import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import {
  anyMixScatterWin,
  centeredWildWin,
  specialBonusGambleFeature,
  specialBonusGambleFeatureAnyMix,
} from '../../config/gameRules';
import { slotData } from '../../config/slotData';
import { IBalance } from '../../global';
import { setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBalanceGql, getBetAmountGql } from '../../gql/query';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { countCoins } from '../../utils';
import Resources from '../../utils/resources';

import { IPayTableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number): number => {
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().slotSettings.globalCoinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userBalance } = useQuery<{ balance: IBalance }>(getBalanceGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const { currency } = userBalance!.balance;

  const { betAmount } = dataBet!;
  const { payTableData } = slotData;

  const LineWinData: IPayTableData[] = payTableData.lineWinData;
  const ScatterWinData: IPayTableData[] = payTableData.scatterWinData;

  // const cautionaryNoteData: IPayTableData[] = config.cautionaryNotePayTableData;

  return (
    <section>
      <h1 className={styles.title}>{t('infoTabPayTable')}</h1>
      <h2 className={styles.subtitle2}>{t('infoTabLineWin')}</h2>
      <div className={styles['paytable-list_1']}>
        {LineWinData.map(
          ({ combos, slug }) =>
            slug === ResourceTypes.symbolWl && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    {i18n.exists(`infoPayTable.${slug}`) && (
                      <p className={styles.caption}>{t(`infoPayTable.${slug}`, '')}</p>
                    )}
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                    <div className={styles.content}>
                      {combos.map((i) => (
                        <div key={i.pattern}>
                          <span className={styles.multiplier} data-combo={i.pattern}>
                            {i.pattern}{' '}
                          </span>
                          {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
      <div className={styles['paytable-list']}>
        {LineWinData.map(
          ({ combos, slug }) =>
            (slug === ResourceTypes.symbolA || slug === ResourceTypes.symbolB) && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                    <div className={styles.content}>
                      {combos.map((i) => (
                        <div key={i.pattern}>
                          <span className={styles.multiplier} data-combo={i.pattern}>
                            {i.pattern}{' '}
                          </span>
                          {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
      <div className={styles['paytable-list__title7']}>
        <div className={styles['img']}>
          {i18n.exists(`infoPayTable_Mix7`) && <p className={styles.caption}>{t(`infoPayTable_Mix7`, '')}</p>}
          <img draggable="false" alt={'symbolA'} src={Resources.getSource(ResourceTypes.symbolA)} />
          <img draggable="false" alt={'symbolB'} src={Resources.getSource(ResourceTypes.symbolB)} />
          <div className={styles.content}>
            <span className={styles.multiplier} data-combo={'x3'}>
              {'x3'}{' '}
            </span>
            {formatNumber({ currency, value: calcMultiplier(200, betAmount) })}
          </div>
        </div>
      </div>
      <div className={styles['paytable-list']}>
        {LineWinData.map(
          ({ combos, slug }) =>
            slug != ResourceTypes.symbolWl &&
            slug != ResourceTypes.symbolA &&
            slug != ResourceTypes.symbolB &&
            slug != ResourceTypes.symbolJ && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                    <div className={styles.content}>
                      {combos.map((i) => (
                        <div key={i.pattern}>
                          <span className={styles.multiplier} data-combo={i.pattern}>
                            {i.pattern}{' '}
                          </span>
                          {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
      <h2 className={styles.subtitle2}>{t('infoTabScatterWin')}</h2>
      <div className={styles['paytable-list__title7']}>
        <div className={styles['img']}>
          {i18n.exists(`infoPayTable_Mix7`) && <p className={styles.caption}>{t(`infoPayTable_Mix7`, '')}</p>}
          <img draggable="false" alt={'symbolA'} src={Resources.getSource(ResourceTypes.symbolA)} />
          <img draggable="false" alt={'symbolB'} src={Resources.getSource(ResourceTypes.symbolB)} />
          <div className={styles.content}>
            {anyMixScatterWin.combos.map((i) => (
              <div key={i.pattern}>
                <span className={styles.multiplier} data-combo={i.pattern}>
                  {i.pattern}{' '}
                </span>
                {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles['paytable-list']}>
        {ScatterWinData.map(
          ({ combos, slug }) =>
            slug != ResourceTypes.symbolWl &&
            slug != ResourceTypes.symbolA &&
            slug != ResourceTypes.symbolB &&
            slug != ResourceTypes.symbolJ && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                  </div>
                  <div className={styles.content}>
                    {combos.map((i) => (
                      <div key={i.pattern}>
                        <span className={styles.multiplier} data-combo={i.pattern}>
                          {i.pattern}{' '}
                        </span>
                        {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
      <h2 className={styles.subtitle2}>{t('infoTabCenteredWildWin')}</h2>
      <div className={styles['paytable-list_1']}>
        {centeredWildWin.map(
          ({ combos, slug }) =>
            slug === ResourceTypes.symbolWl && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                    <p
                      className={`${styles.p} ${styles.caption}`}
                      dangerouslySetInnerHTML={{
                        __html: t('infoPayTable_centerDesc'),
                      }}
                    />
                  </div>
                  <div className={styles.content}>
                    {combos.map((i) => (
                      <div key={i.pattern}>
                        <span className={styles.multiplier} data-combo={i.pattern}>
                          {i.pattern}{' '}
                        </span>
                        {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.content}></div>
              </React.Fragment>
            ),
        )}
      </div>
      <h2 className={styles.subtitle2}>{t('infoTabSpecialBonus')}</h2>
      <div className={styles['paytable-list_1']}>
        {specialBonusGambleFeature.map(
          ({ combos, slug }) =>
            slug === ResourceTypes.symbolWl && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    {i18n.exists(`infoPayTable.${slug}`) && (
                      <p className={styles.caption}>{t(`infoPayTable.${slug}`, '')}</p>
                    )}
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                    <div className={styles.content}>
                      {combos.map((i) => (
                        <div key={i.pattern}>
                          <span className={styles.multiplier} data-combo={i.pattern}>
                            {i.pattern}{' '}
                          </span>
                          {i.multiplier}
                          {'x '}
                          {t('gambleStake')}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
      <div className={styles['paytable-list']}>
        {specialBonusGambleFeature.map(
          ({ combos, slug }) =>
            (slug === ResourceTypes.symbolA || slug === ResourceTypes.symbolB) && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                    <div className={styles.content}>
                      {combos.map((i) => (
                        <div key={i.pattern}>
                          <span className={styles.multiplier} data-combo={i.pattern}>
                            {i.pattern}{' '}
                          </span>
                          {i.multiplier}
                          {'x '}
                          {t('gambleStake')}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
      <div className={styles['paytable-list__title7']}>
        <div className={styles['img']}>
          {i18n.exists(`infoPayTable_Mix7`) && <p className={styles.caption}>{t(`infoPayTable_Mix7`, '')}</p>}
          <img draggable="false" alt={'symbolA'} src={Resources.getSource(ResourceTypes.symbolA)} />
          <img draggable="false" alt={'symbolB'} src={Resources.getSource(ResourceTypes.symbolB)} />
          <div className={styles.content}>
            {specialBonusGambleFeatureAnyMix.combos.map((i) => (
              <div key={i.pattern}>
                <span className={styles.multiplier} data-combo={i.pattern}>
                  {i.pattern}{' '}
                </span>
                {i.multiplier}
                {'x '}
                {t('gambleStake')}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles['paytable-list']}>
        {specialBonusGambleFeature.map(
          ({ combos, slug }) =>
            slug != ResourceTypes.symbolWl &&
            slug != ResourceTypes.symbolA &&
            slug != ResourceTypes.symbolB &&
            slug != ResourceTypes.symbolJ && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                    <div className={styles.content}>
                      {combos.map((i) => (
                        <div key={i.pattern}>
                          <span className={styles.multiplier} data-combo={i.pattern}>
                            {i.pattern}{' '}
                          </span>
                          {i.multiplier}
                          {'x '}
                          {t('gambleStake')}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
    </section>
  );
};

export default PaytableComponent;
