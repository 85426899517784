import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { setGameMode } from '../../../gql/cache';
import { ResourceTypes } from '../../../resources.d';
import { isGambleMode } from '../../../utils';
import Animation from '../../animations/animation';
// import AnimationChain from '../../animations/animationChain';
// import AnimationGroup from '../../animations/animationGroup';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import ViewContainer from '../../components/container';
import {
  REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_X,
  REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_Y,
  REELS_BACKGROUND_BASEGAME_PORTRAIT_POS_X,
  REELS_BACKGROUND_BASEGAME_PORTRAIT_POS_Y,
  REELS_BACKGROUND_GAMBLE_LANDSCAPE_POS_X,
  REELS_BACKGROUND_GAMBLE_LANDSCAPE_POS_Y,
  REELS_BACKGROUND_GAMBLE_PORTRAIT_POS_X,
  REELS_BACKGROUND_GAMBLE_PORTRAIT_POS_Y,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOT_WIDTH,
  Z_INDEX_REELS_BACKGROUND,
  eventManager,
} from '../../config';

const reelFineAdjustment: number[] = [-12, 0, 15];

class ReelsBackgroundContainer extends ViewContainer {
  private reelBacks: PIXI.Sprite[] = [];

  private reelContainer: PIXI.Container[] = [];

  private isLandScape = true;

  // private animationChain: AnimationChain = new AnimationChain({ isLoop: true });
  // private duration = 200;

  constructor() {
    super();

    this.reelContainer = this.initReelBackground();
    // const fadeOutGroupFadeIn = new AnimationGroup();
    // const fadeOutGroupFadeOut = new AnimationGroup();

    // const fadeIn6 = this.getFadeAnimation(this.reelContainer[6]!, 0.2, this.duration, 1);
    // const fadeIn7 = this.getFadeAnimation(this.reelContainer[7]!, 0.2, this.duration, 1);
    // const fadeIn8 = this.getFadeAnimation(this.reelContainer[8]!, 0.2, this.duration, 1);

    // fadeOutGroupFadeIn.addAnimation(fadeIn6);
    // fadeOutGroupFadeIn.addAnimation(fadeIn7);
    // fadeOutGroupFadeIn.addAnimation(fadeIn8);

    // const fadeOut6 = this.getFadeAnimation(this.reelContainer[6]!, 1, this.duration, 0.2);
    // const fadeOut7 = this.getFadeAnimation(this.reelContainer[7]!, 1, this.duration, 0.2);
    // const fadeOut8 = this.getFadeAnimation(this.reelContainer[8]!, 1, this.duration, 0.2);

    // fadeOutGroupFadeOut.addAnimation(fadeOut6);
    // fadeOutGroupFadeOut.addAnimation(fadeOut7);
    // fadeOutGroupFadeOut.addAnimation(fadeOut8);

    // this.animationChain.appendAnimation(fadeOutGroupFadeIn);
    // this.animationChain.appendAnimation(fadeOutGroupFadeOut);
    // this.animationChain.addOnSkip(() => {
    //   this.alpha = 1;
    // });

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));

    this.position.set(REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_X, REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_Y);

    this.zIndex = Z_INDEX_REELS_BACKGROUND;
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      if (isGambleMode(setGameMode())) {
        this.position.set(REELS_BACKGROUND_GAMBLE_LANDSCAPE_POS_X, REELS_BACKGROUND_GAMBLE_LANDSCAPE_POS_Y);
      } else {
        this.position.set(REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_X, REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_Y);
      }
      this.isLandScape = true;
    } else {
      if (isGambleMode(setGameMode())) {
        this.position.set(REELS_BACKGROUND_GAMBLE_PORTRAIT_POS_X, REELS_BACKGROUND_GAMBLE_PORTRAIT_POS_Y);
      } else {
        this.position.set(REELS_BACKGROUND_BASEGAME_PORTRAIT_POS_X, REELS_BACKGROUND_BASEGAME_PORTRAIT_POS_Y);
      }
      this.isLandScape = false;
    }
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isGambleMode(settings.mode)) {
      // this.animationChain.isLoop = true;
      // this.animationChain.animations.forEach((animation) => {
      //   animation.duration = this.duration;
      // });
      // this.animationChain.start();

      this.reelContainer[0]!.visible = false;
      this.reelContainer[1]!.visible = false;
      this.reelContainer[2]!.visible = false;
      this.reelContainer[3]!.visible = false;
      this.reelContainer[5]!.visible = false;
      if (this.isLandScape) {
        this.position.set(REELS_BACKGROUND_GAMBLE_LANDSCAPE_POS_X, REELS_BACKGROUND_GAMBLE_LANDSCAPE_POS_Y);
      } else {
        this.position.set(REELS_BACKGROUND_GAMBLE_PORTRAIT_POS_X, REELS_BACKGROUND_GAMBLE_PORTRAIT_POS_Y);
      }
    } else {
      // this.animationChain.isLoop = false;
      // this.animationChain.allSkip();

      this.reelContainer[0]!.visible = true;
      this.reelContainer[1]!.visible = true;
      this.reelContainer[2]!.visible = true;
      this.reelContainer[3]!.visible = true;
      this.reelContainer[5]!.visible = true;
      this.position.set(312, SLOTS_CONTAINER_HEIGHT);
      this.visible = true;
      if (this.isLandScape) {
        this.position.set(REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_X, REELS_BACKGROUND_BASEGAME_LANDSCAPE_POS_Y);
      } else {
        this.position.set(REELS_BACKGROUND_BASEGAME_PORTRAIT_POS_X, REELS_BACKGROUND_BASEGAME_PORTRAIT_POS_Y);
      }
    }
  }

  private initReelBackground(): PIXI.Container[] {
    for (let i = 0; i < 9; i++) {
      const container = new PIXI.Container();
      const textureReel = PIXI.Texture.from(ResourceTypes.reelbase);
      const reelBase = new PIXI.Sprite(textureReel);
      this.reelBacks.push(reelBase);
      container.y = -881 + Math.floor(i / 3) * 260 + Math.floor(i / 3) * 12 + Math.floor(i / 3) * 4;
      container.x =
        Math.floor(i % 3) * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2 + reelFineAdjustment[Math.floor(i % 3)]! - 300; // - 15.5;
      container.addChild(reelBase);
      this.reelContainer.push(container);
      this.addChild(container);
    }
    return this.reelContainer;
  }

  private getFadeAnimation(object: PIXI.Container, alpha: number, duration: number, begin: number): Animation {
    const animation = new Tween({
      object: object,
      duration,
      propertyBeginValue: begin,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }
}

export default ReelsBackgroundContainer;
