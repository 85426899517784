export enum ResourceTypes {
  featureBanner = 'featureBanner',
  winBanner = 'winBanner',
  colorMap = 'colorMap',
  displacementMapRepeat = 'displacementMapRepeat',
  intro1 = 'intro1',
  intro2 = 'intro2',
  introBg = 'introBg',
  lightMap = 'lightMap',
  logo = 'logo',
  slotBg = 'slotBg',
  symbolA = 'symbolA',
  symbolABlur = 'symbolABlur',
  symbolB = 'symbolB',
  symbolBBlur = 'symbolBBlur',
  symbolC = 'symbolC',
  symbolCBlur = 'symbolCBlur',
  symbolD = 'symbolD',
  symbolDBlur = 'symbolDBlur',
  symbolE = 'symbolE',
  symbolEBlur = 'symbolEBlur',
  symbolF = 'symbolF',
  symbolFBlur = 'symbolFBlur',
  symbolG = 'symbolG',
  symbolGBlur = 'symbolGBlur',
  symbolH = 'symbolH',
  symbolHBlur = 'symbolHBlur',
  symbolI = 'symbolI',
  symbolIBlur = 'symbolIBlur',
  symbolJ = 'symbolJ',
  symbolWl = 'symbolWl',
  backdrop = 'backdrop',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  collect = 'collect',
  collectBtn = 'collectBtn',
  dealer = 'dealer',
  dividendTable = 'dividendTable',
  dividendTable2 = 'dividendTable2',
  gamble = 'gamble',
  gambleBtn = 'gambleBtn',
  gambleBtnframe = 'gambleBtnframe',
  highlow = 'highlow',
  infoPaylines = 'infoPaylines',
  player = 'player',
  reelbase = 'reelbase',
  reelframe = 'reelframe',
  textLogo = 'textLogo',
  titlelogo = 'titlelogo',
}
