import _ from 'lodash';

import { ISongs, audioSprite } from './sprite.generated';

export * from './sprite.generated';

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.SONG_030_01_BG_Loop]: 0.2,
  [ISongs.SONG_030_02_BG_Melo]: 0.2,
  [ISongs.SONG_030_03_FS_int]: 0.2,
  [ISongs.SONG_030_03_FS_Loop]: 0.2,
  [ISongs.SONG_030_04_TotalWin]: 0.3,
  [ISongs.SONG_030_05_Gamble_Loop]: 0.1,
  [ISongs.SONG_030_06_FS_Trigger]: 0.3,
  [ISongs.SONG_030_07_BuyButton]: 0.3,
  [ISongs.SONG_030_08_Win_Loop]: 0.3,
  [ISongs.SONG_030_09_Win_End]: 0.3,
  [ISongs.SONG_030_10_BigWin_Loop]: 0.3,
  [ISongs.SONG_030_11_BigWin_End]: 0.3,
  [ISongs.SONG_030_12_BigWin_Jingle]: 0.3,
  [ISongs.SONG_030_13_Wild_Move1_Loop]: 0.3,
  [ISongs.SONG_030_14_Wild_Move2_Loop]: 0.3,
  [ISongs.SONG_030_15_Wild_Stop1]: 0.3,
  [ISongs.SONG_030_16_Wild_Stop2]: 0.3,
  [ISongs.SONG_030_17_PressGamble]: 0.3,
  [ISongs.SONG_030_18_GambleWin]: 0.3,
  [ISongs.SONG_030_19_GamblePush]: 0.3,
  [ISongs.SONG_030_20_GambleSP]: 0.3,
  [ISongs.SONG_SE_Yocho_Phoenix]: 0.3,
  [ISongs.SONG_SFX_BuyFeature]: 0.3,
  [ISongs.SONG_SFX_SM_CountUp_End]: 0.3,
  [ISongs.SONG_SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SONG_SFX_UI_AutoSpin]: 0.3,
  [ISongs.SONG_SFX_UI_BetChange]: 0.3,
  [ISongs.SONG_SFX_UI_Close]: 0.3,
  [ISongs.SONG_SFX_UI_General]: 0.3,
  [ISongs.SONG_SFX_UI_Hover]: 0.3,
  [ISongs.SONG_SFX_UI_MaxBet]: 0.3,
  [ISongs.SONG_SFX_UI_MenuOpen]: 0.5,
  [ISongs.SONG_SFX_UI_SpinStart]: 0.3,
  [ISongs.SONG_SFX_UI_SpinStop]: 0.3,
  [ISongs.SONG_vo10000]: 0.9,
  [ISongs.SONG_vo10101]: 0.9,
  [ISongs.SONG_vo10102]: 0.9,
  [ISongs.SONG_vo10103]: 0.9,
  [ISongs.SONG_vo10104]: 0.9,
  [ISongs.SONG_vo10105]: 0.9,
  [ISongs.SONG_vo10106]: 0.9,
  [ISongs.SONG_vo10107]: 0.9,
  [ISongs.SONG_vo10108]: 0.9,
  [ISongs.SONG_vo10109]: 0.9,
  [ISongs.SONG_vo10110]: 0.9,
  [ISongs.SONG_vo10111]: 0.9,
  [ISongs.SONG_vo10112]: 0.9,
  [ISongs.SONG_vo10113]: 0.9,
  [ISongs.SONG_vo10201]: 0.9,
  [ISongs.SONG_vo10202]: 0.9,
  [ISongs.SONG_vo10203]: 0.9,
  [ISongs.SONG_vo10204]: 0.9,
  [ISongs.SONG_vo10205]: 0.9,
  [ISongs.SONG_vo10206]: 0.9,
  [ISongs.SONG_vo10207]: 0.9,
  [ISongs.SONG_vo10208]: 0.9,
  [ISongs.SONG_vo10209]: 0.9,
  [ISongs.SONG_vo10210]: 0.9,
  [ISongs.SONG_vo10211]: 0.9,
  [ISongs.SONG_vo10212]: 0.9,
  [ISongs.SONG_vo10213]: 0.9,
  [ISongs.SONG_vo10214]: 0.9,
  [ISongs.SONG_vo10215]: 0.9,
  [ISongs.SONG_vo10216]: 0.9,
  [ISongs.SONG_vo10301]: 0.9,
  [ISongs.SONG_vo10302]: 0.9,
  [ISongs.SONG_vo10303]: 0.9,
  [ISongs.SONG_vo10304]: 0.9,
  [ISongs.SONG_vo10305]: 0.9,
  [ISongs.SONG_vo10306]: 0.9,
  [ISongs.SONG_vo10307]: 0.9,
  [ISongs.SONG_vo10308]: 0.9,
  [ISongs.SONG_vo10309]: 0.9,
  [ISongs.SONG_vo10310]: 0.9,
  [ISongs.SONG_vo10311]: 0.9,
  [ISongs.SONG_vo10312]: 0.9,
  [ISongs.SONG_vo10313]: 0.9,
  [ISongs.SONG_vo10401]: 0.9,
  [ISongs.SONG_vo10402]: 0.9,
  [ISongs.SONG_vo10403]: 0.9,
  [ISongs.SONG_vo10404]: 0.9,
  [ISongs.SONG_vo10405]: 0.9,
  [ISongs.SONG_vo10406]: 0.9,
  [ISongs.SONG_vo10407]: 0.9,
  [ISongs.SONG_vo10408]: 0.9,
  [ISongs.SONG_vo10409]: 0.9,
  [ISongs.SONG_vo10410]: 0.9,
  [ISongs.SONG_vo20101]: 0.9,
  [ISongs.SONG_vo20102]: 0.9,
  [ISongs.SONG_vo20103]: 0.9,
  [ISongs.SONG_vo20104]: 0.9,
  [ISongs.SONG_vo20105]: 0.9,
  [ISongs.SONG_vo20106]: 0.9,
  [ISongs.SONG_vo20201]: 0.9,
  [ISongs.SONG_vo20202]: 0.9,
  [ISongs.SONG_vo20203]: 0.9,
  [ISongs.SONG_vo20301]: 0.9,
  [ISongs.SONG_vo20302]: 0.9,
  [ISongs.SONG_vo20303]: 0.9,
  [ISongs.SONG_vo20304]: 0.9,
  [ISongs.SONG_vo20305]: 0.9,
  [ISongs.SONG_vo20306]: 0.9,
  [ISongs.SONG_vo20401]: 0.9,
  [ISongs.SONG_vo20402]: 0.9,
  [ISongs.SONG_vo20403]: 0.9,
  [ISongs.SONG_vo20404]: 0.9,
  [ISongs.SONG_vo20405]: 0.9,
  [ISongs.SONG_vo20406]: 0.9,
  [ISongs.SONG_vo20501]: 0.9,
  [ISongs.SONG_vo20502]: 0.9,
  [ISongs.SONG_vo20503]: 0.9,
  [ISongs.SONG_vo20504]: 0.9,
  [ISongs.SONG_vo20505]: 0.9,
  [ISongs.SONG_vo20601]: 0.9,
  [ISongs.SONG_vo20602]: 0.9,
  [ISongs.SONG_vo20603]: 0.9,
  [ISongs.SONG_vo20604]: 0.9,
  [ISongs.SONG_vo20605]: 0.9,
  [ISongs.SONG_vo20606]: 0.9,
  [ISongs.SONG_vo20701]: 0.9,
  [ISongs.SONG_vo20702]: 0.9,
  [ISongs.SONG_vo20703]: 0.9,
  [ISongs.SONG_vo20704]: 0.9,
  [ISongs.SONG_vo20705]: 0.9,
  [ISongs.SONG_vo30101]: 0.9,
  [ISongs.SONG_vo30102]: 0.9,
  [ISongs.SONG_vo30103]: 0.9,
  [ISongs.SONG_vo30104]: 0.9,
  [ISongs.SONG_vo30201]: 0.9,
  [ISongs.SONG_vo30202]: 0.9,
  [ISongs.SONG_vo30203]: 0.9,
  [ISongs.SONG_vo30204]: 0.9,
  [ISongs.SONG_vo30301]: 0.9,
  [ISongs.SONG_vo30302]: 0.9,
  [ISongs.SONG_vo30303]: 0.9,
  [ISongs.SONG_vo30304]: 0.9,
  [ISongs.SONG_vo30401]: 0.9,
  [ISongs.SONG_vo30402]: 0.9,
  [ISongs.SONG_vo30403]: 0.9,
  [ISongs.SONG_vo30404]: 0.9,
  [ISongs.SONG_vo30501]: 0.9,
  [ISongs.SONG_vo30502]: 0.9,
  [ISongs.SONG_vo30503]: 0.9,
  [ISongs.SONG_vo30504]: 0.9,
  [ISongs.SONG_vo30601]: 0.9,
  [ISongs.SONG_vo30602]: 0.9,
  [ISongs.SONG_vo30603]: 0.9,
  [ISongs.SONG_vo30604]: 0.9,
  [ISongs.SONG_vo30701]: 0.9,
  [ISongs.SONG_vo30702]: 0.9,
  [ISongs.SONG_vo30703]: 0.9,
  [ISongs.SONG_vo30704]: 0.9,
  [ISongs.SONG_SFX_WIN_FeatureTrigger]: 0.3,
  [ISongs.SONG_SFX_Win_Big]: 0.3,
  [ISongs.SONG_SFX_Win_Epic]: 0.3,
  [ISongs.SONG_SFX_Win_Great]: 0.3,
  [ISongs.SONG_SFX_Win_Mega]: 0.3,
};

const audioSpriteGaps: { [key in ISongs]?: number } = {};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
