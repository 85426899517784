import _ from 'lodash';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, audioSpriteVolume, mappedAudioSprites } from '../../config/audio';
import { BgmSoundTypes, EventTypes, GameMode } from '../../global.d';
import {
  setBrokenGambleGame,
  setBrokenGame,
  setBrokenGameBgm,
  setGameHistory,
  setGameMode,
  setIsMeloFlag,
  setIsReplay,
} from '../../gql/cache';
import { BGM_FADE_OUT_VOLUME, SlotMachineState, eventManager } from '../../slotMachine/config';
import { debugDisplay, isFreeSpinGameMode, isFreeSpinsMode, isGambleMode, wait } from '../../utils';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import { BgSkin } from '../background/config';

type BgmType = Record<BgmSoundTypes, { intro: ISongs | undefined; base: ISongs; melo?: ISongs }>;
export const bgmList: BgmType = {
  regular: { intro: undefined, base: ISongs.SONG_030_01_BG_Loop, melo: ISongs.SONG_030_02_BG_Melo },
  freespin: { intro: ISongs.SONG_030_03_FS_int, base: ISongs.SONG_030_03_FS_Loop },
  gamble: { intro: undefined, base: ISongs.SONG_030_05_Gamble_Loop },
};

class BgmControl {
  private bgmListIndex: BgmSoundTypes | undefined;

  private timer: NodeJS.Timeout | undefined;

  private introBgmDelay: Animation | null = null;

  private isOpening: boolean;

  private isFadeOut: boolean;

  private isChangeRestriction = false;

  constructor() {
    this.bgmListIndex = undefined;
    this.timer = undefined;
    this.isOpening = false;
    this.isFadeOut = false;
    setIsMeloFlag(false);
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      if (this.isChangeRestriction) {
        this.playBgm();
      }
    });
  }

  private clearTimeout() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state === SlotMachineState.IDLE) {
      this.clearTimeout();
      if (setIsMeloFlag()) {
        this.timer = setTimeout(() => {
          this.fadeOutMelo(3000);
        }, 30 * 1000);
      }
    } else if (state === SlotMachineState.SPIN) {
      this.clearTimeout();
    }
  }

  public setIsFadeOut(status: boolean) {
    this.isFadeOut = status;
  }

  private onModeChange(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode } = settings;
    let bgmTitle: BgmSoundTypes | undefined;

    if (isFreeSpinsMode(mode)) {
      bgmTitle = BgmSoundTypes.FS;
    } else if (isGambleMode(mode)) {
      bgmTitle = BgmSoundTypes.GAMBLE;
    } else {
      bgmTitle = BgmSoundTypes.BASE;
    }

    if (bgmTitle === undefined) {
      this.stopBgm();
    } else if (this.bgmListIndex != bgmTitle) {
      this.stopBgm();
      if (this.bgmListIndex === BgmSoundTypes.GAMBLE && bgmTitle === BgmSoundTypes.BASE) {
        this.playBgm(bgmTitle);
        const play = _.reduce(setGameHistory().slice(2), (acc, item) => acc && item);
        if (play) {
          this.fadeInMelo(500);
        }
      } else {
        this.playBgm(bgmTitle);
      }
    }
  }

  public setBaseBgm(): void {
    this.bgmListIndex = BgmSoundTypes.BASE;
  }

  private getBonusState(): BgmSoundTypes {
    let rtn = BgmSoundTypes.BASE;
    if (setGameMode() === GameMode.BASE_GAME) {
      rtn = BgmSoundTypes.BASE;
    } else if (setGameMode() === GameMode.BASE_GAME_GAMBLE) {
      rtn = BgmSoundTypes.GAMBLE;
    } else if (setGameMode() === GameMode.FREE_SPIN) {
      rtn = BgmSoundTypes.FS;
    }
    return rtn;
  }

  private setBgmIndex(): BgmSoundTypes {
    let bgm: BgmSoundTypes;
    if (isFreeSpinGameMode(setGameMode())) {
      if (this.isOpening) {
        bgm = this.getBonusState();
        this.isOpening = false;
      } else {
        if (setBrokenGameBgm()) {
          bgm = this.getBonusState();
          setBrokenGameBgm(false);
        } else {
          bgm = this.getBonusState();
        }
      }
    } else {
      bgm = BgmSoundTypes.BASE;
    }
    if (setBrokenGambleGame()) {
      bgm = BgmSoundTypes.GAMBLE;
      setBrokenGambleGame(false);
    }
    return bgm;
  }

  public playBgm(bgmListIndex?: BgmSoundTypes): void {
    if (setBrokenGame() && bgmListIndex === undefined) {
      return;
    }
    if (AudioApi.isRestricted) {
      return;
    }
    if (bgmListIndex === this.bgmListIndex && bgmListIndex != undefined) {
      return;
    }

    debugDisplay(
      'playBgm--2 ',
      'this.bgmListIndex',
      this.bgmListIndex,
      'bgmListIndex',
      bgmListIndex,
      'this.isFadeOut',
      this.isFadeOut,
    );

    if (bgmListIndex === undefined) {
      const bgmIndex = this.setBgmIndex();
      if (this.bgmListIndex === bgmIndex) {
        return;
      } else {
        this.stopBgm();
        this.bgmListIndex = bgmIndex;
      }
    } else {
      this.stopBgm();
      this.bgmListIndex = bgmListIndex;
    }
    if (this.bgmListIndex != undefined) {
      if (bgmList[this.bgmListIndex]!.intro === undefined) {
        if (this.isFadeOut) {
          this.fadeOutVolume(0, BGM_FADE_OUT_VOLUME);
        }
        AudioApi.play({ type: bgmList[this.bgmListIndex]!.base });
      } else {
        this.introBgmDelay = Tween.createDelayAnimation(
          mappedAudioSprites[bgmList[this.bgmListIndex]!.intro!]!.duration,
        );
        this.introBgmDelay.addOnComplete(() => {
          if (this.bgmListIndex != undefined) {
            AudioApi.play({
              type: bgmList[this.bgmListIndex]!.base,
              stopPrev: true,
            });
          }
        });
        this.introBgmDelay.addOnSkip(() => {
          if (this.bgmListIndex != undefined) {
            AudioApi.stop({ type: bgmList[this.bgmListIndex]!.base });
          }
        });

        AudioApi.play({
          type: bgmList[this.bgmListIndex]!.intro!,
          stopPrev: true,
        });
        this.introBgmDelay.start();
      }
    }
    if ('melo' in bgmList[this.bgmListIndex]) {
      AudioApi.play({
        type: bgmList[this.bgmListIndex].melo!,
        volume: 0,
      });
    }
  }

  public stopBgm(): void {
    // console.log('stopBgm');
    if (this.bgmListIndex != undefined) {
      AudioApi.stop({ type: bgmList[this.bgmListIndex]!.base });
      if ('melo' in bgmList[this.bgmListIndex!]) {
        AudioApi.stop({
          type: bgmList[this.bgmListIndex!].melo!,
          volume: 0,
        });
      }
    }
  }

  public fadeInBase(fadeTime: number): void {
    // console.log('fadeInBase');
    if (this.bgmListIndex != undefined) {
      AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex]!.base, audioSpriteVolume[bgmList[this.bgmListIndex]!.base]);
      if (setIsMeloFlag()) {
        this.fadeInMelo(fadeTime);
      }
    }
  }

  public fadeInMelo(fadeTime: number): void {
    // console.log('fadeInMelo');
    if (this.bgmListIndex != undefined) {
      if ('melo' in bgmList[this.bgmListIndex]) {
        const soundProp = AudioApi.getSoundByKey(bgmList[this.bgmListIndex].melo!);
        if (soundProp.volume !== 0) {
          return;
        }

        setIsMeloFlag(true);
        AudioApi.fadeIn(
          fadeTime,
          bgmList[this.bgmListIndex].melo!,
          audioSpriteVolume[bgmList[this.bgmListIndex].melo!],
        );
      }
    }

    this.onSlotMachineStateChange(SlotMachine.getInstance().state);
  }

  public fadeOutAll(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex]!.base);
      this.fadeOutMelo(fadeTime);
    }
  }

  public fadeOutMelo(fadeTime: number): void {
    // console.log('fadeOutMelo');
    if (this.bgmListIndex != undefined) {
      if ('melo' in bgmList[this.bgmListIndex]) {
        if (!setIsReplay()) {
          setIsMeloFlag(false);
        }
        AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex].melo!);
      }
    }
  }

  public fadeInVolume(fadeTime: number): void {
    debugDisplay('fadeInVolume', this.bgmListIndex);
    if (this.bgmListIndex != undefined) {
      this.isFadeOut = false;
      this.fadeOutVolume(0, 0);
      AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex]!.base, audioSpriteVolume[bgmList[this.bgmListIndex]!.base]);
    }
  }

  public fadeOutVolume(fadeTime: number, volume: number): void {
    debugDisplay('fadeOutVolume', this.bgmListIndex, fadeTime, volume);
    if (this.bgmListIndex != undefined) {
      this.isFadeOut = true;
      AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex]!.base, volume);
    }
  }

  public async handleChangeRestriction(value = true): Promise<void> {
    // console.log('handleChangeRestriction');
    await wait(10);
    AudioApi.unSuspend();
    AudioApi.processRestriction({
      restricted: false,
    });

    if (setBrokenGame() || setBrokenGambleGame() || !value) {
      this.isChangeRestriction = true;
      eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
    } else {
      this.playBgm(this.getBonusState());
    }
  }
}

export default new BgmControl();
