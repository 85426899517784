import { useMutation } from '@apollo/client';

import { IAuthInput } from '../../global.d';
import { setIsAuthorized, setSlotConfig } from '../../gql/cache';
import { authGql } from '../../gql/mutation';
import { parseQuery } from '../../utils';

export const useAuth = (data: { progress: { status: number; wasLoaded?: boolean } } | undefined) => {
  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });
  return { getAuth };
};
