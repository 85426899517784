import TaggedText from 'pixi-tagged-text';
import { Container, IDestroyOptions, Sprite, Texture } from 'pixi.js';

import i18n from '../../../i18next/index';
import { ResourceTypes } from '../../../resources';
import { TweenProperties } from '../../../slotMachine/animations/d';
import Tween from '../../../slotMachine/animations/tween';

import { Dot } from './Dot';
import {
  CAROUSEL_ANIMATION_DELAY,
  CAROUSEL_ANIMATION_DURATION,
  CAROUSEL_DOTS_GAP,
  CAROUSEL_DOTS_SIZE,
  CAROUSEL_IMAGE_HEIGHT,
} from './config';
import { introStylesInfo } from './styles';

interface ISlide {
  img: ResourceTypes;
  txtKey: string;
}

type ErrorTypes = 'introTitle1' | 'introTitle2';

class Carousel extends Container {
  private slides: ISlide[];

  private slidesContainer: Container;

  private dots: Container;

  private slideIndex: number;

  private timer: NodeJS.Timer;

  constructor(slides: ISlide[]) {
    super();
    this.slideIndex = 0;
    this.slides = slides;
    this.slidesContainer = this.initSlides();
    this.dots = this.initDots();
    this.addChild(this.slidesContainer, this.dots);

    this.timer = setInterval(() => {
      this.handleSlide();
    }, CAROUSEL_ANIMATION_DELAY);
  }

  private initSlides(): Container {
    const slides = new Container();
    for (let i = 0; i < this.slides.length; i++) {
      const container = new Container();
      container.name = 'slidesContainer';
      const texture = Texture.from(this.slides[i]!.img);
      const image = new Sprite(texture);
      image.anchor.set(0.5, 0);
      image.x = 0;
      image.name = `image_${i}`;
      image.width = (image.width * CAROUSEL_IMAGE_HEIGHT) / image.height;
      image.height = (image.height * CAROUSEL_IMAGE_HEIGHT) / image.height;
      image.scale.set(1.4);

      const text = new TaggedText(i18n.t(this.slides[i]!.txtKey as ErrorTypes), introStylesInfo);

      text.update();
      text.draw();
      text.y = image.height + image.y;
      text.pivot.x = text.defaultStyle.wordWrapWidth! / 2;

      container.addChild(image);
      container.addChild(text);
      if (i === 0) {
        container.alpha = 1;
      } else {
        container.alpha = 0;
      }

      slides.addChild(container);
    }

    return slides;
  }

  private initDots = (): Container => {
    const dotsContainer = new Container();
    for (let i = 0; i < this.slides.length; i++) {
      const dot = new Dot(`${i + 1}`, i === 0);
      dot.interactive = true;
      dot.buttonMode = true;
      dot.name = `${i}`;
      dot.x = i * (CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_GAP);
      dotsContainer.addChild(dot);
    }
    return dotsContainer;
  };

  private handleSlide(): void {
    for (let i = 0; i < this.slides.length; i++) {
      if (this.slideIndex === i) {
        const fadeIn = new Tween({
          object: this.slidesContainer.children[i]!,
          property: TweenProperties.ALPHA,
          propertyBeginValue: this.slidesContainer.children[i]!.alpha,
          target: 1,
          duration: CAROUSEL_ANIMATION_DURATION,
        });
        fadeIn.start();
      } else {
        const fadeOut = new Tween({
          object: this.slidesContainer.children[i]!,
          property: TweenProperties.ALPHA,
          propertyBeginValue: this.slidesContainer.children[i]!.alpha,
          target: 0,
          duration: CAROUSEL_ANIMATION_DURATION,
        });
        fadeOut.start();
      }

      (this.dots.children[i] as Dot).setActive(i === this.slideIndex);
    }
    if (this.slideIndex === 1) {
      this.slideIndex = 0;
    } else {
      this.slideIndex += 1;
    }
  }

  public setSize(width: number, height: number, _bottomGap: number): void {
    if (width >= height) {
      this.scale.set(height / 1600);
      this.y = height / 2 - this.height / 2;
    }
    if (width < height) {
      this.scale.set(width / 1600);
      this.y = height / 2 - this.height / 1.65;
    }

    this.x = width / 2;
    this.dots.y = this.slidesContainer.height + 15;
    this.dots.x = -this.dots.width / 2;
  }

  public override destroy(options?: boolean | IDestroyOptions | undefined): void {
    clearInterval(this.timer);
    super.destroy(options);
  }
}
export default Carousel;
