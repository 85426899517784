import { ITextStyle } from 'pixi.js';

import { SlotId } from '../../config';

export const HIGH_CHANCE_MODE_COUNTER_POS_X = 415;
export const HIGH_CHANCE_MODE_COUNTER_POS_Y = 150;

export const HIGH_CHANCE_MODE_COUNTER_ANIMATION_SCALE = 1.3;
export const HIGH_CHANCE_MODE_COUNTER_ANIMATION_DELAY = 500;
export const HIGH_CHANCE_MODE_COUNTER_ANIMATION_LOOP = false;

export const HIGH_LOW_LANDSCAPE_X = 471.5;
export const HIGH_LOW_LANDSCAPE_Y = -58;
export const DIVIDEND_TABLE_LEFT_LANDSCAPE_X = 55;
export const DIVIDEND_TABLE_LEFT_LANDSCAPE_Y = 260;
export const DIVIDEND_TABLE_RIGHT_LANDSCAPE_X = 869;
export const DIVIDEND_TABLE_RIGHT_LANDSCAPE_Y = DIVIDEND_TABLE_LEFT_LANDSCAPE_Y;

export const SPECIAL_SYMBOL_HIT_LANDSCAPE_WIDTH = 480;
export const SPECIAL_SYMBOL_HIT_LANDSCAPE_HEIGHt = 60;
export const SPECIAL_SYMBOL_HIT_PORTRAIT_WIDTH = 284.3;
export const SPECIAL_SYMBOL_HIT_PORTRAIT_HEIGHt = 26;

export const HIGH_LOW_PORTRAIT_X = 462;
export const HIGH_LOW_PORTRAIT_Y = -155;
export const DIVIDEND_TABLE_LEFT_PORTRAIT_X = 154;
export const DIVIDEND_TABLE_LEFT_PORTRAIT_Y = 180;
export const DIVIDEND_TABLE_RIGHT_PORTRAIT_X = 778;
export const DIVIDEND_TABLE_RIGHT_PORTRAIT_Y = DIVIDEND_TABLE_LEFT_PORTRAIT_Y;

export const GAMBLE_VIEW_POSITION_LANDSCAPE_Y = 493;
export const GAMBLE_VIEW_POSITION_PORTRAIT_Y = 400;

export const GAMBLE_STAKE_ANIMATION_SCALE = 1.3;
export const GAMBLE_STAKE_ANIMATION_DELAY = 500;
export const GAMBLE_STAKE_ANIMATION_LOOP = false;

const font = 'NotoSans-SemiCondensedBold';

export const textStyle: Partial<ITextStyle> = {
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: ['#b3ffff', '#2078ff', '#407dd4', '#60ffff', '#2d28ff', '#064fff', '#4053ff', '#00c0ff'],
  fillGradientStops: [0.25, 0.38, 0.5, 0.53, 0.55, 0.59, 0.66, 0.8],
  fontFamily: font,
  fontSize: 100,
  fontWeight: '800',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#ffffff',
  strokeThickness: 8,
};

export const spinsStyle: Partial<ITextStyle> = {
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: ['#b3ffff', '#2078ff', '#407dd4', '#60ffff', '#2d28ff', '#064fff', '#4053ff', '#00c0ff'],
  fillGradientStops: [0.25, 0.38, 0.5, 0.53, 0.55, 0.59, 0.66, 0.8],
  fontFamily: font,
  fontSize: 100,
  fontWeight: '800',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#ffffff',
  strokeThickness: 8,
};

type hitRow = Record<SlotId, { x: number; y: number }>;
type hitRow7Mix = { x: number; y: number };

export const HitRowListLand: hitRow = {
  WL: { x: -185, y: 131 },
  A: { x: -185, y: 180 },
  B: { x: -185, y: 225 },
  C: { x: -185, y: 321 },
  D: { x: -185, y: 367 },
  E: { x: 629, y: 152 },
  F: { x: 629, y: 201 },
  G: { x: 629, y: 248 },
  H: { x: 629, y: 295 },
  I: { x: 629, y: 343 },
  J: { x: 629, y: 343 },
};

export const HitRowListPort: hitRow = {
  WL: { x: 15, y: 108.5 },
  A: { x: 15, y: 137 },
  B: { x: 15, y: 163 },
  C: { x: 15, y: 221 },
  D: { x: 15, y: 250 },
  E: { x: 637.5, y: 122 },
  F: { x: 637.5, y: 150 },
  G: { x: 637.5, y: 176 },
  H: { x: 637.5, y: 205 },
  I: { x: 637.5, y: 234 },
  J: { x: 637.5, y: 234 },
};

export const HitRowListAny7MixLand: hitRow7Mix = { x: -185, y: 273 };
export const HitRowListAny7MixPort: hitRow7Mix = { x: 15, y: 191 };
