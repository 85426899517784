import { GameMode, IOutcome, ISettledBet } from '../../global.d';
import { setGambleStake, setGambleStakeResult, setIsReplay, setSlotConfig } from '../../gql/cache';
import { IBet } from '../../gql/d';
import { caseFn, gambleResult } from '../../slotMachine/d';
import { getSpinResult3x1 } from '../math';

import { isDealerWin, isSpecialBonus, setPlayerToNumber } from './gamble';

export const makeGambleResult = (
  bet: IBet,
  nextBet: IBet,
  settledAmount: number,
  settledCurrency: string,
  placedAmount: number,
  placedCurrency: string,
  winCoinAmount: number,
): ISettledBet => {
  const outcome = getOutcomesIndex(undefined, bet);
  const dealer = outcome.stateSnapshot.reelPosition[0]!;
  const p1 = outcome.stateSnapshot.reelPosition[1]!;
  const p2 = outcome.stateSnapshot.reelPosition[2]!;
  const p3 = outcome.stateSnapshot.reelPosition[3]!;

  const reelPos = [0, 0, 0, 0, dealer, 0, p1, p2, p3];

  const spinResult = getSpinResult3x1({
    reelPositions: reelPos.slice(0, 9), // REELS_AMOUNT
    reelSet: setSlotConfig().reels.find((reelSet) => reelSet.type === GameMode.BASE_GAME)!,
    icons: setSlotConfig().icons,
  });

  let attribution: number[] = [];

  if (bet.betStorage.estimatedWinCoinAmount === 0) {
    setGambleStakeResult(gambleResult.loss);
    setGambleStake(0);
    attribution = [4];
  } else {
    if (outcome.stateSnapshot.fieldState[0] === outcome.stateSnapshot.fieldState[setPlayerToNumber() + 1]) {
      setGambleStakeResult(gambleResult.draw);
    } else {
      setGambleStakeResult(gambleResult.win);
    }
    attribution = [setPlayerToNumber() + 6];
  }

  if (isSpecialBonus(spinResult)) {
    setGambleStakeResult(gambleResult.win);
    if (isDealerWin(outcome.stateSnapshot.fieldState[0], outcome.stateSnapshot.fieldState[setPlayerToNumber() + 1])) {
      attribution = [4, 6, 7, 8];
    } else {
      attribution = [6, 7, 8];
    }
  }

  if (setGambleStakeResult() === gambleResult.win && bet.outcomes.length < 2 && !setIsReplay()) {
    setGambleStakeResult(gambleResult.limit);
  }

  const id1 = getOutcomesIndex(undefined, bet);
  const idCollect = getOutcomesIndex(caseFn.closeGamble, bet);
  const idReel1 = getOutcomesIndex(caseFn.selectReel1, bet);
  const idReel2 = getOutcomesIndex(caseFn.selectReel2, bet);
  const idReel3 = getOutcomesIndex(caseFn.selectReel3, bet);

  const winCombinations = [
    {
      attribution: attribution,
      reelPosition: null,
      rewards: [],
    },
  ];

  const outcome0 = {
    id: id1.id,
    rewards: id1.rewards,
    predicament: id1.predicament,
    stateSnapshot: {
      fieldState: id1.stateSnapshot.fieldState,
      reelPosition: reelPos,
      winCombinations: winCombinations,
    },
    selectionState: id1.selectionState,
  };
  const outcome1 = {
    id: idCollect.id,
    rewards: idCollect.rewards,
    predicament: idCollect.predicament,
    stateSnapshot: {
      fieldState: id1.stateSnapshot.fieldState,
      reelPosition: reelPos,
      winCombinations: id1.stateSnapshot.winCombinations,
    },
    selectionState: id1.selectionState,
  };
  const outcome2 = {
    id: idReel1.id,
    rewards: idReel1.rewards,
    predicament: idReel1.predicament,
    stateSnapshot: {
      fieldState: id1.stateSnapshot.fieldState,
      reelPosition: reelPos,
      winCombinations: id1.stateSnapshot.winCombinations,
    },
    selectionState: id1.selectionState,
  };
  const outcome3 = {
    id: idReel2.id,
    rewards: idReel2.rewards,
    predicament: idReel2.predicament,
    stateSnapshot: {
      fieldState: id1.stateSnapshot.fieldState,
      reelPosition: reelPos,
      winCombinations: id1.stateSnapshot.winCombinations,
    },
    selectionState: id1.selectionState,
  };
  const outcome4 = {
    id: idReel3.id,
    rewards: idReel3.rewards,
    predicament: idReel3.predicament,
    stateSnapshot: {
      fieldState: id1.stateSnapshot.fieldState,
      reelPosition: reelPos,
      winCombinations: id1.stateSnapshot.winCombinations,
    },
    selectionState: id1.selectionState,
  };

  const gambleBet = {
    bet: {
      betStorage: nextBet.betStorage,
      id: nextBet.id,
      slotId: nextBet.slotId,
      playerId: nextBet.playerId,
      wager: nextBet.wager,
      status: nextBet.status,
      outcomes: [outcome0, outcome1, outcome2, outcome3, outcome4],
      coinAmount: nextBet.coinAmount,
      effectiveCoinAmount: nextBet.effectiveCoinAmount,
      coinValue: nextBet.coinValue,
      createdAt: nextBet.createdAt,
      spinResult: spinResult,
    },
    balance: {
      settled: {
        amount: settledAmount,
        currency: settledCurrency,
      },
      placed: {
        amount: placedAmount,
        currency: placedCurrency,
      },
    },
    winCoinAmount: winCoinAmount,
  };

  return gambleBet;
};

const getOutcomesIndex = (findText: caseFn | undefined, nextBet: IBet): IOutcome => {
  const outcome = nextBet.outcomes.find((outcome) => outcome.predicament.caseFn === findText);
  return outcome != undefined ? outcome : nextBet!.outcomes[0]!;
};
