import { SlotId } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode, setPlayer, setSpecialBonusAny7Mix, setSpecialBonusIcon } from '../../gql/cache';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { Icon, caseFn } from '../../slotMachine/d';

export const nextGambleSpin = (): void => {
  setGameMode(GameMode.BASE_GAME_GAMBLE);
  eventManager.emit(EventTypes.GAMBLE_WATCH_OVER);
  eventManager.emit(EventTypes.GAMBLE_PLAYER_CLEAR);
  eventManager.emit(EventTypes.SET_STATE, SlotMachineState.GAMBLE_SPIN);
  SlotMachine.getInstance().spin(false);
  eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
    mode: GameMode.BASE_GAME_GAMBLE,
  });
};

export const setPlayerToNumber = (): number => {
  return setPlayer() === caseFn.selectReel1
    ? 0
    : setPlayer() === caseFn.selectReel2
    ? 1
    : setPlayer() === caseFn.selectReel3
    ? 2
    : 0;
};

export const isSpecialBonus = (spinResult: Icon[]): boolean => {
  let firstNotWLSymbol: SlotId = SlotId.WL;
  setSpecialBonusAny7Mix(false);

  for (let i = 6; i < 9; i++) {
    if (spinResult[i]!.id === SlotId.J) {
      return false;
    } else if (spinResult[i]!.id === SlotId.WL) {
      continue;
    } else if (firstNotWLSymbol === SlotId.WL) {
      firstNotWLSymbol = spinResult[i]!.id;
      continue;
    } else if (firstNotWLSymbol === spinResult[i]!.id) {
      continue;
    } else if (
      (firstNotWLSymbol === SlotId.A || firstNotWLSymbol === SlotId.B) &&
      (spinResult[i]!.id === SlotId.A || spinResult[i]!.id === SlotId.B)
    ) {
      setSpecialBonusAny7Mix(true);
      continue;
    } else {
      return false;
    }
  }
  setSpecialBonusIcon(firstNotWLSymbol);
  return true;
};

export const isDealerWin = (dealer: SlotId, player: SlotId): boolean => {
  return gambleSymbolRank[dealer] < gambleSymbolRank[player];
};

export const gambleSymbolRank: Record<SlotId, number> = {
  WL: 0,
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 10,
};
