import { gql } from '@apollo/client';

import { BET, OUTCOME, WAGER } from './fragment';

export const authGql = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      sessionId
      playerIdToken
    }
  }
`;

export const versionGql = gql`
  mutation Version {
    version {
      version
      time
    }
  }
`;

export const placeBetGql = gql`
  ${BET}
  ${WAGER}
  ${OUTCOME}
  mutation PlaceBet($input: PlaceBetInput!) {
    placeBet(input: $input) {
      winCoinAmount
      bet {
        ...bet
      }
      balance {
        settled {
          currency
          amount
        }
        placed {
          currency
          amount
        }
      }
    }
  }
`;

export const buyFeatureGql = gql`
  ${WAGER}
  mutation BuyFeature($input: BuyFeatureInput!) {
    buyFeature(input: $input) {
      wager {
        ...wager
      }
      balance {
        settled {
          currency
          amount
        }
        placed {
          currency
          amount
        }
      }
    }
  }
`;

export const gambleGql = gql`
  ${BET}
  ${WAGER}
  ${OUTCOME}
  mutation SelectOutcomes($input: SelectOutcomesInput!) {
    selectOutcomes(input: $input) {
      bet {
        ...bet
      }
      balance {
        settled {
          currency
          amount
        }
        placed {
          currency
          amount
        }
      }
      winCoinAmount
    }
  }
`;
