import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, bonusIds } from '../../global.d';
import { setIsBuyFeaturePopupOpened, setIsBuyFeaturePurchased } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { SpriteButton } from '../components/button';
import {
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_WIDTH,
  Z_INDEX_BUY_FEATURE_POPUP_CONFIRM,
  eventManager,
} from '../config';

import { betValueStyleConfirm, buyFeatureConfirmStyle, totalCostTextStyle } from './textStyles';

class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private totalCostValue: AutoResizeText;

  private okButton: SpriteButton;

  private cancelButton: SpriteButton;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();
    this.visible = false;
    this.popupBg = this.initPopupBg();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelButton = this.initCancelBtn();
    this.okButton = this.initOkBtn();
    this.interactive = true;
    this.zIndex = Z_INDEX_BUY_FEATURE_POPUP_CONFIRM;

    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, (totalCost: string) => {
      this.handleOpen(totalCost);
    });
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      setIsBuyFeaturePopupOpened(false);
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.initTitle());
    this.addChild(this.initTotalCostText());
    this.addChild(this.totalCostValue);
    this.addChild(this.okButton);
    this.addChild(this.cancelButton);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopupConfirm));
    popupBg.anchor.set(0.5);

    return popupBg;
  }

  private initTitle() {
    const title = new AutoResizeText(i18n.t('buyFeatureConfirmTitle'), buyFeatureConfirmStyle);
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y;
    title.anchor.set(0.5);
    updateTextScale(title, this.popupBg.width - 100, 250);

    return title;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t('buyFeatureTotalCost'), totalCostTextStyle);
    totalCostText.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y;
    totalCostText.anchor.set(0.5);
    updateTextScale(totalCostText, this.popupBg.width - 100, 250);

    return totalCostText;
  }

  private initTotalCostValue(): AutoResizeText {
    const totalCostValue = new AutoResizeText('0', betValueStyleConfirm);
    totalCostValue.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y;
    totalCostValue.anchor.set(0.5);

    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn),
      onClick: () => {
        this.handleClose();
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
        AudioApi.play({ type: ISongs.SONG_SFX_UI_Close });
      },
    });
    cancelBtn.y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X;
    cancelBtn.anchor.set(0.5, 0);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn),
      onClick: this.handleClickOk.bind(this),
    });
    okBtn.y = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X;
    okBtn.anchor.set(0.5, 0);

    return okBtn;
  }

  private handleClickOk(): void {
    if (this.window.width > this.window.height) {
      eventManager.emit(EventTypes.TOGGLE_DIALOG, true);
    } else {
      eventManager.emit(EventTypes.TOGGLE_DIALOG, false);
    }
    this.okButton.interactive = false;
    this.cancelButton.interactive = false;
    AudioApi.play({ type: ISongs.SONG_SFX_UI_General });
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, bonusIds[GameMode.FREE_SPIN]!);
    setIsBuyFeaturePurchased(true);
  }

  public handleOpen(totalCost: string): void {
    this.visible = true;
    this.totalCostValue.text = totalCost;
    this.okButton.interactive = true;
    this.cancelButton.interactive = true;
  }

  public handleClose(): void {
    if (this.window.width > this.window.height) {
      eventManager.emit(EventTypes.TOGGLE_DIALOG, true);
    } else {
      eventManager.emit(EventTypes.TOGGLE_DIALOG, false);
    }
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP);
    this.visible = false;
    this.cancelButton.enable();
    this.okButton.enable();
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (width: number, height: number, _x: number, _y: number, _scale: number): void => {
    this.handleResize(width, height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = 200;
    } else {
      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.y = 300;
    }
  }
}

export default BuyFeaturePopupConfirm;
