import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.H]: ResourceTypes.symbolH,
  [SlotId.I]: ResourceTypes.symbolI,
  [SlotId.J]: ResourceTypes.symbolJ,
  [SlotId.WL]: ResourceTypes.symbolWl,
};
export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolABlur,
  [SlotId.B]: ResourceTypes.symbolBBlur,
  [SlotId.C]: ResourceTypes.symbolCBlur,
  [SlotId.D]: ResourceTypes.symbolDBlur,
  [SlotId.E]: ResourceTypes.symbolEBlur,
  [SlotId.F]: ResourceTypes.symbolFBlur,
  [SlotId.G]: ResourceTypes.symbolGBlur,
  [SlotId.H]: ResourceTypes.symbolHBlur,
  [SlotId.I]: ResourceTypes.symbolIBlur,
  [SlotId.J]: ResourceTypes.symbolJ,
  [SlotId.WL]: ResourceTypes.symbolWl,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_wl_announce',
  },
};

export type StopAnimationRen = {
  type: SymbolAnimationType;
  src?: string;
  animation?: string;
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
    featureTrigger?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_a_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_b_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_c_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_d_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_e_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_f_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_g_win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_h_win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_i_win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_j_win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'symbol_wl_win',
    featureTrigger: 'symbol_wl_win_sp',
  },
};

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.featureBanner,
  ResourceTypes.winBanner,

  ResourceTypes.introBg,
  ResourceTypes.intro1,
  ResourceTypes.intro2,
  ResourceTypes.backdrop,
  ResourceTypes.logo,

  ResourceTypes.gambleBtnframe,
  ResourceTypes.gambleBtn,
  ResourceTypes.collectBtn,
  ResourceTypes.gamble,
  ResourceTypes.collect,
  ResourceTypes.dealer,
  ResourceTypes.player,
  ResourceTypes.dividendTable,
  ResourceTypes.dividendTable2,
  ResourceTypes.highlow,

  ResourceTypes.symbolA,
  ResourceTypes.symbolB,
  ResourceTypes.symbolBBlur,
  ResourceTypes.symbolC,
  ResourceTypes.symbolCBlur,
  ResourceTypes.symbolD,
  ResourceTypes.symbolDBlur,
  ResourceTypes.symbolE,
  ResourceTypes.symbolEBlur,
  ResourceTypes.symbolF,
  ResourceTypes.symbolFBlur,
  ResourceTypes.symbolG,
  ResourceTypes.symbolGBlur,
  ResourceTypes.symbolH,
  ResourceTypes.symbolHBlur,
  ResourceTypes.symbolI,
  ResourceTypes.symbolIBlur,
  ResourceTypes.symbolJ,
  ResourceTypes.symbolWl,

  ResourceTypes.slotBg,

  ResourceTypes.textLogo,
  ResourceTypes.titlelogo,

  ResourceTypes.reelbase,
  ResourceTypes.reelframe,

  ResourceTypes.buyFeatureInput,
  ResourceTypes.buyFeatureBtn,
  ResourceTypes.buyFeatureCancelBtn,
  ResourceTypes.buyFeatureMinusBtn,
  ResourceTypes.buyFeatureOkBtn,
  ResourceTypes.buyFeaturePlusBtn,
  ResourceTypes.buyFeaturePopup,
  ResourceTypes.buyFeaturePopupConfirm,
]!.map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const SPINE_ATLASES: IAddOptions[] = [
  {
    name: 'spine_atlas',
    url: '/animations/desktop/symbol/spine.atlas',
    loadType: LoaderResource.LOAD_TYPE.XHR,
    xhrType: LoaderResource.XHR_RESPONSE_TYPE.TEXT,
  },
];

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'all_symbols',
      url: generateTexturePath('/animations', 'symbol_all/symbol_all.json'), // isMobile
    },
    {
      name: 'coinsAnimation',
      url: generateTexturePath('/animations', 'coins/coins.json', isMobile),
    },
    {
      name: 'avatar_hinako',
      url: generateTexturePath('/animations', 'avatar_hinako/avatar_hinako.json'),
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'buttonsSprite',
    url: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    url: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    url: '/images/loader/loader.json',
  },
  {
    name: 'ui',
    url: '/images/ui/ui.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
