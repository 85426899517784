import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config/audio';
import { EventTypes, GambleBtmProps, GameMode } from '../../global.d';
import { setBrokenGame, setFlashFlg, setIsAutoSpins } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import {
  filterBaseReelFrame,
  filterGambleSpinReelFrame1,
  filterGambleSpinReelFrame2,
  filterMouseOver,
  isFreeSpinGameMode,
} from '../../utils';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { BgSkin } from '../background/config';
import { Z_INDEX_BUY_BTN, eventManager } from '../config';

class GambleBtnBase extends PIXI.Container {
  protected btn: PIXI.Sprite;

  protected frame: PIXI.Sprite;

  protected text: PIXI.Sprite;

  private isDisabled: boolean;

  private filterHover = new PIXI.filters.ColorMatrixFilter();

  private filterGrayscale = new PIXI.filters.ColorMatrixFilter();

  private timerId: NodeJS.Timer | null = null;

  private rect: PIXI.Graphics;

  constructor(props: GambleBtmProps) {
    super();
    this.isDisabled = true;
    this.visible = !setBrokenGame();
    this.btn = this.initCollectBtn(props);
    this.frame = this.initFrame();
    this.text = this.initBtnText();
    this.rect = this.initRect();
    this.addChild(this.btn, this.frame, this.text, this.rect);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));

    this.filterHover.saturate(1, true);
    this.filterGrayscale.grayscale(0.5, true);
    this.zIndex = Z_INDEX_BUY_BTN;
    this.activeGamble(false);
  }

  private initRect(): PIXI.Graphics {
    const rect = new PIXI.Graphics();
    rect.beginFill(0xff0000);
    rect.drawRect(0, 0, this.frame.width, this.frame.height);
    rect.pivot.set(this.frame.width / 2, this.frame.height / 2);
    rect.alpha = 0.0;

    rect.interactive = true;
    rect.buttonMode = true;
    rect.visible = true;

    rect.on('click', () => this.onClick());
    rect.on('touchstart', () => this.onClick());

    rect.addListener('mouseover', () => {
      if (!this.btn.buttonMode) return;
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SONG_SFX_UI_Hover });
        this.text.anchor.set(0.5, 0.5);
        this.scale.set(1.1, 1.1);
        this.btn.filters = [filterMouseOver()];
        this.frame.visible = false;
      }
    });
    rect.addListener('mouseout', () => {
      if (!this.btn.buttonMode) return;
      if (!this.isDisabled) {
        this.text.anchor.set(0.5, 0.5);
        this.scale.set(1.0, 1.0);
        this.btn.filters = null;
        this.frame.visible = true;
      }
    });
    rect.addListener('mousedown', () => {
      if (!this.btn.buttonMode) return;
      if (!this.isDisabled) {
        this.text.anchor.set(0.5, 0.5);
        this.scale.set(0.9, 0.9);
        this.btn.filters = [this.filterHover];
      }
    });
    rect.addListener('mouseup', () => {
      if (!this.btn.buttonMode) return;
      if (!this.isDisabled) {
        this.text.anchor.set(0.5, 0.5);
        this.scale.set(1.0, 1.0);
      }
    });

    return rect;
  }

  protected changeClearFilter(object1: PIXI.Sprite): void {
    object1.visible = false;
    object1.filters = [filterBaseReelFrame()];
  }

  protected initFrame() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.gambleBtnframe));
    btn.anchor.set(0.5, 0.5);
    btn.x = 0;
    btn.y = 0;
    btn.visible = false;

    return btn;
  }

  protected initCollectBtn(props: GambleBtmProps) {
    const btn = new PIXI.Sprite(PIXI.Texture.from(props.btnResourceTypes));
    btn.anchor.set(0.5, 0.5);
    btn.x = 0;
    btn.y = 0;
    return btn;
  }

  protected changeFilter(object1: PIXI.Sprite, filter: boolean): void {
    this.btn.filters = null;
    this.text.filters = null;

    if (filter) {
      object1.filters = [filterGambleSpinReelFrame1()];
    } else {
      object1.filters = [filterGambleSpinReelFrame2()];
    }
  }

  protected initBtnText(): PIXI.Sprite {
    const text = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.collect));
    text.anchor.set(0.5);
    return text;
  }

  protected onClick(): void {}

  protected handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress) {
      this.btn.buttonMode = false;
      return;
    }

    this.isDisabled = disable;
    this.activeGamble(!disable);
  }

  protected activeGamble(disable: boolean): void {
    if (disable) {
      this.visible = true;
      this.btn.buttonMode = true;
      if (this.timerId) clearInterval(this.timerId);
      this.frame.visible = true;
      this.btn.filters = null;
      this.text.filters = null;
      this.changeFilter(this.frame, setFlashFlg() ? true : false);
      this.timerId = setInterval(() => this.changeFilter(this.frame, setFlashFlg() ? true : false), 250);
    } else {
      this.frame.visible = false;
      if (this.timerId) clearInterval(this.timerId);
      this.changeClearFilter(this.frame);
      this.btn.buttonMode = false;
      this.btn.filters = [this.filterGrayscale];
      this.text.filters = [this.filterGrayscale];
      this.visible = false;
    }
  }

  protected get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  protected resize(width: number, height: number): void {
    this.setPosition(width, height);

    this.handleDisable(this.isDisabled);
  }

  protected setPosition(width: number, height: number): void {
    if (width > height) {
      this.x = 1100;
      this.y = 540;
    } else {
      this.x = 464;
      this.y = 950;
    }
  }

  protected onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isFreeSpinGameMode(settings.mode)) {
      this.visible = false;
    } else {
      // this.visible = true;
    }
  }

  protected getFadeAnimation(alpha: number, duration: number, begin: number): Animation {
    const animation = new Tween({
      object: this,
      duration,
      propertyBeginValue: begin,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }
}

export default GambleBtnBase;
