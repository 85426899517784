import { GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const BANNER_POSITION_Y = 10;

// WIN BANNER
export const MESSAGE_WIN_BANNER_Y_LAND = 14;
export const MESSAGE_WIN_BANNER_Y_PORT = -96;
export const MESSAGE_WIN_BANNER_TITLE_Y = 90;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 347;

// BONUS BANNER
export const MESSAGE_BONUS_Y_LAND = 0;
export const MESSAGE_BONUS_Y_PORT = -95;

export const MESSAGE_BONUS_TITLE_Y = 50;
export const MESSAGE_BONUS_DESCRIPTION_Y_PORT = 260;
export const MESSAGE_BONUS_BTN_LAND = 440;
