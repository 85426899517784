import { ISongs } from '../../config/audio';

// 状況管理フラグ
export enum StatusControlFlg {
  NORMAL = 0, // 通常
  HAMARI = 1, // ハマリ
  ZANNEN = 2, // 残念ボナ
  GET = 3, // 良ボナ
  COUNTUP = 4, // ｶｳﾝﾄｱｯﾌﾟ
}

type TypeTalkListInfo = {
  status: StatusControlFlg;
  talkList: number[];
};

type TypeTalkList = Record<number, { talkInfo: TypeTalkListInfo[]; mouthDefault: string; mouthVoice: string }>;

export const takeUndefined: TypeTalkListInfo = {
  status: StatusControlFlg.NORMAL,
  talkList: [101000],
};

// 8.6 セリフ選択テーブル
export const talkList: TypeTalkList = {
  101: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [101000, 101001, 101002, 101003, 101004, 101005, 101006, 101007, 101008, 101009],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [101100, 101101, 101102],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [101200, 101201, 101202],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [101000],
      },
    ],
    mouthDefault: 'mouth_002_default_1',
    mouthVoice: 'mouth_002_voice',
  },
  102: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [102000, 102001, 102002, 102003, 102004, 102005, 102006, 102007, 102008, 102009],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [102100, 102101, 102102],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [102200, 102201, 102202],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [102300, 102301, 102302],
      },
    ],
    mouthDefault: 'mouth_001_default_1',
    mouthVoice: 'mouth_001_voice',
  },
  103: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [103000, 103001, 103002, 103003, 103004, 103005, 103006, 103007, 103008, 103007, 103009],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [103100, 103101, 103102],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [103300, 103301, 103302],
      },
    ],
    mouthDefault: 'mouth_003_default_1',
    mouthVoice: 'mouth_003_voice',
  },
  104: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [104000, 104001, 104002, 104003, 104004, 104005, 104006, 104007, 104008, 104009],
      },
      {
        // TODO 仕様書にない配列
        status: StatusControlFlg.HAMARI,
        talkList: [],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [104300, 104301, 104302],
      },
    ],
    mouthDefault: 'mouth_003_default_1',
    mouthVoice: 'mouth_003_voice',
  },
  105: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [105000],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [105100],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [105200],
      },
      {
        // TODO 仕様書にない配列
        status: StatusControlFlg.GET,
        talkList: [105000],
      },
    ],
    mouthDefault: 'mouth_002_voice_c',
    mouthVoice: 'mouth_002_voice_c',
  },
  201: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [201000, 201001, 201002, 201003],
      },
      {
        // TODO 仕様書にない配列
        status: StatusControlFlg.HAMARI,
        talkList: [],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [201000, 201401, 201402, 201403],
      },
    ],
    mouthDefault: 'mouth_004_default_0',
    mouthVoice: 'mouth_004_default_0',
  },
  202: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [202000, 202001, 202002, 202003],
      },
    ],
    mouthDefault: 'mouth_005_default_0',
    mouthVoice: 'mouth_005_voice',
  },
  203: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [203000, 203001, 203002, 203003],
      },
      {
        // TODO 仕様書にない配列
        status: StatusControlFlg.HAMARI,
        talkList: [],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [202000, 203401, 203402, 203403],
      },
    ],
    mouthDefault: 'mouth_006_default_0',
    mouthVoice: 'mouth_006_voice',
  },
  204: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [204000, 204001, 204002, 204003],
      },
      {
        // TODO 仕様書にない配列
        status: StatusControlFlg.HAMARI,
        talkList: [],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [204000, 204401, 204402, 204403],
      },
    ],
    mouthDefault: 'mouth_001_default_1',
    mouthVoice: 'mouth_001_voice',
  },
  205: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [205000, 205001, 205002, 205003],
      },
      {
        // TODO 仕様書にない配列
        status: StatusControlFlg.HAMARI,
        talkList: [],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [205400, 205401, 205402, 205403],
      },
    ],
    mouthDefault: 'mouth_003_default_1',
    mouthVoice: 'mouth_003_voice',
  },
  206: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [206000, 206001, 206002, 206003],
      },
      {
        // TODO 仕様書にない配列
        status: StatusControlFlg.HAMARI,
        talkList: [],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [206400, 206401, 206402, 206403],
      },
    ],
    mouthDefault: 'mouth_010_default_0',
    mouthVoice: 'mouth_010_voice',
  },
  207: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [207000, 207001, 207002, 207003],
      },
      {
        // TODO 仕様書にない配列
        status: StatusControlFlg.HAMARI,
        talkList: [],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [207400, 207401, 207402, 207403],
      },
    ],
    mouthDefault: 'mouth_003_default_1',
    mouthVoice: 'mouth_003_voice',
  },
  301: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [301000, 301001, 301002, 301003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [301000, 301001, 301002, 301003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [301000, 301001, 301002, 301003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [301000, 301001, 301002, 301003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [301000, 301001, 301002, 301003],
      },
    ],
    mouthDefault: 'mouth_007_default_0',
    mouthVoice: 'mouth_007_voice',
  },
  302: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [302000, 302001, 302002, 302003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [302000, 302001, 302002, 302003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [302000, 302001, 302002, 302003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [302000, 302001, 302002, 302003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [302000, 302001, 302002, 302003],
      },
    ],
    mouthDefault: 'mouth_008_default_0',
    mouthVoice: 'mouth_008_voice',
  },
  303: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [303000, 303001, 303002, 303003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [303000, 303001, 303002, 303003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [303000, 303001, 303002, 303003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [303000, 303001, 303002, 303003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [303000, 303001, 303002, 303003],
      },
    ],
    mouthDefault: 'mouth_004_default_0',
    mouthVoice: 'mouth_004_default_0',
  },
  304: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [304000, 304001, 304002, 304003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [304000, 304001, 304002, 304003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [304000, 304001, 304002, 304003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [304000, 304001, 304002, 304003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [304000, 304001, 304002, 304003],
      },
    ],
    mouthDefault: 'mouth_009_default_0',
    mouthVoice: 'mouth_009_voice',
  },
  305: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [305000, 305001, 305002, 305003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [305000, 305001, 305002, 305003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [305000, 305001, 305002, 305003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [305000, 305001, 305002, 305003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [305000, 305001, 305002, 305003],
      },
    ],
    mouthDefault: 'mouth_003_default_0',
    mouthVoice: 'mouth_003_voice',
  },
  306: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [306000, 306001, 306002, 306003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [306000, 306001, 306002, 306003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [306000, 306001, 306002, 306003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [306000, 306001, 306002, 306003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [306000, 306001, 306002, 306003],
      },
    ],
    mouthDefault: 'mouth_003_default_0',
    mouthVoice: 'mouth_003_voice',
  },
  307: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [307000, 307001, 307002, 307003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [307000, 307001, 307002, 307003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [307000, 307001, 307002, 307003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [307000, 307001, 307002, 307003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [307000, 307001, 307002, 307003],
      },
    ],
    mouthDefault: 'mouth_007_default_0',
    mouthVoice: 'mouth_007_voice',
  },
  311: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [301000, 301001, 301002, 301003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [301000, 301001, 301002, 301003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [301000, 301001, 301002, 301003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [301000, 301001, 301002, 301003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [301000, 301001, 301002, 301003],
      },
    ],
    mouthDefault: 'mouth_007_default_0',
    mouthVoice: 'mouth_007_voice',
  },
  322: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [302000, 302001, 302002, 302003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [302000, 302001, 302002, 302003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [302000, 302001, 302002, 302003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [302000, 302001, 302002, 302003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [302000, 302001, 302002, 302003],
      },
    ],
    mouthDefault: 'mouth_008_default_0',
    mouthVoice: 'mouth_008_voice',
  },
  333: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [303000, 303001, 303002, 303003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [303000, 303001, 303002, 303003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [303000, 303001, 303002, 303003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [303000, 303001, 303002, 303003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [303000, 303001, 303002, 303003],
      },
    ],
    mouthDefault: 'mouth_004_default_0',
    mouthVoice: 'mouth_004_default_0',
  },
  344: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [304000, 304001, 304002, 304003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [304000, 304001, 304002, 304003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [304000, 304001, 304002, 304003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [304000, 304001, 304002, 304003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [304000, 304001, 304002, 304003],
      },
    ],
    mouthDefault: 'mouth_009_default_0',
    mouthVoice: 'mouth_009_voice',
  },
  355: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [305000, 305001, 305002, 305003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [305000, 305001, 305002, 305003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [305000, 305001, 305002, 305003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [305000, 305001, 305002, 305003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [305000, 305001, 305002, 305003],
      },
    ],
    mouthDefault: 'mouth_003_default_0',
    mouthVoice: 'mouth_003_voice',
  },
  366: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [306000, 306001, 306002, 306003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [306000, 306001, 306002, 306003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [306000, 306001, 306002, 306003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [306000, 306001, 306002, 306003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [306000, 306001, 306002, 306003],
      },
    ],
    mouthDefault: 'mouth_003_default_0',
    mouthVoice: 'mouth_003_voice',
  },
  377: {
    talkInfo: [
      {
        status: StatusControlFlg.NORMAL,
        talkList: [307000, 307001, 307002, 307003],
      },
      {
        status: StatusControlFlg.HAMARI,
        talkList: [307000, 307001, 307002, 307003],
      },
      {
        status: StatusControlFlg.ZANNEN,
        talkList: [307000, 307001, 307002, 307003],
      },
      {
        status: StatusControlFlg.GET,
        talkList: [307000, 307001, 307002, 307003],
      },
      {
        status: StatusControlFlg.COUNTUP,
        talkList: [307000, 307001, 307002, 307003],
      },
    ],
    mouthDefault: 'mouth_007_default_0',
    mouthVoice: 'mouth_007_voice',
  },
};

type VoiceParameterInfo = {
  delayMax: number;
  voiceData: ISongs;
  // mousePara: string[];
};

export const VoiceStartParameter: { [key: number]: VoiceParameterInfo } = {};
// 待機1　通常立ち・退屈そう
// モーションID（待機１：１０１）、状況（通常：０、ハマり：１、残念ボナ：２）、２桁の連番
VoiceStartParameter[101000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[101001] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[101002] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[101003] = { delayMax: 3, voiceData: ISongs.SONG_vo10101 };
VoiceStartParameter[101004] = { delayMax: 2, voiceData: ISongs.SONG_vo10102 };
VoiceStartParameter[101005] = { delayMax: 3, voiceData: ISongs.SONG_vo10103 };
VoiceStartParameter[101006] = { delayMax: 2, voiceData: ISongs.SONG_vo10104 };
VoiceStartParameter[101007] = { delayMax: 3, voiceData: ISongs.SONG_vo10105 };
VoiceStartParameter[101008] = { delayMax: 3, voiceData: ISongs.SONG_vo10106 };
VoiceStartParameter[101009] = { delayMax: 3, voiceData: ISongs.SONG_vo10107 };
VoiceStartParameter[101100] = { delayMax: 3, voiceData: ISongs.SONG_vo10108 };
VoiceStartParameter[101101] = { delayMax: 3, voiceData: ISongs.SONG_vo10109 };
VoiceStartParameter[101102] = { delayMax: 3, voiceData: ISongs.SONG_vo10110 };
VoiceStartParameter[101200] = { delayMax: 3, voiceData: ISongs.SONG_vo10111 };
VoiceStartParameter[101201] = { delayMax: 2, voiceData: ISongs.SONG_vo10112 };
VoiceStartParameter[101202] = { delayMax: 3, voiceData: ISongs.SONG_vo10113 };
// 待機2　通常立ち・普通
// モーションID（待機２：１０２）、状況（通常：０、ハマり：１、残念ボナ：２、良ボナ：３）、２桁の連番
VoiceStartParameter[102000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[102001] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[102002] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[102003] = { delayMax: 3, voiceData: ISongs.SONG_vo10201 };
VoiceStartParameter[102004] = { delayMax: 3, voiceData: ISongs.SONG_vo10202 };
VoiceStartParameter[102005] = { delayMax: 3, voiceData: ISongs.SONG_vo10203 };
VoiceStartParameter[102006] = { delayMax: 3, voiceData: ISongs.SONG_vo10204 };
VoiceStartParameter[102007] = { delayMax: 3, voiceData: ISongs.SONG_vo10205 };
VoiceStartParameter[102008] = { delayMax: 3, voiceData: ISongs.SONG_vo10206 };
VoiceStartParameter[102009] = { delayMax: 3, voiceData: ISongs.SONG_vo10207 };
VoiceStartParameter[102100] = { delayMax: 3, voiceData: ISongs.SONG_vo10208 };
VoiceStartParameter[102101] = { delayMax: 3, voiceData: ISongs.SONG_vo10209 };
VoiceStartParameter[102102] = { delayMax: 2, voiceData: ISongs.SONG_vo10210 };
VoiceStartParameter[102200] = { delayMax: 3, voiceData: ISongs.SONG_vo10211 };
VoiceStartParameter[102201] = { delayMax: 2, voiceData: ISongs.SONG_vo10212 };
VoiceStartParameter[102202] = { delayMax: 3, voiceData: ISongs.SONG_vo10213 };
VoiceStartParameter[102300] = { delayMax: 3, voiceData: ISongs.SONG_vo10214 };
VoiceStartParameter[102301] = { delayMax: 3, voiceData: ISongs.SONG_vo10215 };
VoiceStartParameter[102302] = { delayMax: 3, voiceData: ISongs.SONG_vo10216 };
// 待機3　通常立ち・軽くリズム取る
// モーションID（待機３：１０３）、状況（通常：０、ハマり：１、残念ボナ：２、良ボナ：３）、２桁の連番
VoiceStartParameter[103000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[103001] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[103002] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[103003] = { delayMax: 3, voiceData: ISongs.SONG_vo10301 };
VoiceStartParameter[103004] = { delayMax: 3, voiceData: ISongs.SONG_vo10302 };
VoiceStartParameter[103005] = { delayMax: 3, voiceData: ISongs.SONG_vo10303 };
VoiceStartParameter[103006] = { delayMax: 3, voiceData: ISongs.SONG_vo10304 };
VoiceStartParameter[103007] = { delayMax: 2, voiceData: ISongs.SONG_vo10305 };
VoiceStartParameter[103008] = { delayMax: 3, voiceData: ISongs.SONG_vo10306 };
VoiceStartParameter[103009] = { delayMax: 3, voiceData: ISongs.SONG_vo10307 };
VoiceStartParameter[103100] = { delayMax: 3, voiceData: ISongs.SONG_vo10308 };
VoiceStartParameter[103101] = { delayMax: 3, voiceData: ISongs.SONG_vo10309 };
VoiceStartParameter[103102] = { delayMax: 3, voiceData: ISongs.SONG_vo10310 };
VoiceStartParameter[103300] = { delayMax: 3, voiceData: ISongs.SONG_vo10311 };
VoiceStartParameter[103301] = { delayMax: 3, voiceData: ISongs.SONG_vo10312 };
VoiceStartParameter[103302] = { delayMax: 3, voiceData: ISongs.SONG_vo10313 };
// 待機4　通常立ち・ご機嫌状態
// モーションID（待機４：１０４）、状況（通常：０、ハマり：１、残念ボナ：２、良ボナ：３）、２桁の連番
VoiceStartParameter[104000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[104001] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[104002] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[104003] = { delayMax: 1, voiceData: ISongs.SONG_vo10401 };
VoiceStartParameter[104004] = { delayMax: 3, voiceData: ISongs.SONG_vo10402 };
VoiceStartParameter[104005] = { delayMax: 3, voiceData: ISongs.SONG_vo10403 };
VoiceStartParameter[104006] = { delayMax: 2, voiceData: ISongs.SONG_vo10404 };
VoiceStartParameter[104007] = { delayMax: 1, voiceData: ISongs.SONG_vo10405 };
VoiceStartParameter[104008] = { delayMax: 3, voiceData: ISongs.SONG_vo10406 };
VoiceStartParameter[104009] = { delayMax: 2, voiceData: ISongs.SONG_vo10407 };
VoiceStartParameter[104300] = { delayMax: 2, voiceData: ISongs.SONG_vo10408 };
VoiceStartParameter[104301] = { delayMax: 3, voiceData: ISongs.SONG_vo10409 };
VoiceStartParameter[104302] = { delayMax: 1, voiceData: ISongs.SONG_vo10410 };
// 待機5　あくび
// モーションID（待機４：１０５）、状況（通常：０、ハマり：１、残念ボナ：２）、２桁の連番
VoiceStartParameter[105000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[105100] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[105200] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
// 見守り1　肩を落として絶望
// モーションID（見守り１：２０１）、状況（通常：０、ハマり：１、残念ボナ：２、カウントアップ４）、２桁の連番
VoiceStartParameter[201000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[201001] = { delayMax: 3, voiceData: ISongs.SONG_vo20101 };
VoiceStartParameter[201002] = { delayMax: 3, voiceData: ISongs.SONG_vo20102 };
VoiceStartParameter[201003] = { delayMax: 3, voiceData: ISongs.SONG_vo20103 };
VoiceStartParameter[201400] = { delayMax: 3, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[201401] = { delayMax: 3, voiceData: ISongs.SONG_vo20104 };
VoiceStartParameter[201402] = { delayMax: 3, voiceData: ISongs.SONG_vo20105 };
VoiceStartParameter[201403] = { delayMax: 3, voiceData: ISongs.SONG_vo20106 };
// 見守り2　 手を胸の前で組んで半泣きで祈る
// モーションID（見守り２：２０２）、状況（通常：０、ハマり：１、残念ボナ：２）、２桁の連番
VoiceStartParameter[202000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[202001] = { delayMax: 3, voiceData: ISongs.SONG_vo20201 };
VoiceStartParameter[202002] = { delayMax: 3, voiceData: ISongs.SONG_vo20202 };
VoiceStartParameter[202003] = { delayMax: 3, voiceData: ISongs.SONG_vo20203 };
// 見守り3　 通常立ち　見守る
// モーションID（見守り３：２０３）、状況（通常：０、ハマり：１、残念ボナ：２、カウントアップ４）、２桁の連番
VoiceStartParameter[203000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[203001] = { delayMax: 3, voiceData: ISongs.SONG_vo20301 };
VoiceStartParameter[203002] = { delayMax: 3, voiceData: ISongs.SONG_vo20302 };
VoiceStartParameter[203003] = { delayMax: 3, voiceData: ISongs.SONG_vo20303 };
VoiceStartParameter[203400] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[203401] = { delayMax: 3, voiceData: ISongs.SONG_vo20304 };
VoiceStartParameter[203402] = { delayMax: 2, voiceData: ISongs.SONG_vo20305 };
VoiceStartParameter[203403] = { delayMax: 3, voiceData: ISongs.SONG_vo20306 };
// 見守り4　 通常立ち　見守る
// モーションID（見守り４：２０４）、状況（通常：０、ハマり：１、残念ボナ：２、カウントアップ４）、２桁の連番
VoiceStartParameter[204000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[204001] = { delayMax: 3, voiceData: ISongs.SONG_vo20401 };
VoiceStartParameter[204002] = { delayMax: 3, voiceData: ISongs.SONG_vo20402 };
VoiceStartParameter[204003] = { delayMax: 3, voiceData: ISongs.SONG_vo20403 };
VoiceStartParameter[204400] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[204401] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[204402] = { delayMax: 3, voiceData: ISongs.SONG_vo20404 };
VoiceStartParameter[204403] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
// 見守り5　 少し腕を開いた立ち　楽しそうに見守る
// モーションID（見守り５：２０５）、状況（通常：０、ハマり：１、残念ボナ：２、カウントアップ４）、２桁の連番
VoiceStartParameter[205000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[205001] = { delayMax: 3, voiceData: ISongs.SONG_vo20501 };
VoiceStartParameter[205002] = { delayMax: 3, voiceData: ISongs.SONG_vo20502 };
VoiceStartParameter[205003] = { delayMax: 3, voiceData: ISongs.SONG_vo20503 };
VoiceStartParameter[205400] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[205401] = { delayMax: 3, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[205402] = { delayMax: 3, voiceData: ISongs.SONG_vo20504 };
VoiceStartParameter[205403] = { delayMax: 3, voiceData: ISongs.SONG_vo20505 };
// 見守り6　 片腕を上げて応援。逆の腕は胸の前まで上げる
// モーションID（見守り６：２０６）、状況（通常：０、ハマり：１、残念ボナ：２、カウントアップ４）、２桁の連番
VoiceStartParameter[206000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[206001] = { delayMax: 3, voiceData: ISongs.SONG_vo20601 };
VoiceStartParameter[206002] = { delayMax: 3, voiceData: ISongs.SONG_vo20602 };
VoiceStartParameter[206003] = { delayMax: 3, voiceData: ISongs.SONG_vo20603 };
VoiceStartParameter[206400] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[206401] = { delayMax: 3, voiceData: ISongs.SONG_vo20604 };
VoiceStartParameter[206402] = { delayMax: 3, voiceData: ISongs.SONG_vo20605 };
VoiceStartParameter[206403] = { delayMax: 3, voiceData: ISongs.SONG_vo10000 };
// 見守り7　 両腕を上げてぴょんぴょん跳ねる
// モーションID（見守り７：２０７）、状況（通常：０、ハマり：１、残念ボナ：２、カウントアップ４）、２桁の連番
VoiceStartParameter[207000] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[207001] = { delayMax: 3, voiceData: ISongs.SONG_vo20701 };
VoiceStartParameter[207002] = { delayMax: 3, voiceData: ISongs.SONG_vo20702 };
VoiceStartParameter[207003] = { delayMax: 3, voiceData: ISongs.SONG_vo20703 };
VoiceStartParameter[207400] = { delayMax: 0, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[207401] = { delayMax: 3, voiceData: ISongs.SONG_vo10000 };
VoiceStartParameter[207402] = { delayMax: 3, voiceData: ISongs.SONG_vo20704 };
VoiceStartParameter[207403] = { delayMax: 3, voiceData: ISongs.SONG_vo20705 };
// ﾘｱｸｼｮﾝ1　 両腕を胸の前まで上げて悔しがる
// モーションID（リアクション１：３０１）、状況（通常：０）、２桁の連番
VoiceStartParameter[301000] = { delayMax: 0, voiceData: ISongs.SONG_vo30101 };
VoiceStartParameter[301001] = { delayMax: 0, voiceData: ISongs.SONG_vo30102 };
VoiceStartParameter[301002] = { delayMax: 0, voiceData: ISongs.SONG_vo30103 };
VoiceStartParameter[301003] = { delayMax: 0, voiceData: ISongs.SONG_vo30104 };
// ﾘｱｸｼｮﾝ2　 顔を上げてショックを受ける
// モーションID（リアクション２：３０２）、状況（通常：０）、２桁の連番
VoiceStartParameter[302000] = { delayMax: 0, voiceData: ISongs.SONG_vo30201 };
VoiceStartParameter[302001] = { delayMax: 0, voiceData: ISongs.SONG_vo30301 };
VoiceStartParameter[302002] = { delayMax: 0, voiceData: ISongs.SONG_vo30203 };
VoiceStartParameter[302003] = { delayMax: 0, voiceData: ISongs.SONG_vo30204 };
// ﾘｱｸｼｮﾝ3　 通常立ち　がっくりと肩を落とす
// モーションID（リアクション３：３０３）、状況（通常：０）、２桁の連番
VoiceStartParameter[303000] = { delayMax: 0, voiceData: ISongs.SONG_vo30301 };
VoiceStartParameter[303001] = { delayMax: 0, voiceData: ISongs.SONG_vo30201 };
VoiceStartParameter[303002] = { delayMax: 0, voiceData: ISongs.SONG_vo30303 };
VoiceStartParameter[303003] = { delayMax: 0, voiceData: ISongs.SONG_vo30304 };
// ﾘｱｸｼｮﾝ4　 通常立ち　わずかに跳ねて「おっ！」小喜び
// モーションID（リアクション４：３０４）、状況（通常：０）、２桁の連番
VoiceStartParameter[304000] = { delayMax: 0, voiceData: ISongs.SONG_vo30401 };
VoiceStartParameter[304001] = { delayMax: 0, voiceData: ISongs.SONG_vo30402 };
VoiceStartParameter[304002] = { delayMax: 0, voiceData: ISongs.SONG_vo30403 };
VoiceStartParameter[304003] = { delayMax: 0, voiceData: ISongs.SONG_vo30404 };
// ﾘｱｸｼｮﾝ5　 両腕を半分上げる
// モーションID（リアクション５：３０５）、状況（通常：０）、２桁の連番
VoiceStartParameter[305000] = { delayMax: 0, voiceData: ISongs.SONG_vo30501 };
VoiceStartParameter[305001] = { delayMax: 0, voiceData: ISongs.SONG_vo30502 };
VoiceStartParameter[305002] = { delayMax: 0, voiceData: ISongs.SONG_vo30503 };
VoiceStartParameter[305003] = { delayMax: 0, voiceData: ISongs.SONG_vo30504 };
// ﾘｱｸｼｮﾝ5　 片腕を上げてガッツポーズ。逆の腕は胸の前まで上げる
// モーションID（リアクション６：３０６）、状況（通常：０）、２桁の連番
VoiceStartParameter[306000] = { delayMax: 0, voiceData: ISongs.SONG_vo30601 };
VoiceStartParameter[306001] = { delayMax: 0, voiceData: ISongs.SONG_vo30602 };
VoiceStartParameter[306002] = { delayMax: 0, voiceData: ISongs.SONG_vo30603 };
VoiceStartParameter[306003] = { delayMax: 0, voiceData: ISongs.SONG_vo30604 };
// ﾘｱｸｼｮﾝ7　 画面中央を向き盤面を片手でバンバン叩く
// モーションID（リアクション７：３０７）、状況（通常：０）、２桁の連番
VoiceStartParameter[307000] = { delayMax: 0, voiceData: ISongs.SONG_vo30701 };
VoiceStartParameter[307001] = { delayMax: 0, voiceData: ISongs.SONG_vo30702 };
VoiceStartParameter[307002] = { delayMax: 0, voiceData: ISongs.SONG_vo30703 };
VoiceStartParameter[307003] = { delayMax: 0, voiceData: ISongs.SONG_vo30704 };
