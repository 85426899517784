// eslint-disable-next-line
import * as PIXI from 'pixi.js';

// import AudioApi from '@phoenix7dev/audio-api';
import {
  EventTypes,
  FreeSpinsTitleProps,
  GameMode,
  MessageBonusBannerProps,
  MessageWinBannerProps,
} from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { debugDisplay, isGambleMode } from '../../utils';
import VAvatar from '../Avatar/vAvatar';
import Tween from '../animations/tween';
import Backdrop2 from '../backdrop2/backdrop2';
import BgmControl from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import {
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  GAME_VIEW_SLOTS_CONTAINER_1_LANDSCAPE_Y,
  GAME_VIEW_SLOTS_CONTAINER_1_PORTRAIT_Y,
  GAME_VIEW_SLOTS_CONTAINER_2_GAMBLE_LANDSCAPE_Y,
  GAME_VIEW_SLOTS_CONTAINER_2_GAMBLE_PORTRAIT_Y,
  GAME_VIEW_SLOTS_CONTAINER_2_LANDSCAPE_Y,
  GAME_VIEW_SLOTS_CONTAINER_2_PORTRAIT_Y,
  GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_LANDSCAPE_Y,
  GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_PORTRAIT_Y,
  GAME_VIEW_SLOTS_CONTAINER_3_LANDSCAPE_Y,
  GAME_VIEW_SLOTS_CONTAINER_3_PORTRAIT_Y,
  REEL_WIDTH,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_HEIGHT,
  Z_INDEX_GAME_VIEW,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import FreeSpinsPanel from '../freeSpinCounter/freeSpinsPanel';
import { MessageBonusBanner } from '../messageBanner/messageBonusBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import ReelsFrame from '../reels/frame/reelsFrame';
import ReplayText from '../replay/replayText';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GambleView from './gambleView';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public winSlotsContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container[] = [];

  public tintContainer: PIXI.Container[] = [];

  public slotsContainer: PIXI.Container[] = [];

  public winLabelContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gambleView: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public frame: ReelsFrame;

  private messageWinBanner: MessageWinBanner | undefined;

  private avatar: VAvatar;

  private isLandScape = true;

  public freeSpinsPanel: FreeSpinsPanel | undefined;

  private replayText: ReplayText;

  private backdropSafeArea: ViewContainer;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    for (let i = 0; i < 9; i++) {
      this.slotsContainer[i] = new PIXI.Container();
      this.slotsContainer[i]!.width = SLOTS_CONTAINER_WIDTH;
      this.slotsContainer[i]!.height = SLOTS_CONTAINER_HEIGHT;
      this.slotsContainer[i]!.x = 11.5;

      if (Math.floor(i % 3) === 0) {
        this.slotsContainer[i]!.x = 0;
      } else if (Math.floor(i % 3) === 1) {
        this.slotsContainer[i]!.x = 313;
      } else if (Math.floor(i % 3) === 2) {
        this.slotsContainer[i]!.x = 627;
      }
      if (Math.floor(i / 3) === 0) {
        this.slotsContainer[i]!.y = -176.5;
      } else if (Math.floor(i / 3) === 1) {
        this.slotsContainer[i]!.y = 93.5;
      } else if (Math.floor(i / 3) === 2) {
        this.slotsContainer[i]!.y = 366.5;
      }

      this.slotsContainer[i]!.sortableChildren = true;
      this.slotsContainer[i]!.scale.set(1, 1);
      this.slotsContainer[i]!.interactive = true;
      this.slotsContainer[i]!.zIndex = Z_INDEX_GAME_VIEW;
    }
    this.gameTitle = new GameTitle();
    this.gambleView = new GambleView();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;

    for (let i = 0; i < 9; i++) {
      if (this.slotsContainer[i] != undefined) {
        this.slotsContainer[i]!.addChild(this.tintContainer[i]!);
        this.slotsContainer[i]!.addChild(this.reelsContainer[i]!);
        const mask = new PIXI.Graphics()
          .beginFill(0xffffff)
          .drawRect((SLOTS_CONTAINER_WIDTH - SLOTS_BACKGROUND_WIDTH) / 2, 242 / 2, REEL_WIDTH, SLOT_HEIGHT + 75)
          .endFill();
        // mask.x = Math.floor(i % 3) * 300;
        mask.x = 0;
        if (Math.floor(i / 3) === 0) {
          mask.y = -10;
        } else if (Math.floor(i / 3) === 1) {
          mask.y = -9;
        } else if (Math.floor(i / 3) === 2) {
          mask.y = -9;
        }
        this.slotsContainer[i]!.mask = mask;
        this.slotsContainer[i]!.addChild(mask);
      }
    }
    this.addChild(this.reelsBackgroundContainer);
    for (let i = 0; i < 9; i++) {
      this.addChild(this.slotsContainer[i]!);
    }
    this.replayText = new ReplayText();
    this.addChild(this.replayText);
    this.frame = new ReelsFrame();
    this.addChild(this.frame);
    this.avatar = new VAvatar();
    this.addChild(this.avatar);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.gameTitle);
    this.addChild(this.gambleView);
    this.addChild(this.coinsAnimationContainer);

    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage); // TODO

    this.backdropSafeArea = new Backdrop2();
    this.addChild(this.backdropSafeArea);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPINS_TITLE, this.createFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.REMOVE_FREE_SPINS_TITLE, this.removeFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));

    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));

    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private removeFreeSpinsTitle(): void {
    if (this.freeSpinsPanel) {
      this.removeChild(this.freeSpinsPanel);
      this.freeSpinsPanel?.destroy();
      this.freeSpinsPanel = undefined;
    }
  }

  private createFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    this.freeSpinsPanel = new FreeSpinsPanel(props, this.isLandScape);
    this.addChild(this.freeSpinsPanel);
  }

  private resize(width: number, height: number): void {
    for (let i = 0; i < 9; i++) {
      if (Math.floor(i / 3) === 0) {
        this.slotsContainer[i]!.y =
          width > height ? GAME_VIEW_SLOTS_CONTAINER_1_LANDSCAPE_Y : GAME_VIEW_SLOTS_CONTAINER_1_PORTRAIT_Y;
      } else if (Math.floor(i / 3) === 1) {
        if (isGambleMode(setGameMode())) {
          this.slotsContainer[i]!.y =
            width > height
              ? GAME_VIEW_SLOTS_CONTAINER_2_GAMBLE_LANDSCAPE_Y
              : GAME_VIEW_SLOTS_CONTAINER_2_GAMBLE_PORTRAIT_Y;
        } else {
          this.slotsContainer[i]!.y =
            width > height ? GAME_VIEW_SLOTS_CONTAINER_2_LANDSCAPE_Y : GAME_VIEW_SLOTS_CONTAINER_2_PORTRAIT_Y;
        }
      } else if (Math.floor(i / 3) === 2) {
        if (isGambleMode(setGameMode())) {
          this.slotsContainer[i]!.y =
            width > height
              ? GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_LANDSCAPE_Y
              : GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_PORTRAIT_Y;
        } else {
          this.slotsContainer[i]!.y =
            width > height ? GAME_VIEW_SLOTS_CONTAINER_3_LANDSCAPE_Y : GAME_VIEW_SLOTS_CONTAINER_3_PORTRAIT_Y;
        }
      }
    }
    if (width > height) {
      this.isLandScape = true;
    } else {
      this.isLandScape = false;
    }
  }

  private createFreeSpinsMessage(props: MessageBonusBannerProps): void {
    this.addChild(new MessageBonusBanner(props, this.isLandScape).init());
    // TODO AudioApi.play({ type: ISongs.BB_Banner });
  }

  private createWinMessage(props: MessageWinBannerProps): void {
    debugDisplay('createWinMessage');
    const totalWinDelay = Tween.createDelayAnimation(1000);
    totalWinDelay.addOnStart(() => {
      BgmControl.fadeOutAll(250);
    });
    totalWinDelay.addOnComplete(() => {
      BgmControl.stopBgm();
    });
    totalWinDelay.addOnSkip(() => {
      BgmControl.stopBgm();
      // AudioApi.fadeOut(1000, ISongs.TotalWinBanner);
    });

    totalWinDelay.start();
    // bgmControl.StopBonusBgmAll(); TODO bonus音停止確認
    this.messageWinBanner = new MessageWinBanner(
      {
        ...props,
        callback: () => {
          totalWinDelay.skip();
          if (props.callback) props.callback();
        },
      },
      this.isLandScape,
    ).init();
    this.messageWinBanner.visible = false;
    this.addChild(this.messageWinBanner);
    this.messageWinBanner.updateTransform();
    this.messageWinBanner.visible = true;
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isGambleMode(settings.mode)) {
      this.slotsContainer[0]!.visible = false;
      this.slotsContainer[1]!.visible = false;
      this.slotsContainer[2]!.visible = false;
      this.slotsContainer[3]!.visible = false;
      this.slotsContainer[5]!.visible = false;

      this.slotsContainer[4]!.position.y = this.isLandScape
        ? GAME_VIEW_SLOTS_CONTAINER_2_GAMBLE_LANDSCAPE_Y
        : GAME_VIEW_SLOTS_CONTAINER_2_GAMBLE_PORTRAIT_Y;
      this.slotsContainer[6]!.position.y = this.isLandScape
        ? GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_LANDSCAPE_Y
        : GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_PORTRAIT_Y;
      this.slotsContainer[7]!.position.y = this.isLandScape
        ? GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_LANDSCAPE_Y
        : GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_PORTRAIT_Y;
      this.slotsContainer[8]!.position.y = this.isLandScape
        ? GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_LANDSCAPE_Y
        : GAME_VIEW_SLOTS_CONTAINER_3_GAMBLE_PORTRAIT_Y;

      debugDisplay('this.slotsContainer[4]!.position.y', this.slotsContainer[4]!.position.y);
    } else {
      for (let i = 0; i < 9; i++) {
        this.slotsContainer[i]!.visible = true;

        if (Math.floor(i / 3) === 0) {
          this.slotsContainer[i]!.y = this.isLandScape
            ? GAME_VIEW_SLOTS_CONTAINER_1_LANDSCAPE_Y
            : GAME_VIEW_SLOTS_CONTAINER_1_PORTRAIT_Y;
        } else if (Math.floor(i / 3) === 1) {
          this.slotsContainer[i]!.y = this.isLandScape
            ? GAME_VIEW_SLOTS_CONTAINER_2_LANDSCAPE_Y
            : GAME_VIEW_SLOTS_CONTAINER_2_PORTRAIT_Y;
        } else if (Math.floor(i / 3) === 2) {
          this.slotsContainer[i]!.y = this.isLandScape
            ? GAME_VIEW_SLOTS_CONTAINER_3_LANDSCAPE_Y
            : GAME_VIEW_SLOTS_CONTAINER_3_PORTRAIT_Y;
        }
      }
    }
  }

  private resizeGameContainer(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
