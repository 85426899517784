import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export const ISongs = {
  SONG_030_01_BG_Loop: '030_01_BG_Loop',
  SONG_030_02_BG_Melo: '030_02_BG_Melo',
  SONG_030_03_FS_int: '030_03_FS_int',
  SONG_030_03_FS_Loop: '030_03_FS_Loop',
  SONG_030_04_TotalWin: '030_04_TotalWin',
  SONG_030_05_Gamble_Loop: '030_05_Gamble_Loop',
  SONG_030_06_FS_Trigger: '030_06_FS_Trigger',
  SONG_030_07_BuyButton: '030_07_BuyButton',
  SONG_030_08_Win_Loop: '030_08_Win_Loop',
  SONG_030_09_Win_End: '030_09_Win_End',
  SONG_030_10_BigWin_Loop: '030_10_BigWin_Loop',
  SONG_030_11_BigWin_End: '030_11_BigWin_End',
  SONG_030_12_BigWin_Jingle: '030_12_BigWin_Jingle',
  SONG_030_13_Wild_Move1_Loop: '030_13_Wild_Move1_Loop',
  SONG_030_14_Wild_Move2_Loop: '030_14_Wild_Move2_Loop',
  SONG_030_15_Wild_Stop1: '030_15_Wild_Stop1',
  SONG_030_16_Wild_Stop2: '030_16_Wild_Stop2',
  SONG_030_17_PressGamble: '030_17_PressGamble',
  SONG_030_18_GambleWin: '030_18_GambleWin',
  SONG_030_19_GamblePush: '030_19_GamblePush',
  SONG_030_20_GambleSP: '030_20_GambleSP',
  SONG_SE_Yocho_Phoenix: 'SE_Yocho_Phoenix',
  SONG_SFX_BuyFeature: 'SFX_BuyFeature',
  SONG_SFX_SM_CountUp_End: 'SFX_SM_CountUp_End',
  SONG_SFX_SM_CountUp_Loop: 'SFX_SM_CountUp_Loop',
  SONG_SFX_UI_AutoSpin: 'SFX_UI_AutoSpin',
  SONG_SFX_UI_BetChange: 'SFX_UI_BetChange',
  SONG_SFX_UI_Close: 'SFX_UI_Close',
  SONG_SFX_UI_General: 'SFX_UI_General',
  SONG_SFX_UI_Hover: 'SFX_UI_Hover',
  SONG_SFX_UI_MaxBet: 'SFX_UI_MaxBet',
  SONG_SFX_UI_MenuOpen: 'SFX_UI_MenuOpen',
  SONG_SFX_UI_SpinStart: 'SFX_UI_SpinStart',
  SONG_SFX_UI_SpinStop: 'SFX_UI_SpinStop',
  SONG_SFX_WIN_FeatureTrigger: 'SFX_WIN_FeatureTrigger',
  SONG_SFX_Win_Big: 'SFX_Win_Big',
  SONG_SFX_Win_Epic: 'SFX_Win_Epic',
  SONG_SFX_Win_Great: 'SFX_Win_Great',
  SONG_SFX_Win_Mega: 'SFX_Win_Mega',
  SONG_vo10000: 'vo10000',
  SONG_vo10101: 'vo10101',
  SONG_vo10102: 'vo10102',
  SONG_vo10103: 'vo10103',
  SONG_vo10104: 'vo10104',
  SONG_vo10105: 'vo10105',
  SONG_vo10106: 'vo10106',
  SONG_vo10107: 'vo10107',
  SONG_vo10108: 'vo10108',
  SONG_vo10109: 'vo10109',
  SONG_vo10110: 'vo10110',
  SONG_vo10111: 'vo10111',
  SONG_vo10112: 'vo10112',
  SONG_vo10113: 'vo10113',
  SONG_vo10201: 'vo10201',
  SONG_vo10202: 'vo10202',
  SONG_vo10203: 'vo10203',
  SONG_vo10204: 'vo10204',
  SONG_vo10205: 'vo10205',
  SONG_vo10206: 'vo10206',
  SONG_vo10207: 'vo10207',
  SONG_vo10208: 'vo10208',
  SONG_vo10209: 'vo10209',
  SONG_vo10210: 'vo10210',
  SONG_vo10211: 'vo10211',
  SONG_vo10212: 'vo10212',
  SONG_vo10213: 'vo10213',
  SONG_vo10214: 'vo10214',
  SONG_vo10215: 'vo10215',
  SONG_vo10216: 'vo10216',
  SONG_vo10301: 'vo10301',
  SONG_vo10302: 'vo10302',
  SONG_vo10303: 'vo10303',
  SONG_vo10304: 'vo10304',
  SONG_vo10305: 'vo10305',
  SONG_vo10306: 'vo10306',
  SONG_vo10307: 'vo10307',
  SONG_vo10308: 'vo10308',
  SONG_vo10309: 'vo10309',
  SONG_vo10310: 'vo10310',
  SONG_vo10311: 'vo10311',
  SONG_vo10312: 'vo10312',
  SONG_vo10313: 'vo10313',
  SONG_vo10401: 'vo10401',
  SONG_vo10402: 'vo10402',
  SONG_vo10403: 'vo10403',
  SONG_vo10404: 'vo10404',
  SONG_vo10405: 'vo10405',
  SONG_vo10406: 'vo10406',
  SONG_vo10407: 'vo10407',
  SONG_vo10408: 'vo10408',
  SONG_vo10409: 'vo10409',
  SONG_vo10410: 'vo10410',
  SONG_vo20101: 'vo20101',
  SONG_vo20102: 'vo20102',
  SONG_vo20103: 'vo20103',
  SONG_vo20104: 'vo20104',
  SONG_vo20105: 'vo20105',
  SONG_vo20106: 'vo20106',
  SONG_vo20201: 'vo20201',
  SONG_vo20202: 'vo20202',
  SONG_vo20203: 'vo20203',
  SONG_vo20301: 'vo20301',
  SONG_vo20302: 'vo20302',
  SONG_vo20303: 'vo20303',
  SONG_vo20304: 'vo20304',
  SONG_vo20305: 'vo20305',
  SONG_vo20306: 'vo20306',
  SONG_vo20401: 'vo20401',
  SONG_vo20402: 'vo20402',
  SONG_vo20403: 'vo20403',
  SONG_vo20404: 'vo20404',
  SONG_vo20405: 'vo20405',
  SONG_vo20406: 'vo20406',
  SONG_vo20501: 'vo20501',
  SONG_vo20502: 'vo20502',
  SONG_vo20503: 'vo20503',
  SONG_vo20504: 'vo20504',
  SONG_vo20505: 'vo20505',
  SONG_vo20601: 'vo20601',
  SONG_vo20602: 'vo20602',
  SONG_vo20603: 'vo20603',
  SONG_vo20604: 'vo20604',
  SONG_vo20605: 'vo20605',
  SONG_vo20606: 'vo20606',
  SONG_vo20701: 'vo20701',
  SONG_vo20702: 'vo20702',
  SONG_vo20703: 'vo20703',
  SONG_vo20704: 'vo20704',
  SONG_vo20705: 'vo20705',
  SONG_vo30101: 'vo30101',
  SONG_vo30102: 'vo30102',
  SONG_vo30103: 'vo30103',
  SONG_vo30104: 'vo30104',
  SONG_vo30201: 'vo30201',
  SONG_vo30202: 'vo30202',
  SONG_vo30203: 'vo30203',
  SONG_vo30204: 'vo30204',
  SONG_vo30301: 'vo30301',
  SONG_vo30302: 'vo30302',
  SONG_vo30303: 'vo30303',
  SONG_vo30304: 'vo30304',
  SONG_vo30401: 'vo30401',
  SONG_vo30402: 'vo30402',
  SONG_vo30403: 'vo30403',
  SONG_vo30404: 'vo30404',
  SONG_vo30501: 'vo30501',
  SONG_vo30502: 'vo30502',
  SONG_vo30503: 'vo30503',
  SONG_vo30504: 'vo30504',
  SONG_vo30601: 'vo30601',
  SONG_vo30602: 'vo30602',
  SONG_vo30603: 'vo30603',
  SONG_vo30604: 'vo30604',
  SONG_vo30701: 'vo30701',
  SONG_vo30702: 'vo30702',
  SONG_vo30703: 'vo30703',
  SONG_vo30704: 'vo30704',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ISongs = (typeof ISongs)[keyof typeof ISongs];

export const audioSprite: TAudioSprite = {
  '030_01_BG_Loop': [0, 55813.945578231294, true],
  '030_02_BG_Melo': [57000, 55813.94557823129, true],
  '030_03_FS_Loop': [114000, 30909.070294784586, true],
  '030_03_FS_int': [146000, 1818.2086167800549],
  '030_04_TotalWin': [149000, 5626.507936507949],
  '030_05_Gamble_Loop': [156000, 10212.811791383217, true],
  '030_06_FS_Trigger': [168000, 2416.8934240362887],
  '030_07_BuyButton': [172000, 666.6666666666572],
  '030_08_Win_Loop': [174000, 399.7505668934309, true],
  '030_09_Win_End': [176000, 933.4693877551103],
  '030_10_BigWin_Loop': [178000, 20645.170068027197, true],
  '030_11_BigWin_End': [200000, 3396.145124716554],
  '030_12_BigWin_Jingle': [205000, 4463.015873015876],
  '030_13_Wild_Move1_Loop': [211000, 500, true],
  '030_14_Wild_Move2_Loop': [213000, 500, true],
  '030_15_Wild_Stop1': [215000, 1325.714285714298],
  '030_16_Wild_Stop2': [218000, 433.3333333333371],
  '030_17_PressGamble': [220000, 342.31292517006295],
  '030_18_GambleWin': [222000, 2196.5532879818566],
  '030_19_GamblePush': [226000, 916.7120181406005],
  '030_20_GambleSP': [228000, 2023.7188208616885],
  'SE_Yocho_Phoenix': [232000, 4033.333333333331],
  'SFX_BuyFeature': [238000, 666.6666666666572],
  'SFX_SM_CountUp_End': [240000, 933.4693877551103],
  'SFX_SM_CountUp_Loop': [242000, 399.7505668934309, true],
  'SFX_UI_AutoSpin': [244000, 320.47619047619946],
  'SFX_UI_BetChange': [246000, 32.08616780045759],
  'SFX_UI_Close': [248000, 200.68027210885475],
  'SFX_UI_General': [250000, 88.34467120180989],
  'SFX_UI_Hover': [252000, 151.76870748300075],
  'SFX_UI_MaxBet': [254000, 122.92517006801518],
  'SFX_UI_MenuOpen': [256000, 277.1428571428487],
  'SFX_UI_SpinStart': [258000, 249.59183673468033],
  'SFX_UI_SpinStop': [260000, 151.02040816327644],
  'SFX_WIN_FeatureTrigger': [262000, 879.841269841279],
  'SFX_Win_Big': [264000, 1622.3356009070358],
  'SFX_Win_Epic': [267000, 1862.2675736961583],
  'SFX_Win_Great': [270000, 2118.390022675726],
  'SFX_Win_Mega': [274000, 1696.1678004535088],
  'vo10000': [277000, 101.33786848069803],
  'vo10101': [279000, 2612.471655328818],
  'vo10102': [283000, 3055.668934240373],
  'vo10103': [288000, 1671.0430839002015],
  'vo10104': [291000, 3181.2698412698523],
  'vo10105': [296000, 2748.0498866212884],
  'vo10106': [300000, 2917.460317460325],
  'vo10107': [304000, 2519.138321995456],
  'vo10108': [308000, 2639.7959183673265],
  'vo10109': [312000, 2132.811791383233],
  'vo10110': [316000, 2515.8049886621257],
  'vo10111': [320000, 2383.469387755099],
  'vo10112': [324000, 3837.460317460341],
  'vo10113': [329000, 2344.8752834466973],
  'vo10201': [333000, 2646.3038548752706],
  'vo10202': [337000, 1721.269841269816],
  'vo10203': [340000, 1127.4603174603044],
  'vo10204': [343000, 1824.6258503401123],
  'vo10205': [346000, 2217.6870748299393],
  'vo10206': [350000, 2138.639455782311],
  'vo10207': [354000, 2901.836734693859],
  'vo10208': [358000, 2538.5487528344584],
  'vo10209': [362000, 2184.3764172335796],
  'vo10210': [366000, 3671.2698412698614],
  'vo10211': [371000, 1749.3197278911339],
  'vo10212': [374000, 3201.269841269834],
  'vo10213': [379000, 1970.3401360544035],
  'vo10214': [382000, 2023.333333333312],
  'vo10215': [386000, 1615.2154195011121],
  'vo10216': [389000, 2102.87981859409],
  'vo10301': [393000, 1252.5623582766343],
  'vo10302': [396000, 1925.6689342403774],
  'vo10303': [399000, 2088.2993197278665],
  'vo10304': [403000, 2717.9365079364857],
  'vo10305': [407000, 3112.471655328818],
  'vo10306': [412000, 2316.4172335601165],
  'vo10307': [416000, 983.6281179138382],
  'vo10308': [418000, 1205.6009070294635],
  'vo10309': [421000, 1892.0181405895846],
  'vo10310': [424000, 1727.9365079365334],
  'vo10311': [427000, 2701.383219954664],
  'vo10312': [431000, 1413.7188208616749],
  'vo10313': [434000, 1878.9342403628098],
  'vo10401': [437000, 4125.351473922876],
  'vo10402': [443000, 2561.088435374131],
  'vo10403': [447000, 2452.471655328793],
  'vo10404': [451000, 3916.6666666666856],
  'vo10405': [456000, 4317.460317460302],
  'vo10406': [462000, 2497.460317460309],
  'vo10407': [466000, 3234.1950113378743],
  'vo10408': [471000, 3161.76870748302],
  'vo10409': [476000, 1884.6258503401145],
  'vo10410': [479000, 4183.015873015847],
  'vo20101': [485000, 1663.8775510203914],
  'vo20102': [488000, 1067.9365079365084],
  'vo20103': [491000, 1583.7188208616908],
  'vo20104': [494000, 2037.0521541950097],
  'vo20105': [498000, 1140.5442176870793],
  'vo20106': [501000, 1580.7936507936233],
  'vo20201': [504000, 1508.5034013605423],
  'vo20202': [507000, 1861.2925170068024],
  'vo20203': [510000, 2724.353741496543],
  'vo20301': [514000, 1121.7687074829428],
  'vo20302': [517000, 1646.9614512471935],
  'vo20303': [520000, 1281.5192743763646],
  'vo20304': [523000, 1524.9206349205906],
  'vo20305': [526000, 3814.1043083900286],
  'vo20306': [531000, 2248.2993197278347],
  'vo20401': [535000, 1663.628117913845],
  'vo20402': [538000, 1579.0476190476284],
  'vo20403': [541000, 1380.294784580542],
  'vo20404': [544000, 1022.4716553287863],
  'vo20405': [547000, 2756.2131519274544],
  'vo20406': [551000, 1937.9591836734562],
  'vo20501': [554000, 1028.299319727921],
  'vo20502': [557000, 1158.2086167800298],
  'vo20503': [560000, 1362.018140589612],
  'vo20504': [563000, 1105.9637188208171],
  'vo20505': [566000, 1554.1723356009243],
  'vo20601': [569000, 1864.5124716553028],
  'vo20602': [572000, 1878.75283446715],
  'vo20603': [575000, 1273.4693877550853],
  'vo20604': [578000, 1783.1292517006432],
  'vo20605': [581000, 2325.147392290205],
  'vo20606': [585000, 1331.0204081632264],
  'vo20701': [588000, 2017.278911564631],
  'vo20702': [592000, 1400.77097505673],
  'vo20703': [595000, 2828.435374149649],
  'vo20704': [599000, 1692.4716553287453],
  'vo20705': [602000, 2346.4625850340326],
  'vo30101': [606000, 2483.3333333333485],
  'vo30102': [610000, 2297.437641723377],
  'vo30103': [614000, 1580.090702947814],
  'vo30104': [617000, 2174.965986394568],
  'vo30201': [621000, 1176.8934240362796],
  'vo30202': [624000, 1862.5850340135912],
  'vo30203': [627000, 1347.392290249445],
  'vo30204': [630000, 970.2947845804601],
  'vo30301': [632000, 1371.0204081633037],
  'vo30302': [635000, 1549.070294784542],
  'vo30303': [638000, 1443.5600907029311],
  'vo30304': [641000, 1429.1383219954241],
  'vo30401': [644000, 972.9251700680379],
  'vo30402': [646000, 691.0204081632401],
  'vo30403': [648000, 804.9659863945635],
  'vo30404': [650000, 1024.9659863945908],
  'vo30501': [653000, 703.9455782312416],
  'vo30502': [655000, 869.5918367346849],
  'vo30503': [657000, 1445.1700680272097],
  'vo30504': [660000, 813.8775510203686],
  'vo30601': [662000, 987.5963718820913],
  'vo30602': [664000, 1596.5532879818056],
  'vo30603': [667000, 1357.4603174603226],
  'vo30604': [670000, 975.3514739229558],
  'vo30701': [672000, 863.2199546485708],
  'vo30702': [674000, 1736.893424036225],
  'vo30703': [677000, 1344.7619047618673],
  'vo30704': [680000, 1497.7097505668553],
};
