import _ from 'lodash';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, SlotId } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import {
  setGameMode,
  setIsAfterSpin,
  setIsAnticipation,
  setIsTimeoutErrorMessage,
  setNudgeList,
} from '../../gql/cache';
import { NudgeType } from '../../gql/d';
import { debugDisplay, getStopReel, isFreeSpinsMode, isGambleMode } from '../../utils';
import { isBaseGameMode } from '../../utils/helper';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_FIRST_ROLLING_DURATION,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_ROLLING_SPEED,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  FAKE_ROLLING_DURATION,
  FAKE_ROLLING_GAMBLE_DURATION,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT, // REEL_FIND_ADJUSTMENT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOT_HEIGHT,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  SlotMachineState,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_FIRST_ROLLING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  eventManager,
} from '../config';
import { Slot } from '../slot/slot';
import SpinAnimation from '../spin/spin';

import { IReel } from './d';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: SpinAnimation | null = null;

  public slots: Slot[] = [];

  public animator: () => void = this.reelAnimator.bind(this);

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public size: number;

  public stopSoundSymbolNo = 0;

  public anticipationAnimation: Animation | undefined;

  public isWl = false;

  constructor(id: number, data: SlotId[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.width = REEL_WIDTH;
    // this.container.x = Math.floor(id % 3) * REEL_WIDTH + REEL_FIND_ADJUSTMENT[Math.floor(id % 3)]!;
    // this.container.y = 0;
    // if (Math.floor(id / 3) === 1) {
    //   this.container.y = 8;
    // } else if (Math.floor(id / 3) === 2) {
    //   this.container.y = 24;
    // }
    this.container.sortableChildren = true;
    this.createSlots();
    this.position = this.size - startPosition;
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.BREAK_SPIN_ANIMATION, this.breakSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.GAMBLE_CANCEL, this.onCancel.bind(this));
  }

  public init(data: SlotId[], position: number): void {
    this.data = data;
    this.size = data.length;
    this.createSlots();
    this.position = position;
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
  }

  private onAnticipationAnimationStarts(): void {}

  private onAnticipationStart(index: number): void {
    setIsAnticipation(true);
    if (this.id !== index) return;
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.tint = 0xffffff;
    });
  }

  private createSlots(): void {
    for (let i = 0; i < this.data.length; i++) {
      const slotId = this.data[i % this.data.length]!;
      const slot = new Slot(i, slotId);
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  private getRollingDuration(): number {
    const stopReel = getStopReel(this.id);
    if (isGambleMode(setGameMode())) {
      // TODO 暫定対応
      if (this.id < 6) {
        return 0; //BASE_REEL_ROLLING_DURATION + ADDITIONAL_SPIN_TIME_PER_REEL;
      } else {
        // console.log('this.id ', this.id, BASE_REEL_ROLLING_DURATION + ADDITIONAL_SPIN_TIME_PER_REEL + 1500);
        return 1000;
      }
    }

    if (this.isTurboSpin) {
      return (
        TURBO_REEL_ROLLING_DURATION +
        stopReel * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL -
        this.id * TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL
      );
    } else {
      return (
        BASE_REEL_ROLLING_DURATION +
        stopReel * ADDITIONAL_SPIN_TIME_PER_REEL -
        this.id * SPIN_REEL_ANIMATION_DELAY_PER_REEL
      );
    }
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined): SpinAnimation {
    // console.log('createSpinAnimation id = ', this.id, isTurboSpin);
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingSpeed = isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;
    const rollingTime = this.getRollingDuration();
    const target = this.position + Math.round(rollingTime * rollingSpeed);

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay: (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
      duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
    });
    starting.addOnStart(() => {
      this.changeState(ReelState.STARTING);
    });
    const firstRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT + Math.round(BASE_REEL_FIRST_ROLLING_DURATION * rollingSpeed),
      duration: isTurboSpin ? TURBO_REEL_FIRST_ROLLING_DURATION : BASE_REEL_FIRST_ROLLING_DURATION,
    });
    // TODO 暫定で対応　本来はギャンブル中はdurationではなく、ずっと回ってるのがよい
    const fakeRollingDuration = isGambleMode(setGameMode()) ? FAKE_ROLLING_GAMBLE_DURATION : FAKE_ROLLING_DURATION;
    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT + Math.round(fakeRollingDuration * rollingSpeed),
      duration: fakeRollingDuration,
      // isLoop: true,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
    });
    fakeRolling.addOnComplete(() => {
      // fakeRolling.start();
      // console.log('fakeRolling END', this.id);
    });
    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });
    rolling.addOnStart(() => {
      // console.log('rolling start ', this.id, Date.now());
      // console.log('rolling add on start', this.id, rollingTime);
    });
    rolling.addOnComplete(() => {
      // console.log('rolling comp ', this.id, Date.now(), target);
      // console.log('rolling add OnComplete', this.id, rollingTime);
    });
    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin ? TURBO_REEL_ENDING_DURATION : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      // if (this.id === 0) console.log('ending ', this.id, Date.now(), target - REEL_ENDING_SLOTS_AMOUNT, target);
      // ナッジ関連
      if (this.id === 4) {
        this.nudgeMain();
      }
      this.changeState(ReelState.ENDING);
    });
    ending.addOnComplete(() => {
      if (this.id === 4) {
        // console.log('---addOnComplete', this.id);
        AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStop, stopPrev: true });
        if (setNudgeList().nudge === NudgeType.NON || setNudgeList().nudge === NudgeType.SUCCESS_NON) {
          this.changeState(ReelState.IDLE);
          this.onReelStop();
        }

        if (isGambleMode(setGameMode())) {
          eventManager.emit(EventTypes.GAMBLE_DEALER_SYMBOL_DECISION);
        }
      } else {
        this.changeState(ReelState.IDLE);
        this.onReelStop();
      }
    });
    this.spinAnimation = new SpinAnimation({
      startingAnimation: starting,
      firstRollingAnimation: firstRolling,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      endingAnimation: ending,
    });
    return this.spinAnimation;
  }
  private nudgeMain(): void {
    const { nudge } = setNudgeList();
    if (nudge === NudgeType.SUCCESS_DOWN || nudge === NudgeType.SUCCESS_UP) {
      eventManager.emit(EventTypes.NUDGE);
      this.nudgeSuccess();
    } else if (nudge === NudgeType.FAIL1_DOWN || nudge === NudgeType.FAIL1_UP) {
      eventManager.emit(EventTypes.NUDGE);
      this.nudgeFailure1();
    } else if (nudge === NudgeType.FAIL2) {
      eventManager.emit(EventTypes.NUDGE);
      this.nudgeFailure2();
    }
  }

  private nudgeSuccess(): void {
    const { nudge, startPos, targetPos } = setNudgeList();
    const animationChain = new AnimationChain();
    const delay = Tween.createDelayAnimation(1500);
    const to = nudge === NudgeType.SUCCESS_DOWN ? startPos + 0.2 : startPos - 0.2;
    const nudgeSuccess = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: startPos,
      target: to,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: 1000,
    });
    nudgeSuccess.addOnStart(() => {
      AudioApi.play({ type: ISongs.SONG_030_13_Wild_Move1_Loop });
    });
    const delay1 = Tween.createDelayAnimation(500);
    const nudgeSuccess1 = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: to,
      target: targetPos,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: 1000,
    });
    nudgeSuccess1.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SONG_030_13_Wild_Move1_Loop });
    });

    animationChain.appendAnimation(delay);
    animationChain.appendAnimation(nudgeSuccess);
    animationChain.appendAnimation(delay1);
    animationChain.appendAnimation(nudgeSuccess1);
    animationChain.start();
    animationChain.addOnComplete(() => {
      this.changeState(ReelState.IDLE);
      this.onReelStop();
      eventManager.emit(EventTypes.REELS_STOPPED, this.isTurboSpin);
    });
  }

  private nudgeFailure1(): void {
    const { nudge, startPos } = setNudgeList();
    const animationChain = new AnimationChain();
    const delay = Tween.createDelayAnimation(1500);
    const to = nudge === NudgeType.FAIL1_DOWN ? startPos + 0.2 : startPos - 0.2;
    const nudgeFailure = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: startPos,
      target: to,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: 1000,
    });
    nudgeFailure.addOnStart(() => {
      AudioApi.play({ type: ISongs.SONG_030_13_Wild_Move1_Loop });
    });
    const delay1 = Tween.createDelayAnimation(500);
    const nudgeFailure1 = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: to,
      target: startPos,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: 1000,
    });
    nudgeFailure1.addOnStart(() => {
      AudioApi.stop({ type: ISongs.SONG_030_13_Wild_Move1_Loop });
      AudioApi.play({ type: ISongs.SONG_030_14_Wild_Move2_Loop });
    });
    nudgeFailure1.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SONG_030_14_Wild_Move2_Loop });
      AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStop, stopPrev: true });
    });

    animationChain.appendAnimation(delay);
    animationChain.appendAnimation(nudgeFailure);
    animationChain.appendAnimation(delay1);
    animationChain.appendAnimation(nudgeFailure1);
    animationChain.start();
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.NUDGE_MISS);
      this.changeState(ReelState.IDLE);
      this.onReelStop();
      eventManager.emit(EventTypes.REELS_STOPPED, this.isTurboSpin);
    });
  }

  private nudgeFailure2(): void {
    const animationChain = new AnimationChain();
    const delay = Tween.createDelayAnimation(1500);

    animationChain.appendAnimation(delay);
    animationChain.start();
    animationChain.addOnComplete(() => {
      AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStop, stopPrev: true });
      eventManager.emit(EventTypes.NUDGE_MISS);
      this.changeState(ReelState.IDLE);
      this.onReelStop();
      eventManager.emit(EventTypes.REELS_STOPPED, this.isTurboSpin);
    });
  }

  private breakSpinAnimation(): void {
    setIsTimeoutErrorMessage(true);
    if (this.spinAnimation != null) this.spinAnimation.breakSpinAnimation();
  }

  private onReelEnding(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStop(): void {
    this.anticipationAnimation?.skip();
    this.anticipationAnimation = undefined;
    if (setGameMode() === GameMode.BASE_GAME_GAMBLE && this.id < 5) return;
    if (this.isPlaySoundOnStop) {
      if (this.isWl) {
        if (this.id != 4) {
          AudioApi.play({
            type: ISongs.SONG_030_15_Wild_Stop1,
            stopPrev: true,
          });
        } else {
          if (isBaseGameMode(setGameMode())) {
            eventManager.emit(EventTypes.NUDGE_HIT);
            console.log('id', this.id);
            AudioApi.play({ type: ISongs.SONG_030_16_Wild_Stop2, stopPrev: true });
          }
        }
      } else {
        if (this.id != 4) {
          const stopSoundList = [ISongs.SONG_SFX_UI_SpinStop, ISongs.SONG_SFX_UI_SpinStop, ISongs.SONG_SFX_UI_SpinStop];
          // console.log('id', this.id);
          AudioApi.play({
            type: stopSoundList[Math.floor(Math.random() * stopSoundList.length)]!,
            stopPrev: true,
          });
        }
      }
      this.isPlaySoundOnStop = false;
      this.stopSoundSymbolNo = 0;
    }
  }

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    // console.log('onReelIdle this.id', this.id);
    if (previousState === ReelState.ENDING) {
      eventManager.emit(EventTypes.REEL_STOPPED, this.id);
      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      _.forEach(reelStopSlots, (slot) => {
        slot.onSlotStopped();
      });

      if (this.id === 1) {
        debugDisplay('STOP.onReelIdle', SlotMachine.getInstance().state);
        const state = SlotMachine.getInstance().state;
        if (state === SlotMachineState.SPIN || state === SlotMachineState.STOP)
          eventManager.emit(EventTypes.SET_STATE, SlotMachineState.STOP);
      }
      if (isFreeSpinsMode(setGameMode()) && this.id === 2) {
        eventManager.emit(EventTypes.REEL_STOPPED, 4);
      }
    }
    // if (this.id === 0) console.log('onReelIdle this.position ', this.position);
  }

  private onCancel(): void {
    if (!setIsAfterSpin()) {
      this.spinAnimation!.getStarting().skip();
      this.spinAnimation!.getFirstRolling().skip();
      this.spinAnimation!.getFakeRolling().skip();
      this.state = ReelState.IDLE;
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFirstRolling().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    this.spinAnimation!.getEnding().duration = endingDuration;
  }

  private getReelStopSlots(position: number): Slot[] {
    const slots: Slot[] = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle = position % this.slots.length === 0 ? 0 : this.slots.length - (position % this.slots.length);
    const bottom = (this.slots.length - ((position % this.slots.length) - 1)) % this.slots.length;
    const extra = (this.slots.length - ((position % this.slots.length) - 2)) % this.slots.length;
    slots.push(this.slots[top]!);
    slots.push(this.slots[middle]!);
    slots.push(this.slots[bottom]!);
    slots.push(this.slots[extra]!);
    return slots;
  }

  private onReelRolling(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStarting(_previousState: ReelState, _newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.ROLLING) {
      this.onReelRolling(previousState, ReelState.ROLLING);
    }
    if (newState === ReelState.STARTING) {
      this.onReelStarting(previousState, ReelState.STARTING);
    }
    if (newState === ReelState.ENDING) {
      this.onReelEnding(previousState, ReelState.ENDING);
    }
  }

  public reelAnimator(): void {
    // if (this.id === 0) console.log('reelAnimator this.position', this.position);
    this.previousPosition = this.position;
    // console.log('this.id', this.id, 'this.position', this.position);
    // Update symbol positions on reel.
    for (let j = 0; j < this.slots.length; j++) {
      const slot = this.slots[j]!;
      slot.y = ((this.position + j + 2) % this.slots.length) * SLOT_HEIGHT - SLOT_HEIGHT * 2;
      // if (this.id === 1) console.log('this.id', this.id, 'this.position', this.position, 'j', j, 'slot.y', slot.y);
      slot.toggleBlur(this.state === ReelState.ROLLING);
    }
  }
}

export default Reel;
