import { ReelSet } from '../global';
import { Icon } from '../slotMachine/d';

import { getIconById } from './helper';
import { debugDisplay } from './utils';

export const getSpinResult3x1 = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index]![random]!);
    }),
  ];
  return spinResult;
};

export const getSpinResultGamble = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      switch (index) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          for (const [key, val] of Object.entries(reelSet.layout)) {
            if (key === 'Dealer') {
              return getIconById(icons, val[random]!);
            }
          }
          return icons[0]!;
        case 6:
          for (const [key, val] of Object.entries(reelSet.layout)) {
            if (key === 'Left') {
              return getIconById(icons, val[random]!);
            }
          }
          return icons[0]!;
        case 7:
          for (const [key, val] of Object.entries(reelSet.layout)) {
            if (key === 'Center') {
              return getIconById(icons, val[random]!);
            }
          }
          return icons[0]!;
        case 8:
          for (const [key, val] of Object.entries(reelSet.layout)) {
            if (key === 'Right') {
              return getIconById(icons, val[random]!);
            }
          }
          return icons[0]!;
        default:
          return icons[0]!;
      }
    }),
  ];

  debugDisplay('spinResult', spinResult);
  return spinResult;
};
