import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GambleBtmProps, GameMode } from '../../global.d';
import { setFlashFlg, setGambleStake, setGameMode, setNextResult } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { isGambleMode } from '../../utils';
import { nextGambleSpin } from '../../utils/utils/gamble';
import AnimationChain from '../animations/animationChain';
import Tween from '../animations/tween';
import { BgSkin } from '../background/config';
import {
  FAKE_ROLLING_DURATION,
  GAMBLE_BTN_GAMBLE_LANDSCAPE_POSITION_X,
  GAMBLE_BTN_GAMBLE_LANDSCAPE_POSITION_Y,
  GAMBLE_BTN_GAMBLE_PORTRAIT_POSITION_X,
  GAMBLE_BTN_GAMBLE_PORTRAIT_POSITION_Y,
  GAMBLE_BTN_LANDSCAPE_POSITION_X,
  GAMBLE_BTN_LANDSCAPE_POSITION_Y,
  GAMBLE_BTN_PORTRAIT_POSITION_X,
  GAMBLE_BTN_PORTRAIT_POSITION_Y,
  eventManager,
} from '../config';

import GambleBtnBase from './gambleBtnBase';

class GambleBtn extends GambleBtnBase {
  private isLandScape = true;
  private animationChain: AnimationChain = new AnimationChain({ isLoop: true });
  private timeoutDelay = Tween.createDelayAnimation(0);
  private duration = 500;

  constructor(props: GambleBtmProps) {
    super(props);
    this.setPosition(GAMBLE_BTN_LANDSCAPE_POSITION_X, GAMBLE_BTN_LANDSCAPE_POSITION_Y);

    const fadeIn = this.getFadeAnimation(0.5, this.duration, 1);
    const fadeOut = this.getFadeAnimation(1, this.duration, 0.5);
    this.animationChain.appendAnimation(fadeOut);
    this.animationChain.appendAnimation(fadeIn);
    this.animationChain.addOnSkip(() => {
      this.alpha = 1;
    });

    eventManager.on(EventTypes.DISABLE_GAMBLE_BTN, (disable: boolean) => {
      if (disable) {
        this.animationChain.isLoop = false;
        this.animationChain.allSkip();
      } else {
        eventManager.emit(EventTypes.GAMBLE_OR_COLLECT);
        this.animationChain.isLoop = true;
        this.animationChain.animations.forEach((animation) => {
          animation.duration = this.duration;
        });
      }
      this.handleDisable(disable);
    });
    eventManager.addListener(EventTypes.GAMBLE_TIMEOUT, this.timeout.bind(this));
  }

  protected override initBtnText(): PIXI.Sprite {
    const text = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.gamble));
    text.anchor.set(0.5);
    return text;
  }

  protected override setPosition(width: number, height: number): void {
    if (width > height) {
      this.isLandScape = true;
      if (isGambleMode(setGameMode())) {
        this.x = GAMBLE_BTN_GAMBLE_LANDSCAPE_POSITION_X;
        this.y = GAMBLE_BTN_GAMBLE_LANDSCAPE_POSITION_Y;
      } else {
        this.x = GAMBLE_BTN_LANDSCAPE_POSITION_X;
        this.y = GAMBLE_BTN_LANDSCAPE_POSITION_Y;
      }
    } else {
      this.isLandScape = false;
      if (isGambleMode(setGameMode())) {
        this.x = GAMBLE_BTN_GAMBLE_PORTRAIT_POSITION_X;
        this.y = GAMBLE_BTN_GAMBLE_PORTRAIT_POSITION_Y;
      } else {
        this.x = GAMBLE_BTN_PORTRAIT_POSITION_X;
        this.y = GAMBLE_BTN_PORTRAIT_POSITION_Y;
      }
    }
  }

  protected override onClick(): void {
    if (!this.btn.buttonMode) return;
    if (this.isLandScape) {
      eventManager.emit(EventTypes.TOGGLE_UI_BUY_GAMBLE, false);
    } else {
      eventManager.emit(EventTypes.TOGGLE_UI_BUY_GAMBLE, false);
    }
    AudioApi.play({ type: ISongs.SONG_030_17_PressGamble });
    // eventManager.emit(
    //   EventTypes.UPDATE_GAMBLE_STAKE,
    //   SlotMachine.getInstance().nextResult!.bet.data.features.gambleStake,
    //   false,
    // );
    nextGambleSpin();

    // ダミーでスピンさせる
  }

  protected override onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    let gambleFlg = true;
    this.animationChain.isLoop = false;
    this.animationChain.allSkip();

    if (isGambleMode(settings.mode)) {
      this.position.y = this.isLandScape
        ? GAMBLE_BTN_GAMBLE_LANDSCAPE_POSITION_Y
        : GAMBLE_BTN_GAMBLE_PORTRAIT_POSITION_Y;
    } else {
      this.position.y = this.isLandScape ? GAMBLE_BTN_LANDSCAPE_POSITION_Y : GAMBLE_BTN_PORTRAIT_POSITION_Y;
      if (setNextResult() && setGambleStake() > 0) {
        gambleFlg = false;
      }
    }
    if (gambleFlg) {
      eventManager.emit(EventTypes.DISABLE_GAMBLE_BTN, gambleFlg);
    }
    super.onChangeMode(settings);
  }
  protected override activeGamble(disable: boolean): void {
    if (disable) {
      eventManager.emit(EventTypes.IS_GAMBLE_MODE, true);
    }
    super.activeGamble(disable);
  }

  protected override changeFilter(object1: PIXI.Sprite): void {
    super.changeFilter(object1, setFlashFlg() ? false : true);
  }

  private timeout(isStart: boolean) {
    if (isStart) {
      this.timeoutDelay = Tween.createDelayAnimation(FAKE_ROLLING_DURATION);
      this.timeoutDelay.addOnComplete(() => {
        eventManager.emit(EventTypes.THROW_ERROR);
      });
      this.timeoutDelay.start();
    } else {
      this.timeoutDelay.removeOnComplete(() => {
        eventManager.emit(EventTypes.THROW_ERROR);
      });
      this.timeoutDelay.skip();
    }
  }
}

export default GambleBtn;
