import {
  setBrokenGambleGame,
  setGambleEntryReelPosition,
  setNextResult,
  setSlotConfig,
  setUserLastBetResult,
} from '../../gql/cache';
import client from '../../gql/client';
import { ISlotHistoryData } from '../../gql/d';
import { slotHistoryGql } from '../../gql/query';
import { makeGambleResult } from '../../utils/utils/makeGambleResult';

export const useLastBet = () => {
  const getLastBet = async () => {
    const {
      data: { betHistory },
    } = await client.query<{ betHistory: ISlotHistoryData }>({
      query: slotHistoryGql,
      variables: {
        input: { slotId: setSlotConfig().slotId, limit: 1 },
      },
      fetchPolicy: 'network-only',
    });
    if (betHistory.bets[0]) {
      setUserLastBetResult(betHistory.bets[0]);
    }

    const isSelected = betHistory.bets[0]?.outcomes.find((outcome) => outcome.selectionState === 'SELECTED');
    const isUnSelected = betHistory.bets[0]?.outcomes.find((outcome) => outcome.selectionState === 'UNSELECTED');

    if (isSelected === undefined && isUnSelected != undefined) {
      setBrokenGambleGame(true);
      setGambleEntryReelPosition(betHistory.bets[0]?.wager.wagerSettings.originalReelPositions);
      const gambleResult = makeGambleResult(betHistory.bets[0]!, betHistory.bets[0]!, 0, '', 0, '', 0);
      setNextResult(gambleResult);
    }
  };
  return { getLastBet };
};
