import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Popup } from '@phoenix7dev/shared-components/';

import { setGambleStake, setReplayEnd } from '../../gql/cache';
import { replayEndProcess } from '../../slotMachine/replay/replay';

import styles from './replay_end.module.scss';

const ReplayEnd: React.FC = () => {
  const replayEnd = useReactiveVar(setReplayEnd);
  const { t } = useTranslation();

  return (
    <div className={replayEnd ? styles['backdrop'] : ''}>
      <Popup id="replayend" className="popup_non_blur" showNow={replayEnd} setShowNow={() => null}>
        <div className={styles['replay-end']}>
          <div className={styles['center']}>
            {/* <p className={styles['title']}>{t('replayEnd')}</p> */}
            <div className={styles['flex']}>
              <button
                className={`${styles['btn']} btn`}
                onClick={() => {
                  setReplayEnd(false);
                  setGambleStake(0);
                  replayEndProcess();
                }}
              >
                {t('close')}
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default ReplayEnd;
