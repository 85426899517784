import * as PIXI from 'pixi.js';

import { SlotId } from '../config';
import { ReelSet } from '../global.d';

export interface ISlotMachine {
  transparent: boolean;
  width: number;
  height: number;
}

export interface IPlayerSettingsMinMax {
  min: number;
  max: number;
}

export interface IPlayerSettings {
  betAmount: IPlayerSettingsMinMax;
  coinValue: {
    EUR: IPlayerSettingsMinMax;
    FUN: IPlayerSettingsMinMax;
  };
}

export interface IAutoplaySettings {
  conditions: {
    stopIfBalanceDecreasesBy: {
      enabled: boolean;
    };
    stopIfBalanceIncreasesBy: {
      enabled: boolean;
      multipliers: number[];
    };
    stopIfFeatureIsWon: {
      enabled: boolean;
    };
    stopIfSingleWinExceeds: {
      enabled: boolean;
      multipliers: number[];
    };
    stopOnAnyWin: {
      enabled: boolean;
    };
  };
  options: number[];
}

export declare interface ICoinValues {
  code: string;
  variants: [number, ...number[]];
}

export interface IGameSettings {
  slotId: string;
  clientSlotSettings: {
    autoplaySettings: IAutoplaySettings;
    betCoinAmountSettings: number[];
    coinValueSettings: { code: string; variants: number[] }[];
    quickBetCoinAmountSettings: number[];
  };
  slotSettings: {
    cols: number;
    rows: number;
    startingPositions: number[];
    betLines: {
      min: number;
      max: number;
    };
    globalCoinAmountMultiplier: number;
    buyFeaturePackages: {
      coinAmountMultiplier: number;
      packageId: string;
      rounds: number;
    }[];
  };
}

export declare interface ISlotData {
  icons: Icon[];
  id: string;
  lines: number[][];
  previewImage: string;
  reels: ReelSet[];
  settings: {
    betLines: {
      min: number;
      max: number;
    };
    startPosition: number[];
    playerSettings: IPlayerSettings;
  };
  test: string;
  clientSettings: {
    coinAmounts: {
      default: number[];
      quick: number[];
    };
    coinValues: {
      code: string;
      variants: number[];
    }[];
    features: Features[];
    autoplay: {
      options: number[];
      conditions: {
        stopOnAnyWin: {
          enabled: boolean;
        };
        stopIfFeatureIsWon: {
          enabled: boolean;
        };
        stopIfSingleWinExceeds: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceDecreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceIncreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
      };
    };
  };
  lineSets: LineSet[];
}

export interface Features {
  id: string;
  enabled: boolean;
}

export interface LineSet {
  id: string;
  lines: number[];
  coinAmountMultiplier: number;
}

export interface IGameContainer {
  reelsBackgroundContainer: PIXI.Container;
  reelsContainer: PIXI.Container[];
  tintContainer: PIXI.Container[];
  winLabelContainer: PIXI.Container;
  winCountUpMessage: PIXI.Container;
  winSlotsContainer: PIXI.Container;
}

export type Combos = IconCombo[];

export interface Icon {
  id: SlotId;
  type: IconType;
}

export interface WinCombination {
  attribution: number[];
  reelPosition: number | null;
}

export enum IconType {
  EMPTY = 'EMPTY',
  REGULAR = 'REGULAR',
  SCATTER = 'SCATTER',
  WILD = 'WILD',
}

export enum PayoffType {
  LTR = 'LTR',
  RTL = 'RTL',
  ANY = 'ANY',
  ADJACENT = 'ADJACENT',
}
export enum RewardType {
  COINS = 'COINS',
  BONUS = 'BONUS',
}
export interface Reward {
  bonusId: string | null;
  count: number | null;
  multiplier: number | null;
  type: RewardType;
}
export interface IconCombo {
  pattern: string;
  multiplier: number;
}
export interface Combo {
  rewards: Reward[];
  pattern: string;
  payoffType: string;
  type: string;
}

export interface Features {
  id: string;
  enabled: boolean;
}

export enum caseFn {
  closeGamble = 'closeGamble',
  selectReel1 = 'doGambleReel1',
  selectReel2 = 'doGambleReel2',
  selectReel3 = 'doGambleReel3',
}

export enum gambleResult {
  win = 'win',
  loss = 'loss',
  draw = 'draw',
  limit = 'limit',
}
