import { gql } from '@apollo/client';

import { BET, OUTCOME, WAGER } from './fragment';

export const stressfulGql = gql`
  query Stressful {
    stressful @client
  }
`;

export const configGql = gql`
  query Config {
    isAuthorized @client
    isMobile @client
    isTurboSpin @client
    isSoundOn @client
    soundValue @client
    isSpinInProgress @client
    isSlotBusy @client
    isSlotStopped @client
    isShowSoundToast @client
    isShowSuspendedToast @client
    isOpenMenuModal @client
    isOpenBetSettingsModal @client
    isOpenAutoplayModal @client
    isOpenHistoryPopup @client
    isOpenBetSettingsPopup @client
    isOpenAutoplayPopup @client
    isOpenInfoPopup @client
    isHistoryVisible @client
    isSlotStatueIdle @client
  }
`;

export const getBalanceGql = gql`
  query GetBalance {
    balance {
      amount
      currency
    }
  }
`;

export const getProgressGql = gql`
  query GetProgress {
    progress @client
  }
`;

export const getBetAmountGql = gql`
  query GetBet {
    betAmount @client
  }
`;

export const getWinAmountGql = gql`
  query GetWin {
    winAmount @client
  }
`;

export const getIsSlotStoppedGql = gql`
  query IsSlotStopped {
    isSlotStopped @client
  }
`;

export const getAutoSpinsSettingsGql = gql`
  query GetAutoSpinsSettings {
    autoSpinsAmount @client
    stopOnWinExceeds @client
    stopOnBalanceIncrease @client
    stopOnBalanceDecrease @client
    isStopOnAnyWin @client
    isStopOnFeatureWin @client
    isStopOnWinExceeds @client
    isStopOnBalanceIncrease @client
    isStopOnBalanceDecrease @client
    autoSpinsStartBalance @client
  }
`;

export const getAutoSpinsGql = gql`
  query GetAutoSpin {
    isAutoSpins @client
    autoSpinsLeft @client
  }
`;
export const getGameModeGql = gql`
  query getGameMode {
    gameMode @client
  }
`;

export const slotHistoryGql = gql`
  ${BET}
  ${WAGER}
  ${OUTCOME}
  query BetHistory($input: BetHistoryInput!) {
    betHistory(input: $input) {
      bets {
        ...bet
      }
      count
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
`;

export const getGameSettingsGql = gql`
  query gameSettings($input: GameSettingsInput!) {
    gameSettings(input: $input) {
      slotId
      clientSlotSettings {
        betCoinAmountSettings
        quickBetCoinAmountSettings
        coinValueSettings {
          code
          variants
        }
        autoplaySettings {
          options
          conditions {
            stopOnAnyWin {
              enabled
            }
            stopIfFeatureIsWon {
              enabled
            }
            stopIfSingleWinExceeds {
              enabled
              multipliers
            }
            stopIfBalanceDecreasesBy {
              enabled
            }
            stopIfBalanceIncreasesBy {
              enabled
              multipliers
            }
          }
        }
        wagerExpirationDurationSeconds
      }
      slotSettings {
        ... on TumblingReelSlotSettings {
          cols
          rows
          startingPositions
          betLines {
            min
            max
          }
          globalCoinAmountMultiplier
          buyFeaturePackages {
            packageId
            coinAmountMultiplier
            rounds
          }
        }
      }
    }
  }
`;

export const getRestoreStateGql = gql`
  ${BET}
  ${WAGER}
  ${OUTCOME}
  query getRestoreState($input: RestoreStateInput!) {
    restoreState(input: $input) {
      bets {
        ...bet
      }
      wagers {
        ...wager
      }
    }
  }
`;

export const isStoppedGql = gql`
  query IsStopped {
    isSlotStopped @client
    isSlotStatueIdle @client
  }
`;

export enum ReelSetType {
  DEFAULT = 'DEFAULT',
  BONUS = 'BONUS',
}
