import SlotMachine from '..';
import { getStartupTensionLot } from '../../anticipation';
import { EyeControl } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import {
  setAvatarStatusControl,
  setAvatarTension,
  setBetAmount,
  setCurrentBonus,
  setEyeControl,
  setGambleStake,
  setGameMode,
  setIsReplay,
} from '../../gql/cache';
import client from '../../gql/client';
import { getWinAmountGql } from '../../gql/query';
import { calAvatarTension, debugDisplay } from '../../utils';
import Tween from '../animations/tween';
import { StatusControlFlg } from '../avatarTalk/config';
import ViewContainer from '../components/container';
import { EYE_CONTROL_IDLE_TIME, eventManager } from '../config';

class AvatarMotion extends ViewContainer {
  private avatarTension: number;

  private afterBonusEnds: number;
  private tension1: number;

  private gambleStake: number;

  private avatarTalkStatusControl: StatusControlFlg; //8.4.2 状況管理フラグ

  constructor() {
    super();

    this.afterBonusEnds = 0;
    this.tension1 = 0;
    this.gambleStake = 0;
    this.avatarTension = getStartupTensionLot();
    debugDisplay('avatarTension', this.avatarTension);
    this.avatarTalkStatusControl = StatusControlFlg.NORMAL;

    setAvatarStatusControl(this.avatarTalkStatusControl);
    debugDisplay('初期アバターテンション', this.avatarTension);
    setAvatarTension(this.avatarTension);

    eventManager.addListener(EventTypes.TOGGLE_SPIN, this.spinStart.bind(this));
    eventManager.addListener(EventTypes.SPIN_END, this.spinEnd.bind(this));
    eventManager.addListener(EventTypes.BONUS_END, this.bonusEnd.bind(this));

    eventManager.on(EventTypes.UPDATE_GAMBLE_STAKE, (stake: number) => {
      this.gambleStake = stake;
    });

    client.watchQuery<{ winAmount: number }>({ query: getWinAmountGql }).subscribe(({ data }) => {
      debugDisplay('==== winAmount ', data.winAmount);
    });
  }

  private spinStart(): void {
    const date = new Date();
    debugDisplay('setEyeControl(EyeControl.PLAY)', 'date', date.toLocaleString());
    setEyeControl(EyeControl.PLAY);
  }

  private spinEnd(): void {
    if (setIsReplay()) return;
    if (setGameMode() === GameMode.BASE_GAME) {
      this.afterBonusEnds += 1;
      this.gambleStake = 0;
      if (setAvatarTension() === 1) {
        this.tension1 += 1;
      } else {
        this.tension1 = 0;
      }
    }

    this.avatarTension = setAvatarTension();
    this.avatarTalkStatusControl = setAvatarStatusControl();

    // 8.2.2 テンション変化条件
    // 9.4.2 状況管理フラグ
    const winAmountFreeSpin =
      (SlotMachine.getInstance().nextResult!.bet.wager.wagerStorage.paidWinCoinAmount +
        SlotMachine.getInstance().nextResult!.bet.wager.wagerStorage.previousTotalWinCoinAmount) /
      setBetAmount();
    const winAmountBaseGame =
      SlotMachine.getInstance().nextResult!.bet.betStorage.estimatedWinCoinAmount / setBetAmount();
    debugDisplay('==== spinEnd', setGameMode(), setCurrentBonus().roundsPlayed, 'winAmount', winAmountFreeSpin);
    // debugDisplay('setGambleStake()', setGambleStake());

    // Gambleが負けで終了し、GAMBLE STAKEがbetの10倍以上だった
    if (!setIsReplay()) {
      if (
        setGameMode() === GameMode.BASE_GAME_GAMBLE &&
        setGambleStake() === 0 &&
        this.gambleStake > 0 &&
        this.gambleStake / setBetAmount() >= 10
      ) {
        setAvatarTension(1);
        this.avatarTalkStatusControl = StatusControlFlg.ZANNEN;
      } else if (this.afterBonusEnds === 50) {
        // 前回ボーナスから連続してベースゲームを50ゲームプレイした
        calAvatarTension(-1);
        this.avatarTalkStatusControl = StatusControlFlg.HAMARI;
      } else if (setGameMode() === GameMode.BASE_GAME) {
        if (winAmountBaseGame >= 10) {
          // ベースゲームで10倍以上のWinを獲得した
          setAvatarTension(3);
        } else if (winAmountBaseGame >= 5) {
          // ベースゲームで5倍以上のWinを獲得した
          calAvatarTension(1);
        } else if (this.tension1 >= 20) {
          // テンションが1のまま20Gが経過した
          setAvatarTension(2);
        }
      }
    }

    debugDisplay('spin後 テンション 更新', setAvatarTension());
    setAvatarStatusControl(this.avatarTalkStatusControl);

    // 待機モーション時の視線制御抽せん　で使用する　プレイ中 or アイドル状態
    const delay = Tween.createDelayAnimation(EYE_CONTROL_IDLE_TIME);
    delay.addOnComplete(() => {
      const date = new Date();
      debugDisplay('setEyeControl(EyeControl.IDLE)', 'date', date.toLocaleString());
      setEyeControl(EyeControl.IDLE);
    });
    delay.start();
  }

  private bonusEnd(): void {
    this.afterBonusEnds = 0;
  }
}

export default AvatarMotion;
