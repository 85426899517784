import { ITextStyle } from 'pixi.js';

import { Variables } from '../../config/variables';

const font = Variables.FONT_FAMILY;

export const replayTextStyle: Partial<ITextStyle> = {
  fill: 0xffffff,
  fontSize: 32,
  fontFamily: font,
};

export const REPLAY_TEXT_POS_X = 30;
export const REPLAY_LANDSCAPE_TEXT_POS_Y = -40;
export const REPLAY_PORTRAIT_TEXT_POS_Y = -160;
export const REPLAY_LANDSCAPE_GAMBLE_TEXT_POS_Y = 430;
export const REPLAY_PORTRAIT_GAMBLE_TEXT_POS_Y = 290;
export const REPLAY_TEXT_MARGIN = 40;

export const REPLAY_DELAY = 2000;
