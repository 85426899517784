import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_ANIMATIONS, MAPPED_SYMBOLS_STOP_ANIMATIONS, SlotId } from '../../config';
import { EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setAnticipationLine,
  setBrokenGame,
  setCurrentBonus,
  setGameMode,
  setIsAnticipation,
  setNextResult,
  setSlotConfig,
} from '../../gql/cache';
import { destroySpine, isFreeSpinsMode, isGambleMode } from '../../utils';
import { isScatter } from '../../utils/helper';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  DEFAULT_SPRITE_COLOR,
  NOT_WIN_LINE_SPRITE_COLOR,
  REELS_AMOUNT,
  SLOTS_PER_REEL_AMOUNT,
  Z_INDEX_SLOTS_STOP_DISPLAY,
  eventManager,
} from '../config';
import { Icon, WinCombination } from '../d';

import { BASE_SLOT_SPINE_ANIMATE_PRIORITY, STOP_SPECIAL_SYMBOL_PRIORITY, WILD_SPINE_ANIMATE_PRIORITY } from './config';
import { SpineAnimateSlot } from './spineAnimateSlot';

class SlotsAnimationContainer extends ViewContainer {
  private stopSymbolAnimations: Animation[] = [];

  private slotSymbols: SpineAnimateSlot[] = [];

  public animation?: AnimationChain | undefined;

  private gameMode: GameMode;

  private freeSpinsBonus: string | undefined;

  private symbolSpineContainer: Spine[] = [];

  private symbolSpriteContainer: PIXI.Sprite[] = [];

  private isLandscape = true;

  constructor() {
    super();
    this.gameMode = setBrokenGame() ? setCurrentBonus().gameMode : GameMode.BASE_GAME;
    this.sortableChildren = true;
    this.zIndex = Z_INDEX_SLOTS_STOP_DISPLAY;
    for (let i = 0; i < 9; i++) {
      this.symbolSpineContainer![i] = new Spine(
        PIXI.Loader.shared.resources[MAPPED_SYMBOLS_ANIMATIONS[SlotId.WL].src!]!.spineData!,
      );
      this.symbolSpriteContainer![i] = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[SlotId.WL]));
    }

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.START_GET_AMOUNT_WIN, this.skipStopSymbolAnimations.bind(this));
    eventManager.on(EventTypes.SETUP_REEL_POSITIONS, this.onSetupBetResult.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.SPIN_END, this.slotSpritesColorReset.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, (spinResult) => {
      this.animation?.skip();
      this.animation = undefined;
      this.initSymbols(spinResult!);
      this.slotSymbols.forEach((symbol) => (symbol.visible = true));
    });
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      if (this.gameMode === GameMode.BASE_GAME || this.gameMode === GameMode.BUY_FEATURE) {
        this.freeSpinsBonus = undefined;
      } else {
        // this.freeSpinsBonus = SlotMachine.getInstance().getFreeSpinBonus()!.bonus.reelSetId;
      }
    });
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  private clearSymbols(): void {
    this.slotSymbols.forEach((symbol) => {
      symbol.skip();
    });
    this.removeChild(...this.slotSymbols);
    this.slotSymbols = [];
  }

  private initSymbols(spinResult: Icon[]): void {
    this.clearSymbols();

    for (let i = 0; i < spinResult.length; i++) {
      const symbol = new SpineAnimateSlot(
        spinResult[i]!.id,
        i,
        this.symbolSpineContainer![i]!,
        this.symbolSpriteContainer![i]!,
      );

      symbol.x = this.getSymbolPositionX(i);
      symbol.y = this.getSymbolPositionY(i);
      this.addChild(symbol);
      this.slotSymbols.push(symbol);

      symbol.visible = false;
    }
  }

  private getSymbolPositionX(i: number) {
    let posX = 0;

    switch (i % 3) {
      case 0:
        posX = 150;
        break;
      case 1:
        posX = 463;
        break;
      case 2:
        posX = 777;
        break;
    }
    return posX;
  }

  private getSymbolPositionY(i: number) {
    let posY = 0;

    switch (Math.floor(i / 3)) {
      case 0:
        if (isGambleMode(setGameMode())) {
          posY = !this.isLandscape ? -66 : 54.5;
        } else {
          posY = !this.isLandscape ? -66 : 54.5;
        }
        break;
      case 1:
        if (isGambleMode(setGameMode())) {
          posY = !this.isLandscape ? 110 : 254;
        } else {
          posY = !this.isLandscape ? 210 : 330;
        }
        break;
      case 2:
        if (isGambleMode(setGameMode())) {
          posY = !this.isLandscape ? 385 : 526.5;
        } else {
          posY = !this.isLandscape ? 485 : 605;
        }
        break;
    }
    return posY;
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      this.isLandscape = true;
    } else {
      this.isLandscape = false;
    }

    this.slotSymbols.forEach((symbol, index) => {
      symbol.y = this.getSymbolPositionY(index);
    });
  }

  private onStartSpin() {
    this.slotSymbols.forEach((symbol) => {
      this.removeChild(symbol);
    });
    this.skipStopSymbolAnimations();
  }

  private onAnticipationStart(): void {
    this.slotSymbols.forEach((slot) => {
      if (isScatter(slot.slotId)) {
        slot.zIndex = WILD_SPINE_ANIMATE_PRIORITY;
      }
    });
  }

  private onSetupBetResult(): void {
    this.initSymbols(setNextResult()!.bet.spinResult);
  }

  private onReelStopped(reelId: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const symbol = this.slotSymbols[i * REELS_AMOUNT + reelId!]!;
      symbol.visible = true;
      symbol.startStopAnimation();
    }
    this.startOnSymbolsStopAnimations(reelId);
  }

  private startOnSymbolsStopAnimations(reelId: number): void {
    if (isFreeSpinsMode(setGameMode()) && reelId === 4) return;
    if (reelId === 0) this.stopSymbolAnimations = [];

    const slotId = setNextResult()?.bet.spinResult[reelId]!.id;
    if (slotId && MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId] && slotId === SlotId.WL) {
      const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId!];
      if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
      const animation = new SpineAnimation({}, PIXI.Loader.shared.resources[animationData.src]!.spineData);
      const dummy = Tween.createDelayAnimation(1000);
      dummy.addOnStart(() => {
        animation.spine.x = this.getSymbolPositionX(reelId);
        animation.spine.y = this.getSymbolPositionY(reelId);
        this.addChild(animation.getSpine());
        animation.getSpine().zIndex = STOP_SPECIAL_SYMBOL_PRIORITY;
        animation.setAnimation(animationData.animation!, false);
        this.slotSymbols[reelId]!.visible = false;
      });
      dummy.addOnComplete(() => {
        destroySpine(animation);
        if (reelId === 1) {
          setIsAnticipation(false);
        }
        this.removeChild(animation.spine);
        this.slotSymbols[reelId]!.visible = true;
      });
      dummy.addOnSkip(() => {
        destroySpine(animation);
        setIsAnticipation(false);
        this.removeChild(animation.spine);
        this.slotSymbols[reelId]!.visible = true;
      });
      dummy.addOnChange(() => {
        const anticipationLines: number[] = [];
        setAnticipationLine().forEach((anticipationLine) => {
          setSlotConfig().lines[anticipationLine]!.forEach((icon, index) => {
            if (index != 1) anticipationLines.push(icon);
          });
        });
        if (!setIsAnticipation()) {
          // animation.spine.tint = 0xffffff;
        }
      });
      this.stopSymbolAnimations.push(dummy);
      dummy.start();
    }
  }

  private onChangeMode() {
    this.slotSymbols.forEach((symbol, index) => {
      symbol.y = this.getSymbolPositionY(index);
    });
  }

  private skipStopSymbolAnimations(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
    this.stopSymbolAnimations = [];
  }

  private onStartWinAnimation(nextResult: ISettledBet, _payLines: WinCombination[], _winAmount: number) {
    this.showWin(nextResult);
    eventManager.emit(EventTypes.SET_REEL_FRAME_VISIBLE, false);
  }

  private skipWinSlotsAnimation() {
    this.animation?.skip();
    this.slotSymbols.forEach((symbol) => symbol.skip());
    this.slotSpritesColorReset();
    eventManager.emit(EventTypes.SET_REEL_FRAME_VISIBLE, true);
  }

  private showWin(nextResult: ISettledBet): void {
    const { winCombinations } = nextResult.bet.outcomes[0]!.stateSnapshot;
    const spinResult = nextResult.bet.spinResult;

    this.animation = new AnimationChain();
    this.animation.addOnSkip(() => {});

    const set = new Set<number>();
    winCombinations.forEach((d) => {
      d.attribution.forEach((position) => {
        if (spinResult[position]!.id !== SlotId.J) {
          set.add(position);
        }
      });
    });

    if (set.size === 0) return;
    {
      const allSlotsHighlight = this.highlightSlots(Array.from(set));
      allSlotsHighlight.addOnStart(() => {
        this.setWinSlotTint(Array.from(set));
      });
      allSlotsHighlight.addOnComplete(() => {});
      allSlotsHighlight.addOnSkip(() => {});
      this.animation.appendAnimation(allSlotsHighlight);
    }

    {
      const eachSlotsHighlight = this.createHighlightChainAnimation(winCombinations, true);
      this.animation.appendAnimation(eachSlotsHighlight);
    }
    this.animation?.start();
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((position) => {
      animationGroup.addAnimation(this.slotSymbols[position]!.getWinAnimation());
    });
    return animationGroup;
  }

  private createHighlightChainAnimation(winCombinations: WinCombination[], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });
    winCombinations.forEach((winCombination) => {
      if (winCombination.attribution.length > 0) {
        const chain = this.highlightSlots(winCombination.attribution);
        chain.addOnStart(() => {
          this.setWinSlotTint(winCombination.attribution);
        });

        chain.addOnComplete(() => {
          this.slotSpritesColorChange();
        });

        animationChain.appendAnimation(chain);
      }
    });
    return animationChain;
  }

  private slotSpritesColorChange(): void {
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      this.slotSymbols[i]!.setTint(NOT_WIN_LINE_SPRITE_COLOR);
    }
  }

  private slotSpritesColorReset(): void {
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      this.slotSymbols[i]!.setTint(DEFAULT_SPRITE_COLOR);
      this.slotSymbols[i]!.zIndex = BASE_SLOT_SPINE_ANIMATE_PRIORITY;
    }
  }

  private setWinSlotTint(slotPositions: number[]): void {
    this.slotSymbols.forEach((slot) => {
      slot.setTint(NOT_WIN_LINE_SPRITE_COLOR);
    });
    slotPositions.forEach((slot) => {
      this.slotSymbols[slot]!.setTint(DEFAULT_SPRITE_COLOR);
      if (isScatter(this.slotSymbols[slot]!.slotId)) {
        this.slotSymbols[slot]!.zIndex = WILD_SPINE_ANIMATE_PRIORITY;
      } else {
        this.slotSymbols[slot]!.zIndex = BASE_SLOT_SPINE_ANIMATE_PRIORITY;
      }
    });
  }
}

export default SlotsAnimationContainer;
