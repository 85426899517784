import { ResourceTypes } from '../resources.d';

export const introContents = [
  {
    img: ResourceTypes.intro1,
    txtKey: 'introTitle1',
  },
  {
    img: ResourceTypes.intro2,
    txtKey: 'introTitle2',
  },
];
