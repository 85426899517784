import { IPayTableData } from '../components/Info/d';
import { ResourceTypes } from '../resources.d';

const gameRules = [
  { key: 'Rules.0', buyFeatureOnly: false },
  { key: 'Rules.1', buyFeatureOnly: false },
  { key: 'Rules.2', buyFeatureOnly: false },
  { key: 'Rules.3', buyFeatureOnly: false },
  { key: 'Rules.4', buyFeatureOnly: false },
];

export const gameRules1 = [
  { key: 'infoGameRules1_1', buyFeatureOnly: false },
  { key: 'infoGameRules1_2', buyFeatureOnly: false },
  { key: 'infoGameRules1_3', buyFeatureOnly: false },
  { key: 'infoGameRules1_4', buyFeatureOnly: false },
  { key: 'infoGameRules1_5', buyFeatureOnly: false },
];
export const gameRules2 = [
  { key: 'infoGameRules1_6', buyFeatureOnly: false },
  { key: 'infoGameRules1_7', buyFeatureOnly: false },
  { key: 'infoGameRules1_8', buyFeatureOnly: false },
  { key: 'infoGameRules1_9', buyFeatureOnly: false },
  { key: 'infoGameRules1_10', buyFeatureOnly: false },
  { key: 'infoGameRules1_11', buyFeatureOnly: false },
  { key: 'infoGameRules1_12', buyFeatureOnly: false },
];

export const infoFeatureBuy1 = [
  { key: 'infoFeatureBuyDesc_1' },
  { key: 'infoFeatureBuyDesc_2' },
  { key: 'infoFeatureBuyDesc_3' },
  { key: 'infoFeatureBuyDesc_4' },
];

export type infoGameRulesTypes1 =
  | 'infoGameRules1_1'
  | 'infoGameRules1_2'
  | 'infoGameRules1_3'
  | 'infoGameRules1_4'
  | 'infoGameRules1_5';

export type infoGameRulesTypes2 =
  | 'infoGameRules1_6'
  | 'infoGameRules1_7'
  | 'infoGameRules1_8'
  | 'infoGameRules1_9'
  | 'infoGameRules1_10';

export type infoFeatureTypes =
  | 'infoFeatureBuyDesc_1'
  | 'infoFeatureBuyDesc_2'
  | 'infoFeatureBuyDesc_3'
  | 'infoFeatureBuyDesc_4';

export const anyMixScatterWin: IPayTableData = {
  slug: ResourceTypes.symbolA,
  combos: [
    { pattern: 'x9', multiplier: 50000 },
    { pattern: 'x8', multiplier: 40000 },
    { pattern: 'x7', multiplier: 25000 },
    { pattern: 'x6', multiplier: 5000 },
    { pattern: 'x5', multiplier: 1000 },
    { pattern: 'x4', multiplier: 20 },
    { pattern: 'x3', multiplier: 5 },
  ],
};

export const centeredWildWin: IPayTableData[] = [
  {
    slug: ResourceTypes.symbolWl,
    combos: [{ pattern: 'x1', multiplier: 10 }],
  },
];

export const specialBonusGambleFeature: IPayTableData[] = [
  {
    slug: ResourceTypes.symbolWl,
    combos: [{ pattern: 'x3', multiplier: 100 }],
  },
  {
    slug: ResourceTypes.symbolA,
    combos: [{ pattern: 'x3', multiplier: 30 }],
  },
  {
    slug: ResourceTypes.symbolB,
    combos: [{ pattern: 'x3', multiplier: 20 }],
  },
  {
    slug: ResourceTypes.symbolC,
    combos: [{ pattern: 'x3', multiplier: 5 }],
  },
  {
    slug: ResourceTypes.symbolD,
    combos: [{ pattern: 'x3', multiplier: 4 }],
  },
  {
    slug: ResourceTypes.symbolE,
    combos: [{ pattern: 'x3', multiplier: 3 }],
  },
  {
    slug: ResourceTypes.symbolF,
    combos: [{ pattern: 'x3', multiplier: 2 }],
  },
  {
    slug: ResourceTypes.symbolG,
    combos: [{ pattern: 'x3', multiplier: 2 }],
  },
  {
    slug: ResourceTypes.symbolH,
    combos: [{ pattern: 'x3', multiplier: 1 }],
  },
  {
    slug: ResourceTypes.symbolI,
    combos: [{ pattern: 'x3', multiplier: 1 }],
  },
];

export const specialBonusGambleFeatureAnyMix: IPayTableData = {
  slug: ResourceTypes.symbolA,
  combos: [{ pattern: 'x3', multiplier: 10 }],
};

export default gameRules;
