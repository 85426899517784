import { ISongs } from '../config';

//6.3 FREE SPINトリガーあおり
///成功パターン FSトリガー時 演出選択率
export const nudgeLotSuccess = [60, 80, 100];

///失敗パターン ブランク停止時 演出選択率（上と下のブランク共通）
export const nudgeLotLoss = [70, 90, 100];

// 予兆振り分け（予兆あり25%, なし75%）
export const baseGamePhoenix = [25, 100];

// Animation selection table
export enum AnimationType {
  NON = 'No anticipation',
  PHOENIX = 'phoenix',
}

export type RntISongs = ISongs | undefined;

// [7.AvatarMotion]

// 視線移動Delay               10  15  20  25ms
export const eyeMoveDelay = [25, 50, 75, 100];

// 8.2.1 起動時・および再読み込み時の初期値[低、中、高]
export const startUpTension = [0, 50, 100];

// 待機モーション時の視線制御抽せん[正面、リール、ランダム]
export const eyeControlPlay = [10, 90, 100];
export const eyeControlIdle = [40, 60, 100];

// A. ベースゲーム中の待機ループ [待機1～待機5]
export const aBaseGameWaitLoop1 = [40, 80, 80, 80, 100];
export const aBaseGameWaitLoop2 = [0, 50, 100, 100, 100];
export const aBaseGameWaitLoop3 = [0, 20, 60, 100, 100];

// B. ナッジ見守り
export const bNudgeWatchOver = [0, 30, 30, 60, 90, 90, 90, 100];

// C. ナッジハズレリアクション
export const cNudgeMiss = [20, 60, 100, 100, 100, 100];

// D. FS当選リアクション
export const dNudgeHit = [0, 0, 0, 40, 70, 100, 100];

// E. FS当選後見守り
export const eNudgeHitAfterWatchOver = [0, 0, 0, 30, 70, 70, 100];

// F. Big Winリアクション
export const fCountUpBig = [0, 0, 0, 40, 70, 100, 100];

// H. Gamble or Collect見守り
export const hGambleOrCollect = [0, 0, 0, 50, 100, 100, 100];

// I. FS開始時看板見守り
export const fFsStartSignboardWatchOver = [0, 0, 0, 50, 100, 100, 100];

// J. FS中見守り
// 1~5スピン目
export const jFsWatchOver1spin = [
  [0, 0, 0, 70, 100, 100, 100], // Betの10倍未満
  [0, 0, 0, 50, 100, 100, 100], // Betの10倍～30倍
  [0, 0, 0, 20, 60, 100, 100], // Betの30倍以上
];

// 6~8スピン目
export const jFsWatchOver6spin = [
  [0, 50, 100, 100, 100, 100, 100], // Betの20倍未満
  [0, 0, 0, 50, 100, 100, 100], // Betの20倍～40倍
  [0, 0, 0, 0, 50, 100, 100], // Betの40倍以上
];

// 9~10スピン目
export const jFsWatchOver9spin = [
  [50, 100, 100, 100, 100, 100, 100], // Betの25倍未満
  [0, 0, 0, 50, 100, 100, 100], // Betの25倍～40倍
  [0, 0, 0, 0, 0, 50, 100], // Betの40倍以上
];

// L. Gamble前見守り
export const lGambleWatchOver = [0, 0, 0, 50, 100, 100, 100];

// M. Gamble中見守り（前半）
export const mGambleWatchOverFirstHalf = [0, 50, 100, 100, 100, 100, 100];

// N. Dealerシンボルに応じてリアクション
export const nDealerSymbolBlank = [0, 0, 0, 50, 100, 100, 100];
export const nDealerSymbolNotBlank = [0, 50, 100, 100, 100, 100, 100];

// O. Gamble中見守り（後半）
export const oGambleWatchOverLatterHalf = [0, 0, 0, 40, 80, 100, 100];

// P. Gamble結果に応じてリアクション pGambleResult
export const pGambleResultLoss = [30, 70, 100, 100, 100, 100, 100];
export const pGambleResultDraw = [0, 0, 0, 100, 100, 100, 100];
export const pGambleResultWin = [0, 0, 0, 0, 50, 100, 100];
