import * as PIXI from 'pixi.js';
import { Graphics } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setCurrency, setFlashFlg, setPlayer } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { debugDisplay, formatNumber, isGambleMode, normalizeCoins, showCurrency, updateTextScale } from '../../utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';
import {
  GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_X,
  GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_Y,
  GAMBLE_VIEW_DEALER_TEXT_PORTRAIT_X,
  GAMBLE_VIEW_DEALER_TEXT_PORTRAIT_Y,
  GAMBLE_VIEW_LANDSCAPE_Y,
  GAMBLE_VIEW_PLAYER_TEXT_0_X,
  GAMBLE_VIEW_PLAYER_TEXT_1_X,
  GAMBLE_VIEW_PLAYER_TEXT_2_X,
  GAMBLE_VIEW_PLAYER_TEXT_LANDSCAPE_Y,
  GAMBLE_VIEW_PLAYER_TEXT_PORTRAIT_Y,
  GAMBLE_VIEW_PORTRAIT_Y,
  PAY_TABLE_BACKGROUND_COLOR,
  REEL_HEIGHT,
  REEL_WIDTH,
  Z_INDEX_GAMBLE,
  eventManager,
} from '../config';
import { caseFn } from '../d';
import { gambleStakeLandscapeTitleStyle, gambleStakePortraitTitleStyle } from '../gamble/textStyles';

import {
  DIVIDEND_TABLE_LEFT_LANDSCAPE_X,
  DIVIDEND_TABLE_LEFT_LANDSCAPE_Y,
  DIVIDEND_TABLE_LEFT_PORTRAIT_X,
  DIVIDEND_TABLE_LEFT_PORTRAIT_Y,
  DIVIDEND_TABLE_RIGHT_LANDSCAPE_X,
  DIVIDEND_TABLE_RIGHT_LANDSCAPE_Y,
  DIVIDEND_TABLE_RIGHT_PORTRAIT_X,
  DIVIDEND_TABLE_RIGHT_PORTRAIT_Y,
  GAMBLE_STAKE_ANIMATION_DELAY,
  GAMBLE_STAKE_ANIMATION_LOOP,
  GAMBLE_STAKE_ANIMATION_SCALE,
  GAMBLE_VIEW_POSITION_LANDSCAPE_Y,
  GAMBLE_VIEW_POSITION_PORTRAIT_Y,
  HIGH_LOW_LANDSCAPE_X,
  HIGH_LOW_LANDSCAPE_Y,
  HIGH_LOW_PORTRAIT_X,
  HIGH_LOW_PORTRAIT_Y,
  HitRowListAny7MixLand,
  HitRowListAny7MixPort,
  HitRowListLand,
  HitRowListPort,
  SPECIAL_SYMBOL_HIT_LANDSCAPE_HEIGHt,
  SPECIAL_SYMBOL_HIT_LANDSCAPE_WIDTH,
  SPECIAL_SYMBOL_HIT_PORTRAIT_HEIGHt,
  SPECIAL_SYMBOL_HIT_PORTRAIT_WIDTH,
} from './config';

class GambleView extends ViewContainer {
  private dealerText = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.dealer));
  private playerText = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.player));
  private highLow = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.highlow));
  private dividendTableLeft = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.dividendTable));
  private dividendTableRight = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.dividendTable2));
  private gambleStake = new AutoResizeText('0', gambleStakeLandscapeTitleStyle);
  private container: PIXI.Container[] = [];
  private isWidth = true;
  private pulsAnimation: Animation | null = null;
  private timerId: NodeJS.Timer | null = null;
  private rect: Graphics;

  private special: SlotId | undefined;
  private any7mix: boolean;

  constructor() {
    super();

    this.initGambleView();

    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.GAMBLE_PLAYER_CLEAR, this.playerClear.bind(this));
    eventManager.addListener(EventTypes.UPDATE_GAMBLE_STAKE, this.handleUpdate.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.clearFilter.bind(this));
    eventManager.addListener(EventTypes.SET_GAMBLE_PLAYER, this.setPlayer.bind(this));
    eventManager.on(EventTypes.WIN_ANIMATION_DIVIDEND_TABLE, (special: SlotId, any7mix: boolean) => {
      this.rect.visible = true;
      this.special = special;
      this.any7mix = any7mix;
      this.timerId = setInterval(() => this.changeFilter(this.rect, setFlashFlg() ? true : false), 250);
      this.winAnimation(special, any7mix);
    });
    this.zIndex = Z_INDEX_GAMBLE;
    this.visible = false;
    this.special = undefined;
    this.any7mix = false;

    for (let index = 0; index < 3; index++) {
      this.container[index] = new PIXI.Container();
      this.container[index]!.width = REEL_WIDTH;
      this.container[index]!.height = REEL_HEIGHT;
      this.container[index]!.scale.set(1.0, 1.0);
      this.container[index]!.zIndex = 100;
      this.container[index]!.interactive = true;
      const rect = new PIXI.Graphics();
      rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
      rect.drawRect(0, 0, REEL_WIDTH, REEL_HEIGHT);
      rect.alpha = 0.0;
      this.container[index]!.addChild(rect);
      this.container[index]!.on('mousedown', () => {
        debugDisplay('mouse down ', index);
        this.betPlayer(index);
      });
      this.container[index]!.on('touchstart', () => {
        debugDisplay('touchstart', index);
        this.betPlayer(index);
      });
      if (index === 0) {
        this.container[index]!.x = -1;
      } else if (index === 1) {
        this.container[index]!.x = 311.5;
      } else if (index === 2) {
        this.container[index]!.x = 626.5;
      }
      this.container[index]!.y = GAMBLE_VIEW_POSITION_LANDSCAPE_Y;

      this.addChild(this.container[index]!);
    }
    this.rect = new Graphics();
    this.initRect();
    this.addChild(this.rect);
  }

  private betPlayer(index: number): void {
    debugDisplay('betPlayer ', this.playerText.visible);
    if (this.playerText.visible) return;
    debugDisplay('mouse down ', index);
    switch (index) {
      case 0:
        setPlayer(caseFn.selectReel1);
        break;
      case 1:
        setPlayer(caseFn.selectReel2);
        break;
      case 2:
        setPlayer(caseFn.selectReel3);
        break;
    }
    AudioApi.play({ type: ISongs.SONG_SFX_UI_MenuOpen });
    eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, true);
    eventManager.emit(EventTypes.GAMBLE_SPIN);
    eventManager.emit(EventTypes.GAMBLE_WATCH_OVER_FIRST_HALF);
    this.setPlayer(index);
  }

  private initRect(): void {
    this.rect.beginFill(0xffffff);
    this.rect.alpha = 0.6;
    this.rect.drawRoundedRect(0, 0, SPECIAL_SYMBOL_HIT_LANDSCAPE_WIDTH, SPECIAL_SYMBOL_HIT_LANDSCAPE_HEIGHt, 0);
    this.rect.visible = false;
  }

  private setPlayer(index: number): void {
    debugDisplay('setPlayer ', this.playerText.visible);

    if (this.playerText.visible) return;
    this.playerText.visible = true;
    if (index === 0) {
      this.playerText.x = GAMBLE_VIEW_PLAYER_TEXT_0_X;
    } else if (index === 1) {
      this.playerText.x = GAMBLE_VIEW_PLAYER_TEXT_1_X;
    } else if (index === 2) {
      this.playerText.x = GAMBLE_VIEW_PLAYER_TEXT_2_X;
    }
    if (this.isWidth) {
      this.playerText.y = GAMBLE_VIEW_PLAYER_TEXT_LANDSCAPE_Y;
    } else {
      this.playerText.y = GAMBLE_VIEW_PLAYER_TEXT_PORTRAIT_Y;
    }
  }

  private initGambleStake(): void {
    this.gambleStake.y = 30;
    this.gambleStake.x = GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_X;
    this.gambleStake.anchor.set(0.5);
    updateTextScale(this.gambleStake, 500, 500);
    return;
  }

  private initGambleView(): void {
    if (this.isWidth) {
      this.dealerText.position.set(GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_X, GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_Y);
      this.playerText.y = GAMBLE_VIEW_PLAYER_TEXT_LANDSCAPE_Y;
    } else {
      this.dealerText.position.set(GAMBLE_VIEW_DEALER_TEXT_PORTRAIT_X, GAMBLE_VIEW_DEALER_TEXT_PORTRAIT_Y);
      this.playerText.y = GAMBLE_VIEW_PLAYER_TEXT_PORTRAIT_Y;
    }
    this.dealerText.anchor.set(0.5, 0);
    this.playerText.anchor.set(0.5, 0);
    this.highLow.position.set(HIGH_LOW_LANDSCAPE_X, HIGH_LOW_LANDSCAPE_Y);
    this.dividendTableLeft.position.set(DIVIDEND_TABLE_LEFT_LANDSCAPE_X, DIVIDEND_TABLE_LEFT_LANDSCAPE_Y);
    this.dividendTableRight.position.set(DIVIDEND_TABLE_RIGHT_LANDSCAPE_X, DIVIDEND_TABLE_RIGHT_LANDSCAPE_Y);
    this.highLow.anchor.set(0.5, 0.5);
    this.dividendTableLeft.anchor.set(0.5, 0.5);
    this.dividendTableRight.anchor.set(0.5, 0.5);
    this.playerText.visible = false;
    this.initGambleStake();
    this.addChild(
      this.gambleStake,
      this.dealerText,
      this.playerText,
      this.highLow,
      this.dividendTableLeft,
      this.dividendTableRight,
    );
  }

  private onModeChange(settings: { mode: GameMode }) {
    if (isGambleMode(settings.mode)) {
      this.visible = true;
    } else {
      this.visible = false;
      this.playerText.visible = false;
    }
  }

  private playerClear(): void {
    this.playerText.visible = false;
  }

  private winAnimation(special: SlotId, any7mix: boolean): void {
    setFlashFlg(false);

    if (this.isWidth) {
      this.rect.width = SPECIAL_SYMBOL_HIT_LANDSCAPE_WIDTH;
      this.rect.height = SPECIAL_SYMBOL_HIT_LANDSCAPE_HEIGHt;

      if ((special === SlotId.A || special === SlotId.B) && any7mix) {
        this.rect.position.set(HitRowListAny7MixLand.x, HitRowListAny7MixLand.y);
      } else {
        this.rect.position.set(HitRowListLand[special].x, HitRowListLand[special].y);
      }
    } else {
      this.rect.width = SPECIAL_SYMBOL_HIT_PORTRAIT_WIDTH;
      this.rect.height = SPECIAL_SYMBOL_HIT_PORTRAIT_HEIGHt;
      this.rect.position.set(HitRowListPort[special].x, HitRowListPort[special].y);
      if ((special === SlotId.A || special === SlotId.B) && any7mix) {
        this.rect.position.set(HitRowListAny7MixPort.x, HitRowListAny7MixPort.y);
      } else {
        this.rect.position.set(HitRowListPort[special].x, HitRowListPort[special].y);
      }
    }
  }

  private changeFilter(object1: PIXI.Graphics, filter: boolean): void {
    if (setFlashFlg()) {
      setFlashFlg(false);
    } else {
      setFlashFlg(true);
    }

    object1.visible = filter;
  }

  private clearFilter(): void {
    if (this.timerId) clearInterval(this.timerId);
    this.rect.visible = false;
    this.special = undefined;
    this.any7mix = false;
    this.timerId = null;
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      this.dealerText.position.set(GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_X, GAMBLE_VIEW_DEALER_TEXT_LANDSCAPE_Y);
      this.playerText.y = GAMBLE_VIEW_PLAYER_TEXT_LANDSCAPE_Y;
      this.isWidth = true;
      this.position.y = GAMBLE_VIEW_LANDSCAPE_Y;
      this.highLow.scale.set(1);
      this.dividendTableLeft.scale.set(1);
      this.dividendTableRight.scale.set(1);
      this.highLow.position.set(HIGH_LOW_LANDSCAPE_X, HIGH_LOW_LANDSCAPE_Y);
      this.dividendTableLeft.position.set(DIVIDEND_TABLE_LEFT_LANDSCAPE_X, DIVIDEND_TABLE_LEFT_LANDSCAPE_Y);
      this.dividendTableRight.position.set(DIVIDEND_TABLE_RIGHT_LANDSCAPE_X, DIVIDEND_TABLE_RIGHT_LANDSCAPE_Y);
      this.container[0]!.y = GAMBLE_VIEW_POSITION_LANDSCAPE_Y;
      this.container[1]!.y = GAMBLE_VIEW_POSITION_LANDSCAPE_Y;
      this.container[2]!.y = GAMBLE_VIEW_POSITION_LANDSCAPE_Y;
      this.gambleStake.style = gambleStakeLandscapeTitleStyle;
    } else {
      this.dealerText.position.set(GAMBLE_VIEW_DEALER_TEXT_PORTRAIT_X, GAMBLE_VIEW_DEALER_TEXT_PORTRAIT_Y);
      this.playerText.y = GAMBLE_VIEW_PLAYER_TEXT_PORTRAIT_Y;
      this.isWidth = false;
      this.position.y = GAMBLE_VIEW_PORTRAIT_Y;
      this.highLow.scale.set(0.59973);
      this.dividendTableLeft.scale.set(0.59727);
      this.dividendTableRight.scale.set(0.59727);
      this.highLow.position.set(HIGH_LOW_PORTRAIT_X, HIGH_LOW_PORTRAIT_Y);
      this.dividendTableLeft.position.set(DIVIDEND_TABLE_LEFT_PORTRAIT_X, DIVIDEND_TABLE_LEFT_PORTRAIT_Y);
      this.dividendTableRight.position.set(DIVIDEND_TABLE_RIGHT_PORTRAIT_X, DIVIDEND_TABLE_RIGHT_PORTRAIT_Y);
      this.container[0]!.y = GAMBLE_VIEW_POSITION_PORTRAIT_Y;
      this.container[1]!.y = GAMBLE_VIEW_POSITION_PORTRAIT_Y;
      this.container[2]!.y = GAMBLE_VIEW_POSITION_PORTRAIT_Y;
      this.gambleStake.style = gambleStakePortraitTitleStyle;
    }

    if (this.timerId) {
      this.winAnimation(this.special!, this.any7mix);
    }
  }

  private handleUpdate(stake: number, _immediately: boolean): void {
    if (this.gambleStake.text === String(stake)) return;
    this.gambleStake.text =
      i18n.t('gambleStake') +
      '   ' +
      String(formatNumber(setCurrency(), normalizeCoins(stake), showCurrency(setCurrency())));

    this.pulsAnimation = createZoomAnimation(
      this.gambleStake,
      GAMBLE_STAKE_ANIMATION_SCALE,
      GAMBLE_STAKE_ANIMATION_DELAY,
      GAMBLE_STAKE_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
    eventManager.emit(
      EventTypes.UPDATE_WIN_VALUE,
      formatNumber(setCurrency(), normalizeCoins(stake), showCurrency(setCurrency())),
    );
  }
  private onChangeMode(settings: { mode: GameMode }) {
    if (!isGambleMode(settings.mode)) {
      this.gambleStake.text = 0;
    }
  }
}

export default GambleView;
