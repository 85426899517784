import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useMemo } from 'react';

import { EventTypes } from '../../global.d';
import {
  setIsAfterSpin,
  setIsCollectSpin,
  setIsCountUp,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenedMessageBanner,
  setIsPhoenix,
  setIsReplay,
  setSpinWithAutoSpin,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql, getAutoSpinsGql, getBetAmountGql } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';

const EventListener: React.FC = () => {
  const { data: configData } = useQuery<IConfig>(configGql);
  const { isTurboSpin, isSoundOn, isSpinInProgress, isSlotBusy, isSlotStopped, isShowSuspendedToast } = configData!;

  const { data: betData } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { betAmount } = betData!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_AUTO_SPINS, isAutoSpins);
  }, [isAutoSpins]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_AUTO_SPINS_LEFT, autoSpinsLeft);
  }, [autoSpinsLeft]);

  useMemo(() => {
    eventManager.emit(EventTypes.TOGGLE_TURBO_SPIN, isTurboSpin);
  }, [isTurboSpin]);

  useMemo(() => {
    eventManager.emit(EventTypes.TOGGLE_SOUND, isSoundOn);
  }, [isSoundOn]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SUSPENDED, isShowSuspendedToast);
  }, [isShowSuspendedToast]);

  useMemo(() => {
    eventManager.emit(EventTypes.UPDATE_BET, betAmount);
  }, [betAmount]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SPIN_IN_PROGRESS, isSpinInProgress);
  }, [isSpinInProgress]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SLOT_IS_BUSY, isSlotBusy);
  }, [isSlotBusy]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SLOTS_STOPPED, isSlotStopped);
  }, [isSlotStopped]);

  const isFreeSpinsWin = useReactiveVar(setIsFreeSpinsWin);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_FREE_SPINS_WIN, isFreeSpinsWin);
  }, [isFreeSpinsWin]);

  const isCollectSpin = useReactiveVar(setIsCollectSpin);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_COLLECT_SPIN, isCollectSpin);
  }, [isCollectSpin]);

  const isOpenedMessageBanner = useReactiveVar(setIsOpenedMessageBanner);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_OPENED_MESSAGE_BANNER, isOpenedMessageBanner);
  }, [isOpenedMessageBanner]);

  const isInTransition = useReactiveVar(setIsInTransition);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_IN_TRANSITION, isInTransition);
  }, [isInTransition]);

  const isCountUp = useReactiveVar(setIsCountUp);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_COUNT_UP, isCountUp);
  }, [isCountUp]);

  const isAfterSpin = useReactiveVar(setIsAfterSpin);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_AFTER_SPIN, isAfterSpin);
  }, [isAfterSpin]);

  const isReplay = useReactiveVar(setIsReplay);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_REPLAY, isReplay);
  }, [isReplay]);

  const isPhoenix = useReactiveVar(setIsPhoenix);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_PHOENIX, isPhoenix);
  }, [isPhoenix]);

  const isSpinWithAutoSpin = useReactiveVar(setSpinWithAutoSpin);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SPIN_WITH_AUTO_SPIN, isSpinWithAutoSpin);
  }, [isSpinWithAutoSpin]);

  return null;
};

export default EventListener;
