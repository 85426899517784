/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Bone } from '@pixi-spine/runtime-3.8';
import { IAnimationStateListener, Spine } from 'pixi-spine';
import { Container, Loader, Ticker } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import {
  aBaseGameWaitLoop1,
  aBaseGameWaitLoop2,
  aBaseGameWaitLoop3,
  bNudgeWatchOver,
  cNudgeMiss,
  dNudgeHit,
  eNudgeHitAfterWatchOver,
  eyeControlIdle,
  eyeControlPlay,
  eyeMoveDelay,
  fCountUpBig,
  fFsStartSignboardWatchOver,
  hGambleOrCollect,
  jFsWatchOver1spin,
  jFsWatchOver6spin,
  jFsWatchOver9spin,
  lGambleWatchOver,
  mGambleWatchOverFirstHalf,
  nDealerSymbolBlank,
  nDealerSymbolNotBlank,
  oGambleWatchOverLatterHalf,
  pGambleResultDraw,
  pGambleResultLoss,
  pGambleResultWin,
} from '../../anticipation/table';
import { getResultFromTable } from '../../anticipation/util';
import { EyeControl } from '../../config';
import { ISongs, mappedAudioSprites } from '../../config/audio';
import { EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setAvatarStatusControl,
  setAvatarTension,
  setBetAmount,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setEyeControl,
  setGambleStakeResult,
  setGameMode,
  setIsReplay,
  setUserLastBetResult,
} from '../../gql/cache';
import { debugDisplay, isFreeSpinGameMode, isGambleMode, normalizeCoins, repeatAvoidance } from '../../utils';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import {
  EyePosition,
  EyePositionInfo,
  MotionMimamori,
  MotionName,
  MotionReaction,
  MotionReactionShort,
  MotionTaiki,
  PositionInfo,
  gazeMoveDelay,
  motionList,
} from '../avatarMotion/config';
import { StatusControlFlg, VoiceStartParameter, takeUndefined, talkList } from '../avatarTalk/config';
import {
  AVATAR_EYE_RANDOM_TIMER,
  AVATAR_EYE_UI_CONTROL_TIMER,
  AVATAR_LANDSCAPE_POS_X,
  AVATAR_LANDSCAPE_POS_Y,
  AVATAR_PORTRAIT_POS_X,
  AVATAR_PORTRAIT_POS_Y,
  SlotMachineState,
  Z_INDEX_AVATAR,
  eventManager,
} from '../config';
import { gambleResult } from '../d';

import { BaseGameMotionType } from './data';

function calEase(x: number): number {
  return 1 - Math.pow(1 - x, 3);
}

class VAvatar extends Spine {
  public jackpot!: Container;

  private motionType: BaseGameMotionType;

  private motionTypeNext: BaseGameMotionType;

  private gaze1: PositionInfo;

  private eyePosX: number;

  private eyePosY: number;

  private MotionWait: Animation | undefined;

  private voiceDelay: Animation | undefined;

  private delay: Animation | undefined;

  private uiGazeTimer: Animation | undefined;

  private gazeMoveDelay: Animation | undefined;

  private mouseTimer: Animation | undefined;

  private gaveMoveCounter: number;

  private bUiGaze: boolean;

  private voiceData: ISongs | undefined;

  private isEysMotion: boolean;

  private timeId: NodeJS.Timeout[] = [];

  private isVoice: boolean;

  private isIdleMotion: boolean;

  private motion: number;

  private voiceComplete = true;

  private windowWidth = 0;
  private windowHeight = 0;

  private prevFsWatchOverTbl = jFsWatchOver9spin[0]!;
  private prevFlWatchOverLot = 0;
  private gambleWatchOverFirstHalf: number;

  constructor() {
    super(Loader.shared.resources['avatar_hinako']!.spineData!);

    this.eyePosX = EyePositionInfo[EyePosition.Center]!.pos[0]!.x;
    this.eyePosY = EyePositionInfo[EyePosition.Center]!.pos[0]!.y;

    this.voiceData = undefined;
    this.gaveMoveCounter = 0;
    this.mouseTimer = undefined;
    this.isEysMotion = true;
    this.isVoice = true;
    this.isIdleMotion = false;
    this.motion = 0;
    this.gambleWatchOverFirstHalf = 0;

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));

    eventManager.on(EventTypes.BONUS_WIN, () => {
      if (setIsReplay()) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.BonusWin;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.BASE_GAME, () => {
      if (setIsReplay()) return;
      if (this.motionType === BaseGameMotionType.hGambleOrCollect) {
        this.motionType = BaseGameMotionType.aNormal;
        this.cancelTalk();
        this.avatarMotionMain(0);
      } else {
        this.motionType = BaseGameMotionType.aNormal;
      }
    });

    eventManager.on(EventTypes.REPLAY_END, () => {
      this.motionType = BaseGameMotionType.aNormal;
      this.cancelTalk();
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.NUDGE, () => {
      if (setIsReplay()) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.bNudge;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.NUDGE_MISS, () => {
      // console.log('EventTypes.NUDGE_MISS');
      if (setIsReplay()) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.cNudgeMiss;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.NUDGE_HIT, () => {
      if (setIsReplay()) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.dNudgeHit;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.GAMBLE_WATCH_OVER, () => {
      if (setIsReplay()) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.lGambleWatchOver;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.GAMBLE_WATCH_OVER_FIRST_HALF, () => {
      if (setIsReplay()) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.mGambleWatchOverFirstHalf;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.GAMBLE_DEALER_SYMBOL_DECISION, () => {
      if (setIsReplay()) return;
      debugDisplay(
        'setUserLastBetResult().result',
        setUserLastBetResult(),
        SlotMachine.getInstance().nextResult?.bet.outcomes[0],
        SlotMachine.getInstance().nextResult!.bet.spinResult,
      );

      if (
        SlotMachine.getInstance().nextResult!.bet.spinResult[4]!.id === 'J' ||
        SlotMachine.getInstance().nextResult!.bet.spinResult[4]!.id === 'WL' ||
        SlotMachine.getInstance().nextResult!.bet.spinResult[4]!.id === 'A' ||
        SlotMachine.getInstance().nextResult!.bet.spinResult[4]!.id === 'B' ||
        SlotMachine.getInstance().nextResult!.bet.spinResult[4]!.id === 'C' ||
        SlotMachine.getInstance().nextResult!.bet.spinResult[4]!.id === 'D'
      ) {
        this.cancelTalk();
        this.motionType = BaseGameMotionType.nDealerSymbol;
        this.avatarMotionMain(0);
      }
    });

    eventManager.on(EventTypes.GAMBLE_REACTION, () => {
      if (setIsReplay()) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.pGambleResult;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.GAMBLE_OR_COLLECT, () => {
      if (setIsReplay()) return;
      if (this.motionType === BaseGameMotionType.hGambleOrCollect) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.hGambleOrCollect;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.START_SIGN_WATCH_OVER, () => {
      if (setIsReplay()) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.iFsStartSignboardWatchOver;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.FREE_SPIN_WATCH_OVER, () => {
      if (setIsReplay()) return;
      const roundPlay = setCurrentBonus().roundsPlayed;
      if (roundPlay === 0 || roundPlay === 5 || roundPlay === 8) {
        // モーション抽選へ
      } else {
        debugDisplay('FREE_SPIN_WATCH_OVER', setCurrentBonus().roundsPlayed + 1);

        const lotTable = this.getFsLotTable(setCurrentBonus().roundsPlayed + 1);
        if (this.prevFsWatchOverTbl == lotTable && this.motionType === BaseGameMotionType.jFsWatchOver) return;
      }
      this.cancelTalk();
      this.motionType = BaseGameMotionType.jFsWatchOver;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, (data: { mode: GameMode }) => {
      if (setIsReplay()) return;
      if (data.mode === GameMode.BASE_GAME_GAMBLE) {
        this.cancelTalk();
        this.motionType = BaseGameMotionType.lGambleWatchOver;
        this.avatarMotionMain(0);
      }
    });

    eventManager.on(EventTypes.CREATE_WIN_MESSAGE_BANNER, () => {
      if (setIsReplay()) return;
      this.cancelTalk();
      this.motionType = BaseGameMotionType.kTotalWinSignboard;
      this.avatarMotionMain(0);
    });
    eventManager.on(EventTypes.TOGGLE_UI_LEFT, (isUpper = false) => {
      this.bUiGaze = false;
      if (this.uiGazeTimer != undefined) {
        this.uiGazeTimer.skip();
        this.uiGazeTimer = undefined;
      }
      if (isUpper) {
        this.toggleUiBtn(EyePosition.UiLeftUpper);
      } else {
        this.toggleUiBtn(EyePosition.UiLeft);
      }
    });
    eventManager.on(EventTypes.TOGGLE_UI_RIGHT, (isUpper = false) => {
      this.bUiGaze = false;
      if (this.uiGazeTimer != undefined) {
        this.uiGazeTimer.skip();
        this.uiGazeTimer = undefined;
      }
      if (isUpper) {
        this.toggleUiBtn(EyePosition.UiRightUpper);
      } else {
        this.toggleUiBtn(EyePosition.UiRight);
      }
    });
    eventManager.on(EventTypes.TOGGLE_UI_BUY_GAMBLE, (isLandScape = false) => {
      this.bUiGaze = false;
      if (this.uiGazeTimer != undefined) {
        this.uiGazeTimer.skip();
        this.uiGazeTimer = undefined;
      }
      if (isLandScape) {
        this.toggleUiBtn(EyePosition.BuyButtonUpper);
      } else {
        this.toggleUiBtn(EyePosition.BuyButton);
      }
    });
    eventManager.on(EventTypes.TOGGLE_DIALOG, (isLandScape = false) => {
      this.bUiGaze = false;
      if (this.uiGazeTimer != undefined) {
        this.uiGazeTimer.skip();
        this.uiGazeTimer = undefined;
      }
      if (isLandScape) {
        this.toggleUiBtn(EyePosition.Reel);
      } else {
        this.toggleUiBtn(EyePosition.ReelPortrait);
      }
    });

    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SET_BIG_WIN_VISIBILITY, this.setBigWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_MEGA_WIN_VISIBILITY, this.setMegaWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_GREAT_WIN_VISIBILITY, this.setGreatWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_EPIC_WIN_VISIBILITY, this.setEpicWinVisibility.bind(this));

    this.motionType = BaseGameMotionType.aNormal;
    this.motionTypeNext = BaseGameMotionType.Non;
    this.bUiGaze = false;

    this.avatarMotionMain(0, false);

    this.state.setAnimation(0, 'idle_01_a', false);
    this.state.setAnimation(1, 'mouth_001_default_1', true);
    this.position.x = AVATAR_LANDSCAPE_POS_X;
    this.position.y = AVATAR_LANDSCAPE_POS_Y;

    // 口パクるーぷ
    this.stateData.defaultMix = 0;
    this.zIndex = Z_INDEX_AVATAR;

    this.gaze1 = this.skeleton.findBone('gaze') as Bone;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isNumber(arg: any): arg is number {
    return typeof arg === 'number';
  }

  private avatarSetAnimationStart(motion: string): Animation {
    const animation = new Animation({});
    animation.duration = this.skeleton.data.findAnimation(motion)!.duration * 1000;

    // debugDisplay('== animation.duration', animation.duration, 'motion', motion);

    const listener: IAnimationStateListener = {
      complete: () => {
        Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };
    animation.addOnStart(() => {
      this.state.removeListener(listener);
      this.state.setAnimation(0, motion, false);
      this.state.addListener(listener);
    });
    animation.addOnSkip(() => {
      this.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.state.removeListener(listener);
      // debugDisplay('== animation.addOnComplete');
    });

    animation.start();

    return animation;
  }

  private avatarSetAnimation(motion: number): void {
    this.motion = motion;
    this.timeId.forEach((timer) => {
      clearTimeout(timer);
    });
    this.timeId = [];

    let timer = 0;
    for (let i = 0; i < motionList[motion]!.motion.length; i++) {
      this.timeId[this.timeId.length] = setTimeout(() => {
        // const date = new Date();
        // debugDisplay('=========== date', date.toLocaleString());
        this.avatarSetAnimationStart(motionList[motion]!.motion[i]!);
      }, timer);
      timer += this.skeleton.data.findAnimation(motionList[motion]!.motion[i]!)!.duration * 1000;
    }

    this.stateData.defaultMix = 0;
    this.state.setAnimation(1, talkList[motion]!.mouthDefault, true);
    this.timeId[this.timeId.length] = setTimeout(() => {
      this.avatarMotionMain(0);
    }, timer);
  }

  private startWinAnimation(nextResult: ISettledBet | number): void {
    let winCoinAmount = 0;
    if (this.isNumber(nextResult)) {
      // winCoinAmount = getWinCoin();
    } else {
      winCoinAmount = nextResult.bet.betStorage.estimatedWinCoinAmount;
    }

    if (
      winCoinAmount > 0 &&
      this.motionType != BaseGameMotionType.CountUpBig &&
      this.motionType != BaseGameMotionType.CountUpMega &&
      this.motionType != BaseGameMotionType.CountUpGreat &&
      this.motionType != BaseGameMotionType.CountUpEpic
    ) {
      // this.cancelTalk();
      // if (this.motionType === BaseGameMotionType.BallDrop) {
      //   if (this.motionTypeNext === BaseGameMotionType.Non) {
      //     this.motionTypeNext = BaseGameMotionType.CountUp;
      //   }
      // } else {
      //   this.motionType = BaseGameMotionType.CountUp;
      // }
      // this.avatarMotionMain(0);
    }
  }

  private setBigWinVisibility(visible: boolean): void {
    if (setIsReplay()) return;
    if (visible) {
      this.motionType = BaseGameMotionType.fCountUpBig;
      this.avatarMotionMain(0);
    }
  }

  private setMegaWinVisibility(visible: boolean): void {
    if (setIsReplay()) return;
    if (visible) {
      this.motionType = BaseGameMotionType.CountUpMega;
      this.avatarMotionMain(0);
    }
  }

  private setGreatWinVisibility(visible: boolean): void {
    if (setIsReplay()) return;
    if (visible) {
      this.motionType = BaseGameMotionType.CountUpGreat;
      this.avatarMotionMain(0);
    }
  }

  private setEpicWinVisibility(visible: boolean): void {
    if (setIsReplay()) return;
    if (visible) {
      this.motionType = BaseGameMotionType.CountUpEpic;
      this.avatarMotionMain(0);
    }
  }

  private cancelTalk(): void {
    if (this.mouseTimer != undefined) {
      AudioApi.stop({ type: this.voiceData! });
      this.mouseTimer.skip();
      this.mouseTimer = undefined;
      this.stateData.defaultMix = 0;
      const mouthMotion1 = this.state.setAnimation(1, talkList[this.motion]!.mouthDefault, true);
      mouthMotion1.timeScale = 0.6;
    }
  }

  // TODO　ベースゲームダミー　モーション
  private avatarMotionMain(delay: number, isVoice = true): void {
    debugDisplay('=============avatarMotionMain==========');

    // this.isVoice = true;

    if (this.MotionWait != undefined) {
      this.MotionWait.skip();
      this.MotionWait = undefined;

      if (delay === 0 && this.voiceDelay != undefined) {
        debugDisplay('Voiceキャンセル');
        this.mouseTimer?.skip();
        this.voiceDelay.skip();
        this.voiceDelay = undefined;
      }
    }

    // debugDisplay('タイマー前', 'delay', delay); コメントアウト中
    this.MotionWait = Tween.createDelayAnimation(delay);
    this.MotionWait.addOnComplete(() => {
      debugDisplay('タイマー発動');
      if (this.voiceDelay != undefined) {
        this.mouseTimer?.skip();
        this.voiceDelay.skip();
        this.voiceDelay = undefined;
      }

      if (setIsReplay()) {
        this.avatarMotionReplayMain();
        return;
      } else if (isFreeSpinGameMode(setGameMode())) {
        this.avatarMotionFreeSpinGameMain();
        return;
      } else if (isGambleMode(setGameMode())) {
        this.avatarMotionGambleMain();
        return;
      }

      const motion = this.baseGameMotionSelection();

      if (this.motionType === BaseGameMotionType.Non) {
        this.avatarMotionMain(0);
        return;
      }

      // console.log('===== Motion ', motion);

      if (this.isEysMotion) {
        this.lotEyeControl(motion);
      } else {
        if (this.delay != undefined) {
          this.delay?.skip();
          this.delay = undefined;
        }
      }

      debugDisplay('motion', motion, 'setAvatarStatusControl()', setAvatarStatusControl());
      const voiceArray =
        talkList[motion]?.talkInfo[setAvatarStatusControl()] === undefined
          ? takeUndefined
          : talkList[motion]!.talkInfo[setAvatarStatusControl()]!;

      if (this.isVoice && this.motionType != BaseGameMotionType.AfterBonusWin2) {
        debugDisplay('voiceArray', voiceArray, 'motion', motion, 'setAvatarStatusControl()', setAvatarStatusControl());

        let voice = repeatAvoidance(voiceArray.talkList);

        if (voice === undefined) {
          voice = 101000;
        }

        if (!isVoice) {
          voice = 101000;
        }

        debugDisplay('voice', voice);

        const voiceDelay = Math.floor(Math.random() * VoiceStartParameter[voice]!.delayMax);

        debugDisplay(
          '==== モーションID1',
          motion,
          ',状況',
          setAvatarStatusControl(),
          ',セリフDelay',
          voiceDelay,
          '秒',
          ',セリフ',
          VoiceStartParameter[voice]!.voiceData,
        );

        this.voiceDelay = Tween.createDelayAnimation(voiceDelay * 1000);
        this.voiceDelay.addOnComplete(() => {
          this.voiceData = VoiceStartParameter[voice]!.voiceData;
          this.lipSync(motion);
        });
        this.voiceDelay.addOnSkip(() => {
          debugDisplay('==== 口パク addOnSkip ', this.voiceData);
          this.mouseTimer?.onSkip();
        });
        this.voiceDelay.start();
      } else {
        debugDisplay('==== モーションID2', motion, ',状況', setAvatarStatusControl());
      }

      setAvatarStatusControl(StatusControlFlg.NORMAL);

      this.avatarSetAnimation(motion);
    });
    this.MotionWait.start();
  }

  // 7.6.1 ベースゲーム中のモーション選択フロー
  // TODO モーション再生終了イベントを拾ったらここへ来るように
  private baseGameMotionSelection(): number {
    const date = new Date();
    debugDisplay('baseGameMotionSelection', this.motionType, 'date', date.toLocaleString());
    this.isEysMotion = true;

    let motion: number;
    motion = 0;
    if (this.motionType === BaseGameMotionType.bNudge) {
      debugDisplay(' B. ナッジ見守り');
      // B. ナッジ見守り
      this.setDefaultMix(true);
      motion = this.lotNudge();
      this.motionType = BaseGameMotionType.bNudge;
      this.isVoice = true;
      this.isIdleMotion = true;
    } else if (this.motionType === BaseGameMotionType.cNudgeMiss) {
      debugDisplay(' C. ナッジハズレリアクション');
      // C. ナッジハズレリアクション
      this.setDefaultMix(true);
      motion = this.lotNudgeMiss();
      this.motionType = BaseGameMotionType.aNormal;
      this.isVoice = true;
      this.isIdleMotion = true;
    } else if (this.motionType === BaseGameMotionType.dNudgeHit) {
      // D. FS当選リアクション
      this.setDefaultMix(true);
      motion = this.lotNudgeHit();
      this.motionType = BaseGameMotionType.eNudgeHitAfterWatchOver;
      this.isVoice = true;
      this.isIdleMotion = true;
    } else if (this.motionType === BaseGameMotionType.eNudgeHitAfterWatchOver) {
      // E. FS当選後見守り
      this.setDefaultMix(true);
      motion = this.lotNudgeHitAfterWatchOver();
      this.motionType = BaseGameMotionType.eNudgeHitAfterWatchOver;
      this.isVoice = false;
      this.isIdleMotion = true;
    } else if (this.motionType === BaseGameMotionType.fCountUpBig) {
      debugDisplay(' F. Big Winリアクション');
      // F. Big Winリアクション
      this.setDefaultMix(true);
      motion = this.lotCountUpBig();
      this.motionType = BaseGameMotionType.gCountUpWatchOver;
      this.isVoice = true;
      this.isIdleMotion = true;
    } else if (
      this.motionType === BaseGameMotionType.gCountUpWatchOver ||
      this.motionType === BaseGameMotionType.CountUpMega ||
      this.motionType === BaseGameMotionType.CountUpGreat ||
      this.motionType === BaseGameMotionType.CountUpEpic
    ) {
      // G. カウントアップ見守り
      this.setDefaultMix(true);
      motion = this.lotWinCountUp();
      this.isVoice = true;
      this.isIdleMotion = true;
    } else if (this.motionType === BaseGameMotionType.hGambleOrCollect) {
      // H. Gamble or Collect見守り
      this.setDefaultMix(true);
      motion = this.lotGambleOrCollect();
      this.isVoice = false;
      this.isIdleMotion = true;
    }
    // それ以外？
    else {
      // A. 待機ループ
      this.setDefaultMix(true);
      motion = this.lotBaseGameWaitLoop();
      this.motionType = BaseGameMotionType.aNormal;
      this.isIdleMotion = true;
      this.isVoice = true;
    }
    return motion;
  }

  // A. ベースゲーム待機の待機ループ
  private lotBaseGameWaitLoop(): number {
    let lotTable = aBaseGameWaitLoop1;
    switch (setAvatarTension()) {
      case 1:
        lotTable = aBaseGameWaitLoop1;
        break;
      case 2:
        lotTable = aBaseGameWaitLoop2;
        break;
      case 3:
        lotTable = aBaseGameWaitLoop3;
        break;
      default:
        console.error('lotBaseGameWaitLoop default');
        break;
    }

    // A. ベースゲーム待機
    const rand = Math.floor(Math.random() * 100);

    const lot = getResultFromTable(lotTable, rand);
    debugDisplay('A.待機ループ', 'アバターテンション', setAvatarTension(), '待機', MotionTaiki[lot]);
    return MotionTaiki[lot]!;
  }

  // B. ナッジ見守り
  private lotNudge(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(bNudgeWatchOver, rand);
    debugDisplay('B. ナッジ見守り lot', lot);
    if (lot < 7) {
      debugDisplay('B. ナッジ見守り ', '見守り', MotionMimamori[lot]);
      return MotionMimamori[lot]!;
    } else {
      debugDisplay('B. ナッジ見守り ', 'リアクション', MotionReaction[6]);
      return MotionReaction[6]!;
    }
  }

  // C. ナッジハズレリアクション
  private lotNudgeMiss(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(cNudgeMiss, rand);
    debugDisplay('C. ナッジハズレリアクション ', 'リアクション', MotionReaction[lot]);
    return MotionReaction[lot]!;
  }

  // D. FS当選リアクション
  private lotNudgeHit(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(dNudgeHit, rand);
    debugDisplay('D. FS当選リアクション ', 'リアクション', MotionReaction[lot]);
    return MotionReaction[lot]!;
  }

  // E. FS当選後見守り
  private lotNudgeHitAfterWatchOver(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(eNudgeHitAfterWatchOver, rand);
    debugDisplay('E. FS当選後見守り ', '見守り', MotionMimamori[lot]);
    return MotionMimamori[lot]!;
  }

  // F. Big Winリアクション
  private lotCountUpBig(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(fCountUpBig, rand);
    debugDisplay('F. Big Winリアクション ', 'リアクション', MotionReaction[lot]);
    return MotionReaction[lot]!;
  }

  // G. カウントアップ見守り
  private lotWinCountUp(): number {
    let lotTable = MotionMimamori[0]!;

    // Big Win
    if (
      this.motionType === BaseGameMotionType.fCountUpBig ||
      this.motionType === BaseGameMotionType.gCountUpWatchOver
    ) {
      lotTable = MotionMimamori[4]!;
    }
    // Mega Win
    else if (this.motionType === BaseGameMotionType.CountUpMega) {
      lotTable = MotionMimamori[4]!;
    }
    // Great Win
    else if (this.motionType === BaseGameMotionType.CountUpGreat) {
      lotTable = MotionMimamori[5]!;
    }
    // Epic Win
    else if (this.motionType === BaseGameMotionType.CountUpEpic) {
      lotTable = MotionMimamori[6]!;
    }

    setAvatarStatusControl(StatusControlFlg.COUNTUP);

    debugDisplay('J. Winカウントアップ見守り', 'this.motionType', this.motionType, '見守り', lotTable);
    this.eyeControlReel();
    return lotTable;
  }

  // H. Gamble or Collect見守り
  private lotGambleOrCollect(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(hGambleOrCollect, rand);
    debugDisplay('H. Gamble or Collect見守り ', '見守り', MotionMimamori[lot]);
    return MotionMimamori[lot]!;
  }

  // 待機モーション時の視線制御抽せん
  private lotEyeControl(motion: number): void {
    if (this.delay != undefined) {
      debugDisplay('ランダムタイマー解除');
      this.delay.skip();
      this.delay = undefined;
    }

    let eyeLotTable;
    if (setEyeControl() === EyeControl.IDLE) {
      debugDisplay('待機モーション時の視線制御抽せん : IDLE');
      eyeLotTable = eyeControlIdle;
    } else {
      debugDisplay('待機モーション時の視線制御抽せん : PLAY');
      eyeLotTable = eyeControlPlay;
    }
    const rand = Math.floor(Math.random() * 100);
    const lot = this.chkMimamori(motion) ? 1 : getResultFromTable(eyeLotTable, rand);

    if (lot === 0) {
      this.eyePosX = EyePositionInfo[EyePosition.Center]!.pos[0]!.x;
      this.eyePosY = EyePositionInfo[EyePosition.Center]!.pos[0]!.y;
      debugDisplay('視線制御: 正面 x:', this.eyePosX, ',y:', this.eyePosY);
      this.eyeControl(this.eyePosX, this.eyePosY);
    } else if (lot === 1) {
      const eyePosition = this.windowWidth > this.windowHeight ? EyePosition.Reel : EyePosition.ReelPortrait;
      this.eyePosX = EyePositionInfo[eyePosition]!.pos[0]!.x;
      this.eyePosY = EyePositionInfo[eyePosition]!.pos[0]!.y;
      debugDisplay('視線制御: リール部 x:', this.eyePosX, ',y:', this.eyePosY);
      this.eyeControl(this.eyePosX, this.eyePosY);
    } else {
      this.eyeRandomPosition();
    }
  }

  private chkMimamori(motion: number): boolean {
    return (
      motion === MotionName.MIMAMORI1 ||
      motion === MotionName.MIMAMORI2 ||
      motion === MotionName.MIMAMORI3 ||
      motion === MotionName.MIMAMORI4 ||
      motion === MotionName.MIMAMORI5 ||
      motion === MotionName.MIMAMORI6 ||
      motion === MotionName.MIMAMORI7
    );
  }

  private eyeRandomPosition(): void {
    this.delay = Tween.createDelayAnimation(AVATAR_EYE_RANDOM_TIMER);
    this.delay.addOnStart(() => {
      const rand = Math.floor(Math.random() * EyePositionInfo[EyePosition.Random]!.pos.length);
      this.eyePosX = EyePositionInfo[EyePosition.Random]!.pos[rand]!.x;
      this.eyePosY = EyePositionInfo[EyePosition.Random]!.pos[rand]!.y;
      debugDisplay('視線制御: ランダム', rand, 'x:', this.eyePosX, ',y:', this.eyePosY);
      this.eyeControl(this.eyePosX, this.eyePosY);
    });
    this.delay.addOnComplete(() => {
      // debugDisplay('視線制御: ランダム');
      this.eyeRandomPosition();
    });
    this.delay.addOnSkip(() => {
      debugDisplay('視線制御: ランダム skip');
    });
    this.delay.start();
  }

  private toggleUiBtn(eye: EyePosition): void {
    debugDisplay('toggleUiBtn', EyePositionInfo[eye]!.pos[0]!.x, EyePositionInfo[eye]!.pos[0]!.y);

    this.eyeControl(EyePositionInfo[eye]!.pos[0]!.x, EyePositionInfo[eye]!.pos[0]!.y);

    this.bUiGaze = true;
    // const gaze = this.skeleton.findBone('gaze') as Bone;

    if (this.uiGazeTimer != undefined) {
      debugDisplay('視線制御: UI制御　タイマー解除 eye', eye);
      this.uiGazeTimer.skip();
      this.uiGazeTimer = undefined;
    }

    this.uiGazeTimer = Tween.createDelayAnimation(AVATAR_EYE_UI_CONTROL_TIMER);
    this.uiGazeTimer.addOnComplete(() => {
      debugDisplay('視線制御: UI制御 制御終了　モーションに対応した視線に戻り　x:', this.eyePosX, ',y:', this.eyePosY);
      this.bUiGaze = false;
      this.uiGazeTimer = undefined;
      this.eyeControl(this.eyePosX, this.eyePosY);
    });
    this.uiGazeTimer.addOnSkip(() => {
      debugDisplay('視線制御: UI制御 addOnSkip');
    });
    this.uiGazeTimer.start();
  }

  private eyeControlReel(): void {
    debugDisplay('視線制御　見守り固定: リール部');
    const eyePosition = this.windowWidth > this.windowHeight ? EyePosition.Reel : EyePosition.ReelPortrait;
    this.eyePosX = EyePositionInfo[eyePosition]!.pos[0]!.x;
    this.eyePosY = EyePositionInfo[eyePosition]!.pos[0]!.y;
    this.eyeControl(this.eyePosX, this.eyePosY);
  }

  private eyeControl(eyePosX: number, eyePosY: number): void {
    if (this.uiGazeTimer != undefined) {
      debugDisplay('UI 制御中のため視線制御キャンセル');
      return;
    }

    if (this.gazeMoveDelay != undefined) {
      this.gazeMoveDelay.skip();
      this.gazeMoveDelay = undefined;
      this.gaveMoveCounter = 10;
    }

    if (!this.bUiGaze) {
      const x = eyePosX - this.gaze1.x;
      const y = eyePosY - this.gaze1.y;
      const rand = Math.floor(Math.random() * 100);
      const lot = getResultFromTable(eyeMoveDelay, rand);
      const delay = gazeMoveDelay[lot]!;
      this.gaveMoveCounter = 0;
      this.gazeMove(this.gaze1.x, this.gaze1.y, x, y, delay);
    }
  }

  private gazeMove(crX: number, crY: number, x: number, y: number, gazeDelay: number): void {
    this.gazeMoveDelay = Tween.createDelayAnimation(gazeDelay);
    this.gazeMoveDelay.addOnComplete(() => {
      this.gaze1.x = crX + x * calEase(this.gaveMoveCounter * 0.1);
      this.gaze1.y = crY + y * calEase(this.gaveMoveCounter * 0.1);

      this.gaveMoveCounter += 1;

      if (this.gaveMoveCounter <= 10) {
        this.gazeMove(crX, crY, x, y, gazeDelay);
      }
    });
    this.gazeMoveDelay.addOnSkip(() => {});
    this.gazeMoveDelay.start();
  }

  private avatarMotionGambleMain(): void {
    debugDisplay('ギャンブル中のアバター処理メイン', this.motionType);

    let motion = 101;
    let isVoice = false;

    this.isEysMotion = false;

    if (this.motionType === BaseGameMotionType.lGambleWatchOver) {
      // L. Gamble前見守り
      this.setDefaultMix(false);
      motion = this.lotGambleWatchOver();
      isVoice = false;
      this.isIdleMotion = false;
    } else if (this.motionType === BaseGameMotionType.mGambleWatchOverFirstHalf) {
      // M. Gamble中見守り（前半）
      this.setDefaultMix(false);
      motion = this.lotGambleWatchOverFirstHalf();
      this.gambleWatchOverFirstHalf = motion;
      isVoice = false;
      this.isIdleMotion = false;
    } else if (this.motionType === BaseGameMotionType.nDealerSymbol) {
      // N. Dealerシンボルに応じてリアクション

      this.setDefaultMix(false);
      motion = this.lotDealerSymbol();
      isVoice = true;
      this.isIdleMotion = false;

      this.motionTypeNext = BaseGameMotionType.oGambleWatchOverLatterHalf;
    } else if (this.motionType === BaseGameMotionType.oGambleWatchOverLatterHalf) {
      // O. Gamble中見守り（後半）
      this.setDefaultMix(true);
      if (SlotMachine.getInstance().nextResult!.bet.spinResult[4]!.id === 'J') {
        motion = this.lotGambleWatchOverLatterHalf();
      } else {
        debugDisplay('O. Gamble中見守り（後半 前半と同じモーション）', '見守り', this.gambleWatchOverFirstHalf);
        motion = this.gambleWatchOverFirstHalf;
      }
      this.motionType = BaseGameMotionType.aNormal;
      isVoice = false;
      this.isIdleMotion = true;
    } else if (this.motionType === BaseGameMotionType.pGambleResult) {
      // P. Gamble結果に応じてリアクション
      this.setDefaultMix(true);
      motion = this.lotGambleResult();
      this.motionType = BaseGameMotionType.lGambleWatchOver;
      isVoice = true;
      this.isIdleMotion = true;
    }

    debugDisplay('======= motion ======', motion);
    if (this.isEysMotion) {
      this.lotEyeControl(motion);
    } else {
      if (this.delay != undefined) {
        this.delay?.skip();
        this.delay = undefined;
      }
    }

    if (isVoice) {
      debugDisplay('motion', motion, 'setAvatarStatusControl()', setAvatarStatusControl());
      const voiceArray =
        talkList[motion]?.talkInfo[setAvatarStatusControl()] === undefined
          ? takeUndefined
          : talkList[motion]!.talkInfo[setAvatarStatusControl()]!;
      let voice = repeatAvoidance(voiceArray.talkList);

      if (voice === undefined) {
        voice = 101000;
      }
      debugDisplay('voice1', voice, 'voiceArray', voiceArray);

      const voiceDelay = Math.floor(Math.random() * VoiceStartParameter[voice]!.delayMax);

      debugDisplay(
        '==== モーションID3',
        motion,
        ',状況',
        setAvatarStatusControl(),
        ',セリフDelay',
        voiceDelay,
        '秒',
        ',セリフ',
        VoiceStartParameter[voice]!.voiceData,
        mappedAudioSprites[VoiceStartParameter[voice]!.voiceData].duration,
      );

      setAvatarStatusControl(StatusControlFlg.NORMAL);
      this.voiceDelay = Tween.createDelayAnimation(voiceDelay * 1000);
      this.voiceDelay.addOnComplete(() => {
        this.voiceData = VoiceStartParameter[voice]!.voiceData;
        debugDisplay('==== 口パクスタート 2', this.voiceData);

        this.lipSync(motion);
      });
      this.voiceDelay.addOnSkip(() => {
        debugDisplay('==== 口パク addOnSkip ', this.voiceData);
        this.mouseTimer?.onSkip();
      });
      this.voiceDelay.start();
    } else {
      this.stateData.defaultMix = 0;
      this.state.setAnimation(1, talkList[motion]!.mouthDefault, true);
    }

    this.avatarSetAnimation(motion);

    if (this.motionTypeNext != BaseGameMotionType.Non) {
      if (this.voiceComplete) {
        this.motionType = this.motionTypeNext;
        this.motionTypeNext = BaseGameMotionType.Non;
      }
    }
  }

  private avatarMotionFreeSpinGameMain(): void {
    debugDisplay('フリースピン中のアバター処理メイン');

    let motion = 101;
    let isVoice = false;

    this.isEysMotion = false;

    if (this.motionType === BaseGameMotionType.iFsStartSignboardWatchOver) {
      // I. FS開始時看板見守り
      this.setDefaultMix(false);
      motion = this.lotFsStartSignboardWatchOver();
      isVoice = true;
      this.isIdleMotion = false;
    } else if (this.motionType === BaseGameMotionType.jFsWatchOver) {
      // J. FS中見守り
      this.setDefaultMix(false);
      motion = this.lotFsWatchOver();
      isVoice = true;
      this.isIdleMotion = false;
    } else if (this.motionType === BaseGameMotionType.kTotalWinSignboard) {
      // K. Total Win看板見守り
      this.setDefaultMix(false);
      motion = this.lotTotalWin();
      isVoice = true;
      this.isIdleMotion = false;
    } else if (this.motionType === BaseGameMotionType.fCountUpBig) {
      // F. Big Winリアクション
      this.setDefaultMix(true);
      motion = this.lotCountUpBig();
      this.motionType = BaseGameMotionType.gCountUpWatchOver;
      isVoice = true;
      this.isIdleMotion = true;
    } else if (
      this.motionType === BaseGameMotionType.gCountUpWatchOver ||
      this.motionType === BaseGameMotionType.CountUpMega ||
      this.motionType === BaseGameMotionType.CountUpGreat ||
      this.motionType === BaseGameMotionType.CountUpEpic
    ) {
      // G. カウントアップ見守り
      this.setDefaultMix(true);
      motion = this.lotWinCountUp();
      isVoice = true;
      this.isIdleMotion = true;
    }

    debugDisplay('======= motion ======', motion);
    if (this.isEysMotion) {
      this.lotEyeControl(motion);
    } else {
      if (this.delay != undefined) {
        this.delay?.skip();
        this.delay = undefined;
      }
    }

    if (isVoice) {
      debugDisplay('motion', motion, 'setAvatarStatusControl()', setAvatarStatusControl());
      const voiceArray =
        talkList[motion]?.talkInfo[setAvatarStatusControl()] === undefined
          ? takeUndefined
          : talkList[motion]!.talkInfo[setAvatarStatusControl()]!;
      let voice = repeatAvoidance(voiceArray.talkList);

      if (voice === undefined) {
        voice = 101000;
      }
      debugDisplay('voice1', voice, 'voiceArray', voiceArray);

      const voiceDelay = Math.floor(Math.random() * VoiceStartParameter[voice]!.delayMax);

      debugDisplay(
        '==== モーションID3',
        motion,
        ',状況',
        setAvatarStatusControl(),
        ',セリフDelay',
        voiceDelay,
        '秒',
        ',セリフ',
        VoiceStartParameter[voice]!.voiceData,
        mappedAudioSprites[VoiceStartParameter[voice]!.voiceData].duration,
      );

      setAvatarStatusControl(StatusControlFlg.NORMAL);
      this.voiceDelay = Tween.createDelayAnimation(voiceDelay * 1000);
      this.voiceDelay.addOnComplete(() => {
        this.voiceData = VoiceStartParameter[voice]!.voiceData;
        debugDisplay('==== 口パクスタート 2', this.voiceData);

        this.lipSync(motion);
      });
      this.voiceDelay.addOnSkip(() => {
        debugDisplay('==== 口パク addOnSkip ', this.voiceData);
        this.mouseTimer?.onSkip();
      });
      this.voiceDelay.start();
    } else {
      this.stateData.defaultMix = 0;
      this.state.setAnimation(1, talkList[motion]!.mouthDefault, true);
    }

    this.avatarSetAnimation(motion);

    if (this.motionTypeNext != BaseGameMotionType.Non) {
      if (this.voiceComplete) {
        this.motionType = this.motionTypeNext;
        this.motionTypeNext = BaseGameMotionType.Non;
      }
    }
  }

  private avatarMotionReplayMain(): void {
    debugDisplay('Replay中のアバター処理メイン');

    let motion = 101;
    this.isEysMotion = false;

    // Replay中は見守り４固定
    this.setDefaultMix(false);
    motion = MotionName.MIMAMORI4;
    this.isIdleMotion = false;

    if (this.isEysMotion) {
      this.lotEyeControl(motion);
    } else {
      if (this.delay != undefined) {
        this.delay?.skip();
        this.delay = undefined;
      }
    }

    this.stateData.defaultMix = 0;
    this.state.setAnimation(1, talkList[motion]!.mouthDefault, true);

    this.avatarSetAnimation(motion);

    if (this.motionTypeNext != BaseGameMotionType.Non) {
      if (this.voiceComplete) {
        this.motionType = this.motionTypeNext;
        this.motionTypeNext = BaseGameMotionType.Non;
      }
    }
  }

  // I. FS開始時看板見守り
  private lotFsStartSignboardWatchOver(): number {
    const lotTable = fFsStartSignboardWatchOver;
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(lotTable, rand);
    debugDisplay('I. FS開始時看板見守り', '見守り', MotionMimamori[lot]);

    this.eyeControlReel();
    return MotionMimamori[lot]!;
  }

  // J. FS中見守り
  private lotFsWatchOver(): number {
    let lotTable = jFsWatchOver1spin[0]!;

    const round =
      SlotMachine.getInstance().state === SlotMachineState.IDLE
        ? setCurrentBonus().roundsPlayed + 1
        : setCurrentBonus().roundsPlayed;

    lotTable = this.getFsLotTable(round);

    if (this.prevFsWatchOverTbl != lotTable) {
      const rand = Math.floor(Math.random() * 100);
      const lot = getResultFromTable(lotTable, rand);
      this.prevFsWatchOverTbl = lotTable;
      this.prevFlWatchOverLot = lot;
      debugDisplay(
        '★J. FS中見守り',
        '見守り',
        MotionMimamori[this.prevFlWatchOverLot],
        'roundPlay',
        SlotMachine.getInstance().nextResultPrev?.bet.wager.wagerStorage.roundsPlayed,
        'setCurrentBonus().roundsPlayed',
        setCurrentBonus().roundsPlayed,
        'bet',
        normalizeCoins(setCurrentFreeSpinsTotalWin()) / normalizeCoins(setBetAmount()),
        'lotTable',
        lotTable,
        'rand',
        rand,
      );
    }
    this.eyeControlReel();

    return MotionMimamori[this.prevFlWatchOverLot]!;
  }

  private getFsLotTable(round: number): number[] {
    const win = normalizeCoins(setCurrentFreeSpinsTotalWin()) / normalizeCoins(setBetAmount());

    let lotTable = jFsWatchOver1spin[0]!;
    switch (round) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        if (win < 10) {
          lotTable = jFsWatchOver1spin[0]!;
        } else if (win < 30) {
          lotTable = jFsWatchOver1spin[1]!;
        } else {
          lotTable = jFsWatchOver1spin[2]!;
        }
        break;
      case 6:
      case 7:
      case 8:
        if (win < 20) {
          lotTable = jFsWatchOver6spin[0]!;
        } else if (win < 40) {
          lotTable = jFsWatchOver6spin[1]!;
        } else {
          lotTable = jFsWatchOver6spin[2]!;
        }
        break;
      case 9:
      case 10:
        if (win < 25) {
          lotTable = jFsWatchOver9spin[0]!;
        } else if (win < 40) {
          lotTable = jFsWatchOver9spin[1]!;
        } else {
          lotTable = jFsWatchOver9spin[2]!;
        }
        break;
    }
    return lotTable;
  }

  // K. Total Win看板表示中のモーション
  private lotTotalWin(): number {
    let lotTable = MotionName.MIMAMORI1;

    const win = normalizeCoins(setCurrentFreeSpinsTotalWin()) / normalizeCoins(setBetAmount());

    // Betの100倍以上
    if (win >= 100) {
      lotTable = MotionName.MIMAMORI7;
    }
    // Betの40倍～100倍
    else if (win >= 40 && win < 100) {
      lotTable = MotionName.MIMAMORI6;
    }
    // Betの30倍～40倍
    else if (win >= 30 && win < 40) {
      lotTable = MotionName.MIMAMORI5;
    }
    // Betの25倍～30倍
    else if (win >= 25 && win < 30) {
      lotTable = MotionName.MIMAMORI4;
    }
    // Betの20倍～25倍
    else if (win >= 20 && win < 25) {
      lotTable = MotionName.MIMAMORI3;
    } else {
      lotTable = MotionName.MIMAMORI1;
    }

    setAvatarStatusControl(StatusControlFlg.COUNTUP);

    debugDisplay('K. Total Win看板表示中の見守り', lotTable, 'win', win);
    this.eyeControlReel();
    return lotTable;
  }

  // L. Gamble前見守り
  private lotGambleWatchOver(): number {
    const lotTable = lGambleWatchOver;
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(lotTable, rand);
    debugDisplay('L. Gamble前見守り', '見守り', MotionMimamori[lot]);
    this.eyeControlReel();
    return MotionMimamori[lot]!;
  }

  // M. Gamble中見守り（前半）
  private lotGambleWatchOverFirstHalf(): number {
    const lotTable = mGambleWatchOverFirstHalf;
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(lotTable, rand);
    debugDisplay('M. Gamble中見守り（前半）', '見守り', MotionMimamori[lot]);
    this.eyeControlReel();
    return MotionMimamori[lot]!;
  }

  // N. Dealerシンボルに応じてリアクション
  private lotDealerSymbol(): number {
    const lotTable =
      SlotMachine.getInstance().nextResult!.bet.spinResult[4]!.id === 'J' ? nDealerSymbolBlank : nDealerSymbolNotBlank;
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(lotTable, rand);
    debugDisplay('N. Dealerシンボルに応じてリアクション', 'リアクション', MotionReactionShort[lot]);
    this.eyeControlReel();
    return MotionReactionShort[lot]!;
  }

  // O. Gamble中見守り（後半）
  private lotGambleWatchOverLatterHalf(): number {
    const lotTable = oGambleWatchOverLatterHalf;
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(lotTable, rand);
    debugDisplay('O. Gamble中見守り（後半）', '見守り', MotionMimamori[lot]);
    this.eyeControlReel();
    return MotionMimamori[lot]!;
  }

  // P. Gamble結果に応じてリアクション
  private lotGambleResult(): number {
    const lotTable =
      setGambleStakeResult() === gambleResult.loss
        ? pGambleResultLoss
        : setGambleStakeResult() === gambleResult.draw
        ? pGambleResultDraw
        : pGambleResultWin;

    debugDisplay('lotTable', lotTable, 'setGambleStakeResult', setGambleStakeResult());
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(lotTable, rand);
    debugDisplay('P. Gamble結果に応じてリアクション', 'リアクション', MotionReaction[lot]);
    // this.eyeControlReel();　不要のはず 6/24
    return MotionReaction[lot]!;
  }

  private lipSync(motion: number): void {
    if (this.voiceData === undefined) {
      console.error('voiceData undefined');
      return;
    }
    this.mouseTimer?.skip();

    const date = new Date();
    debugDisplay('voice:', this.voiceData, 'date', date.toLocaleString());
    AudioApi.play({ type: this.voiceData });

    if (this.voiceData === ISongs.SONG_vo10000) {
      this.stateData.defaultMix = 0;
      this.state.setAnimation(1, talkList[motion]!.mouthDefault, true);
    } else {
      const mouthVoice = talkList[motion]!.mouthVoice;

      this.stateData.defaultMix = 0;
      const mouthMotion = this.state.setAnimation(1, mouthVoice, true);
      mouthMotion.timeScale = 0.6;
      const date = new Date();
      debugDisplay(
        '口パク開始　口のモーション mouthVoice',
        this.motion,
        talkList[this.motion]!.mouthVoice,
        'this.voiceData',
        this.voiceData,
        'duration',
        mappedAudioSprites[this.voiceData!]!.duration,
        'date',
        date.toLocaleString(),
      );

      this.mouseTimer = Tween.createDelayAnimation(mappedAudioSprites[this.voiceData!]!.duration);
      this.mouseTimer.addOnComplete(() => {
        const date = new Date();
        debugDisplay(
          'addOnComplete 口パク終了　口のモーション mouthVoice',
          this.motion,
          talkList[this.motion]!.mouthVoice,
          'date',
          date.toLocaleString(),
        );
        AudioApi.stop({ type: this.voiceData! });
        this.stateData.defaultMix = 0;
        const mouthMotion1 = this.state.setAnimation(1, talkList[this.motion]!.mouthDefault, false);
        mouthMotion1.timeScale = 0.6;
      });
      this.mouseTimer.addOnSkip(() => {
        const date = new Date();
        debugDisplay(
          'addOnSkip 口パクSkip　口のモーション mouthVoice',
          this.motion,
          talkList[this.motion]!.mouthVoice,
          'date',
          date.toLocaleString(),
        );
        AudioApi.stop({ type: this.voiceData! });
        this.stateData.defaultMix = 0;
        const mouthMotion1 = this.state.setAnimation(1, talkList[this.motion]!.mouthDefault, false);
        mouthMotion1.timeScale = 0.6;
      });
      this.mouseTimer.start();
    }
  }

  private resize(width: number, height: number): void {
    this.windowWidth = width;
    this.windowHeight = height;

    if (width > height) {
      this.position.x = AVATAR_LANDSCAPE_POS_X;
      this.position.y = AVATAR_LANDSCAPE_POS_Y;
      this.scale.set(1, 1);
    } else {
      this.position.x = AVATAR_PORTRAIT_POS_X;
      this.position.y = AVATAR_PORTRAIT_POS_Y;
      this.scale.set(0.7, 0.7);
    }
  }

  private setDefaultMix(isIdle: boolean): void {
    let mix = 0;
    if (isIdle && this.isIdleMotion) {
      mix = 0.1;
    }
    this.stateData.defaultMix = mix;
  }
}

export default VAvatar;
