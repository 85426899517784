import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, Popup } from '@phoenix7dev/shared-components/';

import { ISongs } from '../../config/audio/sprite.generated';
import { PopupOpeningTypes } from '../../global.d';
import { setIsOpenAutoplayPopup, setIsPopupOpeningInProgress } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';

import AutoPlaySettings from '.';

const AutoPlaySettingsPopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { data: configData } = useQuery<IConfig>(configGql);
  const { t } = useTranslation();
  const { isOpenAutoplayPopup } = configData!;

  useEffect(() => {
    if (isOpenAutoplayPopup) {
      AudioApi.play({ type: ISongs.SONG_SFX_UI_MenuOpen });
    }
    setShowPopup(isOpenAutoplayPopup);
  }, [isOpenAutoplayPopup]);

  useEffect(() => {
    if (showPopup && configData?.isSpinInProgress) {
      setShowPopup(false);
    }
  }, [configData?.isSpinInProgress, showPopup]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsPopupOpeningInProgress() &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.AUTOPLAY_POPUP &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP &&
      !directClickToCloseIcon
    )
      return;

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SONG_SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SONG_SFX_UI_Close });
      }
    }, 0);
    setIsOpenAutoplayPopup(false);
  };

  return (
    <Popup id="popup" className="popup" showNow={showPopup} setShowNow={() => null}>
      <div className="popup__title">{t('autoPlay')}</div>
      <Button intent="close" className="popup__close" onClick={handleClose} />
      <AutoPlaySettings handleClose={() => handleClose(true)} />
    </Popup>
  );
};

export default AutoPlaySettingsPopup;
