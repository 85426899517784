import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { EventTypes, GameMode, IGambleInput, ISettledBet } from '../../global.d';
import { setGameMode, setNextResult, setPlayer, setSlotConfig } from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { gambleGql } from '../../gql/mutation';
import { configGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { debugDisplay } from '../../utils';

export const Gamble: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { data: configData } = useQuery<IConfig>(configGql);

  const [gamble] = useMutation<{ selectOutcomes: ISettledBet }, { input: IGambleInput }>(gambleGql, {
    onError(error) {
      console.log(error);
    },
    async onCompleted(data) {
      eventManager.emit(EventTypes.GAMBLE_TIMEOUT, false);
      eventManager.emit(EventTypes.CHEAT_CLEAR);
      const spinState = SlotMachine.getInstance().state;
      if (spinState === SlotMachineState.SPIN) {
        eventManager.emit(EventTypes.SET_IS_SLOTS_STOPPED, true);
      }

      SlotMachine.getInstance().setGambleResult(data.selectOutcomes);
      if (SlotMachine.getInstance().isStopped) {
        const spinState = SlotMachine.getInstance().state;
        if (spinState != SlotMachineState.IDLE) {
          // SlotMachine.getInstance().spin(isTurboSpin);
        }
      }
    },
  });

  useEffect(() => {
    if (showPopup && !configData?.isTurboSpin) {
      setShowPopup(false);
    }
  }, [showPopup, configData?.isTurboSpin]);

  const onGambleSpin = useCallback(() => {
    debugDisplay('onGambleSpin  setNextResult()', setNextResult(), 'setPlayer', setPlayer());
    const { id: betId, coinAmount, coinValue, outcomes } = setNextResult()!.bet;
    const outcomeIds = outcomes.find((d) => d.predicament.caseFn === setPlayer())!.id;
    debugDisplay('onGambleSpin  outcomeIds', outcomeIds);
    eventManager.emit(EventTypes.GAMBLE_TIMEOUT, true);
    gamble({
      variables: {
        input: {
          betId: betId,
          coinAmount: coinAmount,
          coinValue: coinValue,
          outcomeIds: outcomeIds,
          slotId: setSlotConfig().slotId,
        },
      },
    });
    setShowPopup(true);
  }, [gamble]);

  const onSpinButtonClick = useCallback(() => {
    debugDisplay('onSpinButtonClick ', setGameMode());
    if (setGameMode() != GameMode.BASE_GAME_GAMBLE) {
      return;
    }
    onGambleSpin();
  }, [onGambleSpin]);

  useEffect(() => {
    eventManager.on(EventTypes.GAMBLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.GAMBLE_SPIN);
    };
  }, [onSpinButtonClick]);

  return null;
};
