// import { Tension } from '../slotMachine/avatarMotion/config';
import { AnimationType, startUpTension } from './table';
import { getRandomFromUUID, getResultFromTable } from './util';

export function conversionAnimationType(num: number): AnimationType {
  let animType = AnimationType.NON;
  switch (num) {
    case 0:
      animType = AnimationType.PHOENIX;
      break;
    case 1:
      animType = AnimationType.NON;
      break;
    default:
      animType = AnimationType.NON;
      break;
  }
  return animType;
}

export function getAnimationSelectionTableLot(uuid: string, table: number[]): AnimationType {
  const rand = getRandomFromUUID(uuid, 100);
  const result = getResultFromTable(table, rand);
  return conversionAnimationType(result);
}

// 8.2.1 起動時・および再読み込み時の初期値
export function getStartupTensionLot(): number {
  const rand = Math.floor(Math.random() * 100);
  return getResultFromTable(startUpTension, rand) + 1;
}
