import * as PIXI from 'pixi.js';

import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import { FEATURE_BTN_LANDSCAPE_HEIGHT, FEATURE_BTN_LANDSCAPE_WIDTH, buyFeatureTextStyle } from '../config';

import { textPositionY } from './textStyles';

class BuyFeatureBtnIcon extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  constructor() {
    super();
    this.isDisabled = false;
    this.visible = true;
    this.btn = this.initBuyFeatureBtn();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
    btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    btn.anchor.set(0.5, 0.5);
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    btn.buttonMode = true;

    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t('buyFeatureBtn'), buyFeatureTextStyle);

    text.position.y = textPositionY;
    text.anchor.set(0.5);
    updateTextScale(text, 120, 100);

    return text;
  }
}

export default BuyFeatureBtnIcon;
