import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { EventTypes, IBalance } from '../../global.d';
import {
  setBalanceAmount,
  setBetAmount,
  setBrokenGame,
  setCoinValue,
  setCurrentFreeSpinsTotalWin,
  setGameMode,
  setIsReplay,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { getBalanceGql, getBetAmountGql, getWinAmountGql } from '../../gql/query';
import {
  calcBottomContainerHeight,
  calcPercentage,
  countCoins,
  isFreeSpinGameMode,
  normalizeBalance,
  normalizeCoins,
  showCurrency,
} from '../../utils';
import { TextField } from '../components/TextField';
import ViewContainer from '../components/container';
import { bottomContainerTextStyle, eventManager, gameNameTextStyle } from '../config';
import Clock from '../ui/clock';

class BottomContainer extends ViewContainer {
  private isInitialized = false;

  private rect: PIXI.Graphics;

  private balance: TextField;

  private win: TextField;

  private bet: TextField;

  private clock: Clock;

  private gameName: TextField;

  // TODO have to be refactored
  private currency = 'FUN';

  private maxFontHeight = 30;

  private maxFontWidth = 300;

  constructor() {
    super();

    this.rect = new PIXI.Graphics();
    this.addChild(this.rect);

    this.balance = new TextField(this.createText('balance', 0), 270, 100, {}, true, bottomContainerTextStyle);
    this.balance.text.anchor.set(0, 0.5);
    this.addChild(this.balance.getText());
    this.win = new TextField(
      this.createText(
        'win',
        formatNumber({
          currency: this.currency,
          value: normalizeCoins(
            countCoins({
              totalAmount: setWinAmount(),
            }),
          ),
          showCurrency: true,
        }),
      ),
      250,
      100,
      {},
      true,
      bottomContainerTextStyle,
    );
    this.win.text.visible = !setBrokenGame();
    this.win.text.anchor.set(0.5, 0.5);
    this.addChild(this.win.getText());

    this.bet = new TextField(
      this.createText(
        'bet',
        formatNumber({
          currency: this.currency,
          value: normalizeCoins(countCoins({ totalAmount: setBetAmount() })),
          showCurrency: true,
        }),
      ),
      250,
      100,
      {},
      true,
      bottomContainerTextStyle,
    );
    this.bet.text.anchor.set(1, 0.5);
    this.addChild(this.bet.getText());
    this.clock = new Clock();
    this.addChild(this.clock);
    this.gameName = new TextField('NAUGHTY JOKER', 250, 100, gameNameTextStyle);
    this.gameName.text.anchor.set(1, 1);
    this.addChild(this.gameName.getText());

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_LABEL, this.setWinLabelVisible.bind(this, false));

    eventManager.on(EventTypes.UPDATE_TOTAL_WIN_VALUE, this.updateTotalWinValue.bind(this));
    eventManager.addListener(EventTypes.UPDATE_WIN_VALUE, this.updateWinValue.bind(this));
    eventManager.addListener(EventTypes.UPDATE_USER_BALANCE, this.updateUserBalance.bind(this));
    eventManager.addListener(EventTypes.UPDATE_BET, () => this.updateBetAmount(setBetAmount()));

    eventManager.on(EventTypes.UI_VISIBLE, (visible) => {
      if (visible != undefined) {
        this.visible = visible;
      } else {
        this.visible = !this.visible;
      }
    });

    client
      .query({
        query: getBalanceGql,
      })
      .then((res) => {
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          (res as unknown as { data: { balance: IBalance } }).data.balance,
        );
      });
    client.watchQuery<{ betAmount: number }>({ query: getBetAmountGql }).subscribe(({ data }) => {
      this.updateBetAmount(data.betAmount);
    });
    client.watchQuery<{ winAmount: number }>({ query: getWinAmountGql }).subscribe(({ data }) => {
      this.updateWinAmount(data.winAmount);
    });
  }

  private updateUserBalance(balance?: { currency: string; amount: number }): void {
    setBalanceAmount(balance != undefined ? balance.amount : 0);
    if (!balance || setIsReplay()) return;
    if (!this.isInitialized) {
      this.currency = balance.currency;
      this.updateBetAmount(setBetAmount());
      this.updateWinAmount(setWinAmount());

      if (isFreeSpinGameMode(setGameMode())) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }

      this.isInitialized = true;
    }
    this.updateBalance(balance.amount);
  }

  private createText(key: 'balance' | 'win' | 'bet' | 'totalWin', value: number | string): string {
    return `${i18n.t(key).toUpperCase()}: <color>${value}</color>`;
  }

  private setWinLabelVisible(visible: boolean): void {
    this.win.text.visible = visible;
  }

  private updateWinValue(newValue: string): void {
    if (setIsReplay()) return;
    if (setUserLastBetResult().coinValue === setCoinValue()) {
      this.setWinLabelVisible(true);
      this.win.setText(`${this.createText('win', newValue)}`);
    }
  }

  private updateTotalWinValue(newValue: number): void {
    if (newValue === 0 || setIsReplay()) return;
    const coins = setUserLastBetResult().id ? setUserLastBetResult().coinValue : setCoinValue();
    this.setWinLabelVisible(true);
    this.win.setText(
      `${this.createText(
        'totalWin',
        formatNumber({
          currency: this.currency,
          value: normalizeCoins(newValue, coins),
          showCurrency: showCurrency(this.currency),
        }),
      )}`,
    );
  }

  private resize(width: number, height: number): void {
    const containerHeight = calcBottomContainerHeight(width, height);
    const mobilePortrait = width < height;

    this.rect.clear();
    this.rect.beginFill(0x000000, 0.7);
    this.rect.drawRect(0, 0, width, containerHeight);
    this.rect.endFill();
    // const padding = width * 0.02;
    const padding = calcPercentage(width, 2.5);

    this.maxFontHeight = mobilePortrait ? calcPercentage(containerHeight, 35) : calcPercentage(containerHeight, 55);
    this.maxFontWidth = mobilePortrait ? (width - padding * 4) / 2 : (width - padding * 4) / 3;

    this.balance.update(this.maxFontWidth, this.maxFontHeight);
    this.win.update(this.maxFontWidth, this.maxFontHeight);
    this.bet.update(this.maxFontWidth, this.maxFontHeight);
    this.clock.update(this.maxFontWidth, this.maxFontHeight / 2.5);
    this.gameName.update(this.maxFontWidth, this.maxFontHeight / 2.5);

    if (mobilePortrait) {
      this.balance.text.position.set(padding, containerHeight / 4);
      this.bet.text.position.set(width - padding, containerHeight / 4);
      this.win.text.position.set(width / 2, containerHeight / 1.8);
    } else {
      this.balance.text.position.set(padding, containerHeight / 3);
      this.win.text.position.set(width / 2, containerHeight / 3);
      this.bet.text.position.set(width - padding, containerHeight / 3);
    }
    this.clock.position.set(padding, containerHeight * 0.9);
    this.gameName.text.position.set(width - padding, containerHeight * 0.9);

    this.pivot.set(0, containerHeight);
    this.position.set(0, height);
  }

  private updateBalance(amount: number): void {
    this.balance.setText(
      this.createText(
        'balance',
        formatNumber({
          currency: this.currency,
          value: normalizeBalance(amount),
          showCurrency: showCurrency(this.currency),
        }),
      ),
    );
    this.balance.update(this.maxFontWidth, this.maxFontHeight);
  }

  private updateWinAmount(amount: number): void {
    const coins = setUserLastBetResult().id ? setUserLastBetResult().coinValue : setCoinValue();
    this.win.setText(
      this.createText(
        'win',
        formatNumber({
          currency: this.currency,
          value: normalizeCoins(countCoins({ totalAmount: amount }), coins),
          showCurrency: showCurrency(this.currency),
        }),
      ),
    );
    this.win.update(this.maxFontWidth, this.maxFontHeight);
  }

  private updateBetAmount(amount: number): void {
    this.bet.setText(
      this.createText(
        'bet',
        formatNumber({
          currency: this.currency,
          value: normalizeCoins(countCoins({ totalAmount: amount })),
          showCurrency: showCurrency(this.currency),
        }),
      ),
    );
    this.bet.update(this.maxFontWidth, this.maxFontHeight);
  }
}

export default BottomContainer;
