import SlotMachine from '..';
import { BgmSoundTypes, BonusStatus, EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCurrency,
  setCurrentBonus,
  setCurrentStage,
  setGambleStake,
  setGameMode,
  setIsCountUp,
  setIsFadeOut,
  setIsHistoryVisible,
  setIsMeloFlag,
  setIsReplay,
  setIsReplayGamble,
  setPlayer,
  setPrevReelsPosition,
  setReplayGambleCount,
  setReplayResult,
  setReplaySpin,
  setSlotConfig,
} from '../../gql/cache';
import { IBet } from '../../gql/d';
import { getSpinResult3x1, isFreeSpinsMode } from '../../utils';
import { nextGambleSpin, setPlayerToNumber } from '../../utils/utils/gamble';
import Tween from '../animations/tween';
import bgmControl from '../bgmControl/bgmControl';
import { SlotMachineState, eventManager } from '../config';
import { caseFn } from '../d';

import { REPLAY_DELAY } from './config';

export const getReplayResult = (replayBet: IBet): ISettledBet => {
  eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
  eventManager.emit(EventTypes.UI_VISIBLE, false);
  setIsHistoryVisible(false);

  const historySnapshot = replayBet.betStorage.historySnapshot!;

  const gameMode = historySnapshot.wager.wagerSettings.gameMode;
  setGameMode(gameMode);
  const wager = historySnapshot.wager;
  setBetAmount(replayBet.coinAmount * setSlotConfig().slotSettings.globalCoinAmountMultiplier);

  if (isFreeSpinsMode(gameMode)) {
    setCurrentStage(2);
    const reelPosition = setPrevReelsPosition().slice(0, 9);
    reelPosition[4] = 1;

    eventManager.emit(EventTypes.CHANGE_REEL_SET, {
      reelSet: setSlotConfig().reels.find((reels) => reels.type === GameMode.BASE_GAME)!,
      reelPositions: reelPosition,
    });
    const spinResult = getSpinResult3x1({
      reelPositions: reelPosition,
      reelSet: setSlotConfig().reels.find((reels) => reels.type === GameMode.BASE_GAME)!,
      icons: setSlotConfig().icons,
    });
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    setCurrentBonus({
      packageId: wager.wagerSettings.packageId,
      gameMode: wager.wagerSettings.gameMode,
      rounds: wager.wagerSettings.rounds,
      roundsPlayed: wager.wagerStorage.roundsPlayed,
      state: wager.state as BonusStatus,
      coinAmount: wager.coinAmount,
      coinValue: wager.coinValue,
      originalReelPositions: replayBet.outcomes[0]!.stateSnapshot.reelPosition,
      isBuyFeature: true,
      isActive: true,
    });
  }

  let winCoin = 0;
  if (
    replayBet.wager.wagerStorage.gambleOutcomeStorage &&
    replayBet.wager.wagerStorage.gambleOutcomeStorage[0]?.predicament.caseFn === caseFn.closeGamble
  ) {
    winCoin = replayBet.betStorage.estimatedWinCoinAmount;
  }

  let estimatedWinCoinAmount = 0;

  if (replayBet.outcomes.length > 1 && setReplayGambleCount() < 1) {
    const outcome = replayBet.outcomes.find(
      (outcome) => outcome.stateSnapshot && outcome.stateSnapshot.reelPosition.length === 9,
    );
    replayBet.outcomes.unshift(outcome!);

    replayBet.wager.wagerSettings.rewardPrototypes.forEach((reward) => {
      estimatedWinCoinAmount += reward.value;
    });
    estimatedWinCoinAmount *= replayBet.coinAmount;
  } else if (replayBet.outcomes.length < 2) {
    estimatedWinCoinAmount = replayBet.betStorage.estimatedWinCoinAmount;
    winCoin = replayBet.betStorage.estimatedWinCoinAmount;
  }

  const replayResult: ISettledBet = {
    bet: {
      ...replayBet,
      betStorage: {
        ...replayBet.betStorage,
        estimatedWinCoinAmount,
      },
      wager: wager,
    },
    balance: {
      settled: {
        amount: 0,
        currency: setCurrency(),
      },
      placed: {
        amount: 0,
        currency: setCurrency(),
      },
    },
    winCoinAmount: winCoin,
  };

  setReplayResult(replayResult);
  return replayResult;
};

export const replayEndProcess = () => {
  if (setIsReplay() && !setReplaySpin() && !setIsCountUp() && !setIsFadeOut()) {
    setGameMode(GameMode.BASE_GAME);
    setBetAmount(setCoinAmount() * setSlotConfig().slotSettings.globalCoinAmountMultiplier);
    setReplayGambleCount(0);
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
    eventManager.emit(
      EventTypes.START_FADE,
      () => {
        eventManager.emit(EventTypes.SET_REPLAY_TEXT_VISIBILITY, false);
        eventManager.emit(EventTypes.UI_VISIBLE, true);
        eventManager.emit(EventTypes.IS_GAMBLE_MODE, false);

        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: setSlotConfig().reels.find((reels) => reels.type === GameMode.BASE_GAME)!,
          reelPositions: setPrevReelsPosition(),
        });
        const spinResult = getSpinResult3x1({
          reelPositions: setPrevReelsPosition(),
          reelSet: setSlotConfig().reels.find((reels) => reels.type === GameMode.BASE_GAME)!,
          icons: setSlotConfig().icons,
        });
        eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
        eventManager.emit(EventTypes.SPIN_END);
        setCurrentStage(0);
        bgmControl.playBgm(BgmSoundTypes.BASE);
        if (setIsMeloFlag()) {
          bgmControl.fadeInMelo(3000);
        }
        eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, { mode: setGameMode() });
        eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
        setIsHistoryVisible(true);
      },
      () => {
        setIsReplay(false);
        setReplayResult(null);
        eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, { mode: setGameMode() });
        eventManager.emit(EventTypes.DISABLE_PAY_TABLE, true);
        eventManager.emit(EventTypes.REPLAY_END);
      },
    );

    if (setCurrentBonus().isActive) {
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    }
  }
};

export const getReplayGambleResult = (replayBet: ISettledBet, gambleCount: number): ISettledBet => {
  let estimatedWinCoinAmount = 0;
  replayBet.bet.wager.wagerStorage.gambleOutcomeStorage[gambleCount]?.predicament.rewardPrototypes.forEach((reward) => {
    estimatedWinCoinAmount += reward.value * reward.multiplier;
  });
  estimatedWinCoinAmount *= replayBet.bet.coinAmount;
  if (estimatedWinCoinAmount === 0) {
    estimatedWinCoinAmount = replayBet.bet.wager.wagerStorage.paidWinCoinAmount;
  }
  const gambleResult: ISettledBet = {
    ...replayBet,
    bet: {
      ...replayBet.bet,
      betStorage: {
        ...replayBet.bet.betStorage,
        estimatedWinCoinAmount,
      },
      outcomes: [replayBet.bet.wager.wagerStorage.gambleOutcomeStorage[gambleCount - 1]!],
    },
  };
  return gambleResult;
};

export const gambleReplay = () => {
  if (setReplayResult() === null || setReplayResult()!.bet.wager.wagerStorage.gambleOutcomeStorage === null) return;
  setPlayer(setReplayResult()!.bet.wager.wagerStorage.gambleOutcomeStorage[setReplayGambleCount()]?.predicament.caseFn);
  if (setPlayer() === caseFn.closeGamble || !setPlayer()) {
    setGambleStake(0);
    setIsReplayGamble(false);
    eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
    return;
  }
  const gambleDelay = Tween.createDelayAnimation(REPLAY_DELAY);
  gambleDelay.addOnComplete(() => {
    nextGambleSpin();
    eventManager.emit(EventTypes.SET_GAMBLE_PLAYER, setPlayerToNumber());
    const gambleResultDelay = Tween.createDelayAnimation(REPLAY_DELAY);
    gambleResultDelay.addOnComplete(() => {
      SlotMachine.getInstance().setGambleResult(getReplayGambleResult(setReplayResult()!, setReplayGambleCount()));
    });
    gambleResultDelay.start();
    setReplayGambleCount(setReplayGambleCount() + 1);
  });
  gambleDelay.start();
};
