import { Container, Graphics, Text } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import i18n from '../../i18next';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  REPLAY_LANDSCAPE_GAMBLE_TEXT_POS_Y,
  REPLAY_LANDSCAPE_TEXT_POS_Y,
  REPLAY_PORTRAIT_GAMBLE_TEXT_POS_Y,
  REPLAY_PORTRAIT_TEXT_POS_Y,
  REPLAY_TEXT_MARGIN,
  REPLAY_TEXT_POS_X,
  replayTextStyle,
} from './config';

class ReplayText extends ViewContainer {
  private text: Text;
  private triangle: Graphics;
  private back: Graphics;
  private container: Container;
  private animation: Animation | undefined;
  private isGambleMode: boolean;
  private isLandscape: boolean;

  constructor() {
    super();
    this.init();
    this.text = this.initText();
    this.triangle = this.initTriangle();
    this.back = this.initBack();
    this.container = this.initContainer();
    this.addChild(this.back, this.container);
    this.isGambleMode = false;
    this.isLandscape = true;
    this.zIndex = 5;
  }

  private init() {
    this.pivot.set(0.5);
    this.position.set(REPLAY_TEXT_POS_X, REPLAY_LANDSCAPE_TEXT_POS_Y);
    this.visible = false;

    eventManager.addListener(EventTypes.SET_REPLAY_TEXT_VISIBILITY, this.setVisible.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, (setting: { mode: GameMode }) => {
      this.isGambleMode = setting.mode === GameMode.BASE_GAME_GAMBLE;
      this.setPosition();
    });
  }

  private initText(): Text {
    const text = new Text(i18n.t('replay').toLocaleUpperCase(), replayTextStyle);
    text.anchor.set(0, 0.5);
    text.position.set(25, 0);
    return text;
  }

  private initTriangle(): Graphics {
    const triangle = new Graphics();
    triangle
      .beginFill(0xff0000)
      .drawPolygon([
        16,
        0,
        Math.floor(Math.cos(Math.PI * 0.6666) * 16),
        Math.floor(Math.sin(Math.PI * 0.6666) * 16),
        Math.floor(Math.cos(Math.PI * 1.3333) * 16) + 1,
        Math.floor(Math.sin(Math.PI * 1.3333) * 16) + 1,
      ]);
    return triangle;
  }

  private initBack(): Graphics {
    const back = new Graphics();
    back.beginFill(0x000000, 0.3).drawRect(-20, -25, this.triangle.width + this.text.width + REPLAY_TEXT_MARGIN, 50);
    return back;
  }

  private initContainer(): Container {
    const container = new Container();
    container.addChild(this.triangle, this.text);
    this.animation = Tween.createDelayAnimation(500);
    this.animation.addOnComplete(() => {
      container.visible = !container.visible;
    });
    this.animation.isLoop = true;
    this.animation.start();
    return container;
  }

  private setVisible(visible: boolean) {
    this.visible = visible;
    if (visible) {
      this.animation!.isLoop = true;

      this.animation!.start();
    } else {
      this.animation!.isLoop = false;
    }
  }

  private resize(width: number, height: number) {
    this.isLandscape = width > height;
    this.setPosition();
  }

  private setPosition() {
    if (this.isGambleMode) {
      if (this.isLandscape) {
        this.position.set(REPLAY_TEXT_POS_X, REPLAY_LANDSCAPE_GAMBLE_TEXT_POS_Y);
      } else {
        this.position.set(REPLAY_TEXT_POS_X, REPLAY_PORTRAIT_GAMBLE_TEXT_POS_Y);
      }
    } else {
      if (this.isLandscape) {
        this.position.set(REPLAY_TEXT_POS_X, REPLAY_LANDSCAPE_TEXT_POS_Y);
      } else {
        this.position.set(REPLAY_TEXT_POS_X, REPLAY_PORTRAIT_TEXT_POS_Y);
      }
    }
  }
}
export default ReplayText;
