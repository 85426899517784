import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

export const BASE_SLOT_SPINE_ANIMATE_PRIORITY = 10;
export const STOP_SPECIAL_SYMBOL_PRIORITY = 30;
export const SCATTER_SPINE_ANIMATE_PRIORITY = 70;
export const WILD_SPINE_ANIMATE_PRIORITY = 70;
export const REEL_FRAME_PRIORITY = 50;

export const BIG_WIN_JINGLE_DURATION = 3000;
export const WIN_TITLE_Y = SLOTS_CONTAINER_HEIGHT / 2 - 100;
export const WIN_TITLE_X = SLOTS_CONTAINER_WIDTH / 2;
