import _ from 'lodash';

import Animation from './animation';
import { IAnimation } from './d';

export interface IAnimationChain extends IAnimation {
  animations?: Animation[];
  proceedNextAnimationOnSkip?: boolean;
}

class AnimationChain extends Animation implements IAnimationChain {
  public animations: Animation[];

  public currentAnimation: Animation | null = null;

  private callbackChain: Map<Animation, () => void> = new Map();

  public proceedNextAnimationOnSkip: boolean;

  constructor(options?: IAnimationChain) {
    super(options ?? {});
    this.animations = options?.animations ?? [];
    this.proceedNextAnimationOnSkip = options?.proceedNextAnimationOnSkip ?? false;
  }

  public appendAnimation(animation: Animation): void {
    if (this.animations.length > 0) {
      const callback = () => {
        this.currentAnimation = animation;
        animation.start();
      };
      this.callbackChain.set(this.animations[this.animations.length - 1]!, callback);
      this.animations[this.animations.length - 1]!.addOnComplete(callback);
    }
    this.animations.push(animation);
    this.duration += animation.duration;
    animation.addOnChange(this.onChange.bind(this));
  }

  public override end(): void {
    super.end();
    _.forEach(this.animations, (animtion) => animtion.end());
  }

  public override start(): void {
    super.start();
    if (this.animations.length) {
      this.currentAnimation = this.animations[0]!;
      if (!this.animations[this.animations.length - 1]!.complete.includes(this.bindedOnComplete))
        this.animations[this.animations.length - 1]!.addOnComplete(this.bindedOnComplete);
      this.currentAnimation!.start();
    } else {
      this.onComplete();
    }
  }

  private bindedOnComplete = this.onComplete.bind(this);

  public override onComplete(): void {
    super.onComplete();
  }

  public override skip(isLoop?: boolean): void {
    if (!this.proceedNextAnimationOnSkip) {
      this.callbackChain.forEach((callback, animation) => {
        animation.complete.splice(animation.complete.indexOf(callback), 1);
      });
      this.currentAnimation?.skip(this.isLoop || isLoop);
      this.onSkip();
    } else {
      this.currentAnimation?.skip(this.isLoop || isLoop);
      if (this.callbackChain.has(this.currentAnimation!)) {
        this.callbackChain.get(this.currentAnimation!)!();
      } else {
        this.onSkip();
      }
    }
  }

  public allSkip(): void {
    this.currentAnimation?.skip();
    this.callbackChain.forEach((callback) => {
      callback();
      this.currentAnimation?.skip();
    });
    this.onSkip();
  }
}

export default AnimationChain;
