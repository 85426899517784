import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isBaseGameMode } from '../../utils';
import ViewContainer from '../components/container';
import {
  GAME_TITLE_LOGO_X_LANDSCAPE_POSITION,
  GAME_TITLE_LOGO_X_PORTRAIT_POSITION,
  GAME_TITLE_LOGO_Y_LANDSCAPE_POSITION,
  GAME_TITLE_LOGO_Y_PORTRAIT_POSITION,
  Z_INDEX_GAMBLE,
  eventManager,
} from '../config';

class GameTitle extends ViewContainer {
  private titleLogo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));

  constructor() {
    super();

    this.initGameLogo();

    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    this.zIndex = Z_INDEX_GAMBLE;
  }

  private initGameLogo(): void {
    this.titleLogo.anchor.set(0.5, 0);
    this.titleLogo.x = GAME_TITLE_LOGO_X_LANDSCAPE_POSITION;
    this.titleLogo.y = GAME_TITLE_LOGO_Y_LANDSCAPE_POSITION;
    this.addChild(this.titleLogo);
  }

  private onModeChange(settings: { mode: GameMode }) {
    if (isBaseGameMode(settings.mode)) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      this.titleLogo.x = GAME_TITLE_LOGO_X_LANDSCAPE_POSITION;
      this.titleLogo.y = GAME_TITLE_LOGO_Y_LANDSCAPE_POSITION;
    } else {
      this.titleLogo.x = GAME_TITLE_LOGO_X_PORTRAIT_POSITION;
      this.titleLogo.y = GAME_TITLE_LOGO_Y_PORTRAIT_POSITION;
    }
  }
}

export default GameTitle;
