import i18n from 'i18next';
import * as _ from 'lodash';
import * as PIXI from 'pixi.js';
import { Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureTypes, IBonus } from '../../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCurrency,
  setIsBuyFeaturePopupOpened,
  setIsReplay,
  setSlotConfig,
} from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import { normalizeCoins, showCurrency, updateTextScale } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { SpriteButton } from '../components/button';
import {
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_X,
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y,
  FEATURE_POPUP_BET_VALUE_POSITION_X,
  FEATURE_POPUP_BET_VALUE_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_INPUT_POSITION_X,
  FEATURE_POPUP_INPUT_POSITION_Y,
  FEATURE_POPUP_MINUS_BTN_POSITION_X,
  FEATURE_POPUP_MINUS_BTN_POSITION_Y,
  FEATURE_POPUP_OK_BTN_POSITION_X,
  FEATURE_POPUP_OK_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_POSITION_X,
  FEATURE_POPUP_PLUS_BTN_POSITION_Y,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
  FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X,
  FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y,
  FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X,
  FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y, // SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X, // SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH, // SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X, // SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  Z_INDEX_BUY_FEATURE_POPUP,
  eventManager,
} from '../config';

import {
  amountTextStyle,
  betValueStyle,
  buyFeatureTitleStyle,
  totalCostTextAmountStyle,
  totalCostTextStyle,
} from './textStyles';

class BuyFeaturePopup extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: AutoResizeText;

  private totalCostText: AutoResizeText;

  private totalCostTextAmount: AutoResizeText;

  private amountText: AutoResizeText;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private input: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private linesAmount: number;

  private currency = 'FUN';

  private betValue: AutoResizeText;

  isNoFunds: boolean;

  private balance: number;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = setSlotConfig().slotSettings.globalCoinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostTextAmount = this.initTotalCostTextAmount();
    this.amountText = this.initAmountText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.input = this.initInput();
    this.betValue = this.initBetValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.zIndex = Z_INDEX_BUY_FEATURE_POPUP;

    this.setBetValueWidth();

    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      setIsBuyFeaturePopupOpened(true);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance?: { currency: string; amount: number }) => {
      if (setIsReplay()) return;
      if (balance) this.balance = balance!.amount / 100;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, () => {
      this.handleDisable();
      this.visible = true;
    });

    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.visible = false;
    });

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.handleResize(1920, 1080);
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostTextAmount);
    this.addChild(this.amountText);
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.input);
    this.addChild(this.betValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));
    popupBg.anchor.set(0.5, 0.5);

    return popupBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t('buyFeatureTitle'), buyFeatureTitleStyle);
    title.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.x = FEATURE_POPUP_TITLE_POSITION_X;
    title.anchor.set(0.5, 0);
    updateTextScale(title, this.popupBg.width - 200, 300);
    return title;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t('buyFeatureTotalCost'), totalCostTextStyle);
    totalCostText.y = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y;
    totalCostText.x = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X;
    totalCostText.anchor.set(0.5, 0.5);
    updateTextScale(totalCostText, 450, 300);

    return totalCostText;
  }

  private initTotalCostTextAmount(): AutoResizeText {
    const totalCostTextAmount = new AutoResizeText(this.getTotalCost(), totalCostTextAmountStyle);
    totalCostTextAmount.y = FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y;
    totalCostTextAmount.x = FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X;
    totalCostTextAmount.anchor.set(0.5, 0.5);

    return totalCostTextAmount;
  }

  private initAmountText(): AutoResizeText {
    const amountText = new AutoResizeText(i18n.t('buyFeatureBetPerGame'), amountTextStyle);
    amountText.y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y;
    amountText.x = FEATURE_POPUP_AMOUNT_TEXT_POSITION_X;
    amountText.anchor.set(0.5, 0.5);
    updateTextScale(amountText, 380, 250);
    return amountText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      texture: Texture.from(ResourceTypes.buyFeatureMinusBtn),
      onClick: this.handleMinus.bind(this),
    });
    minusBtn.y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
    minusBtn.x = FEATURE_POPUP_MINUS_BTN_POSITION_X;
    minusBtn.anchor.set(0.5);

    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      texture: Texture.from(ResourceTypes.buyFeaturePlusBtn),
      onClick: this.handlePlus.bind(this),
    });
    plusBtn.y = FEATURE_POPUP_PLUS_BTN_POSITION_Y;
    plusBtn.x = FEATURE_POPUP_PLUS_BTN_POSITION_X;
    plusBtn.anchor.set(0.5);

    return plusBtn;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureInput));
    input.y = FEATURE_POPUP_INPUT_POSITION_Y;
    input.x = FEATURE_POPUP_INPUT_POSITION_X;
    input.anchor.set(0.5);

    return input;
  }

  private initBetValue(): AutoResizeText {
    const betValue = new AutoResizeText(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      betValueStyle,
    );
    betValue.y = FEATURE_POPUP_BET_VALUE_POSITION_Y;
    betValue.x = FEATURE_POPUP_BET_VALUE_POSITION_X;
    betValue.anchor.set(0.5, 0.5);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      onClick: this.onCancel.bind(this),
    });
    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
    cancelBtn.anchor.set(0.5, 0);

    return cancelBtn;
  }

  private onCancel() {
    this.toggleEventEmit();
    AudioApi.play({ type: ISongs.SONG_SFX_UI_Close });
    this.visible = false;
    setIsBuyFeaturePopupOpened(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      onClick: this.handleClickOk.bind(this),
    });
    okBtn.y = FEATURE_POPUP_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_OK_BTN_POSITION_X;
    okBtn.anchor.set(0.5, 0);

    return okBtn;
  }

  private getTotalCost = (): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.linesAmount;
      }) + 1
    );
  };

  private updateBets(): void {
    this.totalCostTextAmount.text = this.getTotalCost();
    this.betValue.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;

    this.setBetValueWidth();
  }

  private setBetValueWidth(): void {
    if (this.betValue.width > this.input.width) {
      this.betValue.width = this.input.width - 50;
      this.betValue.scale.y = this.betValue.scale.x;
    }
  }

  private getBetValue = (): number => {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (): number => {
    const bonuses = setSlotConfig().slotSettings.buyFeaturePackages;
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.packageId === FeatureTypes.BUY_FEATURE)
      .get(0, {})
      .value() as IBonus;

    // TODO Unlike the Ukrainian back end, we are calculating.
    // return bonus.coinAmount ver Ukraine
    return bonus.coinAmountMultiplier;
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    if (bet === this.betSettings!.minBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }

    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount());

    if (this.isNoFunds) {
      this.okBtn.disable();
    } else {
      this.okBtn.enable();
    }
    this.cancelBtn.enable();
  };
  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (width: number, height: number, _x: number, _y: number, _scale: number): void => {
    this.handleResize(width, height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = 200; //SAFE_AREA_PORTRAIT_HEIGHT / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y;
    } else {
      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.y = 350; //SAFE_AREA_LANDSCAPE_HEIGHT / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y;
    }
  }

  private handleMinus = (): void => {
    if (this.betAmount > 1 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
      this.toggleEventEmit();
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().slotSettings.globalCoinAmountMultiplier);
      AudioApi.play({ type: ISongs.SONG_SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
      this.toggleEventEmit();
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().slotSettings.globalCoinAmountMultiplier);
      AudioApi.play({ type: ISongs.SONG_SFX_UI_BetChange });
    }
  };

  private handleClickOk = (): void => {
    if (!this.isNoFunds) {
      this.toggleEventEmit();
      const totalCost = this.getTotalCost();
      AudioApi.play({ type: ISongs.SONG_SFX_UI_General });
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, totalCost, this.betSettings.bets[this.betAmount]!);
      this.visible = false;
    }
  };

  public handleClose(): void {
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP);
    this.visible = false;
    this.resetButtons();
  }

  public resetButtons(): void {
    this.removeChild(this.cancelBtn, this.okBtn);
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.addChild(this.cancelBtn, this.okBtn);
  }

  private toggleEventEmit(): void {
    if (this.window.width > this.window.height) {
      eventManager.emit(EventTypes.TOGGLE_DIALOG, true);
    } else {
      eventManager.emit(EventTypes.TOGGLE_DIALOG, false);
    }
  }
}

export default BuyFeaturePopup;
