import { GameMode } from '../global.d';
import { ResourceTypes } from '../resources.d';
import { IconType } from '../slotMachine/d';

import { SlotId } from './config';

export const slotData = {
  lines: [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ],
  reels: [
    {
      layout: [
        [
          'J',
          'WL',
          'J',
          'D',
          'F',
          'G',
          'B',
          'I',
          'E',
          'H',
          'J',
          'C',
          'I',
          'D',
          'G',
          'A',
          'H',
          'J',
          'E',
          'G',
          'C',
          'I',
          'F',
          'J',
          'B',
          'I',
          'G',
          'D',
          'H',
          'F',
          'E',
          'H',
        ] as SlotId[],
        [
          'J',
          'WL',
          'J',
          'D',
          'F',
          'G',
          'B',
          'I',
          'E',
          'H',
          'J',
          'C',
          'I',
          'D',
          'G',
          'A',
          'H',
          'J',
          'E',
          'G',
          'C',
          'I',
          'F',
          'J',
          'B',
          'I',
          'G',
          'D',
          'H',
          'F',
          'E',
          'H',
        ] as SlotId[],
        [
          'J',
          'WL',
          'J',
          'D',
          'F',
          'G',
          'B',
          'I',
          'E',
          'H',
          'J',
          'C',
          'I',
          'D',
          'G',
          'A',
          'H',
          'J',
          'E',
          'G',
          'C',
          'I',
          'F',
          'J',
          'B',
          'I',
          'G',
          'D',
          'H',
          'F',
          'E',
          'H',
        ] as SlotId[],
        [
          'J',
          'WL',
          'J',
          'D',
          'F',
          'G',
          'B',
          'I',
          'E',
          'H',
          'J',
          'C',
          'I',
          'D',
          'G',
          'A',
          'H',
          'J',
          'E',
          'G',
          'C',
          'I',
          'F',
          'J',
          'B',
          'I',
          'G',
          'D',
          'H',
          'F',
          'E',
          'H',
        ] as SlotId[],
        [
          'J',
          'WL',
          'J',
          'D',
          'F',
          'G',
          'B',
          'I',
          'E',
          'H',
          'J',
          'C',
          'I',
          'D',
          'G',
          'A',
          'H',
          'J',
          'E',
          'G',
          'C',
          'I',
          'F',
          'J',
          'B',
          'I',
          'G',
          'D',
          'H',
          'F',
          'E',
          'H',
        ] as SlotId[],
        [
          'J',
          'WL',
          'J',
          'D',
          'F',
          'G',
          'B',
          'I',
          'E',
          'H',
          'J',
          'C',
          'I',
          'D',
          'G',
          'A',
          'H',
          'J',
          'E',
          'G',
          'C',
          'I',
          'F',
          'J',
          'B',
          'I',
          'G',
          'D',
          'H',
          'F',
          'E',
          'H',
        ] as SlotId[],
        [
          'J',
          'WL',
          'J',
          'D',
          'F',
          'G',
          'B',
          'I',
          'E',
          'H',
          'J',
          'C',
          'I',
          'D',
          'G',
          'A',
          'H',
          'J',
          'E',
          'G',
          'C',
          'I',
          'F',
          'J',
          'B',
          'I',
          'G',
          'D',
          'H',
          'F',
          'E',
          'H',
        ] as SlotId[],
        [
          'J',
          'WL',
          'J',
          'D',
          'F',
          'G',
          'B',
          'I',
          'E',
          'H',
          'J',
          'C',
          'I',
          'D',
          'G',
          'A',
          'H',
          'J',
          'E',
          'G',
          'C',
          'I',
          'F',
          'J',
          'B',
          'I',
          'G',
          'D',
          'H',
          'F',
          'E',
          'H',
        ] as SlotId[],
        [
          'J',
          'WL',
          'J',
          'D',
          'F',
          'G',
          'B',
          'I',
          'E',
          'H',
          'J',
          'C',
          'I',
          'D',
          'G',
          'A',
          'H',
          'J',
          'E',
          'G',
          'C',
          'I',
          'F',
          'J',
          'B',
          'I',
          'G',
          'D',
          'H',
          'F',
          'E',
          'H',
        ] as SlotId[],
      ],
      type: GameMode.BASE_GAME,
      additionalReelSets: [['WL', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'] as SlotId[]],
    },
  ],
  icons: [
    {
      id: SlotId.WL,
      type: IconType.WILD,
    },
    {
      id: SlotId.A,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.B,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.C,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.D,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.E,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.F,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.G,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.H,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.I,
      type: IconType.REGULAR,
    },
    {
      id: SlotId.J,
      type: IconType.EMPTY,
    },
  ],
  payTableData: {
    lineWinData: [
      {
        slug: ResourceTypes.symbolWl,
        combos: [{ pattern: 'x3', multiplier: 1000 }],
      },
      {
        slug: ResourceTypes.symbolA,
        combos: [{ pattern: 'x3', multiplier: 500 }],
      },
      {
        slug: ResourceTypes.symbolB,
        combos: [{ pattern: 'x3', multiplier: 300 }],
      },
      {
        slug: ResourceTypes.symbolC,
        combos: [{ pattern: 'x3', multiplier: 100 }],
      },
      {
        slug: ResourceTypes.symbolD,
        combos: [{ pattern: 'x3', multiplier: 70 }],
      },
      {
        slug: ResourceTypes.symbolE,
        combos: [{ pattern: 'x3', multiplier: 30 }],
      },
      {
        slug: ResourceTypes.symbolF,
        combos: [{ pattern: 'x3', multiplier: 20 }],
      },
      {
        slug: ResourceTypes.symbolG,
        combos: [{ pattern: 'x3', multiplier: 10 }],
      },
      {
        slug: ResourceTypes.symbolH,
        combos: [{ pattern: 'x3', multiplier: 8 }],
      },
      {
        slug: ResourceTypes.symbolI,
        combos: [{ pattern: 'x3', multiplier: 6 }],
      },
    ],
    scatterWinData: [
      {
        slug: ResourceTypes.symbolC,
        combos: [
          { pattern: 'x9', multiplier: 50000 },
          { pattern: 'x8', multiplier: 35000 },
          { pattern: 'x7', multiplier: 20000 },
          { pattern: 'x6', multiplier: 4000 },
          { pattern: 'x5', multiplier: 500 },
          { pattern: 'x4', multiplier: 10 },
          { pattern: 'x3', multiplier: 4 },
        ],
      },
      {
        slug: ResourceTypes.symbolD,
        combos: [
          { pattern: 'x9', multiplier: 50000 },
          { pattern: 'x8', multiplier: 30000 },
          { pattern: 'x7', multiplier: 15000 },
          { pattern: 'x6', multiplier: 3000 },
          { pattern: 'x5', multiplier: 300 },
          { pattern: 'x4', multiplier: 10 },
          { pattern: 'x3', multiplier: 3 },
        ],
      },
      {
        slug: ResourceTypes.symbolE,
        combos: [
          { pattern: 'x9', multiplier: 50000 },
          { pattern: 'x8', multiplier: 25000 },
          { pattern: 'x7', multiplier: 5000 },
          { pattern: 'x6', multiplier: 1000 },
          { pattern: 'x5', multiplier: 100 },
          { pattern: 'x4', multiplier: 5 },
        ],
      },
      {
        slug: ResourceTypes.symbolF,
        combos: [
          { pattern: 'x9', multiplier: 30000 },
          { pattern: 'x8', multiplier: 20000 },
          { pattern: 'x7', multiplier: 2500 },
          { pattern: 'x6', multiplier: 500 },
          { pattern: 'x5', multiplier: 50 },
          { pattern: 'x4', multiplier: 5 },
        ],
      },
      {
        slug: ResourceTypes.symbolG,
        combos: [
          { pattern: 'x9', multiplier: 20000 },
          { pattern: 'x8', multiplier: 15000 },
          { pattern: 'x7', multiplier: 1000 },
          { pattern: 'x6', multiplier: 200 },
          { pattern: 'x5', multiplier: 30 },
          { pattern: 'x4', multiplier: 4 },
        ],
      },
      {
        slug: ResourceTypes.symbolH,
        combos: [
          { pattern: 'x9', multiplier: 15000 },
          { pattern: 'x8', multiplier: 10000 },
          { pattern: 'x7', multiplier: 750 },
          { pattern: 'x6', multiplier: 150 },
          { pattern: 'x5', multiplier: 20 },
          { pattern: 'x4', multiplier: 4 },
        ],
      },
      {
        slug: ResourceTypes.symbolI,
        combos: [
          { pattern: 'x9', multiplier: 10000 },
          { pattern: 'x8', multiplier: 7500 },
          { pattern: 'x7', multiplier: 500 },
          { pattern: 'x6', multiplier: 100 },
          { pattern: 'x5', multiplier: 10 },
          { pattern: 'x4', multiplier: 4 },
        ],
      },
    ],
  },
};
