import { makeVar } from '@apollo/client';

import AudioApi from '@phoenix7dev/audio-api';

import { EyeControl, SlotId } from '../config';
import { slotData } from '../config/slotData';
import {
  BonusStatus,
  GameMode,
  IBalance,
  IBonusNew,
  ISettledBet,
  ModalOpeningTypes,
  PopupOpeningTypes,
  ReelAnticipation,
} from '../global.d';
import { StatusControlFlg } from '../slotMachine/avatarTalk/config';
import { caseFn, gambleResult } from '../slotMachine/d';
import { getUserConfig } from '../utils';

import { IBet, INudgeParameter, ISlotConfig, IStressful, NudgeType } from './d';

const isDemoMode = new URLSearchParams(window.location.search).has('isDemo');

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setIsSoundOn = makeVar<boolean>(isDemoMode ? true : getUserConfig('isSoundOn', true));

export const setIsShowSoundToast = makeVar<boolean>(AudioApi.isRestricted);

export const setIsSuspended = makeVar<boolean>(false);

export const setSoundValue = makeVar<number>(Number(setIsSoundOn()));

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setPrevReelsPosition = makeVar<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0]);

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsMobile = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(1);

export const setCurrency = makeVar<string>('FUN');

export const setUserBalance = makeVar<IBalance>({
  amount: 0,
  currency: '',
});

export const setBrokenGame = makeVar<boolean>(false);

export const setBrokenBuyFeature = makeVar<boolean>(false);

export const setBrokenGameBgm = makeVar<boolean>(false);

export const setBrokenGambleGame = makeVar<boolean>(false);

export const setCurrentFreeSpinsTotalWin = makeVar<number>(0);

export const setCoinAmount = makeVar<number>(1);

export const setCoinValue = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(0);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setSpinWithAutoSpin = makeVar<boolean>(false);

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.BASE_GAME);

export const setReelSetId = makeVar<string>('');

export const setNextResult = makeVar<ISettledBet | null>(null);

export const setUserLastBetResult = makeVar<IBet>({
  betStorage: {
    estimatedWinCoinAmount: 0,
    historySnapshot: null,
  },
  id: '',
  slotId: '',
  playerId: '',
  wager: {
    coinAmount: 0,
    coinValue: 0,
    createdAt: '',
    id: '',
    order: 0,
    playerId: '',
    slotId: '',
    state: '',
    type: '',
    wagerSettings: {
      gameMode: GameMode.BASE_GAME,
      packageId: '',
      rounds: 0,
      originalReelPositions: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      rewardPrototypes: [],
    },
    wagerStorage: {
      roundsPlayed: 0,
      totalWinCoinAmount: 0,
      previousTotalWinCoinAmount: 0,
      paidWinCoinAmount: 0,
      gambleOutcomeStorage: [],
    },
  },
  status: '',
  outcomes: [],
  coinAmount: 1,
  effectiveCoinAmount: 1,
  coinValue: 1,
  createdAt: '',
  spinResult: [],
});

export const setCurrentBonus = makeVar<IBonusNew>({
  packageId: '',
  gameMode: GameMode.BASE_GAME,
  rounds: 0,
  roundsPlayed: 0,
  state: BonusStatus.INACTIVE,
  coinAmount: 0,
  coinValue: 0,
  originalReelPositions: [],
  isBuyFeature: false,
  isActive: false,
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  slotSettings: {
    betLines: { max: 0, min: 0 },
    cols: 0,
    rows: 0,
    globalCoinAmountMultiplier: 0,
    startingPositions: [],
    buyFeaturePackages: [],
  },
  slotId: '',
  sessionId: '',
  clientSlotSettings: {
    autoplaySettings: {
      options: [],
      conditions: {
        stopIfBalanceDecreasesBy: {
          enabled: true,
        },
        stopIfBalanceIncreasesBy: {
          enabled: true,
          multipliers: [],
        },
        stopIfFeatureIsWon: {
          enabled: true,
        },
        stopIfSingleWinExceeds: {
          enabled: true,
          multipliers: [],
        },
        stopOnAnyWin: {
          enabled: true,
        },
      },
    },
    betCoinAmountSettings: [],
    coinValueSettings: [{ code: 'FUN', variants: [] }],
    quickBetCoinAmountSettings: [],
  },
  lines: slotData.lines,
  reels: slotData.reels,
  icons: slotData.icons,
});

export const setGameHistory = makeVar<boolean[]>([false, false, false, false, false]);

export const setIsTurboSpin = makeVar<boolean>(isDemoMode ? false : getUserConfig('isTurboSpin', false));

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsOpenMenuModal = makeVar<{
  isOpen: boolean;
  type: 'menu' | 'info';
}>({
  isOpen: false,
  type: 'menu',
});
export const setIsOpenBetSettingsModal = makeVar<boolean>(false);
export const setIsOpenAutoplayModal = makeVar<boolean>(false);
export const setReplayEnd = makeVar<boolean>(false);
export const setIsModalOpeningInProgress = makeVar<ModalOpeningTypes>(ModalOpeningTypes.NONE);

export const setIsBuyFeaturePopupOpened = makeVar<boolean>(false);

export const setIsDuringBigWinLoop = makeVar<boolean>(false);

export const setIsSoundLoading = makeVar<boolean>(false);

export const setIsOpenedMessageBanner = makeVar<boolean>(false);
// export const setIsBattleStartAnim = makeVar<boolean>(false);

export const setIsInTransition = makeVar<boolean>(false);
export const setIsHyperBattleBonus = makeVar<boolean>(false);

export const setReelAnticipation = makeVar<ReelAnticipation>(ReelAnticipation.NON);
export const setIsAnticipation = makeVar<boolean>(false);

export const setAnticipationLine = makeVar<number[]>([0]);

export const setAnticipationSlotId = makeVar<SlotId[]>([]);

export const setBrokenGameReelSet = makeVar<string>('');

export const setStopReel = makeVar<{
  reelPos: number;
  time: number;
}>({
  reelPos: 0,
  time: 0,
});

export const setPage = makeVar<number>(0);

export const setCurrentStage = makeVar<number>(0);

export const setTalks = makeVar<number[]>([]);

export const setStatusControl = makeVar<StatusControlFlg>(StatusControlFlg.NORMAL);

export const setEyeControl = makeVar<EyeControl>(EyeControl.IDLE);

export const setAvatarTension = makeVar<number>(2);

// 8.4.2 状況管理フラグ
export const setAvatarStatusControl = makeVar<StatusControlFlg>(StatusControlFlg.NORMAL);

export const setFreeSpinTotalWin = makeVar<number>(0);

export const setIsCountUp = makeVar<boolean>(false);

export const setIsPopupOpeningInProgress = makeVar<PopupOpeningTypes>(PopupOpeningTypes.NONE);

export const setIsBuyFeaturePurchased = makeVar<boolean>(false);

export const setIsPopupOpened = makeVar<boolean>(false);
export const setIsOpenInfoPopup = makeVar<boolean>(false);
export const setIsOpenHistoryPopup = makeVar<boolean>(getUserConfig('isOpenHistoryPopup', false));
export const setIsOpenBetSettingsPopup = makeVar<boolean>(false);
export const setIsOpenAutoplayPopup = makeVar<boolean>(false);
export const setIsFadeOut = makeVar<boolean>(false);
export const setIsDuringWinCountUpAnimation = makeVar<boolean>(false);

export const setIsReplay = makeVar<boolean>(false);
export const setReplaySpin = makeVar<boolean>(false);
export const setIsHistoryVisible = makeVar<boolean>(true);
export const setIsSlotStatueIdle = makeVar<boolean>(true);
export const setBalanceAmount = makeVar<number>(0);
export const setIsReplayEnd = makeVar<boolean>(false);

export const setNudgeList1 = makeVar<INudgeParameter>({
  nudge: NudgeType.NON,
  startPos: 0,
});

export const setNudgeList = makeVar<{
  nudge: NudgeType;
  startPos: number;
  targetPos: number;
}>({
  nudge: NudgeType.NON,
  startPos: 0,
  targetPos: 0,
});

export const setPlayer = makeVar<caseFn>(caseFn.closeGamble);
export const setGambleStake = makeVar<number>(0);
export const setIsAfterSpin = makeVar<boolean>(false);
export const setIsCollectSpin = makeVar<boolean>(false);
export const setIsCollected = makeVar<boolean>(true);
export const setGambleStakeResult = makeVar<gambleResult>(gambleResult.loss);
export const setSpecialBonusIcon = makeVar<SlotId>(SlotId.WL);
export const setSpecialBonusAny7Mix = makeVar<boolean>(false);

export const setIsMeloFlag = makeVar<boolean>(false);
export const setIsReplayGamble = makeVar<boolean>(false);
export const setReplayGambleCount = makeVar<number>(0);
export const setReplayResult = makeVar<ISettledBet | null>(null);

export const setFlashFlg = makeVar<boolean>(true);
export const setCollectPush = makeVar<boolean>(false);
export const setIsTimeoutErrorMessage = makeVar(false);
export const setGambleEntryReelPosition = makeVar<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0]);
export const setIsPhoenix = makeVar<boolean>(false);
