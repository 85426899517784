import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs } from '../../config';
import { EventTypes, GambleBtmProps, GameMode } from '../../global.d';
import {
  setCollectPush,
  setFlashFlg,
  setGambleStake,
  setGameMode,
  setIsReplayGamble,
  setNextResult,
} from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { debugDisplay, isGambleMode } from '../../utils';
import AnimationChain from '../animations/animationChain';
import Tween from '../animations/tween';
import { BgSkin } from '../background/config';
import {
  COLLECT_BTN_GAMBLE_LANDSCAPE_POSITION_X,
  COLLECT_BTN_GAMBLE_LANDSCAPE_POSITION_Y,
  COLLECT_BTN_GAMBLE_PORTRAIT_POSITION_X,
  COLLECT_BTN_GAMBLE_PORTRAIT_POSITION_Y,
  COLLECT_BTN_LANDSCAPE_POSITION_X,
  COLLECT_BTN_LANDSCAPE_POSITION_Y,
  COLLECT_BTN_PORTRAIT_POSITION_X,
  COLLECT_BTN_PORTRAIT_POSITION_Y,
  FAKE_ROLLING_DURATION,
  SlotMachineState,
  eventManager,
} from '../config';

import GambleBtnBase from './gambleBtnBase';

class CollectBtn extends GambleBtnBase {
  private isLandScape = true;
  private animationChain: AnimationChain = new AnimationChain({ isLoop: true });
  private timeoutDelay = Tween.createDelayAnimation(0);
  private duration = 500;

  constructor(props: GambleBtmProps) {
    super(props);
    this.setPosition(COLLECT_BTN_LANDSCAPE_POSITION_X, COLLECT_BTN_LANDSCAPE_POSITION_Y);

    const fadeIn = this.getFadeAnimation(0.5, this.duration, 1);
    const fadeOut = this.getFadeAnimation(1, this.duration, 0.5);
    this.animationChain.appendAnimation(fadeIn);
    this.animationChain.appendAnimation(fadeOut);
    this.animationChain.addOnSkip(() => {
      this.alpha = 1;
    });

    eventManager.on(EventTypes.DISABLE_COLLECT_BTN, (disable: boolean) => {
      if (setIsReplayGamble()) return;
      debugDisplay('disableCollect', disable);
      if (disable) {
        this.animationChain.isLoop = false;
        this.animationChain.allSkip();
      } else {
        // eventManager.emit(EventTypes.GAMBLE_OR_COLLECT);
        this.animationChain.isLoop = true;
        this.animationChain.animations.forEach((animation) => {
          animation.duration = this.duration;
        });
      }
      this.handleDisable(disable);
    });
    eventManager.addListener(EventTypes.COLLECT_TIMEOUT, this.timeout.bind(this));
  }

  protected override initBtnText(): PIXI.Sprite {
    const text = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.collect));
    text.anchor.set(0.5);
    return text;
  }

  protected override onClick(): void {
    debugDisplay('onClick ', this.btn.buttonMode);
    if (!this.btn.buttonMode) return;
    if (this.isLandScape) {
      eventManager.emit(EventTypes.TOGGLE_UI_BUY_GAMBLE, false);
    } else {
      eventManager.emit(EventTypes.TOGGLE_UI_BUY_GAMBLE, false);
    }
    setCollectPush(true);

    setGameMode(GameMode.BASE_GAME);
    eventManager.emit(EventTypes.BASE_GAME);

    debugDisplay('onClick ', SlotMachine.getInstance().state);

    if (SlotMachine.getInstance().state === SlotMachineState.GAMBLE_SPIN) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      SlotMachine.getInstance().skipAnimations();
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    }

    AudioApi.play({ type: ISongs.SONG_030_09_Win_End, stopPrev: true });

    console.log('$$$$$$$$$$$$$$$$$$$$$3');
    eventManager.emit(EventTypes.GAMBLE_CANCEL);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: GameMode.BASE_GAME,
      isFromGamble: true,
    });
    eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, true);
    eventManager.emit(EventTypes.DISABLE_GAMBLE_BTN, true);
  }

  protected override setPosition(width: number, height: number): void {
    if (width > height) {
      if (isGambleMode(setGameMode())) {
        this.x = COLLECT_BTN_GAMBLE_LANDSCAPE_POSITION_X;
        this.y = COLLECT_BTN_GAMBLE_LANDSCAPE_POSITION_Y;
      } else {
        this.x = COLLECT_BTN_LANDSCAPE_POSITION_X;
        this.y = COLLECT_BTN_LANDSCAPE_POSITION_Y;
      }
      this.isLandScape = true;
    } else {
      if (isGambleMode(setGameMode())) {
        this.x = COLLECT_BTN_GAMBLE_PORTRAIT_POSITION_X;
        this.y = COLLECT_BTN_GAMBLE_PORTRAIT_POSITION_Y;
      } else {
        this.x = COLLECT_BTN_PORTRAIT_POSITION_X;
        this.y = COLLECT_BTN_PORTRAIT_POSITION_Y;
      }
      this.isLandScape = false;
    }
  }

  protected override onChangeMode(settings: { mode: GameMode; background?: BgSkin; gambleStake?: number }) {
    this.animationChain.isLoop = false;
    this.animationChain.allSkip();
    if (isGambleMode(settings.mode)) {
      this.position.y = this.isLandScape
        ? COLLECT_BTN_GAMBLE_LANDSCAPE_POSITION_Y
        : COLLECT_BTN_GAMBLE_PORTRAIT_POSITION_Y;
      eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, false);
    } else {
      this.position.y = this.isLandScape ? COLLECT_BTN_LANDSCAPE_POSITION_Y : COLLECT_BTN_PORTRAIT_POSITION_Y;
      if (setNextResult() && setGambleStake() > 0 && setNextResult()?.winCoinAmount === 0) {
        eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, false);
      } else {
        this.activeGamble(false);
      }
    }
    super.onChangeMode(settings);
  }

  protected override activeGamble(disable: boolean): void {
    setFlashFlg(false);
    super.activeGamble(disable);
    if (disable) {
      eventManager.emit(EventTypes.IS_GAMBLE_MODE, true);
    }
  }

  protected override changeFilter(object1: PIXI.Sprite): void {
    if (setFlashFlg()) {
      setFlashFlg(false);
    } else {
      setFlashFlg(true);
    }

    if (isGambleMode(setGameMode())) {
      eventManager.emit(EventTypes.REEL_FRAME);
    }

    super.changeFilter(object1, setFlashFlg() ? true : false);
  }

  private timeout(isStart: boolean) {
    if (isStart) {
      this.timeoutDelay = Tween.createDelayAnimation(FAKE_ROLLING_DURATION);
      this.timeoutDelay.addOnComplete(() => {
        eventManager.emit(EventTypes.THROW_ERROR);
      });
      this.timeoutDelay.start();
    } else {
      this.timeoutDelay.removeOnComplete(() => {
        eventManager.emit(EventTypes.THROW_ERROR);
      });
      this.timeoutDelay.skip();
    }
  }
}

export default CollectBtn;
