import { IBalance } from '../../global.d';
import { setCurrency, setUserBalance } from '../../gql/cache';
import client from '../../gql/client';
import { getBalanceGql } from '../../gql/query';

export const useUserBalance = () => {
  const getUserBalance = async () => {
    const {
      data: { balance },
    } = await client.query<{ balance: IBalance }>({
      query: getBalanceGql,
      fetchPolicy: 'network-only',
    });
    setUserBalance(balance);
    setCurrency(balance.currency);
  };
  return { getUserBalance };
};
