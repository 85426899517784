import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { infoFeatureBuy1, infoFeatureTypes } from '../../config/gameRules';
import SlotMachine from '../../slotMachine';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation();
  const freeSpinFeatureDesc: string[] = t('infoFeatureFreeSpin', {
    returnObjects: true,
  });

  // if (!setSlotConfig().isBuyFeatureEnabled) {
  //   return null;
  // }

  return (
    <section>
      <section>
        <h1 className={styles.title}>{t('infoFeatureFreeSpinTitle')}</h1>
        {freeSpinFeatureDesc.map((v) => (
          <div className={`${styles.p}`} key={v} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </section>
    </section>
  );
};

const GambleFeature: React.VFC = () => {
  const { t } = useTranslation();
  const infoFeatureGamble: string[] = t('infoFeatureGamble', {
    returnObjects: true,
  });
  const [infoGambleIcon, setInfoGambleIcon] = useState('');
  const [infoCollectIcon, setInfoCollectIcon] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoGambleIcon(extractor.base64(SlotMachine.getInstance().infoGambleIcon));
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoCollectIcon(extractor.base64(SlotMachine.getInstance().infoCollectIcon));
  }, []);

  // if (!setSlotConfig().isBuyFeatureEnabled) {
  //   return null;
  // }

  return (
    <section>
      <section>
        <h1 className={styles.title}>{t('infoFeatureGambleTitle')}</h1>
        {infoFeatureGamble.map((v) => (
          <div className={`${styles.p}`} key={v} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
        <div className={styles.image_gamble}>
          <img draggable="false" src={infoGambleIcon} alt="GambleBtn" />
        </div>
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureGambleButtonDesc') }} />

        <div className={styles.image_gamble}>
          <img draggable="false" src={infoCollectIcon} alt="CollectBtn" />
        </div>
        <div className={`${styles.p}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureCollectButtonDesc') }} />
      </section>
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation();
  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');
  const multiplier = 50;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().infoBuyFeatureIcon));
  }, []);

  // if (!setSlotConfig().isBuyFeatureEnabled) {
  //   return null;
  // }
  return (
    <section>
      <h1 className={styles.title}>{t('infoFeatureBuyTitle')}</h1>
      <div className={styles.image}>
        <img draggable="false" src={infoBuyFeatureIcon} alt="buyFeatureBtn" />
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          {infoFeatureBuy1.map(({ key }) => (
            <div className={styles.p} key={key}>
              {<div dangerouslySetInnerHTML={{ __html: t(key as infoFeatureTypes, { multiplier }) }} />}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div>
      <FreeSpinFeature />
      <GambleFeature />
      <BuyFeatureFunction />
    </div>
  );
};
export default Features;
