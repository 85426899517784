import { IAnimationStateListener, Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_ANIMATIONS, SlotId } from '../../config';
import { isScatter } from '../../utils/helper';
import Animation from '../animations/animation';
import { REEL_HEIGHT, REEL_WIDTH } from '../config';

import {
  BASE_SLOT_SPINE_ANIMATE_PRIORITY,
  SCATTER_SPINE_ANIMATE_PRIORITY,
  WILD_SPINE_ANIMATE_PRIORITY,
} from './config';

export class SpineAnimateSlot extends PIXI.Sprite {
  public slotId: SlotId;

  private sprite: PIXI.Sprite;

  private spine: Spine;

  private winAnimationName = '';

  private positionPriority = 0;

  constructor(slotId: SlotId, positionPriority: number, spineData: Spine, spriteData: PIXI.Sprite) {
    super();

    this.slotId = slotId;
    this.positionPriority = positionPriority;
    this.scale.set(1);

    this.sprite = spriteData;
    this.sprite.texture = PIXI.Texture.from(MAPPED_SYMBOLS[slotId]);
    this.sprite.anchor.set(0.5, 0.5);
    this.sprite.visible = true;

    this.spine = spineData;
    this.spine.visible = false;
    this.winAnimationName = MAPPED_SYMBOLS_ANIMATIONS[this.slotId].animation!;

    this.addChild(this.sprite, this.spine);

    this.spine.state.addListener({
      complete: () => {
        PIXI.Ticker.shared.addOnce(
          () => {
            this.idle = true;
          },
          this,
          PIXI.UPDATE_PRIORITY.HIGH,
        );
      },
    });
    const mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, REEL_WIDTH - 5, REEL_HEIGHT)
      .endFill();
    mask.pivot.set(REEL_WIDTH / 2, REEL_HEIGHT / 2);
    switch (positionPriority % 3) {
      case 1:
        mask.pivot.x = mask.pivot.x - 1;
        break;
      case 2:
        mask.pivot.x = mask.pivot.x - 3;
        break;
    }
    this.mask = mask;
    this.addChild(this.mask);
  }

  private getPriority(): number {
    if (isScatter(this.slotId)) {
      return SCATTER_SPINE_ANIMATE_PRIORITY + this.positionPriority;
    } else if (this.slotId === SlotId.WL) {
      return WILD_SPINE_ANIMATE_PRIORITY + this.positionPriority;
    }
    return BASE_SLOT_SPINE_ANIMATE_PRIORITY + this.positionPriority;
  }

  public startStopAnimation(): void {
    this.idle = true;
    this.spine.state.addEmptyAnimation(0, 0, 0);
  }

  public startWinAnimation(): void {
    this.idle = false;
    this.spine.state.setAnimation(0, this.winAnimationName, false);
    this.zIndex = this.getPriority();
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration = (this.spine.skeleton.data.findAnimation(this.winAnimationName)?.duration ?? 0) * 1000;

    const listener: IAnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      this.zIndex = this.getPriority();
      PIXI.Ticker.shared.addOnce(() => {
        this.startWinAnimation();
        this.spine.state.addListener(listener);
      });
    });

    animation.addOnSkip(() => {
      this.idle = true;
      this.spine.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.spine.state.removeListener(listener);
    });

    return animation;
  }

  public skip(): void {
    this.idle = true;
    this.spine.skeleton.setToSetupPose();
  }

  public set idle(value: boolean) {
    this.sprite.visible = value;
    this.spine.visible = !value;
    this.zIndex = value ? 0 : this.getPriority();
  }

  public get idle(): boolean {
    return this.sprite.visible;
  }

  public setTint(_value: number) {
    // this.sprite.tint = value; // 当選ライン以外の暗転
  }
}
