import { SlotId } from '../../config';
import { BonusStatus, GameMode, IWager } from '../../global.d';
import {
  setBrokenBuyFeature,
  setBrokenGambleGame,
  setBrokenGame,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setIsBuyFeaturePopupOpened,
  setNextResult,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { IBet } from '../../gql/d';
import { getRestoreStateGql } from '../../gql/query';
import { IconType } from '../../slotMachine/d';
import { debugDisplay } from '../../utils';

export const useBonuses = () => {
  const checkBrokenGame = async () => {
    const { data: restoreStateData } = await client.query<{ restoreState: { bets: IBet[]; wagers: IWager[] } }>({
      query: getRestoreStateGql,
      variables: {
        input: { slotId: setSlotConfig().slotId },
      },
      fetchPolicy: 'network-only',
    });

    if (restoreStateData.restoreState.wagers.length > 0) {
      const wager = restoreStateData.restoreState.wagers[0]!;
      if (wager.wagerSettings.gameMode === GameMode.BUY_FEATURE) {
        setBrokenBuyFeature(true);
        setIsBuyFeaturePopupOpened(true);
        setCurrentBonus({
          packageId: wager.wagerSettings.packageId,
          gameMode: wager.wagerSettings.gameMode,
          rounds: wager.wagerSettings.rounds,
          roundsPlayed: wager.wagerStorage.roundsPlayed,
          state: wager.state as BonusStatus,
          coinAmount: wager.coinAmount,
          coinValue: wager.coinValue,
          originalReelPositions: restoreStateData.restoreState.bets[0]?.outcomes[0]?.stateSnapshot.reelPosition!,
          isBuyFeature: true,
          isActive: true,
        });
      } else {
        const roundsPlayed = wager.wagerStorage.roundsPlayed || 0;
        setBrokenGame(true);
        setCurrentFreeSpinsTotalWin(
          wager.wagerStorage.totalWinCoinAmount + wager.wagerStorage.previousTotalWinCoinAmount,
        );
        // TODO 暫定
        setCurrentBonus({
          packageId: GameMode.FREE_SPIN,
          gameMode: GameMode.FREE_SPIN,
          rounds: 10,
          roundsPlayed: roundsPlayed,
          state: wager.state as BonusStatus,
          coinAmount: wager.coinAmount,
          coinValue: wager.coinValue,
          originalReelPositions: wager.wagerSettings.originalReelPositions,
          isBuyFeature: false,
          isActive: true,
        });
      }
    } else if (restoreStateData.restoreState.bets[0] && restoreStateData.restoreState.bets[0]?.outcomes.length >= 5) {
      // TODO 判定見直し必要かも　outcomes.status = OPENならでも行けそう
      setBrokenGambleGame(true);

      setNextResult({
        bet: {
          betStorage: restoreStateData.restoreState.bets[0].betStorage,
          id: restoreStateData.restoreState.bets[0].id,
          slotId: restoreStateData.restoreState.bets[0].slotId,
          playerId: restoreStateData.restoreState.bets[0].playerId,
          wager: restoreStateData.restoreState.bets[0].wager,
          status: restoreStateData.restoreState.bets[0].status,
          outcomes: restoreStateData.restoreState.bets[0].outcomes,
          coinAmount: restoreStateData.restoreState.bets[0].coinAmount,
          effectiveCoinAmount: restoreStateData.restoreState.bets[0].effectiveCoinAmount,
          coinValue: restoreStateData.restoreState.bets[0].coinValue,
          createdAt: restoreStateData.restoreState.bets[0].createdAt,
          spinResult: [
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
            { id: SlotId.A, type: IconType.EMPTY },
          ],
        },
        balance: {
          settled: {
            amount: 0,
            currency: '0',
          },
          placed: {
            amount: 0,
            currency: '0',
          },
        },
        winCoinAmount: 0,
      });
    }
    debugDisplay('checkBrokenGame', restoreStateData);
  };
  return { checkBrokenGame };
};
