import { IOutcome, IWager, ReelSet, UserBonus } from '../global.d';
import { IAutoplaySettings, Icon } from '../slotMachine/d';

export declare interface IConfig {
  isAuthorized: boolean;
  isMobile: boolean;
  isTurboSpin: boolean;
  isSoundOn: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isOpenAutoplayModal: boolean;
  isOpenBetSettingsModal: boolean;
  isSlotStopped: boolean;
  isSlotStatueIdle: boolean;
  isShowSuspendedToast: boolean;
  isOpenMenuModal: {
    isOpen: boolean;
    type: 'menu' | 'info';
  };
  isOpenHistoryPopup: boolean;
  isOpenAutoplayPopup: boolean;
  isOpenBetSettingsPopup: boolean;
  isOpenInfoPopup: boolean;
  [key: string]: boolean | unknown;
  isHistoryVisible: boolean;
}

export declare interface IBetSettings {
  minBet: number;
  maxBet: number;
  bets: number[];
  quick: number[];
  coinValue: number;
  minBetCount: number;
  maxBetCount: number;
}

export type DataGQl = {
  storeFieldName: string;
};

export declare interface ISlotConfig {
  clientSlotSettings: {
    autoplaySettings: IAutoplaySettings;
    betCoinAmountSettings: number[];
    coinValueSettings: {
      code: string;
      variants: number[];
    }[];
    quickBetCoinAmountSettings: number[];
  };
  slotSettings: {
    betLines: {
      min: number;
      max: number;
    };
    cols: number;
    rows: number;
    globalCoinAmountMultiplier: number;
    startingPositions: number[];
    buyFeaturePackages: {
      coinAmountMultiplier: number;
      packageId: string;
      rounds: number;
    }[];
  };
  slotId: string;
  sessionId: string;
  reels: ReelSet[];
  icons: Icon[];
  lines: number[][];
}

export declare interface IBet {
  betStorage: {
    estimatedWinCoinAmount: number;
    historySnapshot: {
      wager: IWager;
      wagers: IWager[];
      outcomes: IOutcome[];
    } | null;
  };
  id: string;
  slotId: string;
  playerId: string;
  wager: IWager;
  status: string;
  outcomes: IOutcome[];
  coinAmount: number;
  effectiveCoinAmount: number;
  coinValue: number;
  createdAt: string;
  spinResult: Icon[];
}

export declare interface ISlotParameter {
  id: string;
  isBuyFeatureEnabled: boolean;
  sessionId: string;
}

export declare interface ISlotHistoryData {
  bets: IBet[];
  count: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string;
  endCursor: string;
}

export declare interface IStressful {
  show: boolean;
  type: 'network' | 'balance' | 'session' | 'none' | 'slotMachine';
  message: string;
}

export declare interface IProgress {
  status: number;
  wasLoaded?: boolean;
}

export enum ReelSetType {
  DEFAULT = 'DEFAULT',
  BONUS = 'BONUS',
}

export type SelectUserReelType = 'Left' | 'Center' | 'Right';

// ナッジ関連
export enum NudgeType {
  NON = 'NON',
  FAIL1_UP = 'FAIL1UP',
  FAIL1_DOWN = 'FAIL1DOWN',
  FAIL2 = 'FAIL2',
  SUCCESS_UP = 'SUCCESS_UP',
  SUCCESS_DOWN = 'SUCCESS_DOWN',
  SUCCESS_NON = 'SUCCESS_NON',
}

export enum DirectionType {
  NON = 'NON',
  UP = 'UP',
  DOWN = 'DOWN',
}

export declare interface INudgeParameter {
  nudge: NudgeType;
  startPos: number;
}

export type BetAdditionalData = {
  bonuses: UserBonus[];
  features: {
    gameRoundStore: {
      baseReelId: string;
      baseReelPositions: number[];
      guaranteedRespins: number;
      respins: number;
    };
  };
};

export type UserBonusData = NonNullable<unknown>;
