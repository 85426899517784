import { setCoinValue, setSlotConfig } from './cache';
import { IBetSettings } from './d';

export const getBetsSetting = (): IBetSettings => {
  const { slotSettings, clientSlotSettings } = setSlotConfig();

  const minBet = clientSlotSettings.betCoinAmountSettings[0] ?? 0;
  const maxBet = clientSlotSettings.betCoinAmountSettings[clientSlotSettings.betCoinAmountSettings.length - 1] ?? 0;
  const bets = clientSlotSettings.betCoinAmountSettings;
  const quick = clientSlotSettings.quickBetCoinAmountSettings;
  const coinValue = setCoinValue();
  const coinAmountMultiplier = slotSettings.globalCoinAmountMultiplier;
  const minBetCount = (minBet * coinAmountMultiplier * coinValue) / 100;
  const maxBetCount = (maxBet * coinAmountMultiplier * coinValue) / 100;

  return {
    minBet,
    maxBet,
    bets,
    quick,
    coinValue,
    minBetCount,
    maxBetCount,
  };
};
