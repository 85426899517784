import { gql } from '@apollo/client';

export const BET = gql`
  fragment bet on Bet {
    betStorage {
      ... on CommonBetStorage {
        estimatedWinCoinAmount
        historySnapshot {
          wager {
            ...wager
          }
          wagers {
            ...wager
          }
          outcomes {
            ...outcome
          }
        }
      }
    }
    id
    slotId
    playerId
    status
    coinAmount
    coinAmountMultiplier
    coinValue
    effectiveCoinAmount
    createdAt
    outcomes {
      ...outcome
    }
    wager {
      ...wager
    }
  }
`;

export const WAGER = gql`
  fragment wager on Wager {
    id
    type
    slotId
    playerId
    coinAmount
    coinValue
    state
    order
    createdAt
    wagerSettings {
      ... on NaughtyJokerWagerSettings {
        gameMode
        rounds
        packageId
        originalReelPositions
        previousWagerStorage {
          ... on CommonWagerStorage {
            roundsPlayed
            totalWinCoinAmount
            previousTotalWinCoinAmount
            paidWinCoinAmount
          }
        }
        rewardPrototypes {
          type
          value
          multiplier
          entityId
        }
      }
    }
    wagerStorage {
      ... on NaughtyJokerWagerStorage {
        roundsPlayed
        previousTotalWinCoinAmount
        paidWinCoinAmount
        totalWinCoinAmount
        gambleOutcomeStorage {
          predicament {
            ... on NaughtyJokerPredicament {
              caseFn
              wagerId
              rewardPrototypes {
                type
                value
                multiplier
                entityId
              }
            }
          }
          stateSnapshot {
            ... on NaughtyJokerSnapshot {
              fieldState
              winCombinations {
                attribution
                reelPosition
                rewards {
                  type
                  value
                  multiplier
                  entityId
                }
              }
              reelPosition
            }
          }
          id
          selectionState
        }
      }
    }
  }
`;

export const OUTCOME = gql`
  fragment outcome on Outcome {
    id
    selectionState
    predicament {
      ... on NaughtyJokerPredicament {
        caseFn
        wagerId
        rewardPrototypes {
          type
          value
          multiplier
          entityId
        }
      }
    }
    stateSnapshot {
      ... on NaughtyJokerSnapshot {
        fieldState
        reelPosition
        winCombinations {
          attribution
          reelPosition
          rewards {
            type
            value
            multiplier
            entityId
          }
        }
      }
    }
  }
`;
