import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { EventTypes } from '../../global.d';
import { setIsSlotBusy, setIsSpinInProgress, setProgress, setSlotConfig } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import IntroScreen from '../../slotMachine/IntroScreen/introScreen';
import Animator from '../../slotMachine/animations/animator';
import { eventManager } from '../../slotMachine/config';
import { wrap } from '../../utils/helper';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsPopup';
import BetSettingsPopup from '../BetSettings/BetSettingsPopup';
import BuyFeature from '../BuyFeature';
import { Collect } from '../Collect';
import EventListener from '../EventListener';
import { Gamble } from '../Gamble';
import HistoryPopup from '../History/HistoryPopup';
import InfoPopup from '../Info/InfoPopup';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';
import { preTextureTransfer } from './preTextureTransfer';

window.PIXI = PIXI;

const initDevTools = (app: PIXI.Application) => {
  import('../../slotMachine/devTool/devTools').then((module) => {
    new module.DevTools(app);
  });
};

const GameScreen: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isSoundOn } = clientData!;
  const [, setShowContent] = useState(false);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const progress = useReactiveVar(setProgress);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    const application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
    });

    preTextureTransfer(application);
    application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    new Animator(application);
    pixiContainerRef.current?.appendChild(application.view);

    initDevTools(application);

    const resize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      application.renderer.resize(width, height);
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    IntroScreen.initIntroScreen(application);
    eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      SlotMachine.initSlotMachine(
        application,
        setSlotConfig(),
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
      resize();
    });

    resize();
    window.addEventListener(EventTypes.RESIZE, resize);

    document.addEventListener('visibilitychange', function () {
      eventManager.emit(EventTypes.WINDOW_NOT_ACTIVE);
    });

    return () => window.removeEventListener(EventTypes.RESIZE, resize);
  }, []);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <>
      <div ref={pixiContainerRef} className={`${styles.canvas} ${progress.wasLoaded ? '' : styles.intro}`} />
      <EventListener />
      <BuyFeature />
      <AutoPlaySettingsMenu />
      <InfoPopup />
      <HistoryPopup />
      <BetSettingsPopup />
      <Spin />
      <Gamble />
      <Collect />
    </>
  );
};

export default GameScreen;
