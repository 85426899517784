import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import type { SlotId } from './config';
import type { IBet } from './gql/d';
import type Animation from './slotMachine/animations/animation';
import type Tween from './slotMachine/animations/tween';
import type { BgSkin } from './slotMachine/background/background';
import type { SlotMachineState } from './slotMachine/config';
import type { IWinLine, Icon, caseFn } from './slotMachine/d';
import { TesterAPI } from './slotMachine/devTool/testerApi';

export interface IBalance {
  amount: number;
  currency: string;
}

export enum RewardType {
  COINS = 'COINS',
  BONUS = 'BONUS',
}

export interface IWager {
  coinAmount: number;
  coinValue: number;
  createdAt: string;
  id: string;
  order: number;
  playerId: string;
  slotId: string;
  state: string;
  type: string;
  wagerSettings: IWagerSettings;
  wagerStorage: IWagerStorage;
}

export interface IWagerSettings {
  gameMode: GameMode;
  packageId: string;
  rounds: number;
  originalReelPositions: number[];
  rewardPrototypes: {
    entityId: string;
    multiplier: number;
    type: RewardType;
    value: number;
  }[];
}

export interface IWagerStorage {
  roundsPlayed: number;
  totalWinCoinAmount: number;
  previousTotalWinCoinAmount: number;
  paidWinCoinAmount: number;
  gambleOutcomeStorage: IOutcome[];
}

export interface IWinReward {
  type: RewardType;
  value: number;
  multiplier: number;
  entityId?: string | null;
}

export interface IOutcome {
  id: string;
  rewards: IWinReward[];
  predicament: {
    caseFn: caseFn;
    rewardPrototypes: IWinReward[];
    wagerId: string;
  };
  stateSnapshot: {
    fieldState: ISlotId[];
    reelPosition: number[];
    winCombinations: {
      attribution: number[];
      reelPosition: number | null;
      rewards: IWinReward[];
    }[];
  };
  selectionState: string;
}

export interface IAuthInput {
  clientId: string;
  connectToken: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface IGambleInput {
  betId: string;
  coinAmount: number;
  coinValue: number;
  outcomeIds: string;
  slotId: string;
}

export interface IGamble {
  gambleStake: number;
  result: {
    dealerReelPosition: number;
    userReels: {
      reelType: 'Left' | 'Center' | 'Right';
      position: number;
    }[];
  };
  balance: {
    placed: {
      amount: number;
      currency: 'FUN';
    };
    settled: {
      amount: number;
      currency: 'FUN';
    };
  };
  rewards: BetReward[];
}

export interface ISettledBet {
  bet: IBet;
  balance: {
    settled: {
      amount: number;
      currency: string;
    };
    placed: {
      amount: number;
      currency: string;
    };
  };
  winCoinAmount: number;
}

export type BetRewardType = 'BetBonusReward' | 'BetCoinReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BonusType = 'FREE_SPIN' | 'SPECIAL_ROUND' | 'PRE_LOADED' | 'GAME_MODE' | '';
export type BetReward = BetBonusReward | BetCoinReward;

export type ReelSet = {
  layout: SlotId[][];
  type: GameMode;
  additionalReelSets: SlotId[][];
};

export type BonusRoundData = {
  remainingRounds: number;
  landingDiamonds: { position: number; score: number }[];
};
export type BonusData = {
  legend: BonusRoundData[];
  multipliers: {
    rows: [number, number, number];
    columns: [number, number, number, number, number];
  };
  rewardAmount: number;
};
export type UserBonus = {
  betId: string;
  bonusId: string;
  currentRound: number;
  id: string;
  isActive: boolean;
  rounds: number;
  reelSetId: string;
  bonus: Bonus;
  coinAmount: number;
  totalWinAmount: number;
  status: BonusStatus;
  coinValue: number;
  data: UserBonusData;
};
export type Bonus = {
  coinAmount: number;
  id: string;
  reelSetId: string;
  type: BonusType;
  slotId: string;
  title?: string;
  description?: string;
  lineSetId: string | null;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};

export type UserBonusData = {
  pachiCroonRounds: PachiCroonRounds[]; // CroonRoundData[];
  stage: Stage[];
  count: number;
  creditMultiplier: number;
  preLoadedGrantBreakdown: PreLoadedGrantBreakdown[];
};

export enum FeatureState {
  BB,
  HBB,
}

export enum HoleType {
  END = 'END',
  NEXT = 'NEXT',
  COLLECT = 'COLLECT',
  EXTRA = 'EXTRA',
}

export type PachiCroonRounds = {
  coinReward: number;
  round: number;
  position: number;
};

export type Stage = {
  0: string;
  1: string;
  2: string;
};

export enum GameMode {
  BASE_GAME = 'BASE_GAME',
  FREE_SPIN = 'FREE_SPIN',
  BUY_FEATURE = 'BUY_FEATURE',
  BASE_GAME_GAMBLE = 'BASE_GAME_GAMBLE',
}

export enum ReelId {
  BASE_GAME = '79686cef-2fe6-4411-8be1-80b7826ca1d6',
  FREE_SPIN = '2994df84-9fa1-4602-97ea-31d97ff92c1f',
  BASE_GAME_GAMBLE = '00000000-0000-0000-0000-000000000000',
}

export const reelSets: Record<GameMode, string> = {
  [GameMode.BASE_GAME]: ReelId.BASE_GAME,
  [GameMode.FREE_SPIN]: ReelId.FREE_SPIN,
  [GameMode.BASE_GAME_GAMBLE]: ReelId.BASE_GAME_GAMBLE,
};

export const bonusIds: Record<GameMode, string> = {
  [GameMode.FREE_SPIN]: 'e9d83bdf-62ab-4d03-8bf6-cdc058e6a10f',
};

export const lineSets: Record<GameMode, string> = {
  [GameMode.BASE_GAME]: 'b4b614cb-7c27-4244-9775-6de369b0d7d0',
  [GameMode.FREE_SPIN]: 'b4b614cb-7c27-4244-9775-6de369b0d7d0',
};

export interface IBonusNew {
  packageId: string;
  gameMode: GameMode;
  rounds: number;
  roundsPlayed: number;
  state: BonusStatus;
  coinAmount: number;
  coinValue: number;
  originalReelPositions: number[];
  isBuyFeature: boolean;
  isActive: boolean;
}

export interface IBonus {
  packageId: string;
  coinAmountMultiplier: number;
  rounds: number;
}
export interface GetUserBonusesInput {
  id: string;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: number;
  currentSpin: number;
};

export type BattleBnsTitleProps = {
  text: string;
  currentSet: number;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

export type MessageBattleBonusBannerProps = BaseMessageBannerProps & {
  title: string;
  description: string;
  btnText: string;
  vsText: string;
  youText: string;
  rivalText: string;
};

export type MessageBonusBannerProps = BaseMessageBannerProps & {
  title: string;
  description1: string;
  btnText: string;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
};

export type GambleBtmProps = {
  btnResourceTypes: ResourceTypes;
};

export enum TotalWinBannerMode {
  NON,
  DISABLE,
  ENABLE,
}

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_BACK_GROUND = 'ResizeBackGround',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_SLOTS_STOP_DISPLAY_VISIBILITY = 'setSlotsStopDisplayVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_FADE = 'startFade',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREE_SPINS_WIN = 'isFreeSpinWin',
  SET_IS_COLLECT_SPIN = 'isCollectSpin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_COUNT_UP = 'setIsCountUp',
  SET_IS_IN_TRANSITION = 'isInTransition',
  SET_IS_AFTER_SPIN = 'setIsAfterSpin',
  SET_IS_REPLAY = 'setIsReplay',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  START_GET_AMOUNT_WIN = 'StartGetAmountWin',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_UPDATE_BTN = 'openBuyFeatureUpdateBtn',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  SET_STATE = 'setState',
  SLOT_STOP_DISPLAY_HIDE = 'SlotStopDisplayHide',
  SET_SLOT_BUSY_DISABLE = 'SetSlotBusyDisable',
  PHOENIX_START = 'PhoenixStart',
  UI_VISIBLE = 'UiVisible',
  TOOL_VISIBLE = 'ToolVisible',
  SPIN_END = 'SpinEnd',
  BONUS_END = 'BonusEnd',
  BONUS_WIN = 'BonusWin',
  BONUS_TENPAI = 'BonusTenpai',
  WINDOW_NOT_ACTIVE = 'WindowNotActive',
  FREE_SPIN = 'FreeSpin',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_FADEOUT = 'setIsFadeOut',
  SET_BROKEN_BUY_FEATURE = 'setBrokenFeature',
  RESIZE_UI_BUTTON = 'resizeUiButton',
  OPEN_POPUP = 'openPopup',
  CLOSE_POPUP = 'closePopup',
  SET_IS_SUSPENDED = 'setIsSuspended',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SET_ACTIVE_GAMBLE = 'setActiveGamble',
  SET_REEL_FRAME_VISIBLE = 'SetReelFrameVisible',
  PLACE_BET_COMPLETED = 'placeBetCompleted',
  REPLAY_SPIN = 'replaySpin',
  REPLAY_END = 'replayEnd',
  SET_REPLAY_TEXT_VISIBILITY = 'setReplayTextVisibility',
  DISABLE_COLLECT_BTN = 'disableCollectBtn',
  DISABLE_GAMBLE_BTN = 'disableGambleBtn',
  GAMBLE_SPIN = 'gambleSpin',
  GAMBLE_PLAYER_CLEAR = 'gamblePlayerClear',
  SCATTER_WIN_END = 'scatterWinEnd',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  UPDATE_FREE_SPINS_COUNT = 'updateFreeSpinsCount',
  GAMBLE_CANCEL = 'gambleCancel',
  UPDATE_GAMBLE_STAKE = 'updateGambleStake',
  REEL_FRAME = 'reelFrame',
  AFTER_SPIN = 'afterSpin',
  AUTO_SPIN_COLLECT = 'afterSpin',
  TOGGLE_UI_LEFT = 'ToggleUiLeft',
  TOGGLE_UI_RIGHT = 'ToggleUiRight',
  TOGGLE_UI_BUY_GAMBLE = 'ToggleUiBuy',
  TOGGLE_DIALOG = 'ToggleDialog',
  BASE_GAME = 'baseGame',
  NUDGE = 'nudge',
  NUDGE_MISS = 'nudgeMiss',
  NUDGE_HIT = 'nudgeHit',
  NUDGE_HIT_AFTER_WATCH_OVER = 'nudgeHitAfterWatchOver',
  COUNT_UP_WATCH_OVER = 'countUpWatchOver',
  GAMBLE_OR_COLLECT = 'gambleOrCollect',
  GAMBLE_TIMEOUT = 'gambleTimeout',
  COLLECT_TIMEOUT = 'collectTimeout',
  START_SIGN_WATCH_OVER = 'startSingWatchOver',
  FREE_SPIN_WATCH_OVER = 'freeSpinWatchOver',
  TOTAL_WIN_SING_WATCH_OVER = 'totalWinSingWatchOver',
  GAMBLE_WATCH_OVER = 'gambleWatchOver',
  GAMBLE_WATCH_OVER_FIRST_HALF = 'gambleWatchOverFirstHalf',
  GAMBLE_DEALER_SYMBOL_DECISION = 'gambleDealerSymbolDecision',
  GAMBLE_WATCH_OVER_SECOND_HALF = 'gambleWatchOverSecondHalf',
  GAMBLE_REACTION = 'gambleReaction',
  GAMBLE_CHECK = 'gambleCheck',
  SET_GAMBLE_PLAYER = 'setGamblePlayer',
  IS_GAMBLE_MODE = 'isGambleMode',
  CHEAT_CLEAR = 'cheatClear',
  WIN_ANIMATION_DIVIDEND_TABLE = 'winAnimationDividendTable',
  OPEN_MESSAGE_BANNER = 'openMessageBanner',
  CLOSE_MESSAGE_BANNER = 'closeMessageBanner',
  BACK_DROP_CLICK = 'backDropClick',
  SPIN_OK = 'spinOK',
  SET_IS_PHOENIX = 'setIsPhoenix',
  SET_IS_SPIN_WITH_AUTO_SPIN = 'setIsSpinWithAutSpin',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: () => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: (isExpand: boolean, value?: number) => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
    gambleStake?: number;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageBonusBannerProps) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: {
    mode: GameMode;
    background?: BgSkin;
    gambleStake?: number;
    isFromGamble?: boolean;
  }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_BACK_GROUND]: (scale: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_SLOTS_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (nextResult: ISettledBet, payLines: IWinLine[], winAmount: number) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_FADE]: (callback: () => void, finish: () => void) => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    background?: BgSkin;
  }) => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], reelId?: number) => void;
  [EventTypes.SHOW_TINT]: (state: boolean, reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: number[],
    scatterNo: number[],
    anticipationStartReelId: number,
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREE_SPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_COLLECT_SPIN]: (isCollectSpin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_COUNT_UP]: (isCountUp: boolean) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.SET_IS_AFTER_SPIN]: (isAfterSpin: boolean) => void;
  [EventTypes.SET_IS_REPLAY]: (isReplay: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: () => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusId: string) => void;
  [EventTypes.START_GET_AMOUNT_WIN]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_UPDATE_BTN]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP]: () => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: () => void;
  [EventTypes.SET_STATE]: (state: SlotMachineState) => void;
  [EventTypes.SLOT_STOP_DISPLAY_HIDE]: (slots: number) => void;
  [EventTypes.SET_SLOT_BUSY_DISABLE]: () => void;
  [EventTypes.PHOENIX_START]: () => void;
  [EventTypes.UI_VISIBLE]: (visible?: boolean) => void;
  [EventTypes.TOOL_VISIBLE]: (visible?: boolean) => void;

  [EventTypes.SPIN_END]: () => void;
  [EventTypes.BONUS_END]: () => void;
  [EventTypes.BONUS_WIN]: () => void;
  [EventTypes.BONUS_TENPAI]: () => void;
  [EventTypes.WINDOW_NOT_ACTIVE]: () => void;
  [EventTypes.FREE_SPIN]: (isTurboSpin: boolean | undefined) => void;

  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.SET_IS_FADEOUT]: (boolean) => void;
  [EventTypes.SET_BROKEN_BUY_FEATURE]: (boolean) => void;

  [EventTypes.RESIZE_UI_BUTTON]: (width: number, height: number) => void;
  [EventTypes.OPEN_POPUP]: () => void;
  [EventTypes.CLOSE_POPUP]: () => void;
  [EventTypes.SET_IS_SUSPENDED]: (boolean) => void;
  [EventTypes.FORCE_CLOSE_BUYFEATURE]: () => void;
  [EventTypes.SET_ACTIVE_GAMBLE]: (disable: boolean) => void;
  [EventTypes.SET_REEL_FRAME_VISIBLE]: (visible: boolean) => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.REPLAY_SPIN]: (result: IBet) => void;
  [EventTypes.REPLAY_END]: () => void;
  [EventTypes.SET_REPLAY_TEXT_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.DISABLE_COLLECT_BTN]: (disable: boolean) => void;
  [EventTypes.DISABLE_GAMBLE_BTN]: (disable: boolean) => void;
  [EventTypes.GAMBLE_SPIN]: () => void;
  [EventTypes.GAMBLE_PLAYER_CLEAR]: () => void;
  [EventTypes.SCATTER_WIN_END]: () => void;
  [EventTypes.CREATE_FREE_SPINS_TITLE]: (props: FreeSpinsTitleProps) => void;
  [EventTypes.REMOVE_FREE_SPINS_TITLE]: () => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (total: number, current: number, immediately?: boolean) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.UPDATE_FREE_SPINS_COUNT]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.GAMBLE_CANCEL]: () => void;
  [EventTypes.UPDATE_GAMBLE_STAKE]: (stake: number, immediately: boolean) => void;
  [EventTypes.REEL_FRAME]: () => void;
  [EventTypes.AFTER_SPIN]: () => void;
  [EventTypes.AUTO_SPIN_COLLECT]: () => void;
  [EventTypes.TOGGLE_UI_LEFT]: (isUpper?: boolean) => void;
  [EventTypes.TOGGLE_UI_RIGHT]: (isUpper?: boolean) => void;
  [EventTypes.TOGGLE_UI_BUY_GAMBLE]: (isLandScape?: boolean) => void;
  [EventTypes.TOGGLE_DIALOG]: (isLandScape?: boolean) => void;
  [EventTypes.BASE_GAME]: () => void;
  [EventTypes.NUDGE]: () => void;
  [EventTypes.NUDGE_MISS]: () => void;
  [EventTypes.NUDGE_HIT]: () => void;
  [EventTypes.NUDGE_HIT_AFTER_WATCH_OVER]: () => void;
  [EventTypes.COUNT_UP_WATCH_OVER]: () => void;
  [EventTypes.GAMBLE_OR_COLLECT]: () => void;
  [EventTypes.GAMBLE_TIMEOUT]: (isStart: boolean) => void;
  [EventTypes.COLLECT_TIMEOUT]: (isStart: boolean) => void;
  [EventTypes.START_SIGN_WATCH_OVER]: () => void;
  [EventTypes.FREE_SPIN_WATCH_OVER]: () => void;
  [EventTypes.TOTAL_WIN_SING_WATCH_OVER]: () => void;
  [EventTypes.GAMBLE_WATCH_OVER]: () => void;
  [EventTypes.GAMBLE_WATCH_OVER_FIRST_HALF]: () => void;
  [EventTypes.GAMBLE_DEALER_SYMBOL_DECISION]: () => void;
  [EventTypes.GAMBLE_WATCH_OVER_SECOND_HALF]: () => void;
  [EventTypes.GAMBLE_REACTION]: () => void;
  [EventTypes.GAMBLE_CHECK]: () => void;
  [EventTypes.SET_GAMBLE_PLAYER]: (index: number) => void;
  [EventTypes.IS_GAMBLE_MODE]: (isGamble: boolean) => void;
  [EventTypes.CHEAT_CLEAR]: () => void;
  [EventTypes.WIN_ANIMATION_DIVIDEND_TABLE]: (special: SlotId, any7mix: boolean) => void;
  [EventTypes.OPEN_MESSAGE_BANNER]: () => void;
  [EventTypes.CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.BACK_DROP_CLICK]: () => void;
  [EventTypes.SPIN_OK]: () => void;
  [EventTypes.SET_IS_PHOENIX]: (isPhoenix: boolean) => void;
  [EventTypes.SET_IS_SPIN_WITH_AUTO_SPIN]: (isPhoenix: boolean) => void;
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
  BUY_FEATURE = 'default',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  NONE = null,
}

export enum BgmSoundTypes {
  BASE = 'regular',
  FS = 'freespin',
  GAMBLE = 'gamble',
}

export enum ReelAnticipation {
  NON = 'NON',
  BONUS = 'BONUS',
}

export enum BattleBonusUpperColor {
  RED = 'RED',
  PURPLE = 'PURPLE',
  RAINBOW = 'RAINBOW',
}

declare global {
  interface Window {
    __PIXI_APP__: PIXI.Application;
    __: TesterAPI;
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    PIXI: PIXI;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export type StageData = {
  win: number;
  inHoleType: HoleType;
};

export enum PopupTypes {
  BUY_FEATURE,
  BUY_FEATURE_CONFIRMATION,
  FREE_SPINS,
  FREE_SPINS_END,
  RAGE_MODE,
  RAGE_MODE_INFO,
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
  TURBO_SPIN = 'TURBO_SPIN',
}
