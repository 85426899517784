export enum BaseGameMotionType {
  aNormal, // A. ベースゲーム待機
  bNudge, // B. ナッジ見守り
  cNudgeMiss, // C. ナッジハズレリアクション
  dNudgeHit, // D. FS当選リアクション
  eNudgeHitAfterWatchOver, //E. FS当選後見守り
  fCountUpBig, // F. Big Winリアクション CountUpBig,CountUpMega,CountUpGreat,CountUpEpic
  gCountUpWatchOver, // G. カウントアップ見守り
  hGambleOrCollect, // H. Gamble or Collect見守り
  iFsStartSignboardWatchOver, // I. FS開始時看板見守り
  jFsWatchOver, // J. FS中見守り
  kTotalWinSignboard, // K. Total Win看板見守り
  lGambleWatchOver, // L. Gamble前見守り
  mGambleWatchOverFirstHalf, // M. Gamble中見守り（前半）
  nDealerSymbol, // N. Dealerシンボルに応じてリアクション
  oGambleWatchOverLatterHalf, // O. Gamble中見守り（後半）
  pGambleResult, // P. Gamble結果に応じてリアクション
  Regret,
  BonusWin,
  AfterBonusWin1,
  AfterBonusWin2,
  CountUp,
  CountUpBig,
  CountUpMega,
  CountUpGreat,
  CountUpEpic,
  TotalWin,
  Non,
}
