import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../resources.d';

import { textPositionY } from './textStyles';

class CollectBtnIcon extends PIXI.Container {
  private btn: PIXI.Sprite;

  protected frame: PIXI.Sprite;

  private text: PIXI.Sprite;

  private rect: PIXI.Graphics;

  isDisabled: boolean;

  constructor() {
    super();
    this.isDisabled = false;
    this.visible = true;
    this.btn = this.initBtn();
    this.frame = this.initFrame();
    this.text = this.initText();
    this.rect = this.initRect();
    this.addChild(this.btn, this.frame, this.text, this.rect);
  }

  private initBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.collectBtn));
    btn.anchor.set(0.5, 0.5);
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    btn.buttonMode = true;

    return btn;
  }

  protected initFrame() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.gambleBtnframe));
    btn.anchor.set(0.5, 0.5);
    btn.x = 0;
    btn.y = 0;
    btn.visible = false;

    return btn;
  }

  private initText(): PIXI.Sprite {
    const text = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.collect));

    text.position.y = textPositionY;
    text.anchor.set(0.5);

    return text;
  }

  private initRect(): PIXI.Graphics {
    const rect = new PIXI.Graphics();
    rect.beginFill(0xff0000);
    rect.drawRect(0, 0, this.frame.width, this.frame.height);
    rect.pivot.set(this.frame.width / 2, this.frame.height / 2);
    rect.alpha = 0.0;

    rect.interactive = true;
    rect.buttonMode = true;
    rect.visible = true;

    return rect;
  }
}

export default CollectBtnIcon;
