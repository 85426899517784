import AudioApi from '@phoenix7dev/audio-api';

import { EventTypes } from '../../global.d';
import { setIsSoundOn } from '../../gql/cache';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class SoundBtn extends UiButton {
  constructor() {
    super(setIsSoundOn() ? 'sound-on' : 'sound-off');
    this.addChild(this.btn);
    this.interactive = true;
    this.initEvents();
    this.initSubscriptions();

    if (!AudioApi.isInitialized) {
      this.setDisable(true);
    }

    eventManager.on(EventTypes.UI_VISIBLE, (visible) => {
      this.visible = visible ? visible : !this.visible;
    });
  }

  private initEvents(): void {
    this.btn.on('click', () => this.handleClick());
    this.btn.on('touchstart', () => this.handleClick());
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.TOGGLE_SOUND, (isSoundOn: boolean) => {
      this.updateIntent(isSoundOn ? 'sound-on' : 'sound-off');
    });

    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      this.setDisable(false);
    });
  }

  private handleClick(): void {
    if (this.isPortraitMode) {
      eventManager.emit(EventTypes.TOGGLE_UI_RIGHT, true);
    } else {
      eventManager.emit(EventTypes.TOGGLE_UI_LEFT, true);
    }

    const nextState = !setIsSoundOn();
    AudioApi.unSuspend();
    AudioApi.setSoundState(nextState);
    setIsSoundOn(nextState);

    this.updateIntent(nextState ? 'sound-on' : 'sound-off');
  }
}

export default SoundBtn;
