import { nudgeLotLoss, nudgeLotSuccess } from '../../anticipation/table';
import { getRandomFromUUID, getResultFromTable } from '../../anticipation/util';
import { SlotId } from '../../config';
import { EventTypes, ReelSet } from '../../global.d';
import { setGameMode, setIsTurboSpin, setNextResult, setNudgeList } from '../../gql/cache';
import { NudgeType } from '../../gql/d';
import { debugDisplay, isFreeSpinsMode, isGambleMode } from '../../utils';
import { setPlayerToNumber } from '../../utils/utils/gamble';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  BASE_REEL_PHOENIX_ROLLING_DURATION,
  BASE_REEL_PHOENIX_ROLLING_DURATION_TURBO,
  BASE_REEL_ROLLING_SPEED,
  BASE_SPIN_TIME,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  GAMBLE_REEL_STOP,
  REELS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_SPIN_TIME,
  eventManager,
} from '../config';

import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  public forcedStop = false;

  private animationDelay: number;

  private id: number;

  constructor(reels: SlotId[][], startPosition: number[], id: number) {
    super();
    this.id = id;
    this.initContainer();
    if (reels.length != 0 && startPosition.length != 0) {
      this.initReels(reels, startPosition);
    }
    eventManager.addListener(EventTypes.REEL_STOPPED, this.hideSlots.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, () => {
      this.hideSlots();
    });
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.showSlots.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));
    eventManager.addListener(EventTypes.CHANGE_REEL_SET, this.changeReelSet.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));

    eventManager.addListener(EventTypes.PHOENIX_START, () => {
      if (setIsTurboSpin()) {
        this.animationDelay += BASE_REEL_PHOENIX_ROLLING_DURATION_TURBO;
      } else {
        this.animationDelay += BASE_REEL_PHOENIX_ROLLING_DURATION;
      }
    });
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.animationDelay = 0;
    });

    this.sortableChildren = true;
    this.animationDelay = 0;
  }

  private hideSlots(reelId?: number): void {
    const arr = [];
    if (reelId !== undefined) {
      if (reelId != this.id) return;
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i * REELS_AMOUNT + reelId);
      }
    } else {
      for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i);
      }
    }
    if (arr.length > 0) this.setSlotsVisibility(arr, false);
  }

  private showSlots(): void {
    const arr = [];
    for (let i = 0; i < 9; i++) arr.push(i);
    this.setSlotsVisibility(arr, true);
  }

  private rollbackReels(positions: number[]): void {
    // for (let i = 0; i < positions.length; i++) {
    eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[0]!.spinAnimation?.getStarting() as Tween);
    eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[0]!.spinAnimation?.getFirstRolling() as Tween);
    eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[0]!.spinAnimation?.getFakeRolling() as Tween);
    this.reels[0]!.position = this.reels[0]!.size - positions[this.id]!;
    this.reels[0]!.state = ReelState.IDLE;
    // }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private changeReelSet(settings: { reelSet: ReelSet; reelPositions: number[] }): void {
    if (!isGambleMode(setGameMode())) {
      const reelLayout = settings.reelSet.layout.map((reel) =>
        reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel,
      );

      const reelPositions = (reelLayout[this.id]!.length - settings.reelPositions[this.id]!) % reelLayout[0]!.length;
      this.reels[0]!.clean();
      this.reels[0]!.init(reelLayout[0]!, reelPositions);
    }
  }

  private initReels(reels: SlotId[][], startPosition?: number[]): void {
    reels = reels.map((reel) => (reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel));

    const position = startPosition ? startPosition[this.id]! : 0;
    const reel = new Reel(this.id, reels[this.id]!, position);
    this.reels[0] = reel;
    this.addChild(reel.container);

    eventManager.emit(EventTypes.REGISTER_ANIMATOR, reel.animator);
  }

  private forceStopReels(isTurboSpin: boolean): void {
    this.forcedStop = true;
    if (this.reels[0]?.spinAnimation === null) return;
    const stopAllReelsAtSameTime =
      Date.now() - this.reels[0]!.spinAnimation!.startTime < (isTurboSpin ? TURBO_SPIN_TIME : BASE_SPIN_TIME);

    if (stopAllReelsAtSameTime) {
      let [maxSoundNo, maxSoundIdx] = [-1, 0];
      for (let i = 0; i < this.reels.length; i++) {
        if (maxSoundNo < this.reels[i]!.stopSoundSymbolNo) {
          [maxSoundNo, maxSoundIdx] = [this.reels[i]!.stopSoundSymbolNo, i];
        }
        this.reels[i]!.isPlaySoundOnStop = false;
      }
      this.reels[maxSoundIdx]!.isPlaySoundOnStop = true;
    }

    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i]!.stopReel(
        stopAllReelsAtSameTime
          ? FORCE_STOP_SPIN_ANIMATION_DURATION
          : FORCE_STOP_SPIN_ANIMATION_DURATION + i * FORCE_STOP_SPIN_PER_EACH_DURATION,
      );
    }
  }

  private prolongTarget = (reel: Reel, minValue: number): number => {
    let res = 0;
    while (res < minValue) res += reel.data.length;
    return res;
  };

  private setupAnimationTarget(
    reelPositions: number[],
    stopSoundSymbolNo: number[],
    anticipationStartReelId: number,
  ): void {
    const rollingSpeed = this.reels[0]!.isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;

    for (let j = 0; j < this.reels.length; j++) {
      if (isFreeSpinsMode(setGameMode()) && this.id === 4) continue;
      const fakeRollingAnimation = this.reels[j]!.spinAnimation!.getFakeRolling();
      fakeRollingAnimation.duration = 0;

      this.reels[j]!.stopSoundSymbolNo = stopSoundSymbolNo[j]!;

      const rollingAnimation = this.reels[j]!.spinAnimation!.getRolling();
      const endingAnimation = this.reels[j]!.spinAnimation!.getEnding();
      let target = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]!);

      this.reels[j]!.isWl = this.reels[j]!.data[reelPositions[this.id]!] === 'WL' ? true : false;

      if (!isGambleMode(setGameMode())) {
        const nextIndex = reelPositions[this.id] === this.reels[j]!.data.length - 1 ? 0 : reelPositions[this.id]! + 1;
        const prevIndex = reelPositions[this.id] === 0 ? this.reels[j]!.data.length - 1 : reelPositions[this.id]! - 1;

        // ナッジ関連
        if (this.id === 4) {
          if (this.reels[j]!.data[reelPositions[this.id]!] === 'WL') {
            const rand = getRandomFromUUID(setNextResult()!.bet.id, 100);
            const result = getResultFromTable(nudgeLotSuccess, rand);
            debugDisplay('result ', result === 0 ? 'そのまま停止' : result === 1 ? '動いて戻る' : 'ちょっと待ち');

            target = this.reels[j]!.getTarget(this.reels[j]!.data.length - 1);
            switch (result) {
              case 0:
                setNudgeList({ nudge: NudgeType.SUCCESS_NON, startPos: 0, targetPos: 0 });
                break;
              case 1: {
                target = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]! - 1);
                const startPos = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]! - 1);
                const targetPos = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]!);
                debugDisplay('startPos:', startPos, 'targetPos:', targetPos);
                setTimeout(() => {
                  const startPos1 = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]! - 1);
                  const targetPos1 = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]!);
                  debugDisplay('startPos1:', startPos1, 'targetPos1:', targetPos1);
                }, 10);
                if (startPos + 1 === targetPos) {
                  setNudgeList({
                    nudge: NudgeType.SUCCESS_DOWN,
                    startPos: targetPos - 1,
                    targetPos: targetPos,
                  });
                } else {
                  debugDisplay('================startPos:', startPos, 'targetPos:', targetPos);
                  setNudgeList({
                    nudge: NudgeType.SUCCESS_DOWN,
                    startPos: startPos,
                    targetPos: startPos + 1,
                  });
                }
                break;
              }
              case 2: {
                target = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]! + 1);
                const startPos = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]! + 1);
                const targetPos = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]!);
                debugDisplay('startPos:', startPos, 'targetPos:', targetPos);
                setTimeout(() => {
                  const startPos1 = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]! + 1);
                  const targetPos1 = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]!);
                  debugDisplay('startPos1:', startPos1, 'targetPos1:', targetPos1);
                }, 10);
                if (startPos - 1 === targetPos) {
                  setNudgeList({
                    nudge: NudgeType.SUCCESS_UP,
                    startPos: targetPos + 1,
                    targetPos: targetPos,
                  });
                } else {
                  debugDisplay('================startPos:', startPos, 'targetPos:', targetPos);
                  setNudgeList({
                    nudge: NudgeType.SUCCESS_DOWN,
                    startPos: startPos,
                    targetPos: startPos - 1,
                  });
                }
                break;
              }
              default:
                break;
            }
          } else if (this.reels[j]!.data[nextIndex!] === 'WL') {
            const rand = getRandomFromUUID(setNextResult()!.bet.id, 100);
            const result = getResultFromTable(nudgeLotLoss, rand);
            debugDisplay('result ', result === 0 ? 'そのまま停止' : result === 1 ? '動いて戻る' : 'ちょっと待ち');
            switch (result) {
              case 0:
                setNudgeList({ nudge: NudgeType.NON, startPos: 0, targetPos: 0 });
                break;
              case 1:
                setNudgeList({
                  nudge: NudgeType.FAIL1_UP,
                  startPos: this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]!),
                  targetPos: 0,
                });
                break;
              case 2:
                setNudgeList({ nudge: NudgeType.FAIL2, startPos: 0, targetPos: 0 });
                break;
              default:
                break;
            }
          } else if (this.reels[j]!.data[prevIndex!] === 'WL') {
            const rand = getRandomFromUUID(setNextResult()!.bet.id, 100);
            const result = getResultFromTable(nudgeLotLoss, rand);
            debugDisplay('rand ', result === 0 ? 'そのまま停止' : result === 1 ? '動いて戻る' : 'ちょっと待ち');
            switch (result) {
              case 0:
                setNudgeList({ nudge: NudgeType.NON, startPos: 0, targetPos: 0 });
                break;
              case 1:
                setNudgeList({
                  nudge: NudgeType.FAIL1_DOWN,
                  startPos: this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[this.id]!),
                  targetPos: 0,
                });
                break;
              case 2:
                setNudgeList({ nudge: NudgeType.FAIL2, startPos: 0, targetPos: 0 });
                break;
              default:
                break;
            }
          } else {
            setNudgeList({ nudge: NudgeType.NON, startPos: 0, targetPos: 0 });
          }
        }
      } else {
        setNudgeList({
          nudge: NudgeType.NON,
          startPos: 0,
          targetPos: 0,
        });
      }

      rollingAnimation.duration += this.animationDelay;

      let beginValue = target - REEL_ENDING_SLOTS_AMOUNT - Math.round(rollingAnimation.duration * rollingSpeed);
      if (beginValue < 0) {
        const prolong = this.prolongTarget(this.reels[j]!, Math.abs(beginValue));

        beginValue += prolong;
        target += prolong;
      }

      rollingAnimation.propertyBeginValue = beginValue;
      rollingAnimation.target = target - REEL_ENDING_SLOTS_AMOUNT;

      endingAnimation.propertyBeginValue = target - REEL_ENDING_SLOTS_AMOUNT;
      endingAnimation.target = target;

      if (j === anticipationStartReelId) {
        rollingAnimation.addOnComplete(() => {
          eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_START);
          eventManager.emit(EventTypes.ANTICIPATION_STARTS, j);
        });
        endingAnimation.addOnComplete(() => {
          eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_END);
          if (stopSoundSymbolNo[2] === 3) {
            eventManager.emit(EventTypes.BONUS_WIN);
          } else if (stopSoundSymbolNo[1] === 2) {
            eventManager.emit(EventTypes.BONUS_TENPAI);
          }
        });
      } else {
        rollingAnimation.duration += 0;
        let beginValue = target - REEL_ENDING_SLOTS_AMOUNT - Math.round(rollingAnimation.duration * rollingSpeed);
        if (beginValue < 0) {
          const prolong = this.prolongTarget(this.reels[j]!, Math.abs(beginValue));
          beginValue += prolong;
          target += prolong;
        }
        rollingAnimation.propertyBeginValue = beginValue;
        rollingAnimation.target = target - REEL_ENDING_SLOTS_AMOUNT;

        const index = setPlayerToNumber();

        if (isGambleMode(setGameMode())) {
          if (this.id >= 6) {
            debugDisplay('ローリング時間設定');

            let target =
              this.reels[0]!.position + Math.round(GAMBLE_REEL_STOP[index]![this.id]! * TURBO_REEL_ROLLING_SPEED);
            rollingAnimation.duration = GAMBLE_REEL_STOP[index]![this.id]!;
            let beginValue =
              target - REEL_ENDING_SLOTS_AMOUNT - Math.round(rollingAnimation.duration * TURBO_REEL_ROLLING_SPEED);
            if (beginValue < 0) {
              const prolong = this.prolongTarget(this.reels[j]!, Math.abs(beginValue));
              beginValue += prolong;
              target += prolong;
            }
            rollingAnimation.propertyBeginValue = beginValue;
            rollingAnimation.target = target - REEL_ENDING_SLOTS_AMOUNT;
          }
        }

        // endingAnimation.propertyBeginValue = target - REEL_ENDING_SLOTS_AMOUNT;
        // endingAnimation.target = target;
      }
    }
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = 0;
      const position = this.reels[x]!.size - (Math.round(this.reels[x]!.position) % this.reels[x]!.size) + y;
      const normalizedPosition = position === -1 ? this.reels[x]!.size - 1 : position % this.reels[x]!.size;
      const slot = this.reels[x]!.slots[normalizedPosition];
      if (slot) slot.visible = visibility;
    });
  }
}

export default ReelsContainer;
