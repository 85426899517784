import { SlotId } from '../config';
import { GameMode } from '../global.d';
import { setAvatarTension, setSlotConfig } from '../gql/cache';
import { IConfig } from '../gql/d';
import SpineAnimation from '../slotMachine/animations/spine';
import { Icon } from '../slotMachine/d';

import { debugDisplay, nextTick } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key!]: value,
    };
  }, {});
  return param as T;
};

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  const { slotSettings } = setSlotConfig();
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (slotSettings.globalCoinAmountMultiplier || 1)) / 100;
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Icon[], id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  throw error;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
  window.dispatchEvent(new CustomEvent('setpos'));
};

export const destroySpine = (spine: SpineAnimation): void => {
  nextTick(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy({ children: true });
    }
  });
};

export const isBaseGameMode = (mode: string): boolean => {
  return mode === GameMode.BASE_GAME;
};

export const isFreeSpinGameMode = (mode: string): boolean => {
  return mode === GameMode.FREE_SPIN;
};

export const isScatter = (_slotId: SlotId): boolean => {
  return false;
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

// export const getWinCoin = (): number => {
//   return (
//     setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.coinReward *
//     setCoinAmount() *
//     setSlotConfig().lineSet.coinAmountMultiplier
//   );
// };

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotStopped,
  isOpenedMessageBanner,
  isInTransition,
  isCountUp,
  isBuyFeaturePopupOpened,
  isAutoPlay,
  isFadeOut,
  isBrokenBuyFeature,
  isAfterSpin,
  isReplay,
  isPhoenix,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenedMessageBanner: boolean;
  isInTransition: boolean;
  isCountUp: boolean;
  isBuyFeaturePopupOpened: boolean;
  isAutoPlay: boolean;
  isFadeOut: boolean;
  isBrokenBuyFeature: boolean;
  isAfterSpin: boolean;
  isReplay: boolean;
  isPhoenix: boolean;
}): boolean => {
  if (isPhoenix) {
    return false;
  }
  if (isBrokenBuyFeature) {
    return false;
  }

  if (isAutoPlay) {
    return false;
  }
  if (isOpenedMessageBanner) {
    return true;
  }

  if (isInTransition) {
    return false;
  }

  if (gameMode === GameMode.BASE_GAME_GAMBLE) {
    return false;
  }

  if (gameMode === GameMode.BASE_GAME && isFreeSpinsWin) {
    return false;
  }

  // TODO 暫定
  // if (isFreeSpinsMode(gameMode) && !isSlotBusy) {
  //   return false;
  // }

  if (isCountUp) {
    return true;
  }

  if (isReplay) {
    return true;
  }

  if (isAfterSpin) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  if (isFadeOut) {
    return false;
  }

  return true;
};
export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};

export const calAvatarTension = (tension: number): void => {
  const currentTension = setAvatarTension();
  let nextTension: number;

  debugDisplay('helper.アバターテンション更新前 currentTension', currentTension, 'tension', tension);

  if (currentTension + tension > 3) {
    nextTension = 3;
  } else if (currentTension + tension < 1) {
    nextTension = 1;
  } else {
    nextTension = currentTension + tension;
  }
  debugDisplay('helper.アバターテンション更新前', setAvatarTension());
  setAvatarTension(nextTension);
  debugDisplay('helper.アバターテンション更新後', setAvatarTension());
};

export const queryParams = new URLSearchParams(window.location.search);
export const isDemo = queryParams.has('isDemo');

export const getUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const config: IConfig = JSON.parse(item);
    if (config[name] === undefined) {
      Object.assign(config, { [name]: value });
      localStorage.setItem('config', JSON.stringify(config));
    }
    return config[name] as boolean;
  }
  localStorage.setItem('config', JSON.stringify({ [name]: value }));
  return value;
};

export const setUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const config: IConfig = JSON.parse(item);
    Object.assign(config, { [name]: value });
    localStorage.setItem('config', JSON.stringify(config));
  }
  return value;
};

/**
 * ⚠️CAUTION⚠️
 * This function ignores null checks and is unsafe
 * @param value Nullable value
 * @returns value typecast to NonNullable
 */
export function getNonNullableValue<T>(value: T): RecursiveNonNullable<T> {
  return value as RecursiveNonNullable<T>;
}
