import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { getAnimationSelectionTableLot } from '../anticipation';
import { AnimationType, baseGamePhoenix } from '../anticipation/table';
import { SlotId, red } from '../config';
import { mappedAudioSprites } from '../config/audio';
import { ISongs } from '../config/audio/sprite.generated';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IOutcome,
  ISettledBet,
  MessageBonusBannerProps,
  ReelAnticipation,
  ReelId,
  reelSets,
} from '../global.d';
import {
  setAnticipationLine,
  setAnticipationSlotId,
  setAvatarStatusControl,
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGambleGame,
  setBrokenGame,
  setBrokenGameBgm,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setCurrentStage,
  setFreeSpinsTotalWin,
  setGambleEntryReelPosition,
  setGambleStake,
  setGambleStakeResult,
  setGameMode,
  setIsAfterSpin,
  setIsAutoSpins,
  setIsBuyFeaturePurchased,
  setIsCollected,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsDuringWinCountUpAnimation,
  setIsFreeSpinsWin,
  setIsPhoenix,
  setIsReplay,
  setIsReplayEnd,
  setIsReplayGamble,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSlotStatueIdle,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setNextResult,
  setNudgeList,
  setPage,
  setPlayer,
  setPrevReelsPosition,
  setReelAnticipation,
  setReelSetId,
  setReplayEnd,
  setReplayGambleCount,
  setReplayResult,
  setReplaySpin,
  setSlotConfig,
  setSpecialBonusAny7Mix,
  setSpecialBonusIcon,
  setSpinWithAutoSpin,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { IBet, ISlotConfig, ISlotHistoryData, NudgeType } from '../gql/d';
import { isStoppedGql, slotHistoryGql } from '../gql/query';
import { ResourceTypes } from '../resources.d';
import {
  calAvatarTension,
  debugDisplay,
  formatNumber,
  getSpinResult3x1,
  isBaseGameMode,
  isFreeSpinGameMode,
  isFreeSpinsMode,
  isGambleMode,
  nextTick,
  normalizeCoins,
  showCurrency,
} from '../utils';
import { isSpecialBonus, nextGambleSpin } from '../utils/utils/gamble';
import { makeGambleResult } from '../utils/utils/makeGambleResult';

import Animation from './animations/animation';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import AvatarMotion from './avatarMotion/avatarMotion';
import { StatusControlFlg } from './avatarTalk/config';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BgSkin } from './background/config';
import bgmControl from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_START_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  BONUS_SYMBOLS_ID,
  FREE_SPINS_FADE_IN_DURATION,
  FREE_SPINS_FADE_OUT_DURATION,
  FREE_SPINS_TIME_OUT_BANNER,
  PHOENIX_DIRECTION_TOTAL_BET,
  REELS_AMOUNT,
  REPLAY_END_DELAY,
  SlotMachineState,
  eventManager,
} from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuBtn from './controlButtons/menuBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import { Icon, caseFn, gambleResult } from './d';
import FadeArea from './fadeArea/fadeArea';
import CollectBtn from './gamble/collectBtn';
import CollectBtnIcon from './gamble/collectBtnIcon';
import GambleBtn from './gamble/gambleBtn';
import GambleBtnIcon from './gamble/gambleBtnIcon';
import GameView from './gameView/gameView';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import { gambleReplay, getReplayResult } from './replay/replay';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import SlotsAnimationContainer from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotConfig;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public nextResultPrev: ISettledBet | null = null;

  public nextResultFlg = true;

  public stopCallback: (() => void) | null = null;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  private endingDisplay = false;

  private reelPositions: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  private reelSetId: string | null = null;

  public menuBtn: MenuBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBtn: InfoBtn;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(application, slotConfig, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer[] = [];

  public tintContainer: TintContainer[] = [];

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  private phoenix: Phoenix;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public infoBuyFeatureIcon: PIXI.Container;

  public infoGambleIcon: PIXI.Container;

  public infoCollectIcon: PIXI.Container;

  private slotsAnimationContainer: SlotsAnimationContainer;

  private avatarMotion: AvatarMotion;

  private constructor(
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();

    const lastReelPosition = setUserLastBetResult().outcomes.find(
      (outcome) => outcome.stateSnapshot != undefined && outcome.stateSnapshot.reelPosition.length === 9,
    );

    let startPosition = setUserLastBetResult().id
      ? lastReelPosition != undefined
        ? lastReelPosition.stateSnapshot.reelPosition
        : slotConfig.slotSettings.startingPositions
      : slotConfig.slotSettings.startingPositions;

    const reelSet = slotConfig.reels.find((reelSet) => reelSet.type === GameMode.BASE_GAME)!;

    if (startPosition.length === 4) {
      const dealer = startPosition[0]!;
      const player1 = startPosition[1]!;
      const player2 = startPosition[2]!;
      const player3 = startPosition[3]!;
      startPosition = [0, 0, 0, 0, dealer, 0, player1, player2, player3];
    } else if (startPosition.length !== 9) {
      startPosition = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
    setPrevReelsPosition(startPosition.slice(0, 9)); //REELS_AMOUNT

    setReelSetId(reelSet.type);
    for (let i = 0; i < 9; i++) {
      // TODO  1-18
      this.reelsContainer[i] = new ReelsContainer(reelSet.layout, startPosition, i);
      this.tintContainer[i] = new TintContainer();
    }

    debugDisplay('startPosition', startPosition);

    const spinResult = getSpinResult3x1({
      reelPositions: startPosition.slice(0, 9),
      reelSet,
      icons: slotConfig.icons,
    });
    debugDisplay('spinResult', spinResult);

    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.background = new Background();
    this.application.stage.addChild(this.background);
    this.application.stage.sortableChildren = true;

    this.bottom = new BottomContainer();
    this.menuBtn = new MenuBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();

    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();
    this.infoGambleIcon = new GambleBtnIcon();
    this.infoCollectIcon = new CollectBtnIcon();

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.slotsAnimationContainer = new SlotsAnimationContainer();

    this.gameView = new GameView({
      winSlotsContainer: this.slotsAnimationContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });
    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);

    this.avatarMotion = new AvatarMotion();
    this.application.stage.addChild(this.avatarMotion);
    this.application.stage.addChild(this.bottom);

    this.application.stage.addChild(
      this.menuBtn,
      this.turboSpinBtn,
      this.spinBtn,
      this.betBtn,
      this.autoplayBtn,
      this.infoBtn,
    );

    this.initBuyFeature(this.gameView);

    setIsSlotStatueIdle(true);

    if (setBrokenBuyFeature()) {
      setIsSlotBusy(true);
      eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
      nextTick(() => {
        eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, false);
        if (this.state === SlotMachineState.IDLE) eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
      });
    }

    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.fadeArea);

    this.phoenix = new Phoenix();

    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.application.stage.addChild(this.phoenix);

    if (setBrokenGame()) {
      this.onBrokenGame();
    } else if (setBrokenGambleGame()) {
      this.onBrokenGambleGame();
    }
  }

  private initBuyFeature(view: GameView): void {
    view.addChild(
      new BuyFeatureBtn(),
      new Backdrop(),
      new BuyFeaturePopup(),
      new BuyFeaturePopupConfirm(),
      new CollectBtn({ btnResourceTypes: ResourceTypes.collectBtn }),
      new GambleBtn({ btnResourceTypes: ResourceTypes.gambleBtn }),
    );
  }

  private getStateSnapshotFromResult(): IOutcome | undefined {
    return this.nextResult!.bet.outcomes.find((outcome) => outcome.stateSnapshot != undefined);
  }

  private onBrokenGame(): void {
    const gameMode = setCurrentBonus().gameMode;
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(gameMode);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
      text: 'freeSpinsTitleText',
      spins: setCurrentBonus().rounds,
      currentSpin: setCurrentBonus().roundsPlayed,
    });

    if (setCurrentBonus().roundsPlayed === 0) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: i18n.t('freeSpinBonus'),
        description1: i18n.t('freeSpinBonusText1'),
        btnText: i18n.t('startText'),
        callback: () => {
          eventManager.emit(EventTypes.FREE_SPIN_WATCH_OVER);
          eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
          setCurrentStage(0);
          debugDisplay('setCurrentBonus3', setCurrentBonus());
        },
      });
    } else {
      this.setState(SlotMachineState.IDLE);
    }
    eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

    eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

    eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);

      setCurrentStage(setPage());

      this.clearNextResult();
    }
    setBrokenGameBgm(true);
    setBrokenGame(false);
  }

  private onBrokenGambleGame(): void {
    setGameMode(GameMode.BASE_GAME_GAMBLE);
    eventManager.emit(EventTypes.GAMBLE_WATCH_OVER);
    eventManager.emit(EventTypes.GAMBLE_PLAYER_CLEAR);
    eventManager.emit(EventTypes.SET_STATE, SlotMachineState.GAMBLE_SPIN);

    this.nextResult = this.nextResultPrev != null ? this.nextResultPrev : setNextResult();

    setGambleStake(setWinAmount());
    eventManager.emit(EventTypes.UPDATE_GAMBLE_STAKE, setGambleStake(), false);
    this.spin(false);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: GameMode.BASE_GAME_GAMBLE,
    });
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.SONG_030_08_Win_Loop });
        AudioApi.play({ type: ISongs.SONG_030_10_BigWin_Loop });
      }
    });

    eventManager.addListener(EventTypes.SET_SLOT_BUSY_DISABLE, () => {
      this.isSlotBusyCallback();
    });

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));

    eventManager.addListener(EventTypes.FREE_SPIN, this.spin.bind(this));

    eventManager.addListener(EventTypes.REPLAY_SPIN, this.getReplayData.bind(this));

    eventManager.addListener(EventTypes.SET_STATE, this.setState.bind(this));
    eventManager.addListener(EventTypes.GAMBLE_CANCEL, this.onGambleCancel.bind(this));
    eventManager.addListener(EventTypes.GAMBLE_CHECK, this.checkGamble.bind(this));

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.setLastBaseGameReelPosition.bind(this));
  }

  private setLastBaseGameReelPosition(settings: { mode: GameMode; background?: BgSkin; isFromGamble?: boolean }): void {
    if (settings.isFromGamble) {
      // ここでリールを戻す
      const reelPos = setGambleEntryReelPosition();
      const reelSet = setSlotConfig().reels.find((reels) => reels.type === GameMode.BASE_GAME);
      const spinResult = getSpinResult3x1({
        reelPositions: reelPos,
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });
      setPrevReelsPosition(reelPos);
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.type === GameMode.BASE_GAME)!,
        reelPositions: reelPos,
      });
      eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
    }
  }

  private onGambleCancel(): void {
    if (!setIsAfterSpin()) {
      this.state = SlotMachineState.IDLE;
    }
  }

  private createFreeSpinsMessage(_props: MessageBonusBannerProps): void {
    //   // this.application.stage.addChild(new MessageBonusBanner(props).init());
    //   // AudioApi.play({ type: ISongs.BB_Banner }); // TODO
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
    this.nextResultFlg = true;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      debugDisplay('----- settings.mode', settings.mode);
      setGameMode(settings.mode);

      if (settings.mode === GameMode.BASE_GAME) {
        setReelSetId(settings.reelSetId);

        const reelSet = setSlotConfig().reels.find((reels) => reels.type === GameMode.BASE_GAME);
        const spinResult = getSpinResult3x1({
          reelPositions: settings.reelPositions.slice(0, 9),
          reelSet: reelSet!,
          icons: setSlotConfig().icons,
        });
        setPrevReelsPosition(settings.reelPositions.slice(0, 9));

        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: setSlotConfig().reels.find((reels) => reels.type === GameMode.BASE_GAME)!,
          reelPositions: settings.reelPositions,
        });
        eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

        setCurrentFreeSpinsTotalWin(0);
        this.isSpinInProgressCallback();
      }
    }
    if (settings.mode === GameMode.BASE_GAME) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
      });
      debugDisplay('setCurrentBonus1', setCurrentBonus());
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
    } else if (settings.mode === GameMode.FREE_SPIN) {
      // const bonus = this.getFreeSpinBonus();
      // console.log('bonus', bonus);

      // // todo replace with normal error
      // if (!bonus) throw new Error('Something went wrong');
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);

      debugDisplay('setCurrentBonus2', setCurrentBonus());
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
        text: 'freeSpinsTitleText',
        spins: setCurrentBonus().rounds,
        currentSpin: 0,
      });

      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.START_SIGN_WATCH_OVER);
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: i18n.t('freeSpinBonus'),
          description1: i18n.t('freeSpinBonusText1'),
          btnText: i18n.t('startText'),
          callback: () => {
            eventManager.emit(EventTypes.FREE_SPIN_WATCH_OVER);
            eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
            setCurrentStage(0);

            debugDisplay('setCurrentBonus3', setCurrentBonus());
          },
        });
      } else {
        debugDisplay('setCurrentBonus4', setCurrentBonus());

        this.setState(SlotMachineState.IDLE);

        // eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
        setCurrentStage(0);
        debugDisplay('setCurrentBonus5', setCurrentBonus());
      }
    }
  }

  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    debugDisplay('setCurrentBonus', setCurrentBonus());
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.FREE_SPIN,
      reelPositions: [1, 1, 1],
      reelSetId: reelSets[GameMode.FREE_SPIN]!,
      fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
      fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
    });
    setTimeout(() => {
      if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      } else {
        eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      }
    }, FREE_SPINS_FADE_IN_DURATION);
  }

  private async endFreeSpins(): Promise<void> {
    const { reelPositions, reelSetId } = {
      reelPositions: setCurrentBonus().originalReelPositions,
      reelSetId: ReelId.BASE_GAME,
    };

    this.reelPositions = reelPositions;

    debugDisplay('setCurrentFreeSpinsTotalWin()', setCurrentFreeSpinsTotalWin());
    debugDisplay('setFreeSpinsTotalWin()', setFreeSpinsTotalWin());

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();
    AudioApi.play({ type: ISongs.SONG_030_04_TotalWin, stopPrev: true });

    debugDisplay('========================1');
    this.setEndingDisplay(reelPositions, reelSetId);
    setBrokenGameBgm(false);
    setBrokenGame(false);
  }

  private setEndingDisplayCallback(): void {
    if (this.endingDisplay && this.nextResultFlg) {
      this.endingDisplay = false;
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.BASE_GAME,
        reelSetId: this.reelSetId!,
        fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
        fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
        reelPositions: this.reelPositions,
      });
      this.reelSetId = null;
      this.reelPositions = [0, 0, 0];
      setTimeout(() => {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency())),
        );
        if (isBaseGameMode(setGameMode()) && !setIsAutoSpins()) {
          //
        }
      }, FREE_SPINS_FADE_IN_DURATION);
    }
  }

  private setEndingDisplay(reelPositions: number[], reelSetId: string): void {
    this.reelPositions = reelPositions;
    this.reelSetId = reelSetId;

    const callback = () => {
      this.endingDisplay = true;
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);

      const winAmountFreeSpin =
        (SlotMachine.getInstance().nextResult!.bet.wager.wagerStorage.paidWinCoinAmount +
          SlotMachine.getInstance().nextResult!.bet.wager.wagerStorage.previousTotalWinCoinAmount) /
        setBetAmount();

      // FS終了
      if (setGameMode() === GameMode.FREE_SPIN && setCurrentBonus().roundsPlayed === 10) {
        // フリースピン終了し、Betの50倍以上を獲得した
        if (winAmountFreeSpin >= 50) {
          calAvatarTension(3);
          setAvatarStatusControl(StatusControlFlg.GET);
        }
        // フリースピン終了し、Betの20倍未満を獲得した
        else if (winAmountFreeSpin < 20) {
          calAvatarTension(-1);
          setAvatarStatusControl(StatusControlFlg.ZANNEN);
        }
      }

      this.setEndingDisplayCallback();
    };
    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    eventManager.emit(EventTypes.SET_SLOT_BUSY_DISABLE);
    eventManager.emit(EventTypes.BONUS_END);
    debugDisplay(
      '看板 setFreeSpinsTotalWin',
      setFreeSpinsTotalWin(),
      'normalizeCoins(setFreeSpinsTotalWin())',
      normalizeCoins(setFreeSpinsTotalWin()),
      'setCoinAmount()',
      setCoinAmount(),
    );

    bgmControl.fadeOutAll(100);
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )} `,
        preventDefaultDestroy: true,
        callback,
        title: i18n.t('TotalWinTitle'),
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency()))}`,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: i18n.t('TotalWinTitle'),
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isNumber(arg: any): arg is number {
    return typeof arg === 'number';
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(): void {
    for (let i = 0; i < 9; i++) {
      this.reelsContainer[i]!.reels[0]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
    }
  }

  public spin(isTurboSpin: boolean | undefined): void {
    for (let i = 0; i < 9; i++) {
      this.reelsContainer[i]!.forcedStop = false;
    }

    if (this.state === SlotMachineState.GAMBLE_SPIN) {
      eventManager.emit(EventTypes.SPIN_OK);
      this.isReadyForStop = false;
      this.nextResult = null;
      this.dynamicReelSetChange();
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      const spinAnimation = this.getSpinAnimation(!isTurboSpin);
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          this.removeErrorHandler();
          this.dynamicReelSetChange();
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.getStateSnapshotFromResult()?.stateSnapshot.reelPosition!,
            this.getStopSoundSymbolCount(this.nextResult.bet.spinResult),
            this.getAnticipationStartReelId(this.nextResult.bet.spinResult),
          );
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;

      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      const spinAnimation = this.getSpinAnimation(!!isTurboSpin);
      spinAnimation.start();
      this.setState(SlotMachineState.SPIN);

      setIsSlotStatueIdle(false);

      if (isFreeSpinsMode(setGameMode())) {
        debugDisplay('IDLE currentRound', setCurrentBonus());
        const bonus = setCurrentBonus();
        bonus.roundsPlayed += 1;
        eventManager.emit(EventTypes.UPDATE_FREE_SPINS_COUNT, setCurrentBonus().rounds, bonus.roundsPlayed, false);
        setCurrentBonus(bonus);
      }
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }

    if (this.state === SlotMachineState.STOP) {
      debugDisplay(red + ' == spin setNextResult()!.bet.result.spinResult', setNextResult()!.bet.spinResult);
    }
  }

  private getReplayData = async (replayBet: IBet) => {
    if (setReplaySpin() || setIsReplay()) return;
    setReplaySpin(true);
    setIsReplay(true);
    const date = new Date(replayBet.createdAt).valueOf() + 1;
    const ISODate = new Date(date).toISOString();
    const {
      data: { betHistory },
    } = await client.query<{ betHistory: ISlotHistoryData }>({
      query: slotHistoryGql,
      variables: {
        input: { limit: 1, before: ISODate, slotId: setSlotConfig().slotId },
      },
      fetchPolicy: 'no-cache',
    });
    if (betHistory.bets[0]) {
      this.replaySpin(betHistory.bets[0]);
    } else {
      console.log('replay data non');
    }
  };

  private replaySpin(replayBet: IBet): void {
    // if (setReplaySpin() || setIsReplay()) return;
    // setReplaySpin(true);
    // setIsReplay(true);
    setCurrentStage(0);
    this.isStopped = false;
    this.isReadyForStop = false;
    this.nextResult = null;

    const replayResult = getReplayResult(replayBet);
    const wager = replayResult.bet.wager;
    const gameMode = wager.wagerSettings.gameMode;

    eventManager.emit(
      EventTypes.START_FADE,
      () => {
        eventManager.emit(EventTypes.SET_REPLAY_TEXT_VISIBILITY, true);
        eventManager.emit(EventTypes.START_SPIN_ANIMATION);
        this.skipAnimations();
        eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
        const spinAnimation = this.getSpinAnimation(false);

        if (isFreeSpinsMode(gameMode)) {
          eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, { mode: setGameMode() });
          eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
            text: 'freeSpinsTitleText',
            spins: wager.wagerSettings.rounds,
            currentSpin: wager.wagerStorage.roundsPlayed,
          });
        }
        AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
        spinAnimation.start();

        this.setState(SlotMachineState.SPIN);
      },
      () => {
        this.setResult(replayResult);
      },
    );
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      for (let y = 0; y < 9; y++) {
        if (isFreeSpinsMode(setGameMode()) && y === 4) continue;

        const reel = this.reelsContainer[y]!.reels[i]!;
        const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

        if (i === 0) {
          spinAnimation.getFakeRolling().addOnChange(() => {
            if (this.nextResult && !this.isReadyForStop) {
              this.isReadyForStop = true;
              if (!isGambleMode(setGameMode())) {
                this.removeErrorHandler();
              }
              this.dynamicReelSetChange();
              eventManager.emit(
                EventTypes.SETUP_REEL_POSITIONS,
                this.getStateSnapshotFromResult()?.stateSnapshot.reelPosition!,
                this.getStopSoundSymbolCount(this.nextResult.bet.spinResult),
                this.getAnticipationStartReelId(this.nextResult.bet.spinResult),
              );
            }
          });
          // TODO 暫定対応
          if (!isGambleMode(setGameMode())) {
            spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
          }
        }
        this.reelsContainer[y]!.reels[i]!.isPlaySoundOnStop = true;

        spinAnimation.addOnComplete(() => {
          // debugDisplay('setPlayer()', setPlayer()); コメントアウト中
          let index = 0;
          if (isGambleMode(setGameMode())) {
            switch (setPlayer()) {
              case caseFn.selectReel1:
                index = 6;
                break;
              case caseFn.selectReel2:
                index = 7;
                break;
              case caseFn.selectReel3:
                index = 8;
                break;
              default:
                break;
            }
            if (y === index) {
              eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);
            }
          }
        });

        if (!this.nextResult) {
          spinAnimation.addOnComplete(() => {
            if (!isFreeSpinGameMode(setGameMode()) && y === 4) {
              // ナッジ関連
              const { nudge } = setNudgeList();
              if (nudge === NudgeType.NON || nudge === NudgeType.SUCCESS_NON) {
                if (!isGambleMode(setGameMode())) {
                  eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);
                }
              }
            } else if (isFreeSpinsMode(setGameMode()) && y === 2) {
              eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);
            }
          });
        }
        // }
        animationGroup.addAnimation(spinAnimation);
      }
    }

    return animationGroup;
  }

  public isTriggerFs(): boolean {
    return (
      (!setCurrentBonus().isActive ||
        (setCurrentBonus().isActive &&
          (setCurrentBonus().gameMode === GameMode.BUY_FEATURE ||
            (setCurrentBonus().gameMode === GameMode.FREE_SPIN && setCurrentBonus().roundsPlayed === 0)))) &&
      this.nextResult != null &&
      this.nextResult!.bet.outcomes[0]!.stateSnapshot.fieldState[4] === SlotId.WL &&
      (this.nextResult!.bet.wager.wagerStorage.roundsPlayed === null ||
        this.nextResult!.bet.wager.wagerStorage.roundsPlayed === 0) &&
      !setIsReplay()
    );
  }

  public isTriggerFsReplay(): boolean {
    return (
      (!setCurrentBonus().isActive ||
        (setCurrentBonus().isActive &&
          (setCurrentBonus().gameMode === GameMode.BUY_FEATURE ||
            (setCurrentBonus().gameMode === GameMode.FREE_SPIN && setCurrentBonus().roundsPlayed === 0)))) &&
      this.nextResult != null &&
      this.nextResult!.bet.outcomes[0]!.stateSnapshot.fieldState[4] === SlotId.WL &&
      (this.nextResult!.bet.wager.wagerStorage.roundsPlayed === null ||
        this.nextResult!.bet.wager.wagerStorage.roundsPlayed === 0)
    );
  }

  private onCountUpEnd(): void {
    const freeSpinsBonus = this.isTriggerFs();
    const mode = setGameMode();

    if (mode === GameMode.BASE_GAME || mode === GameMode.BASE_GAME_GAMBLE) {
      setWinAmount(
        isGambleMode(setGameMode())
          ? setGambleStake() === 0
            ? 0
            : setGambleStake()
          : this.nextResult!.bet.betStorage.estimatedWinCoinAmount,
      );
    }

    if (freeSpinsBonus && isBaseGameMode(mode)) {
      if (setIsReplay()) {
        this.setState(SlotMachineState.IDLE);
        return;
      }
      debugDisplay('ボーナス揃い', this.nextResult?.bet.betStorage.estimatedWinCoinAmount);

      setLastRegularWinAmount(this.nextResult?.bet.betStorage.estimatedWinCoinAmount);
      setCurrentFreeSpinsTotalWin(this.nextResult?.bet.betStorage.estimatedWinCoinAmount);
      setCurrentBonus({
        packageId: GameMode.FREE_SPIN,
        gameMode: GameMode.FREE_SPIN,
        rounds: 10,
        roundsPlayed: 0,
        state: BonusStatus.ACTIVE,
        coinAmount: this.nextResult!.bet.coinAmount,
        coinValue: this.nextResult!.bet.coinValue,
        originalReelPositions: this.nextResult!.bet.outcomes[0]?.stateSnapshot.reelPosition!,
        isBuyFeature: false,
        isActive: true,
      });
      debugDisplay('setCurrentBonus6', setCurrentBonus());

      this.setState(SlotMachineState.IDLE);
      this.startFreeSpins();
    } else if (!freeSpinsBonus && isFreeSpinsMode(mode)) {
      debugDisplay('setCurrentBonus()', setCurrentBonus());
      debugDisplay(' setCoinAmount()', setCoinAmount(), 'setCoinValue()', setCoinValue());
      debugDisplay('this.nextResult!.bet.result.winCoinAmount', this.nextResult?.bet.betStorage.estimatedWinCoinAmount);
      setCurrentFreeSpinsTotalWin(
        this.nextResult!.bet.wager.wagerStorage.totalWinCoinAmount +
          this.nextResult!.bet.wager.wagerStorage.previousTotalWinCoinAmount,
      );
      debugDisplay('ボーナス中 setCurrentFreeSpinsTotalWin()', setCurrentFreeSpinsTotalWin());
      if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }

      // debugDisplay('--onCountUpEnd2', performance.now());
      this.setState(SlotMachineState.IDLE);
    } else {
      setLastRegularWinAmount(this.nextResult?.bet.betStorage.estimatedWinCoinAmount);
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      if (isFreeSpinGameMode(mode)) {
        debugDisplay(
          '====== setCurrentFreeSpinsTotalWin()',
          setCurrentFreeSpinsTotalWin(),
          'this.nextResult!.bet.result.winCoinAmount',
          this.nextResult?.bet.betStorage.estimatedWinCoinAmount,
        );
        setCurrentFreeSpinsTotalWin(
          this.nextResult!.bet.wager.wagerStorage.totalWinCoinAmount +
            this.nextResult!.bet.wager.wagerStorage.previousTotalWinCoinAmount,
        );
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
      this.setState(SlotMachineState.IDLE);
    }

    // TODO フリースピン終わりもここに入る予定
    if (isBaseGameMode(setGameMode())) {
      // eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      //　ここコメントアウト予定S8W-83
      // eventManager.emit(EventTypes.GAMBLE_CHECK); //S8W-83
    }
    if (isGambleMode(setGameMode())) {
      let nextActionDelay: Animation; //= Tween.createDelayAnimation(1000);
      if (setGambleStakeResult() === gambleResult.loss || setGambleStakeResult() === gambleResult.limit) {
        setGambleStake(0);
        setIsCollected(true);
        nextActionDelay = Tween.createDelayAnimation(2000);
        nextActionDelay.addOnComplete(() => {
          eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, true);
          eventManager.emit(EventTypes.DISABLE_GAMBLE_BTN, true);
          eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
          if (!setIsReplayGamble()) {
            setGameMode(GameMode.BASE_GAME);
            eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
              mode: GameMode.BASE_GAME,
              gambleStake: 0,
              isFromGamble: true,
            });
            bgmControl.fadeInVolume(0);
          } else {
            setIsReplayGamble(false);
          }
          this.setState(SlotMachineState.IDLE);
        });
      } else {
        this.setState(SlotMachineState.IDLE);
        bgmControl.fadeInVolume(100);
        nextActionDelay = Tween.createDelayAnimation(0);
        nextActionDelay.addOnComplete(() => {
          // 次のダミースピンへ
          if (setIsReplayGamble()) {
            gambleReplay();
          } else {
            eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, false);
            eventManager.emit(EventTypes.DISABLE_GAMBLE_BTN, false);
            nextGambleSpin();
          }
        });
      }
      nextActionDelay.start();
    }
  }

  private checkGamble() {
    if (!isFreeSpinGameMode(setGameMode()) && (!setIsCollected() || setIsReplay())) {
      /// GAMBLE突入時のリールpositionを保持
      setGambleEntryReelPosition(setPrevReelsPosition());
      if (!setIsCollected() && !setIsReplay()) {
        setGambleStake(
          this.nextResult
            ? this.nextResult.bet.betStorage.estimatedWinCoinAmount
            : setNextResult()!.bet.betStorage.estimatedWinCoinAmount,
        );
        if (!setSpinWithAutoSpin() && !isGambleMode(setGameMode())) {
          eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, false);
          eventManager.emit(EventTypes.DISABLE_GAMBLE_BTN, false);
          eventManager.emit(EventTypes.UPDATE_GAMBLE_STAKE, setGambleStake(), false);
        } else if (isGambleMode(setGameMode())) {
          eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, false);
          eventManager.emit(EventTypes.GAMBLE_PLAYER_CLEAR);
          eventManager.emit(EventTypes.UPDATE_GAMBLE_STAKE, setGambleStake(), false);
        }
      } else {
        if (!SlotMachine.getInstance().isTriggerFsReplay()) {
          setIsReplayGamble(true);
        }
        setReplayGambleCount(0);
        setGambleStake(this.nextResult!.bet.betStorage.estimatedWinCoinAmount);
        eventManager.emit(EventTypes.UPDATE_GAMBLE_STAKE, setGambleStake(), false);
        gambleReplay();
      }
    }
  }

  public dynamicReelSetChange(): void {
    if (setReelSetId() !== reelSets[setGameMode()]) {
      const reel = isFreeSpinsMode(setGameMode())
        ? this.nextResult!.bet.outcomes[0]!.stateSnapshot.reelPosition
        : [0, 0, 0, 0, 0, 0, 0, 0, 0];
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.type === GameMode.BASE_GAME)!,
        reelPositions: reel,
      });
      if (!setIsReplay()) {
        setReelSetId(setGameMode());
      }
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
    setIsBuyFeaturePurchased(false);
  }

  private getAnticipationStartReelId(spinResult: Icon[]): number {
    if (isGambleMode(setGameMode())) return 1;
    setReelAnticipation(ReelAnticipation.NON);
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;

    const lineResult = this.slotConfig.lines.map((line) => line.map((v) => spinResult[v]));

    if (lineResult.length === 0) {
      return 0;
    }

    const anticipationLine: number[] = [];
    const anticipationSlotId: SlotId[] = [];

    ANTICIPATION_SYMBOLS_ID.forEach((symbolId, i) => {
      const count = ANTICIPATION_START_SYMBOLS_AMOUNT[i]!;

      lineResult.some((line, index) => {
        if (line[0] != undefined && line[1] != undefined) {
          if (line[0]!.id === symbolId && line[1]!.id === symbolId) {
            setReelAnticipation(ReelAnticipation.BONUS);
            minReelId = Math.min(minReelId, count);
            anticipationLine.push(index);
            anticipationSlotId.push(symbolId);
          }
        }
      });
    });

    // Total Betの200倍を超えるWINを獲得するスピン
    if (
      isBaseGameMode(setGameMode()) &&
      setNextResult()!.bet.betStorage.estimatedWinCoinAmount / setBetAmount() >= PHOENIX_DIRECTION_TOTAL_BET
    ) {
      const AnimationPtn = getAnimationSelectionTableLot(setNextResult()!.bet.id, baseGamePhoenix);
      debugDisplay(
        'Total Betの200倍越え',
        AnimationPtn,
        setNextResult()!.bet.betStorage.estimatedWinCoinAmount / setBetAmount(),
      );

      if (AnimationPtn === AnimationType.PHOENIX) {
        eventManager.emit(EventTypes.PHOENIX_START);
      }
    }

    setAnticipationLine(anticipationLine);
    setAnticipationSlotId(anticipationSlotId);

    return minReelId;
  }

  private getStopSoundSymbolCount(spinResult: Icon[]): number[] {
    let reelStopSound = [0, 0, 0];
    if (isBaseGameMode(setGameMode())) {
      reelStopSound = this.calcStopSoundSymbolCount(spinResult, 1, BONUS_SYMBOLS_ID);
    }
    return reelStopSound;
  }

  private calcStopSoundSymbolCount(_spinResult: Icon[], _cal: number, _symbol: SlotId[][]): number[] {
    const iSoundCnt = [0, 0, 0];
    return iSoundCnt;
  }

  public skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (!setIsDuringWinCountUpAnimation()) {
      if (this.state === SlotMachineState.IDLE || this.state === SlotMachineState.GAMBLE_SPIN) {
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      }
    }
  }

  public setResult(result: ISettledBet): void {
    this.nextResultFlg = true;
    const reelSet = setSlotConfig().reels.find((reelSet) => reelSet.type === result.bet.wager.wagerSettings.gameMode);
    const spinResult = getSpinResult3x1({
      reelPositions: result.bet.outcomes[0]!.stateSnapshot.reelPosition.slice(0, 9), // REELS_AMOUNT
      reelSet: reelSet || setSlotConfig().reels[0]!,
      icons: setSlotConfig().icons,
    });
    result.bet.spinResult = spinResult;
    if (!setIsReplay()) {
      setPrevReelsPosition(result.bet.outcomes[0]!.stateSnapshot.reelPosition); // .slice(0, REELS_AMOUNT));
    }
    this.nextResult = result;
    this.nextResultPrev = result;
    setNextResult(result);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult.balance.placed);
  }

  public setGambleResult(result: ISettledBet): void {
    setGambleStake(result.bet.betStorage.estimatedWinCoinAmount);
    this.nextResultFlg = true;
    this.nextResult = this.nextResultPrev != null ? this.nextResultPrev : setNextResult();

    const gambleResult = makeGambleResult(
      result.bet,
      this.nextResult!.bet,
      result.balance.settled.amount,
      result.balance.settled.currency,
      result.balance.placed.amount,
      result.balance.placed.currency,
      this.nextResult!.winCoinAmount,
    );
    setNextResult(gambleResult);
    this.nextResult = gambleResult;

    if (!setIsReplay()) {
      setPrevReelsPosition(gambleResult.bet.outcomes[0]!.stateSnapshot.reelPosition.slice(0, 9)); // REELS_AMOUNT
    }
    setNextResult(this.nextResult!);
  }

  public clearNextResult(): void {
    this.nextResultFlg = false;
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    if (setBrokenBuyFeature()) {
      setBrokenBuyFeature(false);
    }
    this.isSpinInProgressCallback();
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    debugDisplay(red + 'STOP.StopSpin', SlotMachine.getInstance().state);
    const state = SlotMachine.getInstance().state;
    if (state === SlotMachineState.SPIN || state === SlotMachineState.STOP) this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer[0]!.reels[x]!.slots[
      (2 * this.reelsContainer[0]!.reels[x]!.data.length - this.reelsContainer[0]!.reels[x]!.position + y - 1) %
        this.reelsContainer[0]!.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    eventManager.emit(EventTypes.RESIZE_UI_BUTTON, width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;

    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinGameMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  public hasWin() {
    if (this.nextResult === null) {
      return false;
    }
    return this.nextResult!.bet.betStorage.estimatedWinCoinAmount > 0;
  }

  public gambleWin() {
    if (!isGambleMode(setGameMode())) {
      return false;
    }
    return setGambleStake() > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, state !== SlotMachineState.IDLE || this.isTriggerFs());

    if (state === SlotMachineState.SPIN) {
      eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, true);
      eventManager.emit(EventTypes.DISABLE_GAMBLE_BTN, true);
    }

    if (state === SlotMachineState.IDLE) {
      if (setIsReplay()) {
        const isLimit =
          setReplayResult()!.bet.wager.wagerStorage.gambleOutcomeStorage != null &&
          setGambleStakeResult() === gambleResult.win &&
          setReplayResult()!.bet.wager.wagerStorage.gambleOutcomeStorage.length === setReplayGambleCount();
        const isLoss =
          setReplayResult()!.bet.wager.wagerStorage.gambleOutcomeStorage != null &&
          setGambleStakeResult() === gambleResult.loss &&
          setReplayResult()!.bet.wager.wagerStorage.gambleOutcomeStorage.length === setReplayGambleCount();

        const isCollect =
          setReplayResult() != null && setReplayResult()!.bet.wager.wagerStorage.gambleOutcomeStorage === null
            ? true
            : setReplayResult()!.bet.wager.wagerStorage.gambleOutcomeStorage[setReplayGambleCount()]?.predicament
                .caseFn === caseFn.closeGamble
            ? true
            : false;

        if (!setIsReplayEnd() && (isCollect || isLimit || isLoss)) {
          setIsReplayEnd(true);
          const delay = Tween.createDelayAnimation(REPLAY_END_DELAY);
          delay.addOnComplete(() => {
            setReplayEnd(true);
          });
          delay.start();
        }
      }

      setReplaySpin(false);
      this.isSlotBusyCallback();

      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      if (isFreeSpinsMode(setGameMode()) && !setIsReplay()) {
        if (setCurrentBonus().rounds === setCurrentBonus().roundsPlayed) {
          // setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          debugDisplay('setCurrentBonus7', setCurrentBonus());

          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().roundsPlayed === 0 ? 0 : 500,
          );
        }
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      setIsSlotStatueIdle(true);
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.isTriggerFs()) {
        const jingleWait = Tween.createDelayAnimation(1000);
        jingleWait.addOnComplete(() => {
          const jingleDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SONG_030_06_FS_Trigger]!.duration);
          jingleDelay.addOnStart(() => {
            AudioApi.play({ type: ISongs.SONG_030_06_FS_Trigger, stopPrev: true });
          });
          jingleDelay.addOnComplete(() => {
            this.setState(SlotMachineState.WINNING);
          });
          jingleDelay.start();
        });
        jingleWait.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }

      if (isGambleMode(setGameMode())) {
        eventManager.emit(EventTypes.GAMBLE_REACTION);
      }
    }
    if (state === SlotMachineState.WINNING) {
      setIsPhoenix(false);
      eventManager.emit(EventTypes.SPIN_END);

      if (this.hasWin() || this.gambleWin()) {
        if (isGambleMode(setGameMode())) {
          let delay = Tween.createDelayAnimation(0);
          if (isSpecialBonus(this.nextResult!.bet.spinResult)) {
            AudioApi.play({ type: ISongs.SONG_030_20_GambleSP, stopPrev: true });
            delay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SONG_030_06_FS_Trigger]!.duration);
          } else if (setGambleStakeResult() === gambleResult.win || setGambleStakeResult() === gambleResult.limit) {
            AudioApi.play({ type: ISongs.SONG_030_18_GambleWin, stopPrev: true });
            delay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SONG_030_06_FS_Trigger]!.duration);
          } else if (setGambleStakeResult() === gambleResult.draw) {
            AudioApi.play({ type: ISongs.SONG_030_19_GamblePush, stopPrev: true });
            delay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SONG_030_06_FS_Trigger]!.duration);
          }

          eventManager.emit(EventTypes.UPDATE_GAMBLE_STAKE, setGambleStake(), false);
          setWinAmount(setGambleStake());

          delay.addOnStart(() => {
            bgmControl.fadeOutAll(0);
            eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, [], 0);
            if (isSpecialBonus(this.nextResult!.bet.spinResult)) {
              eventManager.emit(
                EventTypes.WIN_ANIMATION_DIVIDEND_TABLE,
                setSpecialBonusIcon(),
                setSpecialBonusAny7Mix(),
              );
            }
          });
          delay.addOnComplete(() => {
            this.onCountUpEnd();
          });
          delay.start();
        } else {
          const winAmount = this.nextResult!.bet.betStorage.estimatedWinCoinAmount;
          eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, [], winAmount);
        }
      } else {
        if (isGambleMode(setGameMode())) {
          eventManager.emit(EventTypes.UPDATE_GAMBLE_STAKE, setGambleStake(), false);
        }
        if (!isFreeSpinGameMode(setGameMode())) {
          eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
        }
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
