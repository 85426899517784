import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { setFlashFlg, setGameMode } from '../../../gql/cache';
import { ResourceTypes } from '../../../resources.d';
import {
  debugDisplay,
  filterBaseReelFrame,
  filterGambleSpinReelFrame1,
  filterGambleSpinReelFrame2,
  isGambleMode,
} from '../../../utils';
import ViewContainer from '../../components/container';
import {
  REELS_FRAME_ADJUSTMENT,
  SLOTS_BASE_GAME_ADJUST,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  Z_INDEX_REELS_FRAME,
  eventManager,
} from '../../config';

class ReelsFrame extends ViewContainer {
  private baseFrameTex = PIXI.Texture.from(ResourceTypes.reelframe);

  private frame: PIXI.Sprite[] = [];

  private isLandScape = true;

  constructor() {
    super();

    for (let i = 0; i < 9; i++) {
      this.frame[i] = this.initFrame();

      this.frame[i]!.x = 150 + this.frame[i!]!.width * Math.floor(i % 3) + Math.floor(i % 3) - (i % 3 != 0 ? 1 : 0);
      this.frame[i]!.y = 193 + this.frame[i!]!.height * Math.floor(i / 3) + Math.floor(i / 3);

      this.addChild(this.frame[i]!);
    }

    this.frame[6]!.on('click', () => this.onClick(6));
    this.frame[7]!.on('click', () => this.onClick(7));
    this.frame[8]!.on('click', () => this.onClick(8));

    this.frame[6]!.on('touchstart', () => this.onClick(6));
    this.frame[7]!.on('touchstart', () => this.onClick(7));
    this.frame[8]!.on('touchstart', () => this.onClick(8));

    this.zIndex = Z_INDEX_REELS_FRAME;
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.on(EventTypes.DISABLE_COLLECT_BTN, (disable: boolean) => {
      if (disable) {
        this.changeClearFilter(this.frame[6]!, this.frame[7]!, this.frame[8]!);
      }
    });
    eventManager.addListener(EventTypes.REEL_FRAME, this.setFrameFilter.bind(this));
  }

  private onClick(index: number): void {
    debugDisplay('index', index);
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      if (isGambleMode(setGameMode())) {
        this.y = -80;
      } else {
        this.y = 0;
      }
      this.isLandScape = true;
    } else {
      if (isGambleMode(setGameMode())) {
        this.y = -220;
      } else {
        this.y = -120;
      }
      this.isLandScape = false;
    }
  }

  private changeFilter(object1: PIXI.Container, object2: PIXI.Container, object3: PIXI.Container): void {
    if (!setFlashFlg()) {
      object1.filters = [filterGambleSpinReelFrame2()];
      object2.filters = [filterGambleSpinReelFrame2()];
      object3.filters = [filterGambleSpinReelFrame2()];
    } else {
      object1.filters = [filterGambleSpinReelFrame1()];
      object2.filters = [filterGambleSpinReelFrame1()];
      object3.filters = [filterGambleSpinReelFrame1()];
    }
  }

  private changeClearFilter(object1: PIXI.Container, object2: PIXI.Container, object3: PIXI.Container): void {
    object1.filters = [filterBaseReelFrame()];
    object2.filters = [filterBaseReelFrame()];
    object3.filters = [filterBaseReelFrame()];
  }

  private setFrameFilter(): void {
    this.changeFilter(this.frame[6]!, this.frame[7]!, this.frame[8]!);
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isGambleMode(settings.mode)) {
      this.frame[0]!.visible = false;
      this.frame[1]!.visible = false;
      this.frame[2]!.visible = false;
      this.frame[3]!.visible = false;
      this.frame[5]!.visible = false;
      this.position.y = this.isLandScape ? -80 : -220;
    } else {
      this.changeClearFilter(this.frame[6]!, this.frame[7]!, this.frame[8]!);

      this.frame[0]!.visible = true;
      this.frame[1]!.visible = true;
      this.frame[2]!.visible = true;
      this.frame[3]!.visible = true;
      this.frame[5]!.visible = true;
      this.visible = true;
      this.position.y = 0;

      this.y = this.isLandScape ? 0 : -120;
    }
  }

  private initFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(this.baseFrameTex);
    frame.anchor.set(0.5, 1);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT);
    frame.zIndex = 10;
    this.setPosition(frame);

    debugDisplay(' --------------- ', SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT);
    frame.y = SLOTS_CONTAINER_HEIGHT + SLOTS_BASE_GAME_ADJUST;
    debugDisplay('frame.y', frame.y, SLOTS_CONTAINER_HEIGHT, SLOTS_BASE_GAME_ADJUST);
    return frame;
  }

  private setPosition(frame: PIXI.Sprite): void {
    frame.anchor.set(0.5, 1);
    frame.position.set(
      SLOTS_CONTAINER_WIDTH / 2, //
      SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT,
    );

    frame.zIndex = 10;
  }
}

export default ReelsFrame;
