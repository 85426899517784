import { ITextStyle, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { filterDisable, filterMouseDown, filterMouseOver } from '../../utils';

export enum SpriteButtonState {
  DEFAULT,
  HOVER,
  PRESSED,
  DISABLED,
  ACTIVE,
}
export interface SpriteButtonProps {
  texture: Texture;
  onClick?: () => void;
  onHover?: () => void;
  onPointerOut?: () => void;
}
export class SpriteButton extends Sprite {
  protected state: SpriteButtonState = SpriteButtonState.DEFAULT;

  protected isPointerDown = false;

  protected onClick: (() => void) | undefined;

  protected onHover: (() => void) | undefined;

  protected onPointerOut: (() => void) | undefined;

  protected onTouchStart: (() => void) | undefined;

  protected textStyles?: { [key in SpriteButtonState]?: Partial<ITextStyle> };

  constructor(props: SpriteButtonProps) {
    super();
    this.interactive = true;
    this.buttonMode = true;
    this.texture = props.texture;
    this.onHover = props.onHover;
    this.onClick = props.onClick;
    this.onPointerOut = props.onPointerOut;
    this.changeState(SpriteButtonState.DEFAULT);
    this.on('pointerover', this.onPointerOverCallback.bind(this));
    this.on('pointerout', this.onPointerOutCallback.bind(this));
    this.on('pointerdown', this.onPointerDownCallback.bind(this));
    this.on('pointertap', this.onPointerTapCallback.bind(this));
    this.on('pointerupoutside', this.onPointerUpOutSideCallback.bind(this));
  }

  protected onPointerOverCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    if (this.isPointerDown) return;
    this.changeState(SpriteButtonState.HOVER);
    AudioApi.play({ type: ISongs.SONG_SFX_UI_Hover });
    if (this.onHover) {
      this.onHover();
    }
  }

  protected onPointerOutCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    if (this.isPointerDown) return;
    this.changeState(SpriteButtonState.DEFAULT);
    if (this.onPointerOut) {
      this.onPointerOut();
    }
  }

  protected onPointerDownCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.isPointerDown = true;
    this.changeState(SpriteButtonState.PRESSED);
  }

  protected onPointerTapCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.isPointerDown = false;
    this.changeState(SpriteButtonState.DEFAULT);
    if (this.onClick) {
      this.onClick();
    }
  }

  protected onPointerUpOutSideCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.isPointerDown = false;
    this.changeState(SpriteButtonState.DEFAULT);
  }

  public disable(): void {
    this.changeState(SpriteButtonState.DISABLED);
    this.interactive = false;
  }

  public enable(): void {
    this.changeState(SpriteButtonState.DEFAULT);
    this.interactive = true;
  }

  protected changeState(state: SpriteButtonState): void {
    this.state = state;

    switch (state) {
      case SpriteButtonState.DEFAULT:
        this.filters = null;
        break;
      case SpriteButtonState.HOVER:
        this.filters = [filterMouseOver()];
        break;
      case SpriteButtonState.PRESSED:
        this.filters = [filterMouseDown()];
        break;
      case SpriteButtonState.DISABLED:
        this.filters = [filterDisable()];
        break;
      case SpriteButtonState.ACTIVE:
        this.filters = null;
        break;
      default:
        break;
    }
  }
}
