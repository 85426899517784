import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
// import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { Z_INDEX_BACKDROP2, eventManager } from '../config';

class Backdrop2 extends ViewContainer {
  // private backdrop = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.backdrop));
  // private backdrop = new PIXI.Graphics();
  private backdrop: PIXI.Container;

  constructor() {
    super();

    this.backdrop = new PIXI.Graphics().beginFill(0xffffff).drawRect(0, 0, 1800, 1800).endFill();

    this.addChild(this.backdrop);
    this.backdrop.visible = false;
    this.backdrop.alpha = 0.9;
    this.backdrop.interactive = true;
    this.expandBackdropOverScreen();
    this.zIndex = Z_INDEX_BACKDROP2;

    this.backdrop.on('click', () => {
      eventManager.emit(EventTypes.BACK_DROP_CLICK);
    });
    this.backdrop.on('touchstart', () => {
      eventManager.emit(EventTypes.BACK_DROP_CLICK);
    });

    eventManager.on(EventTypes.OPEN_MESSAGE_BANNER, () => {
      this.backdrop.visible = true;
      eventManager.emit(EventTypes.OPEN_POPUP);
    });
    eventManager.on(EventTypes.CLOSE_MESSAGE_BANNER, () => {
      this.backdrop.visible = false;
      eventManager.emit(EventTypes.CLOSE_POPUP);
    });
  }

  // Workaround to position backdrop over view in all landscapes
  public expandBackdropOverScreen(): void {
    this.backdrop.scale.set(4);
    this.x = -(this.backdrop.width / 2);
    this.y = -(this.backdrop.height / 2);
    this.backdrop.alpha = 0.0;
  }
}
export default Backdrop2;
